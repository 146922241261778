import React, {Component} from 'react';
import {connect} from 'react-redux';
import MissedOffer from "./MissedOffer";


class MissedOffers extends Component {
    render() {
        if(!this.props.cart?.photoGroups) {
            return null;
        }

        const missedOffers = this.props.cart.photoGroups.filter(photoGroup => photoGroup.missedOffer);

        if(missedOffers.length === 0) {
            return null;
        }

        return (
            <div className="p-4 bg-yellow text-center text-lg text-blue-darker">
                {
                    missedOffers.map((missedOffer) => <MissedOffer key={missedOffer.id} offer={missedOffer}/>)
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        cart: state.photomatch.cart,
    };
};

export default connect(mapStateToProps)(MissedOffers);