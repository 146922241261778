const INITIAL_STATE = {
    hotels: null,
    cruises: null,
    cruiseItinerary: null,
    flights: {flightsOut: null, flightsIn: null},
    passengers: null,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'CLEAR_ITINERARY':
            return INITIAL_STATE;

        case 'SET_ITINERARY_HOTELS':
            return { ...state, hotels: action.hotels};

        case 'SET_ITINERARY_CRUISES':
            return { ...state, cruises: action.cruises};

        case 'SET_ITINERARY_CRUISE_ITINERARY':
            return { ...state, cruiseItinerary: action.cruiseItinerary};

        case 'SET_ITINERARY_FLIGHTS':
            return { ...state, flights: action.flights};

        case 'SET_ITINERARY_PASSENGERS':
            return { ...state, passengers: action.passengers};

        default:
            return state;
    }
}
