import axios from 'axios';
import {
    CLEAR_AUDIO_ERROR,
    SET_AUDIO_ALBUMS,
    SET_AUDIO_ERROR,
    UPDATE_AUDIO_STREAM,
} from "../constants/audio-action-types";
import {REQUEST_FINISHED, REQUEST_STARTED} from "../constants/vod-action-types";
import {extractErrorMessage, getApiVersion, getDeviceUuid, getMaxicasterUrl, getToken} from "./vodActions";


export function clearAudiorror() {
    return {type: CLEAR_AUDIO_ERROR};
}

export function fetchAudioStreamsFromMaxicaster() {
    return async function (dispatch, getStore) {

        dispatch({type: SET_AUDIO_ALBUMS, audios: null});
        dispatch({type: REQUEST_STARTED});

        let url = getMaxicasterUrl(getStore()) + "vods/getVods&token="+getToken(getStore())+"&device_uuid="+getDeviceUuid(getStore(), dispatch)+"&api_version="+getApiVersion(getStore())+"&languages=eng";

        url += "&include=details,images&provider_ids=146&types=series&series_format=series_seasons&languages=eng";

        return axios({
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then(function (response) {
                if (response.data.code === 200) {
                    dispatch({type: SET_AUDIO_ALBUMS, audios: response.data.data});
                    dispatch({type: REQUEST_FINISHED});
                } else {
                    dispatch({type: SET_AUDIO_ERROR, errormessage: extractErrorMessage(response)});
                }
            })
            .catch(function (error) {
                dispatch({type: SET_AUDIO_ERROR, errormessage: error.message});
            });
    }
}

export function updateAudio(audio) {
    return async function (dispatch) {
        dispatch({ type: UPDATE_AUDIO_STREAM, audio: audio});
    }
}


