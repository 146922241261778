import React from 'react';
import {connect} from 'react-redux';
import {PropTypes} from 'prop-types';
import styled from 'styled-components';
import {formatItineraryDate, formatPortTime} from '../utils/dateTimeUtils';
import ItineraryHeader from "./ItineraryHeader";
import ItineraryDivider from "./ItineraryDivider";

class ItineraryFlights extends React.Component {
    render() {
        if (!this.props.flightsOut && !this.props.flightsIn) {
            return null;
        }

        return (
            <div>
                <ItineraryHeader title="Flights" icon="plane_45.svg"/>
                <FlightList
                    flights={this.props.flightsOut}/>
                <FlightList
                    flights={this.props.flightsIn}/>
                <ItineraryDivider />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        flightsOut: (state.itinerary.flights.flightsOut ? state.itinerary.flights.flightsOut: null),
        flightsIn: (state.itinerary.flights.flightsIn ? state.itinerary.flights.flightsIn: null),
    };
}

ItineraryFlights.contextTypes = {
    t: PropTypes.func.isRequired
};

export default connect(mapStateToProps) (ItineraryFlights);

const StyledFlight = styled.div`
    .flight-indicator {
        display: flex;
        align-items: center;
    }
    
    .border-center { 
        width: 100%;
        position: relative;
    }

    .border-center:before { content: '';
        position: absolute;

        border-bottom: 2px #092A5E dotted;
        width: 100%;
        z-index:0;
    }
    
    ul {
        margin: 0;
    }
    
    li {
        display: inline list-item;
        margin-right: 0.5rem;
    }
`;

class FlightList extends React.Component {
    render() {
        if(!this.props.flights) {
            return null;
        }

        return (
            <div className="margin-bottom-1">
                <div className="text-uppercase">{this.props.headerText}</div>
                    {this.props.flights.map(flight => {
                        return (
                            <div key={flight.id}>
                                <StyledFlight className="ml-6">
                                    {/* airport codes*/}
                                    <div className="flight-indicator mb-2 text-base lh-1">
                                        <div className="font-bold mr-4">{flight.departureAirportCode}</div>
                                        <div className="border-center" style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}><img className="w-4 h-4" src={require('../resources/images/itinerary_icons/plane_straight.svg')} alt="" /></div>
                                        <div className="font-bold ml-4">{flight.arrivalAirportCode}</div>
                                    </div>

                                    {/* airport names */}
                                    <div className="mb-1">
                                        {flight.departureAirportName} - {flight.arrivalAirportName}
                                    </div>

                                    {/* time and flight details*/}
                                    <div>
                                        <ul>
                                            <li>{formatItineraryDate(flight.departureTime)}</li>
                                            <li>{formatPortTime(flight.departureTime)} - {formatPortTime(flight.arrivalTime)}</li>
                                            <li>{this.context.t("itinerary_flight_number")} {flight.flightNumber}</li>
                                        </ul>
                                    </div>
                                </StyledFlight>
                            </div>
                        )
                    })}
            </div>
        )
    }
}

FlightList.contextTypes = {
    t: PropTypes.func.isRequired
};
