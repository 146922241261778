import React, {Component} from 'react';
import {connect} from 'react-redux';

class MissedOffer extends Component {
    offerReached = () => {
        return this.props.offer.missedOfferRemainingTriggerCount === 0;
    };

    offerLimit = () => {
        return this.props.offer.missedOfferRemainingDiscountedCount > 10 ? 0 : this.props.offer.missedOfferRemainingDiscountedCount;
    };

    offerToReach = () => {
        return this.props.offer.missedOfferRemainingTriggerCount;
    };

    formatOfferText = () => {
        const offerReached = this.offerReached();
        const offerToReach = this.offerToReach();
        const offerLimit = this.offerLimit() ? this.offerLimit() - offerToReach : 0;
        const offerPrice = this.props.offerConfig.forZ ? "£" + this.props.offerConfig.forZ + " each" : "FREE";
        const offerName = this.props.offer.id.toLowerCase();
        let offerText = this.props.offerConfig.blurb + " - ";

        if(!offerReached) {
            offerText += `If you buy ${offerToReach} more ${offerName} photo${offerToReach === 1 ? "" : "s"} `
        }

        offerText += ` You can choose ${offerLimit ? `${offerLimit} additional` : 'any additional'} ${offerName} photo${offerLimit === 1 ? "" : "s"} for ${offerPrice}`

        return (<span><span className="font-bold">SPECIAL OFFER </span>{offerText}</span>);
    };


    render() {
        if(!this.props.offerConfig) {
            return null;
        }

        return (
            <div>
                {this.formatOfferText()}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        offerConfig: state.photomatch.config.photoGroups ?
            state.photomatch.config.photoGroups.find(group => group.id === ownProps.offer.id) :
            null,
    };
};

export default connect(mapStateToProps)(MissedOffer);