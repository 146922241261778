import React, {useEffect, useState} from 'react';
import {getDailyScheduleByGoSpark} from "../../actions/networkActions";
import Loading from "../../components/Loading";
import {DefaultButton, ModalContainer, ModalContent} from "../../resources/styles";
import {formatSparkFromToTime, formatYMDtoDMY} from "../../utils/dateTimeUtils";

function SparkDetail(props) {
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedEventIndex, setSelectedEventIndex] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const sparkEvents = await getDailyScheduleByGoSpark(props.match.params.date);
            setEvents(sparkEvents?.lineup?.[0]?.events || []);
            setLoading(false);
        }

        fetchData();
    }, [props.match.params.date]);


    if(loading) {
        return (
            <Loading/>
        )
    }

    if(events.length === 0) {
        return (
            <div>
                No events on {formatYMDtoDMY(props.match.params.date)}
            </div>
        )
    }

    return (
        <div>
            <div className="my-4">
                There are {events.length} events on {formatYMDtoDMY(props.match.params.date)}
            </div>

            <table className="text-center">
                <thead>
                <tr className="text-center">
                    <th className="text-left">Time</th>
                    <th>Title</th>
                    <th>Deck</th>
                    <th>Venue</th>
                    <th>More</th>
                </tr>
                </thead>

                <tbody>
                {
                    events.map((event, index) => {
                        const timeString = formatSparkFromToTime(event)
                        return (<tr key={event.id}>
                            <td className="text-left">{timeString}</td>
                            <td>{event.name}</td>
                            <td>{event.venueLevel}</td>
                            <td>{event.venue}</td>
                            {event.description ?
                                <td className="cursor-pointer" onClick={() => setSelectedEventIndex(index)}>
                                    <i className="fa fa-chevron-down"></i>
                                </td> :
                                <td></td>
                            }
                        </tr>)
                    })
                }
                </tbody>
            </table>

            {selectedEventIndex !== null &&
            <ModalContainer onClick={() => setSelectedEventIndex(null)}>
                <ModalContent onClick={(e) => {e.stopPropagation()}}>
                    <div className="bg-white p-4">
                        <h3>{events[selectedEventIndex].name}</h3>
                        <div>
                            {formatYMDtoDMY(props.match.params.date)} {formatSparkFromToTime(events[selectedEventIndex])}
                        </div>
                        <div className="mt-4">
                            {events[selectedEventIndex].description}
                        </div>
                        <div className="mt-4 text-right">
                            <DefaultButton onClick={() => setSelectedEventIndex(null)}>Close</DefaultButton>
                        </div>
                    </div>
                </ModalContent>
            </ModalContainer>
            }
        </div>
    );
}

export default SparkDetail;