import React from "react";
import {PropTypes} from 'prop-types';
import Rating from "../../components/Rating";
import {getTranslation} from '../../translations/translationUtils';
import If from '../../components/If';

function renderFieldOption(fieldValue, fieldValueIndex) {
    return (<option key={fieldValueIndex} value={fieldValue}>{fieldValue}</option>)
}

function renderFieldCheckbox(fieldValue, fieldEditor, saved) {
    const checkboxStyle = {
        margin: "0px 0.5rem",
        verticalAlign: "middle"
    };
    const checkboxLabelStyle = {
        fontSize: "1rem"
    };
    return (<label style={checkboxLabelStyle} ><input type="checkbox" disabled={saved} className="whaleCheckbox" style={checkboxStyle} id={fieldValue} name={fieldValue} onClick={fieldEditor.onChanged}/>{fieldValue}</label>)
}

export default class FeedbackForm extends React.Component {
    constructor() {
        super();
        this.state = {
            id: null,
            fieldEditors: null,
            answerTextEditors: null,
            answerRatingEditors: null,
            hasRatings: false,
            isValid: false,
            amountSubmitPressed: 0
        };
    }

    componentDidMount() {
        let profile = this.props.profile;
        if (profile != null) {
            this.setState(this._initEditors(profile), () => {
                if (this.props.onValidChanged != null) {
                    this.props.onValidChanged(this.state.isValid);
                }
            });
        }
    }

    componentDidUpdate() {
        if (this.props.amountSubmitPressed) {
            if (this.state.amountSubmitPressed !== this.props.amountSubmitPressed) {
                const missingMessagesElements = document.getElementsByClassName("feedbackMandatoryMissing");
                if (missingMessagesElements.length > 0) {
                    const firstMssingMessage = missingMessagesElements[0];
                    firstMssingMessage.scrollIntoView();

                    let pxScrollBy = -80;
                    if (firstMssingMessage.previousElementSibling.type === "textarea") {
                        pxScrollBy = -280;
                    }
                    window.scrollBy(0, pxScrollBy);
                }
                this.setState({amountSubmitPressed: this.props.amountSubmitPressed});
            }
        }
    }

    _initEditors(profile) {
        let hasRatings = false;
        let answerTextEditors = {};
        let answerRatingEditors = {};
        let fieldEditors = {};

        for (let field of profile.fields) {
            fieldEditors[field.id] = {
                editValue: "",
                onChanged: this._fieldChanged.bind(this, field),
                mandatory: field.mandatory != null ? field.mandatory : true
            };
            if (field.multipleChoice) {
                fieldEditors[field.id].selectedValues = [];
            }
        }
        for (let question of profile.questions) {
            if (question.inputText) {
                answerTextEditors[question.id] = {
                    editValue: "",
                    onChanged: this._answerTextChanged.bind(this, question),
                    mandatory: question.mandatory != null ? question.mandatory : true,
                    inputTextSmall: question.inputTextSmall
                };
            }
            if (question.inputRating) {
                hasRatings = true;
                answerRatingEditors[question.id] = {
                    editValue: null,
                    onChanged: this._answerRatingChanged.bind(this, question),
                    mandatory: question.mandatory != null ? question.mandatory : true
                };
            }
        }
        return {
            id: profile.id,
            fieldEditors: fieldEditors,
            answerTextEditors: answerTextEditors,
            answerRatingEditors: answerRatingEditors,
            hasRatings: hasRatings,
            isValid: this._validate(fieldEditors, answerRatingEditors, answerTextEditors)
        }
    }

    _validate(fieldEditors, answerRatingEditors, answerTextEditors) {
        let profile = this.props.profile;
        if (profile === null) {
            return false;
        }
        const fieldsValid = !profile.fields.some((field) => {
            // check select field option if is not null or empty (initial value is empty)
            const fieldInvalid = this.getIsMandatoryFieldMissing(fieldEditors[field.id]);
            return fieldInvalid;
        });

        const questionsValid = !profile.questions.some((question) => {
            const questionInvalid = this.getIsMandatoryQuestionMissing(question, answerRatingEditors[question.id], answerTextEditors[question.id]);
            return questionInvalid;
        });

        return fieldsValid &&
                // check if question contains rating and if rating is given by user
                questionsValid;
    }

    _fieldChanged(field, event) {
        let newValue = event.target.value;
        let checked = event.target.checked;
        let selectedStringValue = event.target.name;

        let isValidChanged = false;
        this.setState((prevState) => {
                let fieldEditors = prevState.fieldEditors;

                if (field.multipleChoice) {
                    const prevSelectedValues = fieldEditors[field.id].selectedValues;

                    const newSelectedValues = this.getNewMultipleChoiceValue(prevSelectedValues, selectedStringValue, checked);
                    fieldEditors[field.id].selectedValues = newSelectedValues;
                    fieldEditors[field.id].editValue = newSelectedValues.toString();
                } else {
                    fieldEditors[field.id].editValue = newValue;
                }

                let isValid = this._validate(fieldEditors, prevState.answerRatingEditors, prevState.answerTextEditors);
                isValidChanged = prevState.isValid !== isValid;
                return {fieldEditors: fieldEditors, isValid: isValid};
            }, () => {
                if (isValidChanged && this.props.onValidChanged != null) {
                    this.props.onValidChanged(this.state.isValid);
                }
            });
    }

    getNewMultipleChoiceValue(prevSelectedValues, stringValue, checked) {
        if (checked) {
            prevSelectedValues.push(stringValue);
        } else {
            prevSelectedValues = prevSelectedValues.filter(function(e) { return e !== stringValue })
        }
        return prevSelectedValues;
    }

    _answerTextChanged(question, event) {
        let newValue = event.target.value;
        let isValidChanged = false;
        this.setState((prevState) => {
            let answerRatingEditors = prevState.answerRatingEditors;
            let answerTextEditors = prevState.answerTextEditors;
            answerTextEditors[question.id].editValue = newValue;
            let isValid = this._validate(prevState.fieldEditors, answerRatingEditors, prevState.answerTextEditors);
            isValidChanged = prevState.isValid !== isValid;
            return {answerTextEditors: answerTextEditors, isValid: isValid};
        }, () => {
            if (isValidChanged && this.props.onValidChanged != null) {
                this.props.onValidChanged(this.state.isValid);
            }
        });
    }

    _answerRatingChanged(question, value) {
        let isValidChanged = false;
        this.setState((prevState) => {
                let answerRatingEditors = prevState.answerRatingEditors;
                answerRatingEditors[question.id].editValue = value;
                let isValid = this._validate(prevState.fieldEditors, answerRatingEditors, prevState.answerTextEditors);
                isValidChanged = prevState.isValid !== isValid;
                return {answerRatingEditors: answerRatingEditors, isValid: isValid};
            }, () => {
                if (isValidChanged && this.props.onValidChanged != null) {
                    this.props.onValidChanged(this.state.isValid);
                }
            });
    }

    getFeedbackResult() {
        let profile = this.props.profile;
        if (profile === null) {
            return false;
        }
        let fields = profile.fields.map((field) => ({
            "id": field.id,
            "field": getTranslation(field.title),
            "value": this.state.fieldEditors[field.id].editValue
        }));
        let answers = profile.questions.map((question) => {
            let answer = {"id": question.id};
            if (question.inputText) {
                answer["text"] = encodeURIComponent(this.state.answerTextEditors[question.id].editValue);
            }
            if (question.inputRating) {
                answer["rating"] = this.state.answerRatingEditors[question.id].editValue;
            }
            return answer;
        });
        return {
            "id": profile.id,
            "fields": fields,
            "answers": answers
        }
    }

    getIsMandatoryFieldMissing(fieldEditor) {
        if (fieldEditor.mandatory && (!fieldEditor.editValue || fieldEditor.editValue === "")) {
            return true;
        }
        return false;
    }

    getIsMandatoryQuestionMissing(question, answerRatingEditor, answerTextEditor) {
        if (!question.mandatory) {
            return false;
        }


        if (question.inputRating && question.inputText) {
            if (answerRatingEditor && answerRatingEditor.mandatory && (question.inputRating && (answerRatingEditor.editValue !== null))) {
                return false;
            }
        }

        if (question.inputRating && !question.inputText) {
            if (answerRatingEditor && answerRatingEditor.mandatory && (question.inputRating && (answerRatingEditor.editValue !== null))) {
                return false;
            }
        }

        if (!question.inputRating && question.inputText) {
            if (answerTextEditor && answerTextEditor.mandatory && answerTextEditor.editValue !== "") {
                return false;
            }
        }

        return true;
    }


    tuiValueSelectorClassName(fieldEditor) {

        const isMandatoryFieldMissing = this.getIsMandatoryFieldMissing(fieldEditor);
        if (this.props.amountSubmitPressed > 0 && isMandatoryFieldMissing) {
            return "tui_ValueSelector feedbackValueSelectorMissing";
        }
        return "tui_ValueSelector";
    }

    render() {

        let profile = this.props.profile;
        if (profile === null || this.state.id === null) {
            return false;
        }

        if (!this.props.combinedProfile) {
            return false;
        }

        let hasRatings = this.state.hasRatings;

        const mandatoryErrorText = this.context.t("mandatory_answer");

        const selectAndTextareaStyle = {
            marginBottom: 0
        };

        return (
            <div>
                {
                    this.props.combinedProfile.items.map((item, itemIdx) => {

                        if (item.type === "field") {
                            const field = item;
                            const fieldIdx = itemIdx;


                            let fieldEditor = this.state.fieldEditors[field.id];
                            return (
                                <div key={fieldIdx} className="row">

                                    <If test={field.multipleChoice}>
                                        <div className="small-12 medium-8 text-left">
                                            <div key={field.id}><If test={field.mandatory}><span>* </span></If>{getTranslation(field.title)}
                                                {field.values.map((fieldValue) => renderFieldCheckbox(fieldValue, fieldEditor, this.props.saved))}
                                                <If test={this.props.amountSubmitPressed > 0 && this.getIsMandatoryFieldMissing(fieldEditor)}>
                                                    <div className="feedbackMandatoryMissing">{this.context.t("mandatory_answer")}</div>
                                                </If>

                                            </div>
                                        </div>
                                    </If>

                                    <If test={!field.multipleChoice}>
                                        <div className="small-12 medium-8 text-left">
                                            <If test={field.mandatory}><span>* </span></If><span className="feedbackFieldTitle">{getTranslation(field.title)}</span>
                                            <select className={this.tuiValueSelectorClassName(fieldEditor)} defaultValue="" onChange={fieldEditor.onChanged} disabled={this.props.saved}>
                                                <option key="-1" value="" disabled>{this.context.t("feedback_please_select")}</option>
                                                {field.values.map((fieldValue, fieldValueIndex) => renderFieldOption(fieldValue, fieldValueIndex,this.props.saved))}
                                            </select>
                                            <If test={this.props.amountSubmitPressed > 0 && this.getIsMandatoryFieldMissing(fieldEditor)}>
                                                <div className="feedbackMandatoryMissing">{this.context.t("mandatory_answer")}</div>
                                            </If>
                                        </div>
                                    </If>
                                </div>



                            )
                        }

                        if (item.type === "question") {

                            const question = item;
                            const questionIdx = itemIdx;

                            let answerTextEditor = this.state.answerTextEditors[question.id];
                            let answerRatingEditor = this.state.answerRatingEditors[question.id];
                            let answerHeader = hasRatings ? (
                                <div key={questionIdx}>
                                    <div>
                                        {answerRatingEditor != null ? (
                                            <div>
                                                <div>
                                                    <div className="feedbackFieldTitle"><If test={question.mandatory}><span>* </span></If>{getTranslation(question.question)}</div>
                                                </div>
                                                <div>
                                                    <Rating
                                                        value={answerRatingEditor.editValue > 0 ? answerRatingEditor.editValue : 0}
                                                            maxValue={10} onRatingChanged={answerRatingEditor.onChanged}
                                                            disabled={this.props.saved}
                                                    />
                                                </div>
                                                <If test={this.props.amountSubmitPressed > 0 && !answerTextEditor && this.getIsMandatoryQuestionMissing(question, answerRatingEditor)}>
                                                    <div className="feedbackMandatoryMissing">{this.context.t("mandatory_answer")}</div>
                                                </If>

                                            </div>
                                        ) : false}
                                    </div>
                                </div>
                            ) : (<div key={questionIdx}/>);
                            if (answerTextEditor != null) {
                                return [answerHeader, (
                                    <div key={question.id + 'Details'}>
                                        <div>
                                            <div className="feedbackFieldTitle"><If test={question.mandatory && !question.inputRating}><span>* </span></If>{getTranslation(question.question)}</div>
                                        </div>
                                        <div>
                                            <If test={!question.inputTextSmall}>
                                               <textarea style={selectAndTextareaStyle} rows="5" cols="20" placeholder={this.context.t("feedback_textarea_placeholder")}
                                                         onChange={answerTextEditor.onChanged}
                                                         value={answerTextEditor.editValue}
                                                         disabled={this.props.saved}
                                               />
                                            </If>
                                            <If test={question.inputTextSmall}>
                                                <input onChange={answerTextEditor.onChanged}
                                                       value={answerTextEditor.editValue}
                                                       disabled={this.props.saved}/>
                                            </If>
                                            <If test={this.props.amountSubmitPressed > 0 && this.getIsMandatoryQuestionMissing(question, answerRatingEditor, answerTextEditor)}>
                                                <div className="feedbackMandatoryMissing">{mandatoryErrorText}</div>
                                            </If>

                                        </div>
                                    </div>
                                )];
                            } else {
                                return answerHeader;
                            }

                        }
                        return null;
                    })
                }
            </div>
        );
    }

}

FeedbackForm.contextTypes = {
    t: PropTypes.func.isRequired
};
