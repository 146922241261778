import React, {Component} from 'react';
import './App.css';

import configureStore from './store/configureStore';
import {Provider} from "react-redux";
import ThemedApp from "./modules/ThemedApp";

import I18n from "redux-i18n";
import {translations} from "./translations/translations.js";


export const store = configureStore();


class App extends Component {

    render() {
        const initialLang = (store.getState().i18nState && store.getState().i18nState.lang) ? store.getState().i18nState.lang : store.getState().configuration.defaultLanguage;
        const fallbackLang = store.getState().configuration.fallbackLanguage;

        return (

                <Provider store={store}>
                    <I18n translations={translations} initialLang={initialLang} fallbackLang={fallbackLang}>
                        <ThemedApp/>
                    </I18n>
                </Provider>

        );
    }
}

export default App;
