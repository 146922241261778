import * as React from "react";
import {connect} from "react-redux";
import OverviewImageButtonPanel from "../../components/OverviewImageButtonPanel";
import OverviewImageButton from "../../components/OverviewImageButton";
import {getTranslation} from "../../translations/translationUtils";
import {clearWayfinderProfiles} from "../../actions/wayfinderActions";


class Dashboard extends React.Component {

    componentDidMount() {
        clearWayfinderProfiles();
    }

    render() {
        if (!this.props.modules) {
            return false;
        }

        return (
            <OverviewImageButtonPanel>
                {
                    this.props.modules.map((module) =>
                            <OverviewImageButton
                                key={module.id}
                                item={module}
                                link={module.url}
                                externalLink={module.externalUrl}
                                title={getTranslation(module.label)}
                                thumbnail={this.props.configuration.hubUrl + "blobs/image?reference=" + module.id + "&width=400"}/>

                    )
                }
            </OverviewImageButtonPanel>

        );
    }
}



const mapStateToProps = state => {
    return {
        modules: state.modules.modules,
        configuration: state.configuration,
        lang: state.i18nState.lang
    };
};

export default connect(mapStateToProps) (Dashboard);