import React from "react";
import If from "../../components/If";
import DetailImage from "../../components/DetailImage";
import {connect} from "react-redux";
import {fetchFromHub, postToHub} from "../../actions/networkActions";
import {getTranslation} from "../../translations/translationUtils";
import PropTypes from "prop-types";
import {updateNumberOfPersons, updateSelectedItem} from '../../actions/tacBookingActions';
import {REQUEST_FINISHED, REQUEST_STARTED} from "../../constants/vod-action-types.js";
import {DefaultButton, DefaultSelect} from "../../resources/styles.js";
import styled from "styled-components";
import {capitalizeFirstLetter} from "../../utils/stringUtils.js";
import Checkbox from "../../components/Checkbox.js";
import {recordVisit} from "../../actions/metricsActions";

const ErrorMessage = styled.div`
    font-size: 1.5rem;
    font-weight: bold;
    color: red;
`;

class TacBooking extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            detail: null,
            selectedNumberOfPersons: 0,
            selectedDate: null,
            showNumberOfPersons: true,
            bookingInformation: {
                minimumNumberOfPersons: 1,
                maximumNumberOfPersons: 4,
                dates: []
            },
            bookingRequest: {
                numberOfPersons: 0,
                date: null,
                fromTime: null
            },
            availableTimes: [],
            inProgress: false,
            bookingReference: null,
            errorMessage: null,
            conditions: null,
            conditionsAccepted: false
        };
    }

    componentDidMount() {
        this.props.showSpinner();
        const id = this.props.tacBookings.objectId;
        const module = this.props.tacBookings.module;
        // if (TacModuleTypeEnum.SPA == module) {
        //     this.setState({selectedNumberOfPersons: 1});
        // }
        this.props.fetchFromHub(module + '/' + id, this.updateDetail);
        this.props.fetchFromHub('conditions/' + capitalizeFirstLetter(module), this.updateConditions);
    }

    updateDetail = (data) => {
        this.setState({
            detail: data
        });
        this.props.fetchFromHub(this.props.tacBookings.module + '/bookingInformation/' + this.state.detail.id,
            this.updateBookingInformation,
            (error) => {console.log('something happened: ', error); this.setState({inProgress: false, errorMessage: error}); this.props.hideSpinner();});
    };

    updateConditions = (data) => {
        this.setState({
            conditions: data
        });
    };

    onNumberOfPersonsChanged = (event) => {
        this.props.showSpinner();
        this.setState({inProgress: true, selectedDate: null, availableTimes: []});
        const bookingRequest = {
            numberOfPersons: event.target.value,
            date: null,
            fromTime: null,
        }
        this.setState({bookingRequest: bookingRequest});

        this.setState({selectedNumberOfPersons: event.target.value});
        this.setState({selectedDate: null});

        if (document.getElementById('dateSelector')) {
            document.getElementById('dateSelector').options[0].selected = true;
        }

        this.setState({inProgress: false});
        this.props.hideSpinner();
    }

    updateBookingInformation = (info) => {
        this.setState({inProgress: false});
        this.setState({bookingInformation: info});
        this.props.hideSpinner();
    }

    onDateOptionChanged = (event) => {
        this.props.showSpinner();
        this.setState({inProgress: true});
        const bookingRequest = this.state.bookingRequest;
        bookingRequest.date = event.target.value;

        this.setState({bookingRequest: bookingRequest});

        this.setState({selectedDate: event.target.value});
        this.setState({availableTimes: []});

        let timesRequest = {
            "date": event.target.value,
            "bookingRequest": bookingRequest
        };
        this.props.postToHub(
            this.props.tacBookings.module + '/availabilityTimes/' + this.state.detail.id,
            timesRequest,
            this.updateAvailabilityTimes,
            (error) => {console.log('something happened: ', error); this.setState({inProgress: false, errorMessage: error}); this.props.hideSpinner();}
        );
    }

    updateAvailabilityTimes = (times) => {
        this.setState({availableTimes: times});
        this.setState({inProgress: false});
        this.props.hideSpinner();
    }

    onTimeOptionChanged = (event) => {
        const bookingRequest = this.state.bookingRequest;
        let optionValue = JSON.parse(event.target.value);
        bookingRequest.fromTime = optionValue.fromTime;
        bookingRequest.toTime = optionValue.toTime;

        this.setState({bookingRequest: bookingRequest});
        this.setState({inProgress: false});
    }

    sendBookingRequest = () => {
        const bookingRequest = this.state.bookingRequest
        bookingRequest.objectId = this.state.detail.id;
        this.setState({inProgress: true});
        const url = this.props.tacBookings.module + '/book/' + this.state.detail.id;
        this.props.postToHub(
            url,
            bookingRequest,
            this.updateBookingResponse,
            (error) => {console.log('something happened while sendingBookingRequest: ', error); this.setState({inProgress: false, errorMessage: error}); this.props.hideSpinner();}
        );
    }

    updateBookingResponse = (response) => {
        console.log('in updateBookingResponse: ', response);
        if (response.failed) {
            this.setState({inProgress: false, errorMessage: response.systemInformation});
        } else {
            recordVisit(`${this.props.tacBookings.module}/book/${this.props.tacBookings.objectId}`);
            this.setState({inProgress: false, bookingReference: response.bookingReference});
        }
    }

    showConditions = () => {
        let showconditions = false;
        showconditions = this.state.bookingRequest &&
            this.state.bookingRequest.fromTime &&
            this.state.bookingRequest.toTime &&
            !this.state.inProgress &&
            !this.state.bookingReference &&
            !this.state.errorMessage &&
            this.isConditionsAvailable();
        return showconditions;
    }

    isConditionsAvailable = () => {
        if (this.state.conditions && this.state.conditions.conditionText && this.state.conditions.conditionText.defaultValue && this.state.conditions.conditionText.defaultValue.length > 0) {
            return true;
        }
        return false;
    }

    onConditionsAcceptChanged = () => {
        this.setState({conditionsAccepted: !this.state.conditionsAccepted});
    }

    showBookNowButton = () => {
        let showBooknowButton = this.state.bookingRequest.fromTime &&  this.state.bookingRequest.toTime && !this.state.inProgress && !this.state.bookingReference && !this.state.errorMessage;

        if (this.isConditionsAvailable() && !this.state.conditionsAccepted) {
            showBooknowButton = false;
        }
        return showBooknowButton;
    }


    render() {

        let item = this.state.detail;

        if (item === null) {
            return false;
        }

        let personsAmountArray = [];

        for (let i = this.state.bookingInformation.minimumNumberOfPersons; i <= this.state.bookingInformation.maximumNumberOfPersons; i++) {
            personsAmountArray.push(i);
        }

        let conditions = null;
        let afterConditions = null;
        if (this.state.conditions != null) {
            conditions = this.state.conditions.conditionText;
            afterConditions = this.state.conditions.additionalConditionText;
        }

        return (
            <div>
                <div className="grid-x grid-padding-x">
                    <div className="cell small-12 medium-4 large-4">
                        <DetailImage image={this.props.configuration.hubUrl + "blobs/image?reference=" + this.state.detail.id}/>
                    </div>
                    <div className="cell small-12 medium-8 large-8 text-left">
                        <h1>
                            {getTranslation(item.title)}
                        </h1>

                        <If test={this.state.showNumberOfPersons && !this.state.bookingReference && !this.state.errorMessage}>
                            <div>
                                {this.context.t("number_of_persons")}<br/>
                                <DefaultSelect defaultValue="" onChange={(event) => this.onNumberOfPersonsChanged(event)}>
                                    <option disabled value="">{this.context.t("please_select")}</option>
                                    {
                                        personsAmountArray.map((option) => {
                                            return (
                                                <option key={option} value={option} label={option}>{option}</option>)
                                        })
                                    }
                                </DefaultSelect>
                            </div>
                        </If>

                        <If test={this.state.bookingInformation && this.state.bookingInformation.dates && this.state.selectedNumberOfPersons > 0 && !this.state.bookingReference && !this.state.errorMessage}>
                            <div>
                                {this.context.t("date")}<br/>
                                <DefaultSelect id="dateSelector"  defaultValue="" onChange={(event) => {this.onDateOptionChanged(event)}}>
                                    <option value="" disabled >{this.context.t("please_select")}</option>
                                    {
                                        this.state.bookingInformation.dates.map((option) => {
                                            return (
                                                <option key={option.value} value={option.value}
                                                        label={option.label}>{option.label}</option>)
                                        })
                                    }
                                </DefaultSelect>
                            </div>
                        </If>
                        <If test={this.state.availableTimes.length > 0 && this.state.selectedDate && !this.state.bookingReference && !this.state.errorMessage}>
                            <div>
                                {this.context.t("time")}<br/>
                                <DefaultSelect id="timeSelector" defaultValue=""  onChange={(event) => this.onTimeOptionChanged(event)}>
                                    <option value="" disabled >{this.context.t("please_select")}</option>
                                    {
                                        this.state.availableTimes.map((option) => {
                                            return (
                                                <option key={JSON.stringify(option)} value={JSON.stringify(option)}
                                                        label={option.label}>{option.label}</option>)
                                        })
                                    }
                                </DefaultSelect>
                            </div>
                        </If>


                        <If test={this.state.availableTimes.length === 0 && this.state.selectedDate && !this.state.inProgress}>
                            <div>
                                <h4>{this.context.t("no_timeslots")}</h4>
                            </div>
                        </If>

                        <If test={this.state.inProgress}>
                            <div>
                                <h4>{this.context.t("please_wait")}</h4>
                            </div>
                        </If>

                        <If test={this.state.errorMessage}>
                            <div>
                                <ErrorMessage>{this.context.t("book_not_complete")}</ErrorMessage>
                            </div>
                        </If>

                        <If test={this.showConditions()}>
                            <div>
                                <p><Checkbox label={this.context.t("basket_accept_conditions")} checked={this.state.conditionsAccepted} handleCheckboxChange={() => this.onConditionsAcceptChanged()}/></p>
                                <If test={this.showBookNowButton()} >
                                    <DefaultButton onClick={() => {this.sendBookingRequest()}}>
                                        {this.context.t("book_now")}
                                    </DefaultButton>
                                </If>
                                <h4>Terms and Conditions</h4>
                                <p dangerouslySetInnerHTML={{__html: getTranslation(conditions)}}></p>
                            </div>
                        </If>


                        <If test={this.state.bookingRequest && this.state.bookingRequest.fromTime && this.state.bookingReference}>
                            <div>
                                <h4><b>{getTranslation(item.title)}</b> {this.context.t("book_success")}.</h4>

                                <h4>
                                    <div>
                                        {this.context.t("date")}: {this.state.selectedDate}<br/>
                                    </div>

                                    <div>
                                        {this.context.t("time")}: {this.state.bookingRequest.fromTime} <br/>
                                    </div>

                                    <div>
                                        {this.context.t("number_of_persons")}: {this.state.selectedNumberOfPersons} <br/>
                                    </div>
                                </h4>

                                <If test={afterConditions}>
                                    <h4>
                                        <div dangerouslySetInnerHTML={{__html: getTranslation(afterConditions)}}></div>
                                    </h4>
                                </If>

                                <h4>{this.context.t("book_reference_info")} <b>{this.state.bookingReference}</b>.</h4>
                                <h4>
                                    {this.context.t("book_additional_info")}<br/>
                                </h4>
                            </div>
                        </If>

                    </div>
                </div>
            </div>
        )
    }
}


TacBooking.contextTypes = {
    t: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
    return {
        configuration: state.configuration,
        tacBookings: state.tacBookings,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        showSpinner: () => dispatch({type: REQUEST_STARTED}),
        hideSpinner: () => dispatch({type: REQUEST_FINISHED}),
        fetchFromHub: (param, success, error) => dispatch(fetchFromHub(param, success, error)),
        postToHub: (url, payload, success, error) => dispatch(postToHub(url, payload, success, error)),
        updateSelectedItem: (param) => dispatch(updateSelectedItem(param)),
        updateNumberOfPersons: (amount) => dispatch(updateNumberOfPersons(amount)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TacBooking);
