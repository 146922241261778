import React, {useState} from "react";
import RestaurantAvailabilityTimeSlot from "./RestaurantAvailabilityTimeslot";
import RestaurantAvailabilityTimesHeader from "./RestaurantAvailabilityTimesHeader";
import styled from "styled-components";
import {device, SecondaryButtonLink} from "../../resources/styles";
import moment from "moment";

const RestaurantContainer = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
    
    @media ${device.largePhone} {
        max-width: 500px;
    }
    
    @media ${device.tablet} {
        max-width: 48%;
    }
    
    @media ${device.laptop} {
        max-width: 31%;
        margin-right: 1rem;
    }
    
`;

const NameAndTimesContainer = styled.div`
    width: 100%;
`;

const NameAndLocation = styled.div`
    font-size: 1.1rem;
    display: block;
    padding-bottom: 0.7rem;
    p {
        display: inline;
        margin-left: 0.5rem;
        padding-left: 0.5rem;
        border-left: 2px solid;
    }
`;

const TimesContainer = styled.div`
    background-color: ${props => props.theme.contentBoxBackground || "white"};
    border: solid 1px lightgray;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding-bottom: 1.2rem;

    div {
        display: flex;
        justify-content: center
    }
`;

const NoTimeSlotsMessage = styled.div`
    line-height: 1.6;
    padding: 0.6rem 0;
`;

export default function RestaurantAvailabilityRestaurant(props) {

    const now = moment();

    const filteredByTime = props.restaurant.timeSlots
        .filter(timeslot => timeslot.time._d > now)
        .map((timeslot, index) => ({...timeslot, position: (index + 1)}));

    const [timeSlotState, setTimeSlotState] = useState(filteredByTime);

    if (!props.restaurant || !props.restaurant.timeSlots || props.restaurant.timeSlots.length<1) {
        return false;
    }
    const timeFormat = "HH:mm";
    let fromTime = props.restaurant.timeSlots[0].time.format(timeFormat);
    let toTime = props.restaurant.timeSlots[props.restaurant.timeSlots.length-1].time.format(timeFormat);

    const handleSetPosition = (delta) => {
        setTimeSlotState(timeSlotState.map(timeSlot => ({...timeSlot, position: (timeSlot.position + delta)})));
    };

    // Maximum timeslots shown at once is 5
    let shownTimeSlots = filteredByTime.length;
    if (filteredByTime.length > 5) {
        shownTimeSlots = 5;
    }

    return (
        <RestaurantContainer>
            <NameAndTimesContainer>
                <NameAndLocation>
                    <SecondaryButtonLink to={props.restaurant.restaurantLink}>› {props.restaurant.restaurantName}</SecondaryButtonLink>
                    <p>{props.restaurant.restaurantLocation}</p>
                </NameAndLocation>
                <TimesContainer>
                    <RestaurantAvailabilityTimesHeader
                        timeSlotState={timeSlotState}
                        handleSetPosition={handleSetPosition}
                        fromTime={fromTime}
                        toTime={toTime}
                        shownTimeSlots={shownTimeSlots}
                    />
                    <div>
                        {
                            timeSlotState.length > 0 ?
                                timeSlotState.map( timeSlot =>
                                    <RestaurantAvailabilityTimeSlot
                                        key={timeSlot.time}
                                        timeSlot={timeSlot}
                                        position={timeSlot.position}
                                        shownTimeSlots={shownTimeSlots}
                                    /> )
                                :
                                <NoTimeSlotsMessage>No timeslots remain for this venue today</NoTimeSlotsMessage>
                        }
                    </div>
                </TimesContainer>
            </NameAndTimesContainer>
        </RestaurantContainer>
    )
}