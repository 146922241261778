import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {getFromHubNew} from "../../actions/networkActions";
import Loading from "../../components/Loading";
import {store} from "../../App";
import {useGet} from "../../hooks/useGet";
import PresalesExcursionDetail from "../../components/presalesExcursions/PresalesExcursionDetail";
import {useExcursionImages} from "../../hooks/useExcursionImages";

// TODO refactor the useEffects that need to transform data to also use useGet hook
function OnboardExcursionDetail(props) {
    const tags = useSelector(state => state.excursionTags);
    const [loadingTags, setLoadingTags] = useState(false);
    const images = useExcursionImages(props.match.params.id);
    const [descriptions, setDescriptions] = useState(null);
    const [availability, setAvailability] = useState(null);

    const excursion = useGet(`excursions/${props.match.params.id}`);

    const dispatch = store.dispatch;

    useEffect(() => {
        if(tags || loadingTags) {
            return;
        }

        const fetchTags = async () => {
            const tagsData = await getFromHubNew('excursions/tags');

            const tagsObject = {};
            tagsData.forEach(tag => {
                tagsObject[tag.name] = tag.imageUrl?.id;
            });

            dispatch({
                type: "SET_TAGS",
                tags: tagsObject,
            });
        }
        setLoadingTags(true);
        fetchTags();

    }, [dispatch, loadingTags, tags])

    useEffect(() => {
        const fetchDescriptions = async () => {
            const descriptionsArray = await getFromHubNew(`descriptions/${props.match.params.id}`);

            const descriptionsObject = descriptionsArray.reduce((result, entry) => {
                result[entry.descriptionType] = entry.value;
                return result;
            }, {});

            setDescriptions(descriptionsObject);
        }

        fetchDescriptions();
    }, [props.match.params.id]);

    useEffect(() => {
        if(!excursion.data || excursion.data.day){
            return;
        }
        const fetchAvailability = async () => {
            const availabilityData = await getFromHubNew(`excursions/checkAvailability/${props.match.params.id}`);
            handleCurrentAvailability({availabilityData});
        }
        fetchAvailability();
    }, [props.match.params.id, excursion]);

    const handleCurrentAvailability = ({availabilityData}) => {
            if (availabilityData?.currentAvailability) {
                setAvailability(availabilityData.currentAvailability);
            } else if (availabilityData?.availability) {
                setAvailability(99);
            } else {
                setAvailability(0);
            }
    }

    if(images.length === 0 || excursion.data === null || descriptions === null || (availability === null && !excursion.data.day) ) {
        return (
            <Loading/>
        )
    }

    return (
        <div>
            <PresalesExcursionDetail
                tags={tags}
                excursion={excursion.data}
                currentAvailability={availability}
                images={images}
                descriptions={descriptions}
            />
        </div>
    );
}

export default OnboardExcursionDetail;