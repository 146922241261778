import React from 'react';
import {Link} from 'react-router-dom';
import {useGet} from "../../hooks/useGet";
import Loading from "../../components/Loading";
import {useSelector} from "react-redux";


function TvChannelsOverview(props) {
    const {isLoading, error, data} = useGet("channel/getAll/tv/app");
    const channels = data?.channel

    if(isLoading) {
        return <Loading/>
    }

    if(error) {
        return <div>
            There has been an error: {error}
        </div>
    }

    if(!channels || channels.length === 0) {
        return <div>
            No channels.
        </div>
    }

    return (
        channels.map(channel => <TvChannelEntry channel={channel} key={channel.id} />)
    );
}

export default TvChannelsOverview;

function TvChannelEntry(props) {

    const hubUrl = useSelector(state => state.configuration.hubUrl);

    return (
        <Link className="block my-4 border border-primary  bg-white p-4"
            to={{
                pathname: `/channel/${props.channel?.id}`,
                state: {
                    urlToPlay: props.channel.mediaUrl?.[0]?.streamingUrl,
                    title: props.channel?.name?.defaultPlainValue,
                }
            }}>
        {/*    TODO - style the list to look like on TV, add image and such */}
        <div className="flex items-center">
            <div className="w-8 h-8">
                <img alt="channel-logo" src={`${hubUrl}${props.channel?.logo?.url}`} />
            </div>
            <div className="ml-4">
                <div>
                    {props.channel?.name?.defaultPlainValue}
                </div>
                <div>
                    {props.channel?.code}
                </div>
            </div>
        </div>

        </Link>
    )
}