import {SET_CHANNELS, SET_CHANNELS_ERROR} from "../constants/channel-action-types";

const INITIAL_STATE = {
    channels: [],
    errormessage: null
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_CHANNELS:
            return { ...state, channels: action.channels };

        case SET_CHANNELS_ERROR:
            return { ...state, errormessage: action.errormessage };

        default:
            return state;
    }
}
