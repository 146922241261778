import React from 'react';
import {Route, Redirect} from 'react-router-dom'
import {useSelector} from 'react-redux';

function GuestRoute({component: Component, ...rest}) {
    const authentication = useSelector(state => state.authentication);

    const redirectUrl = rest?.location?.state?.from || "/";
    
    return (
        <Route
            {...rest}
            render={(props) => authentication.isAuthenticated
                ? <Redirect to={{pathname: redirectUrl, state: {from: props.location}}} />
                : <Component {...props} />
            }
        />
    );
}

export default GuestRoute;