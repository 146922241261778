import React from 'react';

function NotFound() {
    return (
        <div>
            <p>
                404 - content not found.
            </p>
        </div>
    );
}

export default NotFound;