import {useEffect} from 'react';
import {useSelector} from "react-redux";


function HubEventReload(props) {
    const hubUrl  = useSelector(state => state.configuration.hubApiUrl);
    const deviceType = useSelector(state => state.configuration.hubSettings.deviceType);

    useEffect(() => {
        const sse = new EventSource(`${hubUrl}digitalsignage/events/register`,);
        sse.onmessage = e => {
            if(e?.data === deviceType.toUpperCase()) {
                window.location.reload();
            }
        };
        sse.onerror = (e) => {
            // error log here
            console.log("error with sse", e);
            sse.close();
        }

        return () => {
            sse.close();
        };
    }, [hubUrl, deviceType]);

    return null;
}

export default HubEventReload;