import React from 'react';
import {Helmet} from "react-helmet"

function ThemedHeadElements(props) {
    return (
        <>
            {props.appTitle &&
            <Helmet>
                <title>{props.appTitle}</title>
                <meta name="apple-mobile-web-app-title" content={props.appTitle} />
            </Helmet>
            }

            {props.resourcesFolder &&
            <Helmet>
                <link rel="manifest" href={`${props.resourcesFolder}site.webmanifest`} />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-status-bar-style" content="default" />

                <link rel="shortcut icon" href={`${props.resourcesFolder}icons/favicon.ico`} />
                <link rel="icon" type="image/png" sizes="16x16" href={`${props.resourcesFolder}icons/favicon-16x16.png`} />
                <link rel="icon" type="image/png" sizes="32x32" href={`${props.resourcesFolder}icons/favicon-32x32.png`} />
                <link rel="apple-touch-icon" sizes="180x180" href={`${props.resourcesFolder}icons/apple-touch-icon.png`} />


                <link rel="apple-touch-startup-image" href={`${props.resourcesFolder}splash/launch-640x1136.png`} media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"/>
                <link rel="apple-touch-startup-image" href={`${props.resourcesFolder}splash/launch-750x1334.png`} media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"/>
                <link rel="apple-touch-startup-image" href={`${props.resourcesFolder}splash/launch-828x1792.png`} media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"/>

                <link rel="apple-touch-startup-image" href={`${props.resourcesFolder}splash/launch-1125x2436.png`} media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"/>
                <link rel="apple-touch-startup-image" href={`${props.resourcesFolder}splash/launch-1242x2208.png`} media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"/>
                <link rel="apple-touch-startup-image" href={`${props.resourcesFolder}splash/launch-1242x2688.png`} media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"/>

                <link rel="apple-touch-startup-image" href={`${props.resourcesFolder}splash/launch-1536x2048.png`} media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"/>
                <link rel="apple-touch-startup-image" href={`${props.resourcesFolder}splash/launch-1668x2224.png`} media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"/>
                <link rel="apple-touch-startup-image" href={`${props.resourcesFolder}splash/launch-2048x2732.png`} media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"/>

                <link rel="apple-touch-startup-image" href={`${props.resourcesFolder}splash/launch-2048x1536.png`} media="(device-width: 1024px) and (device-height: 768px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"/>
                <link rel="apple-touch-startup-image" href={`${props.resourcesFolder}splash/launch-2224x1668.png`} media="(device-width: 1112px) and (device-height: 834px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"/>
                <link rel="apple-touch-startup-image" href={`${props.resourcesFolder}splash/launch-2732x2048.png`} media="(device-width: 1366px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"/>

                <link rel="apple-touch-startup-image" href={`${props.resourcesFolder}splash/launch-2048x1536.png`} media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"/>
                <link rel="apple-touch-startup-image" href={`${props.resourcesFolder}splash/launch-2224x1668.png`} media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"/>
                <link rel="apple-touch-startup-image" href={`${props.resourcesFolder}splash/launch-2732x2048.png`} media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"/>
            </Helmet>
            }
        </>
    );
}

export default ThemedHeadElements;