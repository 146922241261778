import * as React from "react";
import Modal from "../../../components/Modal";
import OverviewImageButtonPanel from "../../../components/OverviewImageButtonPanel";
import OverviewImageButton from "../../../components/OverviewImageButton";
import {connect} from "react-redux";
import VodUtils from "../VodUtils";

class VodSerie extends React.Component {

    render() {

        if (! this.props.series) {
            return false;
        }

        let serie = null;

        const serieId = parseInt(this.props.match.params.id, 10);
        for (let i = 0; i < this.props.series.length; i++) {
            if (this.props.series[i].series_info.series_id === serieId) {
                serie = this.props.series[i];
            }
        }

        let seasons = serie.series_info.seasons;

        return (
            <div>
                <Modal show={this.props.isFetching}/>

                <OverviewImageButtonPanel>
                    {
                        seasons.map((season) => {
                            const id = season.season;

                            const link = "/vod/overview/serie/"+serie.series_info.series_id+"/"+season.season;

                            return (
                                <OverviewImageButton key={id}
                                                     link={link}
                                                     title={serie.title + " - Season " + season.season}
                                                     thumbnail={VodUtils.getPictureUrl(serie)}/>

                            )
                        })
                    }

                </OverviewImageButtonPanel>
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        series: state.vod.series,
        isFetching: state.vod.isFetching,
    };
};

export default connect(mapStateToProps) (VodSerie)