import React from "react";
import If from "../../components/If";
import DetailImage from "../../components/DetailImage";
import {connect} from "react-redux";
import {fetchFromHub} from "../../actions/networkActions";
import {getTranslation} from "../../translations/translationUtils";
import PropTypes from "prop-types";
import VideoComponent from "../../components/VideoComponent";
import PdfItems from "../../components/PdfItems";
import {Title} from "../../resources/styles";
import {updateSelectedItem} from '../../actions/tacBookingActions';
import {TacModuleTypeEnum} from '../../constants/tac-booking-types.js';
import browserHistory from '../../utils/history.js';
import Description from "../../components/Description.js";
import LocationAndTimes from "../../components/LocationAndTimes";
import TacBookButton from "../../components/TacBookButton";
import {setWayfinderProfiles} from "../../actions/wayfinderActions";

class DiningDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            detail: null,
            videos: null,
            pdfs: [],
        };
    }

    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.fetchFromHub('dining/' + id, this.updateDetail);
        this.props.fetchFromHub(`blobs/videos/` + id, this.postProcessVideos);
        this._updatePdfInfo(id);
    }

    _updatePdfInfo(id) {
        this.props.fetchFromHub(`blobs/pdfs/` + id, this.postProcessPdfInfo);
    }

    updateDetail = (data) => {
        const wayfinderProfileIds = data.wayfinderProfile ? [data.wayfinderProfile.id] : [];
        setWayfinderProfiles(wayfinderProfileIds);

        this.setState({
            detail: data
        });
    };

    postProcessVideos = (videos) => {
        const videosFull = [];
        for (let i = 0; i < videos.length; i++) {
            const fullVideo = videos[i];
            fullVideo.urlFrontend = videos[i].urlFrontend;
            videosFull.push(fullVideo);
        }

        this.setState({videos: videosFull});
    };

    postProcessPdfInfo = (pdfs) => {
        this.setState({pdfs: pdfs});
    };

    setObject = () => {
        const item = {
            module: TacModuleTypeEnum.DINING,
            objectId: this.state.detail.id,
            numberOfPersons: 4
        }
        this.props.updateSelectedItem(item);

        browserHistory.push('/tacbooking');
    }

    render() {
        let item = this.state.detail;

        if (item === null) {
            return false;
        }

        return (
            <div>
                <div className="grid-x grid-padding-x">
                    <div className="cell small-12 medium-4 large-4">
                        <DetailImage image={this.props.configuration.hubUrl + "blobs/image?reference=" + item.id}/>
                    </div>
                    <div className="cell small-12 medium-8 large-8 text-left">
                        <Title>
                            {getTranslation(item.title)}
                        </Title>
                        <Description textContent={getTranslation(item.description)}/>
                        <LocationAndTimes item={item} />
                        { (this.state.detail.externalId && !this.props.configuration.hubSettings.isDigitalSignage) &&
                            <TacBookButton setObject={this.setObject} />
                        }
                        <If test={this.state.pdfs}>
                            <PdfItems items={this.state.pdfs}/>
                        </If>
                        <If test={this.state.videos}>
                            <VideoComponent videoItems={this.state.videos}/>
                        </If>
                    </div>
                </div>
            </div>
        )
    }
}


DiningDetail.contextTypes = {
    t: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
    return {
        configuration: state.configuration,
        tacBookings: state.tacBookings,
    };
};

export default connect(mapStateToProps, {fetchFromHub, updateSelectedItem})(DiningDetail);
