// a reducer receives the current state, and an action
import {REQUEST_FAILED, REQUEST_STARTED, REQUEST_SUCCESSFUL} from "./constants";

export const reducer = (state, action) => {
    switch (action.type) {
        case REQUEST_STARTED:
            return {
                ...state,
                isLoading: true,
            };
        case REQUEST_SUCCESSFUL:
            return {
                ...state,
                isLoading: false,
                error: null,
                data: action.data,
            };
        case REQUEST_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };

        default:
            return state;
    }
};
