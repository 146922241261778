import React from 'react';
import {imagePath} from "../../utils/imageUtils";

function GalleryToggleButton(props) {
    return (
        <div className="absolute bottom-0 right-0">
            {props.toggleGallery &&
            <button className="p-3 bg-primary-light cursor-pointer"
                    onClick={props.toggleGallery}>
                <img src={imagePath('playiconamb.svg')} alt="open gallery" className="w-6 h-6"/>
            </button>
            }
        </div>
    );
}

export default GalleryToggleButton;