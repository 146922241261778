import React from 'react';
import VodImageButton from '../../components/VodImageButton';
import OverviewImageButtonPanel from '../../components/OverviewImageButtonPanel';

function RiverActivitiesList(props) {
    const activities = props.location.state?.excursions;

    if(activities.length === 0) {
        return (
            <div>
                No activities found for this location.
            </div>
        )
    }

    return (
        <OverviewImageButtonPanel>
            {activities.map(activity =>
                <VodImageButton
                    heightWidthRatio="100%"
                    truncateTitle={true}
                    key={activity.uuid}
                    link={`/musement/activity/${activity.uuid}`}
                    title={activity.title}
                    extraState={{pageTitle: "Musement activity - " + activity.title, date: props.location.state.date}}
                    thumbnail={activity.cover_image_url}
                />
            )}
        </OverviewImageButtonPanel>
    );
}

export default RiverActivitiesList;

