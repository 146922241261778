import React from 'react';
import {useGet} from "../../hooks/useGet";
import Loading from "../../components/Loading";
import OverviewImageButtonPanel from "../../components/OverviewImageButtonPanel";
import DateButton from "../../components/DateButton";
import {createSubtitle} from "../../utils/itineraryUtils";
import {formatDMYtoYMD} from "../../utils/dateTimeUtils";

function SparkOverview(props) {

    const {isLoading, error, data} = useGet("itinerary/schedules");

    if(isLoading) {
        return <Loading/>
    }

    if(error) {
        return <div>
            There has been an error: {error}
        </div>
    }

    return (
        <div>
            <OverviewImageButtonPanel>
                {
                    data.map((day, index) =>
                        <div key={day.id} className="cell">
                            <DateButton link={"/itinerary/spark"}
                                        id={formatDMYtoYMD(day.date)}
                                        date={day.date}
                                        disabled={false}
                                        index={index}
                                        title={day.title.defaultValue}
                                        subtitle={createSubtitle(day)}
                                        thumbnail={day.image}
                            />
                        </div>
                    )}
            </OverviewImageButtonPanel>
        </div>
    );
}

export default SparkOverview;