import React, {Component} from 'react';
import {connect} from "react-redux";
import {DefaultButtonLinkExternal} from "../../resources/styles";

class OrderDetail extends Component {

    render() {
        const order = this.props.location.state;
        const nrDigitals = order.digital.photos.length;
        const zipName = order.digital.zip.split("/").pop();
        const photoUrls = order.digital.photos.map(photo => photo.url.split("/").pop());
        return (
                <div className="row">
                    <div className="medium-12 columns">
                        <h2>
                            Order Details
                        </h2>

                        <div>
                            Total: {order.totals.grandTotalString}
                        </div>
                        <div>
                            This order contains {nrDigitals} photo{nrDigitals !== 1 ? "s" : ""}.

                            {nrDigitals > 9 &&
                            <div>
                                You can view and download them individually or as a zip archive.
                                <div>
                                    <DefaultButtonLinkExternal href={`${this.props.hubUrl}photomatch/downloadzip/${zipName}`} download target="_blank">Download zip file</DefaultButtonLinkExternal>
                                </div>
                            </div>
                            }
                        </div>
                        <div>
                            {photoUrls.map(photoUrl =>
                                <div className="my-2" key={photoUrl}>
                                    <img src={`${this.props.hubUrl}photomatch/viewphoto/${photoUrl}?thumb`} alt="Download preview"/>
                                    <div className="mt-1">
                                        <DefaultButtonLinkExternal href={`${this.props.hubUrl}photomatch/viewphoto/${photoUrl}`} download target="_blank">Download image</DefaultButtonLinkExternal>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        hubUrl: state.configuration.hubApiUrl,
    };
};

export default connect(mapStateToProps)(OrderDetail);

