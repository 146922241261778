export default class CurrencyUtils {

    static convertToCurrency(value) {
        return parseFloat(value).toFixed(2);
    }

    static formatCurrency(currencySymbol, value) {
        if (currencySymbol) {
            return currencySymbol + " " + this.convertToCurrency(value);
        }
        const amount = this.convertToCurrency(value);
        return `\u00A3 ${amount}`;

    }
}