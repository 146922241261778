import React from "react";
import styled from "styled-components";

const StyledDaySwitch = styled.div`
    color: ${props => props.theme.subheaderColor || "#fefefe"};   
    border: 1px solid white;
`;

function WeatherDayPicker(props) {
    const todayButtonClassName = props.day === 0 ? "day-switch-button-active button" : "day-switch-button button";
    const tomorrowButtonClassName = props.day === 1 ? "day-switch-button-active button" : "day-switch-button button";

    return (
        <div className="grid-x">
            <div className="medium-12 cell">
                <div className="text-center weather-switch-container">
                    <StyledDaySwitch className={todayButtonClassName}
                                     onClick={() => props.setDay(0)}>Today
                    </StyledDaySwitch>
                    <StyledDaySwitch className={tomorrowButtonClassName}
                                     onClick={() => props.setDay(1)}>Tomorrow
                    </StyledDaySwitch>
                </div>
            </div>
        </div>
    )
}

export default WeatherDayPicker;