import React from "react";
import {connect} from "react-redux";
import PlayerSelection from "./PlayerSelection";
import {fetchPlayoutAssets, fetchVodDetails} from "../../actions/vodActions";

class VodPrefetch extends React.Component {
    constructor() {
        super();
        this.state = {
            errorMessage: null,
        };
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.props.fetchPlayoutAssets(this.props.match.params.id);
            this.props.fetchVodDetails(this.props.match.params.id);
        }
    }

    render() {
        if (!this.props.vodassets || !this.props.voddetails) {
            return false;
        }

        return (
            <PlayerSelection />
        );

    }

}


const mapStateToProps = state => {
    return {
        vodassets: state.voditem.vodassets,
        voddetails: state.voditem.voddetails,
    };
};

export default connect(mapStateToProps, {fetchPlayoutAssets: fetchPlayoutAssets, fetchVodDetails: fetchVodDetails} )(VodPrefetch);
