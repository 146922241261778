import React from 'react';

function DepartureTime(props) {
    if(!props.fromTime) {
        return null;
    }

    const fromTime = props.fromTime.lengh > 12 ? props.fromTime.substring(11) : props.fromTime;

    return (
        <div>
            Time: {fromTime}
        </div>
    );
}

export default DepartureTime;