import React from "react";
import styled from "styled-components";
import TrafficLight from "./TrafficLight";

const RestaurantAvailabilityKey = styled.div`
    display: flex;
    background-color: ${props => props.theme.contentBoxBackground || "white"};
    border: solid 1px lightgray;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    max-width: max-content;
    padding: 0.5rem;
    align-items: center; 
    margin-bottom: 1rem;
    
    p {
        margin: 0 0.5rem 0 0.3rem;
        margin-bottom: 0;
    }    
        
    @media screen and (orientation: landscape) {
        margin-top: 0;    
    }         
`;

const RestaurantAvailabilitySubHeading = styled.p`
    font-size: 1.2rem;
`;

export default function RestaurantAvailabilityHeader(props) {
    return (
        <div>
            <h1>{props.templateValues.pageTitle}</h1>
            { props.templateValues.subHeading !== "" &&
                <RestaurantAvailabilitySubHeading>{props.templateValues.subHeading}</RestaurantAvailabilitySubHeading>
            }
            <div>
                <h4>Key</h4>
                <RestaurantAvailabilityKey>
                    <TrafficLight trafficLightColor="Green"/>
                    <p>Plenty of room</p>
                    <TrafficLight trafficLightColor="Yellow"/>
                    <p>Busy</p>
                    <TrafficLight trafficLightColor="Red"/>
                    <p>Full/Queues</p>
                </RestaurantAvailabilityKey>

            </div>
        </div>
    )
}