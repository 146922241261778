import React, {Component} from 'react';
import {connect} from 'react-redux';
import browserHistory from "../utils/history";
import {logoutUser} from "../actions/authenticationActions";
const logoutTime = 60;

class InactivityLogout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            secondsRemaining: logoutTime,
        };
        this.lastActionTimestamp =  new Date().getTime();
    }

    componentDidMount() {
        this.interval = setInterval(this.tick, 500);
        document.addEventListener("click", this.handleClick);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        document.removeEventListener("click", this.handleClick);
    }

    handleClick = () => {
        this.lastActionTimestamp = new Date().getTime();
    }

    tick = () => {
        const currentTime = new Date().getTime();

        const diff = currentTime - this.lastActionTimestamp
        if(diff > logoutTime * 1000 ) {
            this.logoutInClass()
        }
        else {
            this.setState({
                secondsRemaining: logoutTime - parseInt(diff/1000),
            })
        }
    }

    async logoutInClass() {
        await this.props.logoutUser();
        browserHistory.push("/");
    }

    render() {
        return (
            <div className="text-center">
                Logout - {this.state.secondsRemaining} seconds
            </div>
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        logoutUser: () => dispatch(logoutUser()),
    };
};

export default connect(null, mapDispatchToProps) (InactivityLogout);