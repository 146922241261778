import {
    CLEAR_VOD_AUTHENTICATION,
    REMOVE_FROM_WATCHLIST,
    SET_AUDIO_ALBUMS,
    SET_AUDIO_GENRES,
    SET_AUDIO_WATCHLIST,
    ADD_AUDIO_STREAM,
    UPDATE_AUDIO_STREAM,
    MAX_AUDIOS_REACHED,
    SET_SEARCHTERM_AUDIOS,
    SET_AUDIO_ERROR,
    CLEAR_AUDIO_ERROR
} from "../constants/audio-action-types";

const INITIAL_STATE = {
    audios: [],
    genres: [],
    watchlistitems: [],
    errormessage: null,
    searchTermAudio: "",
    maxReached: false
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_AUDIO_ALBUMS:
            return { ...state, audios: action.audios, maxReached: false };

        case SET_AUDIO_GENRES:
            return { ...state, genres: action.genres};

        case SET_AUDIO_WATCHLIST:
            return { ...state, watchlistitems: action.watchlistitems };

        case REMOVE_FROM_WATCHLIST:
            return { ...state,
                watchlistitems: state.watchlistitems.filter(
                    item => item.id !== action.watchlistitem.id
                ),
            };

        case ADD_AUDIO_STREAM:
            return { ...state, audios: [...state.audios, action.audios] };

        case UPDATE_AUDIO_STREAM:
            return { ...state,
                audios: state.audios.map(
                    (audio, i) => {
                        if (audio.id === action.audio.id) {
                            return action.audio;
                        } else {
                            return audio;
                        }
                    }
                )
            };

        case MAX_AUDIOS_REACHED:
            return { ...state, maxReached: true };

        case SET_SEARCHTERM_AUDIOS:
            return { ...state, searchTermAudio: action.searchTermAudio };

        case SET_AUDIO_ERROR:
            return { ...state, errormessage: action.errormessage };

        case CLEAR_AUDIO_ERROR:
            return { ...state, errormessage: null };

        case CLEAR_VOD_AUTHENTICATION:
            return INITIAL_STATE;

        default:
            return state;
    }
}
