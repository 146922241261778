import React from 'react';
import {Link} from 'react-router-dom';
import ExcursionPrices from "./ExcursionPrices";
import styled, { css } from 'styled-components';

const ButtonStyles = css`
    text-transform: uppercase;
    text-align: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: .25rem;
    font-size: 1.125rem;
    line-height: 1.333;
    box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.2);
    color: #fff;
    background-color: ${props => props.theme.headerColor || '#6B207D'};
    cursor: pointer;
    display: block;
    width: 100%;

    :hover{
        color: #fff;
    }
`;

const StyledLink = styled(Link)`${ButtonStyles}`;
const StyledButton = styled.button`${ButtonStyles}`;

class PriceInfoBox extends React.Component {

    getButton = () => {
        if(this.props.buttonIsLink) {
            return (
                <StyledLink to={this.props.linkTo}> {this.props.buttonText} </StyledLink>
            );
        }
        else {
            return (
                <StyledButton onClick={this.props._onClick}> {this.props.buttonText} </StyledButton>
            )
        }
    };

    render() {
        const adultPrice = this.props.adultPrice;
        let childPrice = this.props.childPrice;
        let hidePoundSign = false;
        let ageRange = "(3-12)";

        if (!this.props.childPrice) {
            childPrice = this.props.childPrice === 0 ? "FREE" : "N/A";
            hidePoundSign = true;
        }

        if(this.props.minimumAge && this.props.minimumAge > 2) {
            ageRange = `(${this.props.minimumAge}-12)`;

            if(this.props.minimumAge > 12) {
                childPrice = "N/A";
                ageRange = "(3-12)";
            }

        }
        const limitedPlaces = getLimitedAvailabilityText(this.props.currentAvailability);

        return (
            <div className={this.props.classes}>
                {limitedPlaces && <div className="mb-2"><span className="text-red border-dotted border-b border-red">{limitedPlaces}</span></div>}
                <ExcursionPrices
                    adultPrice={adultPrice}
                    hidePoundSign={hidePoundSign}
                    childPrice={childPrice}
                    ageRange={ageRange}
                />

                {!this.props.hideButton &&
                <div className="mt-2">
                    {this.getButton()}
                </div>
                }
            </div>
        )
    }

}

export default PriceInfoBox;

function getLimitedAvailabilityText(currentAvailability) {
    if(currentAvailability > 20 || currentAvailability < 1) {
        return "";
    }
    if(currentAvailability > 9) {
        return "Limited Spaces Available ";
    }
    return `${currentAvailability} Space${currentAvailability > 1 ? "s" : ""} Remaining`;

}