import React from "react";

export default class BasketImage extends React.Component {
    render() {
        let style = {
            height: "auto",
            width: "100px",
            marginBottom: "0"
        };

        return (
            <div>
                <img src={this.props.image} className="thumbnail" alt="basket entry" style={style}/>
            </div>
        )
    }
}