import React, {useState} from 'react';
import {extractImageUrl, getVariantBlurb} from "../../utils/photoMatchUtils";
import MyPhotosDetail from "./MyPhotosDetail";
import CurrencyUtils from "../../utils/currencyUtils";
import {removePhotoFromCart} from "../../actions/photoMatchActions";
import BasketImage from "./BasketImage";
import {PhotomatchButtonLink, PhotomatchCartEntry} from "../../resources/styles";


function PhotoMatchCartEntry(props) {
    const [showPhotoModal, setShowPhotoModal] = useState(false)
    let thumbnail = extractImageUrl(props.photo.url);

    const toggleModalPhoto = () => {
        setShowPhotoModal(!showPhotoModal);
    }

    return (
        <div className="my-4 relative">

            {showPhotoModal &&
            <MyPhotosDetail
                onClose={toggleModalPhoto}
                image={props.photo}
                closeOnUpdate={true}
            />
            }

            <PhotomatchCartEntry>
                <div className="mr-4 cursor-pointer" onClick={toggleModalPhoto}>
                    <BasketImage image={thumbnail}/>
                </div>

                <div>
                    {
                        props.photo.variants.map(variant => {
                            if (variant.count === 0) {
                                return false;
                            }

                            return (
                                <div key={variant.id}>
                                    {variant.count} x {getVariantBlurb(variant.id)} £{CurrencyUtils.convertToCurrency(variant.total)}
                                </div>
                            )
                        })
                    }
                    <PhotomatchButtonLink onClick={toggleModalPhoto}>Edit extras</PhotomatchButtonLink>
                    {props.photo.isDiscounted &&
                    <div>
                        *This item has discount applied to it
                    </div>
                    }
                </div>
                <div className="absolute right-0 top-0 mr-4 mt-2">
                    <button className="cursor-pointer" type="button" onClick={() => {removePhotoFromCart(props.photo.id)}}>
                        <i className="fa fa-times text-lg" />
                    </button>
                </div>
            </PhotomatchCartEntry>
        </div>
    );
}

export default PhotoMatchCartEntry;