import React from "react";

function *ratingStars({value, maxValue, onHover, onHoverEnd, onClick}) {
    for (let index = 0; index < maxValue; index++) {
        let isChecked = value != null && index < value;
        yield <span key={index} data-rating={index+1} className={isChecked ? 'rating-checked' : ''} 
            onMouseEnter={onHover} onMouseLeave={onHoverEnd} onClick={onClick}>{isChecked ? '\u2605' : '\u2606'}</span>;
    }
}

export default class Rating extends React.Component {
    constructor() {
        super();
        this.state = {editingValue: null};
        this._hover = this._hover.bind(this);
        this._hoverEnd = this._hoverEnd.bind(this);
        this._clicked = this._clicked.bind(this);
    }

    render() {
        let ratingProps = {
            value: this.state.editingValue != null ? this.state.editingValue : this.props.value, 
            maxValue: this.props.maxValue
        };
        if (!this.props.disabled) {
            ratingProps.onHover = this._hover;
            ratingProps.onHoverEnd = this._hoverEnd;
            ratingProps.onClick = this._clicked;
        }
        return <div className="rating">{Array.from(ratingStars(ratingProps))}</div>;
    }

    _hover(event) {
        this.setState({editingValue: event.target.getAttribute('data-rating')});
    }

    _hoverEnd(event) {
        this.setState({editingValue: null});
    }
    
    _clicked(event) {
        let ratingValue = event.target.getAttribute('data-rating');
        if (this.props.onRatingChanged != null) {
            this.props.onRatingChanged(ratingValue);
        }
    }
}