import React from "react";
import browserHistory from "../utils/history";
import moment from "moment";
import {connect} from "react-redux";
import {fetchFromHub} from "../actions/networkActions";
import {ItemContainer, TileContainer, device} from "../resources/styles";
import styled, {withTheme} from "styled-components";
import {PropTypes} from "prop-types";

const DateTileContainer = styled.div `
    background-color: white;
    height: 100%;
    text-align: center;
    border-radius: ${props => props.theme.tileBorderRadius || "0px"};
`;

const TitleArea = styled.div `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.backgroundColor};
    height: 100%;
    font-size: 0.7rem;
    padding: 0.2rem;
    border-radius: ${props => props.theme.tileBorderRadius || "0px"};
    div {
        padding: 0.1rem;
    }
    
    div:nth-child(2n-1) {
        font-weight: bold;
        text-transform: uppercase;
    }
    
    @media ${device.tablet} {
        font-size: 1rem;
        padding: 0.5rem;
        div {
            padding: 0.2rem;
        }        
    }
`;


const DayOfWeek = styled.div `
    display: none;
    @media ${device.tablet} {
        display: inline;
    }
`;


class DateButton extends React.Component {

    goToLink(e) {
        e.preventDefault();
        browserHistory.push(this.props.link + "/" + this.props.id, this.props.linkState);
    }

    render() {
        const index = this.props.index % 8;
        let backgroundColor;
        const colorFromTheme = this.props.theme.itineraryGradientColor || "#dfffff";
        const opacity = `0.${index + 2}`;

        if (colorFromTheme.includes('#')) {
            function colorConvert() {
                const removeHash = colorFromTheme.substring(1);
                const hex = removeHash.match(/.{1,2}/g);
                const convertedToRgba = 'rgba(' + [
                        parseInt(hex[0], 16),
                        parseInt(hex[1], 16),
                        parseInt(hex[2], 16)]
                    + ',' + opacity + ')'
                ;
                return convertedToRgba;
            }
            backgroundColor = colorConvert();
        } else {
            const newColor = colorFromTheme.replace(/rgb/i, "rgba");
            backgroundColor = newColor.replace(/\)/i,',' + opacity + ')');
        }

        let linkStyle = {};

        let disabledButton = false;
        if (this.props.disabled) {
            linkStyle = {
                pointerEvents: "none",
                cursor: "default",
            };
            disabledButton = true;
        }

        const momentDate = moment(this.props.date, "DD/MM/YYYY");
        const dayMonth = momentDate.format("DD MMM");
        const dayOfWeek = momentDate.format("dddd");

        return (
            <TileContainer heightWidthRatio={this.props.theme.tileHeightWidthRatio || "100%"}>
                    <ItemContainer onClick={this.goToLink.bind(this)} disabled={disabledButton} style={linkStyle}>
                        <DateTileContainer>
                            <TitleArea backgroundColor={backgroundColor}>
                                {this.props.date ? (
                                    <>
                                        <div dangerouslySetInnerHTML={{__html: dayMonth}}/>
                                        <DayOfWeek dangerouslySetInnerHTML={{__html: dayOfWeek}}/>
                                    </>
                                ):(
                                    <div dangerouslySetInnerHTML={{__html: `${this.context.t('day')} ${this.props.day}`}}/>
                                )}
                                <div dangerouslySetInnerHTML={{__html: this.props.title}}/>
                                <div id="tui_DateButton_subtitle1"
                                     dangerouslySetInnerHTML={{__html: this.props.subtitle}}/>
                            </TitleArea>
                        </DateTileContainer>
                    </ItemContainer>
            </TileContainer>
        )
    }
}

DateButton.contextTypes = {
    t: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
    return {
        date: ownProps.date,
        lang: state.i18nState.lang,
    };
};

export default connect(mapStateToProps, {fetchFromHub})(withTheme(DateButton));
