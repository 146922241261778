import React from "react";
import {connect} from "react-redux";
import {PropTypes} from "prop-types";
import {fetchFromHub} from "../../actions/networkActions";
import {getTranslation} from "../../translations/translationUtils";
import OverviewImageButton from "../../components/OverviewImageButton";
import OverviewImageButtonPanel from "../../components/OverviewImageButtonPanel";

class Overview extends React.Component {
    constructor() {
        super();
        this.state = {
            profiles: null
        };
    }

    componentDidMount() {
        this.props.fetchFromHub(`ratings/profiles?allInOne=false`, this.updateProfiles);
    }

    updateProfiles = (profiles) => {
        this.setState({
            profiles: profiles,
        })
    };

    render() {
        if (this.state.profiles == null) {
            return false;
        }

        return (
            <OverviewImageButtonPanel>
                {
                    this.state.profiles.map((profile) => {
                        const title = getTranslation(profile.title);

                        return (
                            <OverviewImageButton
                                        key={profile.id}
                                        link={"/ratings/feedback/" + profile.id}
                                        title={title}
                                        thumbnail={this.props.configuration.hubUrl + "blobs/image?reference=" + profile.id + "&width=400"}
                             />
                        )
                    })
                }
            </OverviewImageButtonPanel>
        )
    }
}

const mapStateToProps = state => {
    return {
        configuration: state.configuration,
        lang: state.i18nState.lang
    };
};

Overview.contextTypes = {
    t: PropTypes.func.isRequired
};

export default connect(mapStateToProps, {fetchFromHub: fetchFromHub})(Overview);