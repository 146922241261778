import React, {useEffect, useState} from 'react';
import {getFromHubNew} from "../../actions/networkActions";
import Loading from "../../components/Loading";
import OverviewImageButton from "../../components/OverviewImageButton";
import {getTranslation} from "../../translations/translationUtils";
import OverviewImageButtonPanel from "../../components/OverviewImageButtonPanel";
import {useSelector} from "react-redux";
import { PropTypes } from 'prop-types';

function ShoreExcursionList(props, context) {
    const dateString = props.match.params.date.replace(/-/g, "/");
    const hubUrl = useSelector(state => state.configuration.hubUrl);

    const [excursions, setExcursions] = useState([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const fetchData = async () => {
            const excursionsData = await getFromHubNew(`excursions/byDate?dateAsString=${dateString}`);
            setExcursions(excursionsData);
            setLoading(false);
        }

        fetchData();
    }, [dateString]);

    if(loading) {
        return (
            <Loading/>
        )
    }

    if(!excursions || excursions.length === 0) {
        return (
            <div>
                <h3>{context.t("no_experiences_available")}</h3>
            </div>
        )
    }

    return (
        <OverviewImageButtonPanel>
            {
                excursions.map((excursion) =>
                    <OverviewImageButton
                        key={excursion.id}
                        item={excursion}
                        link={`/shoreexcursion/${excursion.id}`}
                        title={getTranslation(excursion.name)}
                        thumbnail={hubUrl + "blobs/image?reference=" + excursion.id + "&width=400"}/>
                )
            }
        </OverviewImageButtonPanel>
    );
}

ShoreExcursionList.contextTypes = {
    t: PropTypes.func.isRequired,
}

export default ShoreExcursionList;