import React, { useEffect, useState } from "react";
import ImageWithFallback from "../../components/ImageWithFallback";
import ImageGallery from 'react-image-gallery';
import { getFromHubNew } from "../../actions/networkActions";
import styled from "styled-components";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { defaultImageSrc } from "../../utils/imageUtils";
import { useGet } from "../../hooks/useGet";
import Loading from "../../components/Loading";
import { PropTypes } from "prop-types";

export const ColouredBox = styled.div`
   background-color: ${props => props.color};
   --bg-opacity: 1;
   border-radius: 0.25rem;
   width: 3rem;
   margin-right: 1rem;
   height: 2rem;
`;

const TableHeaderContainer = styled.div`
  background-color: ${props => props.theme.headerColor || '#6B207D'};
  color: ${props => props.theme.bodyBackground || '#F3F3F3'};
  text-transform: uppercase;
  padding: 1rem;
`;
   

const ShipDetail = (props, context) =>{

    const hubUrl = useSelector(state => state.configuration.hubUrl);

    const [images,setImages] = useState({
        shipImages:[],
        shipDeckPlan: [],
        cabinImages: [],
    })
  
    const shipData = useGet('ships/content');

  useEffect(()=>{
    const getImages = async()=>{

        const promiseArray = [];
        promiseArray.push(getFromHubNew(`blobs/images/${shipData.data.shipCode}`));
        shipData.data.cabin.cabinDeck.forEach(({cabinCode})=>{
            promiseArray.push(getFromHubNew(`blobs/images/${shipData.data.shipCode}_${cabinCode}`));
        })

        const [shipImagesResponse, ...cabinImagesResponse] = await Promise.all(promiseArray);

        if(shipImagesResponse && cabinImagesResponse){
            const allShipImages = shipImagesResponse.filter(image => image.category === "Ship");
            const shipDeckPlan = shipImagesResponse.filter(image => image.category === "DeckPlan");
            const buildImages = image => ({ thumbnail: `${hubUrl}${image.url}&width=200`, original: `${hubUrl}${image.url}`})
            setImages({
                shipImages: allShipImages.map(buildImages),
                shipDeckPlan: shipDeckPlan.map(buildImages),
                cabinImages: cabinImagesResponse.map(eachCabin => eachCabin.map(buildImages)),
            });
        }
    }

    if(shipData.data?.shipCode){
        getImages();
    }
  },[shipData, hubUrl])

  if(shipData.isLoading){
    return <Loading/>;
  }

  if(isEmpty(shipData.data) || shipData.error){
    return "No Ship Data";
  }

    return (
        <div className="container mt-4 mb-8 mx-auto">
            <div className="container-2-1">
                {images.shipImages.length>0 && (
                    <ImageWithFallback
                        className="container-2-1-img cover-fit"
                        alt={shipData.data.shipName + " - header image"}
                        src={images.shipImages?.[0].original || defaultImageSrc()}
                        />
                )}
            </div>
            <div>
                <div>
                    <h1 className="excursion bold">{shipData.data.shipName}</h1>
                </div>
                <div className="flex-none sm:flex">
                    {/* Description */}
                    <div className="w-full py-2" dangerouslySetInnerHTML={{__html: shipData.data.description}}/>
                    {/* tech data */}
                    <KeyValueTable className="w-full" data={shipData.data.technicalData} title={context.t('technical_data')} />
                </div>
            </div>
            <div>
                <div>   
                    <h1 className="excursion bold">{shipData.data.cabin.title}</h1>
                </div>
                <div className="flex-none sm:flex">
                    {/* Cabin Description */}
                    <div className="w-full sm:w-1/2 py-2" dangerouslySetInnerHTML={{__html: shipData.data.cabin.description}}/>
                    {/* Deck plan */}
                    <div className="w-full sm:w-1/2 p-4">
                        <ImageWithFallback
                            className="w-full h-auto"
                            alt={`${shipData.data.shipCode}_deckplan`}
                            src={images.shipDeckPlan.length>0 ? images.shipDeckPlan[0].original : defaultImageSrc()}
                        />
                    </div>
                </div>
            </div>
            <div className="mt-6">
                {shipData.data.cabin.cabinDeck.map(({cabinCategory, colours, cabinCode},index)=>(
                    <div className="mt-4" key={index}>
                        {/* Cabin colors and images */}
                        <div>   
                            <h2 className="excursion bold">{cabinCategory}</h2>
                        </div>
                        <div className="flex">
                            {colours.map((color, index)=>(
                                <ColouredBox color={`#${color}`} key={`${color}-${index}`}/>
                            ))}
                        </div>
                        <div className="flex-none sm:flex pt-4">
                            <div className="w-full sm:w-1/2 mb-2 mr-2">
                                <ImageWithFallback
                                    className="w-full h-auto"
                                    alt={`${shipData.data.shipCode} - ${cabinCode}`}
                                    src={images.cabinImages.length > 0 ? images.cabinImages?.[index]?.[1]?.original : defaultImageSrc()}
                                />
                            </div>
                            <div className="w-full sm:w-1/2 mb-2">
                                <ImageWithFallback
                                    className="w-full h-auto"
                                    alt={`${shipData.data.shipCode} - ${cabinCode} - deck-plan`}
                                    src={images.cabinImages.length > 0 ? images.cabinImages?.[index]?.[0]?.original : defaultImageSrc()}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="mt-6">
                <ImageGallery
                    items={images.shipImages.slice(1)}
                    lazyLoad={true}
                    showIndex={false}
                    defaultImage={defaultImageSrc()}
                    showPlayButton={false}
                />
            </div>
        </div>
    )
}

const KeyValueTable = ({data, title, className}) =>{
    return(
        <div className={`${className} p-2`}>
            <TableHeaderContainer>{title}</TableHeaderContainer>
            <div className="bg-primary-lightest p-4 py-0">
                <table className="table-auto">
                    <thead></thead>
                    <tbody>
                        {data.map(({spalte1,spalte2},index)=>(
                            <tr className="border" key={index}>
                                <td className="p-2">{spalte1}</td>
                                <td className="p-2">{spalte2}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            
        </div>
    )
}

ShipDetail.contextTypes = {
    t: PropTypes.func.isRequired
};

export default ShipDetail;