import {
    SET_VOD_APIVERSION,
    SET_VOD_APPIDENTIFIER,
    SET_VOD_DEMOSYSTEM,
    SET_VOD_PAYMENTENABLED,
    SET_VOD_URL,
    SET_VOD_USEWITHCREDENTIALS
} from "../constants/vodconfiguration-action-types";


export function setVodConfiguration (vodConfiguration){

    return async function (dispatch) {

        if (vodConfiguration.url && vodConfiguration.url !== "") {
            dispatch({type: SET_VOD_URL, url: vodConfiguration.url});
        }
        if (vodConfiguration.appIdentifier && vodConfiguration.appIdentifier !== "") {
            dispatch({type: SET_VOD_APPIDENTIFIER, appIdentifier: vodConfiguration.appIdentifier});
        }
        if (vodConfiguration.apiVersion && vodConfiguration.apiVersion >= 3) {
            dispatch({type: SET_VOD_APIVERSION, apiVersion: vodConfiguration.apiVersion});
        }
        if (vodConfiguration.paymentEnabled) {
            dispatch({type: SET_VOD_PAYMENTENABLED, paymentEnabled: vodConfiguration.paymentEnabled});
        }
        if (vodConfiguration.isDemoSystem) {
            dispatch({type: SET_VOD_DEMOSYSTEM, isDemoSystem: vodConfiguration.isDemoSystem});
        }
        if (vodConfiguration.useWithCredentials) {
            dispatch({type: SET_VOD_USEWITHCREDENTIALS, useWithCredentials: vodConfiguration.useWithCredentials});
        }
    }
}