import React from 'react';
import ReactDOM from 'react-dom';
import 'font-awesome/css/font-awesome.min.css';
import 'foundation-sites/dist/css/foundation.css';
import 'foundation-sites/dist/css/foundation-prototype.css';
import 'leaflet/dist/leaflet.css';
import 'leaflet/dist/leaflet';
import './index.css';
import './resources/css/modal.css';
import App from './App';
import {unregister} from './registerServiceWorker';

ReactDOM.render(<App/>, document.getElementById('root'));
unregister();