import React from 'react';
import {connect} from 'react-redux';
import {PropTypes} from "prop-types";
import browserHistory from '../../utils/history';
import styled, {keyframes} from "styled-components";

const rotate = keyframes`
   0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
 display: inline-block;
 position: relative;
 width: 80px;
 height: 80px;
    
 div{
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    animation: ${rotate} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border: 8px solid ${props => props.theme.tileTitleColor || "#FFF"};
    border-color: ${props => props.theme.tileTitleColor || "#FFF"} transparent transparent transparent;
 }
  
  div:nth-child(1) {
     animation-delay: -0.45s;
  }
   div:nth-child(2) {
     animation-delay: -0.3s;
  }
   div:nth-child(3) {
     animation-delay: -0.15s;
  }
`;

class RetinaLoginSuccess extends React.Component {
    constructor() {
        super();
        this.state = {
            secondsLeft: 5
        };
    }

    componentDidMount() {
        this.refreshInterval = setInterval(this.reduceSeconds, 1000);

        setTimeout(() => {
            this.goToDashboard();
        }, 5000);
    }

    componentWillUnmount() {
        clearInterval(this.refreshInterval);
    }

    goToDashboard = () => {
        browserHistory.push("/");
    }

    reduceSeconds = () => {
        this.setState({secondsLeft: this.state.secondsLeft - 1});
    }

    render() {
        let guestName = "";

        if (this.props.authentication && this.props.authentication.pms && this.props.authentication.pms.information) {
            let pmsInfoObject = JSON.parse(this.props.authentication.pms.information);
            console.log("pmsInfoObject: ", pmsInfoObject);
            guestName = pmsInfoObject.name;
        }

        let largerFont = {
            fontWeight: 600
        };

        return (

            <div>
                <div className="grid-x grid-padding-x">
                    <div className="small-4 text-center">
                        <Spinner className="center-vertical-div">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </Spinner>
                    </div>
                    <div className="small-8">
                        <label style={largerFont}>{this.context.t("login_success")} {guestName}.</label><br/>
                        <p>{this.context.t("redirect_dashboard")} {this.state.secondsLeft}</p>
                        <p>{this.context.t("redirect_click_dashboard")} <a href="/">{this.context.t("click_here")}</a></p>
                    </div>
                </div>
            </div>
        );
    }

}

RetinaLoginSuccess.contextTypes = {
    t: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    return {
        authentication: state.authentication,
        developmentMode: state.configuration.developmentMode,
        lang: state.i18nState.lang
    };
};


export default connect(mapStateToProps, null)(RetinaLoginSuccess);
