import React from "react";
import CurrencyUtils from "../../utils/currencyUtils";
import {PropTypes} from "prop-types";
import {connect} from "react-redux";

class Invoices extends React.Component {

    render() {
        let invoices = this.props.invoices;
        if (invoices === null) {
            return false;
        }

        let total = 0;
        invoices.forEach((invoice) => {
            total += invoice.chargesWithDiscounts ? parseFloat(invoice.chargesWithDiscounts) : parseFloat(invoice.charges);
        });
        total = Math.round(total * 100) / 100;

        const showDepartment = !!invoices?.[0]?.department;


        return (
            <div>
                <h4>{this.context.t("total_charges")} {CurrencyUtils.formatCurrency(this.props.currency, total)}</h4>
                <table className="animated fadeIn">
                    <thead>
                    <tr>
                        <th>{this.context.t("date")}</th>
                        {showDepartment && <th>{this.context.t("department")}</th>}
                        <th>{this.context.t("description")}</th>
                        <th>{this.context.t("charges")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    { invoices.length === 0 &&
                        <tr>
                            <td colSpan="4">
                                {this.context.t("no_charges")}
                            </td>
                        </tr>
                    }
                    {
                        invoices.map((invoice, i) =>
                            <React.Fragment key={i}>
                                <tr>
                                    <td>{invoice.chargeDate}</td>
                                    {showDepartment && <td>{invoice.department}</td>}
                                    <td>{invoice.description}</td>
                                    <td>{CurrencyUtils.formatCurrency(this.props.currency, invoice.charges)}</td>
                                </tr>

                                {/* hotelfriend clients will have discounts field coming back as string, other clients as null */}
                                {invoice.discounts && invoice.discounts !== "0.00" &&
                                <tr>
                                    <td></td>
                                    {showDepartment && <td></td>}
                                    <td>{this.context.t("discount")}</td>
                                    <td>{CurrencyUtils.formatCurrency(this.props.currency, invoice.discounts)}</td>
                                </tr>
                                }
                            </React.Fragment>
                        )
                    }
                    </tbody>
                </table>
            </div>
        )
    }
}

Invoices.contextTypes = {
    t: PropTypes.func.isRequired
};
const mapStateToProps = (state, ownProps) => {
    return {
        invoices: ownProps.invoices,
        currency: state.configuration.hubSettings.currency
    };
};
export default connect(mapStateToProps)(Invoices);


