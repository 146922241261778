import React from 'react';
import {Link} from 'react-router-dom';
import SoldOutBox from './SoldOutBox';
import PriceInfoBox from './PriceInfoBox';
import ExcursionDuration from './ExcursionDuration';
import ExcursionAttributes from './ExcursionAttributes';
import {getResourceImageUrl} from '../../actions/networkActions';
import CenteredImageContainer from './CenteredImageContainer';
import BookingCode from "./BookingCode";
import DepartureTime from "./DepartureTime";
import If from "../If";
import {PropTypes} from 'prop-types';
import browserHistory from "../../utils/history";

class ExcursionEntry extends React.Component {
    render() {
        const containerClasses = "bg-white border border-primary flex flex-wrap" + (this.props.first === true ? "" : " mt-8");
        const excursionLink = {
            pathname: `/onboard/excursion/${this.props.id}`,
            state: this.props.locationState,
        };

        const priceBoxDivClasses = `${this.props.currentAvailability > 0 ? "w-1/2" : "w-full"} sm:w-1/3 xl:w-1/4 self-end py-4 px-2 sm:px-4`

        return (
            <div className={containerClasses}>
                <div className="w-full sm:w-1/3 p-2 sm:p-4">
                    <CenteredImageContainer
                        alt="excursion preview"
                        src={getResourceImageUrl(this.props.id, {width: 400, category: "cruiseExcursion"})}
                    />
                </div>

                <div className="w-1/2 sm:w-1/3 xl:flex-grow px-2 sm:px-0 pb-4 sm:pt-4 flex flex-col">
                        <Link className={`text-primary text-2xl uppercase ${this.props.buttonIsLink ? "text-underline" : "text-no-underline"}`} to={excursionLink}>
                            {this.props.name.defaultValue}
                        </Link>

                    <ExcursionAttributes attributes={this.props.excursionTypes} />

                    <div className="text-primary mt-2">
                        <BookingCode bookingCode={this.props.bookingCode} />
                        <DepartureTime fromTime={this.props.fromTime} />
                    </div>

                    <ExcursionDuration duration={this.props.duration} publishedDuration={this.props.publishedDuration} />

                </div>

                <div className={priceBoxDivClasses}>
                    <If test={this.props.currentAvailability <= 0}>
                        <SoldOutBox/>
                        <Link className="block w-full action-button action-button-enabled mt-4" to={excursionLink}>
                            More details
                        </Link>
                    </If>

                    <If test={this.props.currentAvailability > 0}>
                        <PriceInfoBox
                            classes="w-full text-primary-dark float-right"
                            adultPrice={this.props.price.adult}
                            childPrice={this.props.price.child}
                            minimumAge={this.props.minimumAge}
                            buttonText={this.context.t('more_details')}
                            buttonIsLink={this.props.buttonIsLink}
                            _onClick={()=>browserHistory.push(excursionLink)}
                            linkTo={excursionLink}
                            currentAvailability={this.props.currentAvailability}
                        />
                    </If>
                </div>
            </div>
        )
    }
}

ExcursionEntry.contextTypes = {
    t: PropTypes.func.isRequired
};


export default ExcursionEntry;