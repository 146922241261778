export function createSubtitle(schedule) {
    let text = "";
    if (schedule.arrives) {
        text = text + schedule.arrives.defaultValue;
    }
    if (schedule.arrives.defaultValue || schedule.departs.defaultValue) {
        text = text + " - ";
    }
    if (schedule.departs) {
        text = text + schedule.departs.defaultValue;
    }
    if (text.length === 0) {
        text = "&nbsp;";
    }
    return text;
}