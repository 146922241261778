const INITIAL_STATE = {modules: []};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case "SET_MODULES":
            return { modules: action.modules };

        case "CLEAR_MODULES":
            return INITIAL_STATE;

        default:
            return state;
    }
}
