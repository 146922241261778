import React from 'react';

class SoldOutBox extends React.Component {
    render() {
        return (
            <div className="w-full text-grey-darkest bg-grey-lighter p-2 md:p-4">
                <div className="uppercase text-2xl mb-2">
                    Sold out
                </div>
                <div>
                    Contact the onboard team for latest availability
                </div>
            </div>
        );
    }
}

export default SoldOutBox