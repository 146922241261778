import React from 'react';

class ExcursionAttributes extends React.Component {
    render() {
        return (
            <ul className="excursion-attribute-list text-grey-darker flex-grow">
                {this.props.attributes.map((attribute, index) => (
                    <li className="excursion-attribute" key={index}>
                        {attribute}
                    </li>)
                )}
            </ul>
        )
    }
}

export default ExcursionAttributes;