// TODO decide if this is needed
export function clearModules() {
    return async function (dispatch) {
        dispatch({type: "CLEAR_MODULES"});
    }
}

export function setModules(modules) {
    return async function (dispatch) {
        dispatch({
            type: "SET_MODULES",
            modules,
        })
    }
}
