import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {getFromHubNew} from "../../actions/networkActions";
import Loading from "../../components/Loading";
import ImageGallery from "react-image-gallery";
import {defaultImageSrc} from "../../utils/imageUtils";
import {getTranslation} from "../../translations/translationUtils";
import {formatDuration, formatPortDate} from "../../utils/dateTimeUtils";
import {store} from "../../App";
import ExcursionTags from "../../components/ExcursionTags";
import {DefaultButtonLink} from "../../resources/styles";
import {useGet} from "../../hooks/useGet";
import {useExcursionImages} from "../../hooks/useExcursionImages";
import {removeHtmlStyles} from "../../utils/stringUtils";

// TODO refactor the useEffects that need to transform data to also use useGet hook
function ShoreExcursionDetail(props) {
    const deviceType = useSelector(state => state.configuration.hubSettings.deviceType);
    const tags = useSelector(state => state.excursionTags);
    const [loadingTags, setLoadingTags] = useState(false);
    const images = useExcursionImages(props.match.params.id);
    const [descriptions, setDescriptions] = useState(null);

    const availability = useGet(`excursions/availability/${props.match.params.id}`);
    const excursion = useGet(`excursions/${props.match.params.id}`);

    const dispatch = store.dispatch;

    useEffect(() => {
        if(tags || loadingTags) {
            return;
        }

        const fetchTags = async () => {
            const tagsData = await getFromHubNew('excursions/tags');

            const tagsObject = {};
            tagsData.forEach(tag => {
                tagsObject[tag.name] = tag.imageUrl?.id;
            });

            dispatch({
                type: "SET_TAGS",
                tags: tagsObject,
            });
        }
        setLoadingTags(true);
        fetchTags();

    }, [dispatch, loadingTags, tags])

    useEffect(() => {
        const fetchDescriptions = async () => {
            const descriptionsArray = await getFromHubNew(`descriptions/${props.match.params.id}`);

            const descriptionsObject = descriptionsArray.reduce((result, entry) => {
                result[entry.descriptionType] = entry.value;
                return result;
            }, {});

            setDescriptions(descriptionsObject);
        }

        fetchDescriptions();
    }, [props.match.params.id]);

    if(images.length === 0 || excursion.data === null || descriptions === null || availability.data === null) {
        return (
            <Loading/>
        )
    }

    return (
        <div>
            <div className="grid-x grid-padding-x">
                <div className="cell small-12 medium-4 large-4">
                    {images.length > 0 &&
                        <ImageGallery
                            additionalClass="excursion-image-gallery"
                            items={images}
                            lazyLoad={true}
                            showIndex={true}
                            defaultImage={defaultImageSrc()}
                            showPlayButton={false}
                            showThumbnails={false}
                            useBrowserFullscreen={true}
                        />
                    }
                </div>
                <div className="cell small-12 medium-8 large-8">

                    <div className="text-uppercase text-3xl my-2">{getTranslation(excursion.data.name)}</div>

                    {/* time, duration, price */}
                    <div className="padding-horizontal-1">

                        <div>
                            <span className="font-bold">Date:</span> {formatPortDate(excursion.data.date)}
                        </div>

                        {excursion.data.timeOfDay &&
                            <div className="text-capitalize">
                                <span className="font-bold">Time:</span> {excursion.data.timeOfDay.toLowerCase()}
                            </div>
                        }

                        <div>
                            <span className="font-bold">Duration:</span> {formatDuration(excursion.data.duration)}
                        </div>

                        <div>
                            <span className="font-bold">Price:</span> £{excursion.data.price.adult}
                            {!!excursion.data.price.child && `(Adult) / £${excursion.data.price.child} (Child)`}
                        </div>
                    </div>


                    {/* TODO - check if we need the availability.outdated field */}
                    {availability.data.availability && deviceType !== "DigitalSignage" &&
                        <div className="my-4">
                            <DefaultButtonLink to={`/onboard/bookexcursion/${excursion.data.id}`}>
                                Book Excursion
                            </DefaultButtonLink>
                        </div>
                    }


                    {/* description */}
                    <div className="margin-vertical-1" dangerouslySetInnerHTML={{__html: removeHtmlStyles(descriptions.Description.defaultWhitelistedValue)}} />

                    {/* just so you know */}
                    {descriptions.Hint && descriptions.Hint.defaultPlainValue !== "" &&
                        <>
                            <div className="text-2xl">Just So You Know</div>
                            <div className="margin-bottom-1" dangerouslySetInnerHTML={{__html: removeHtmlStyles(descriptions.Hint.defaultValue)}}/>
                        </>
                    }

                    {/* suitability icons */}
                    { tags && excursion.data.tags.length > 0 &&
                        <>
                            <div className="text-2xl">Suitability</div>
                            <div>
                                <ExcursionTags displayInline={true} attributes={excursion.data.tags.map(tag => tag.name)} />
                            </div>
                        </>
                    }
                </div>

            </div>
        </div>
    );
}

export default ShoreExcursionDetail;