import React, {Component} from 'react';
import {PropTypes} from "prop-types";
import DetailImage from "./DetailImage";
import If from "./If";
import styled from "styled-components";
import {connect} from "react-redux";

const WeatherInfoContainer = styled.div`
    padding-left: 20pt;
    position: relative;
    top: -88%;
    left: 33%;
    max-width: 50%;
`;

const WeatherBoxContainer = styled.div`
    background: ${props => props.theme.subheaderBackground || "rgb(112, 203, 244);"};
    color: ${props => props.theme.subheaderColor || "#000000"};
`;



class WeatherInformation extends Component {
    render() {
        const weather = this.props.weather;
        const {synopsis, temperature, windSpeed, windDirection, humidity} = weather;
        return (

            <div className="grid-x grid-margin-x">
                <div className="medium-4 small-12 cell">
                    <DetailImage image={this.props.configuration.hubUrl + "blobs/image?reference=" + weather.schedule.id}/>
                </div>
                <div className="medium-8 small-12 cell">
                    <h1>
                        {weather.schedule.title.defaultValue}
                    </h1>
                    <WeatherBoxContainer className="weather-container">
                        <div className="weather-icon-container">
                            <If test={weather.weatherIcon === null}>
                                <img className="weather-icon" src="\images\weather\fewclouds.svg"  alt="Few clouds icon"/>
                            </If>
                            <If test={weather.weatherIcon === "SUN"}>
                                <img className="weather-icon" src="\images\weather\sun.svg"  alt="Sun icon"/>
                            </If>
                            <If test={weather.weatherIcon === "FEWCLOUDS"}>
                                <img className="weather-icon" src="\images\weather\fewclouds.svg" alt="Few clouds icon"/>
                            </If>
                            <If test={weather.weatherIcon === "SCATTEREDCLOUDS"}>
                                <img className="weather-icon" src="\images\weather\cloud.svg"  alt="Scattered clouds icon"/>
                            </If>
                            <If test={weather.weatherIcon === "CLEARSKY"}>
                                <img className="weather-icon" src="\images\weather\sun.svg" alt="Sun icon"/>
                            </If>
                            <If test={weather.weatherIcon === "RAIN"}>
                                <img className="weather-icon" src="\images\weather\rain.svg" alt="Rain icon"/>
                            </If>
                            <If test={weather.weatherIcon === "BROKENCLOUDS"}>
                                <img className="weather-icon" src="\images\weather\clouds.svg" alt="Broken clouds icon"/>
                            </If>
                            <If test={weather.weatherIcon === "SHOWERRAIN"}>
                                <img className="weather-icon" src="\images\weather\showerrain.svg"  alt="Shower rain icon"/>
                            </If>
                            <If test={weather.weatherIcon === "THUNDERSTORM"}>
                                <img className="weather-icon" src="\images\weather\thunder.svg"  alt="Thunderstorm icon"/>
                            </If>
                            <If test={weather.weatherIcon === "SNOW"}>
                                <img className="weather-icon" src="\images\weather\snow.svg" alt="Snow icon"/>
                            </If>
                            <If test={weather.weatherIcon === "MIST"}>
                                <img className="weather-icon" src="\images\weather\mist.svg"  alt="Mist icon"/>
                            </If>
                            <If test={weather.weatherIcon === "OVERCASTCLOUDS"}>
                                <img className="weather-icon" src="\images\weather\overcastclouds.svg"  alt="Overcast clouds icon"/>
                            </If>
                        </div>
                        <WeatherInfoContainer>
                            <If test={synopsis !== null}>
                                <div>
                                    <b>{this.context.t("synopsis")}:</b> {synopsis}<br/>
                                </div>
                            </If>
                            <If test={temperature !== null}>
                                <div>
                                    <b>{this.context.t("temperature")}:</b> {temperature}°C<br/>
                                </div>
                            </If>
                            <If test={windSpeed !== null && windDirection !== null}>
                                <div>
                                    <b>{this.context.t("wind")}:</b> {windDirection} {windSpeed}km/h<br/>
                                </div>
                            </If>
                            <If test={humidity !== null}>
                                <div>
                                    <b>{this.context.t("humidity")}:</b> {humidity}%<br/>
                                </div>
                            </If>
                        </WeatherInfoContainer>
                    </WeatherBoxContainer>
                    <div>
                        <If test={weather.schedule.currency.defaultValue}>
                            <div className="weather-schedule-field">
                                <b>{this.context.t("currency")}:</b> {weather.schedule.currency.defaultValue}<br/>
                            </div>
                        </If>


                        <If test={weather.schedule.language.defaultValue}>
                            <div className="weather-schedule-field">
                                <b>{this.context.t("language")}:</b> {weather.schedule.language.defaultValue}<br/>
                            </div>
                        </If>

                        <If test={weather.schedule.nameOfPier.defaultValue}>
                            <div className="weather-schedule-field">
                                <b>{this.context.t("name_of_pier")}:</b> {weather.schedule.nameOfPier.defaultValue}<br/>
                            </div>
                        </If>

                        <If test={weather.schedule.localAgentName.defaultValue}>
                            <div className="weather-schedule-field">
                                <b>{this.context.t("ships_local_agent")}:</b> {weather.schedule.localAgentName.defaultValue}<br/>
                            </div>
                        </If>

                        <If test={weather.schedule.gangway.defaultValue}>
                            <div className="weather-schedule-field">
                                <b>{this.context.t("gangway")}:</b> {weather.schedule.gangway.defaultValue}<br/>
                            </div>
                        </If>

                        <div className="weather-schedule-field">
                            <b>{this.context.t("date")}:</b> {weather.schedule.date}<br/>
                        </div>

                        <If test={weather.schedule.arrives.defaultValue}>
                            <div className="weather-schedule-field">
                                <b>{this.context.t("arrives")}:</b> {weather.schedule.arrives.defaultValue}<br/>
                            </div>
                        </If>

                        <If test={weather.schedule.allOnBoard.defaultValue}>
                            <div className="weather-schedule-field">
                                <b>{this.context.t("all_on_board")}:</b> {weather.schedule.allOnBoard.defaultValue}<br/>
                            </div>
                        </If>

                        <If test={weather.schedule.departs.defaultValue}>
                            <div className="weather-schedule-field">
                                <b>{this.context.t("departs")}:</b> {weather.schedule.departs.defaultValue}<br/>
                            </div>
                        </If>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        configuration: state.configuration,
    };
};

WeatherInformation.contextTypes = {
    t: PropTypes.func.isRequired
};

export default connect(mapStateToProps, null)(WeatherInformation);
