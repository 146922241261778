import React from 'react';
import {connect} from 'react-redux';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import {fetchFromHub, getFromMusement} from '../../actions/networkActions';
import {PropTypes} from "prop-types";
import {DefaultButton, device} from "../../resources/styles";
import {formatDMYtoYMD, formatIso8601Duration, formatIso8601MinMaxDuration} from "../../utils/dateTimeUtils";
import ExcursionAttributes from "../../components/ExcursionAttributes";
import Feature from "../../components/musement/Feature";
import styled from 'styled-components';
import {groupTaxonomies, stripHtmlTags} from "../../utils/musementUtils";
import {defaultImageSrc} from "../../utils/imageUtils";
import {recordPageview} from "../../utils/gaUtils";
import {recordVisit} from "../../actions/metricsActions";

const FeaturesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const FeatureContainer = styled.div`
    width: 100%;
    
    @media ${device.tablet} {
        width: 50%;
    }
    
`;
const MusementLink = styled.a`
    color: ${props => props.theme.buttonColor || "#333333"};
    text-decoration: none;
    :hover, :focus {
        color: ${props => props.theme.buttonColor || "#333333"};
        text-decoration: none;
    }
`;

const MeetingPoint = styled.div`
    display: flex;
    align-items: center;
`;

class Activity extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activity: {},
            loaded: false,
            taxonomies: {},
            taxonomiesLoaded: false,
            images: [],
            imagesLoaded: false,
        };
    }

    componentDidMount() {
        getFromMusement(`activities/${this.props.match.params.activityId}`, this.updateActivity);
        getFromMusement(`activities/${this.props.match.params.activityId}/taxonomies`, this.updateTaxonomies);
        getFromMusement(`activities/${this.props.match.params.activityId}/media`, this.updateImages);
    }


    updateActivity = (activity) => {
        recordPageview(window.location.pathname + window.location.search, "Musement activity - " + activity.title);
        activity.formatted_duration = activity.duration ? formatIso8601Duration(activity.duration) : formatIso8601MinMaxDuration(activity.duration_range);

        this.setState((prevState, props) => ({
            activity,
            loaded: true,
            images: [{thumbnail: activity.cover_image_url, original: activity.cover_image_url},...prevState.images]
        }));
    };

    updateTaxonomies = (rawTaxonomies) => {
        this.setState({
            taxonomies: groupTaxonomies(rawTaxonomies),
            taxonomiesLoaded: true,
        });
    };

    updateImages = (rawImages) => {
        const images = rawImages.filter(image => image.type === "image").map(image => {
            return {
                thumbnail: `${image.url}?fit=clip&w=200&h=100`,
                original: `${image.url}?fit=clip&w=1200&h=800`,
            };
        });

        this.setState((prevState, props) => ({
            imagesLoaded: true,
            images: [...prevState.images, ...images]
        }));
    };

    recordExternalClick = (e, url) => {
        const parsedUrl = new URL(url);
        recordVisit("EXTERNAL_LINK/" + parsedUrl.pathname);
    };

    getMusementLink = () => {
        try {
            const pmsObject = JSON.parse(this.props.pmsInformation);
            const embarkDate = formatDMYtoYMD(pmsObject.embarkDate);
            const activityId = this.props.match.params.activityId;
            const activityDate = formatDMYtoYMD(this.props.location.state.date);

            return `https://rivercruises.gotui.com/uk/activity/${activityId}?shipDate=${embarkDate}&shipId=${this.props.shipId}&startDate=${activityDate}`
        }
        catch (e) {
            return "https://rivercruises.gotui.com/uk";
        }


    }


    render() {
        if(!this.state.loaded) {
            return (<div>{this.context.t("loading")}</div>);
        }
        const musementUrl = this.getMusementLink();

        return (
            <div>
                <div className="grid-x">
                    <div className="cell small-12 large-5 large-padding-right-1">
                        { this.state.imagesLoaded && this.state.images && this.state.images.length > 0 &&
                        <ImageGallery
                            additionalClass="excursion-image-gallery"
                            items={this.state.images}
                            lazyLoad={true}
                            showIndex={true}
                            defaultImage={defaultImageSrc()}
                            showPlayButton={false}
                            showThumbnails={false}
                            useBrowserFullscreen={true}
                        />
                        }
                    </div>
                    <div className="cell small-12 large-7">

                        <div className="text-uppercase text-3xl my-2">{this.state.activity.title}</div>

                        <div>
                            {/* keypoints */}
                            <div className="padding-horizontal-1">
                                {this.state.activity.formatted_duration &&
                                    <div>
                                        <span className="font-bold">
                                            {this.context.t("musement_activity_duration")}{" "}
                                        </span>
                                        {this.state.activity.formatted_duration}
                                    </div>
                                }

                                <div>
                                    <span className="font-bold">
                                        {this.context.t("musement_activity_price")}
                                    </span>
                                    {" "}{this.context.t("musement_activity_from")}{" "}
                                    {this.state.activity.retail_price.formatted_value}
                                </div>

                                {this.state.activity.languages.length > 0  &&
                                    <div>
                                        <span className="font-bold">
                                            {this.state.activity.languages.length === 1 ?
                                                this.context.t("musement_activity_language") :
                                                this.context.t("musement_activity_languages")}
                                        </span>
                                        {" "}{this.state.activity.languages.map(lang => lang.name).join(", ")}
                                    </div>
                                }
                            </div>


                            {/* features */}
                            {this.state.activity.features && this.state.activity.features.length > 0 &&
                                <div className="bg-grey-lighter padding-horizontal-05 padding-vertical-05 my-2">
                                    <FeaturesContainer>
                                        {this.state.activity.features.map(feature => (
                                            <FeatureContainer key={feature.code}>
                                                <Feature code={feature.code} name={feature.name}/>
                                            </FeatureContainer>
                                        ))}
                                    </FeaturesContainer>
                                </div>
                            }

                            <div className="my-4">
                                <DefaultButton>
                                    <MusementLink href={musementUrl} target="_blank"  rel="noopener noreferrer" onClick={(e) => this.recordExternalClick(e, musementUrl)}>Book Excursion</MusementLink>
                                </DefaultButton>
                            </div>

                            {/* Do this because - highlights */}
                            {this.state.taxonomiesLoaded && this.state.taxonomies.HIGHLIGHT && this.state.taxonomies.HIGHLIGHT.length > 0 &&
                                <>
                                    <div className="text-2xl">{this.context.t("musement_activity_do_this_because")}</div>
                                    <div>
                                        <ExcursionAttributes attributes={this.state.taxonomies.HIGHLIGHT} />
                                    </div>
                                </>
                            }

                            {/* What to expect - about */}
                            <div className="margin-vertical-1">
                                <div className="text-2xl">{this.context.t("musement_activity_what_to_expect")}</div>
                                <div>
                                    {this.state.activity.about}
                                </div>
                            </div>


                            {/* where */}
                            <div className="margin-vertical-1">
                                <div className="text-2xl my-2">{this.context.t("musement_activity_where")}</div>
                                <div>
                                    <div >{stripHtmlTags(this.state.activity.where_text)}</div>
                                    <div className="my-1">{this.context.t("musement_activity_meeting_point")}</div>
                                    <MeetingPoint>
                                        <img className="w-4 h-4 mr-2" src={require('../../resources/images/musement/meeting_point.svg')} alt="" />

                                        <div>{stripHtmlTags(this.state.activity.meeting_point)}</div>
                                    </MeetingPoint>

                                </div>
                            </div>

                            {/* What is included*/}
                            {this.state.taxonomiesLoaded && this.state.taxonomies.INCLUSION && this.state.taxonomies.INCLUSION.length > 0  &&
                            <>
                                <div className="text-2xl">{this.context.t("musement_activity_what_is_included")}</div>
                                <div>
                                    <ExcursionAttributes icon="included.svg" attributes={this.state.taxonomies.INCLUSION} />
                                </div>
                            </>
                            }

                            {/* What is not included*/}
                            {this.state.taxonomiesLoaded && this.state.taxonomies.EXCLUSION && this.state.taxonomies.EXCLUSION.length > 0 &&
                            <>
                                <div className="text-2xl">{this.context.t("musement_activity_what_is_not_included")}</div>
                                <div>
                                    <ExcursionAttributes icon="not_included.svg" attributes={this.state.taxonomies.EXCLUSION} />
                                </div>
                            </>
                            }

                            {/* What to remember - info */}
                            {this.state.activity.info && this.state.activity.info.length > 0 &&
                            <div className="margin-vertical-1">
                                <div
                                    className="text-2xl">{this.context.t("musement_activity_what_to_remember")}</div>
                                <div>
                                    {stripHtmlTags(this.state.activity.info)}
                                </div>
                            </div>
                            }
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

Activity.contextTypes = {
    t: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    return {
        pmsInformation: state.authentication?.pms?.information,
        shipId: state.configuration?.hubSettings?.uiSettings?.shipId,
    };
};

export default connect(mapStateToProps, {fetchFromHub})(Activity);
