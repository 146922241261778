import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {PropTypes} from 'prop-types';
import {formatBasketDate} from '../utils/dateTimeUtils';
import {removeExcursionFromCart} from '../actions/cartActions';
import {getTranslation} from '../translations/translationUtils';
import {BasketItemContainer, BasketXButton} from "../resources/styles";



class CartElementCruise extends React.Component {
    render() {
        const excursion = this.props.excursion;

        return (
            <BasketItemContainer>
                <BasketXButton
                    className="cursor-pointer"
                    onClick={() => this.props.removeExcursionFromCart("CruiseExcursion", excursion.itineraryId, excursion.excursionId)}
                >
                    &#10006;
                </BasketXButton>

                {/* excursion and passengers info */}
                <div className="padding-bottom-05 border-b mb-2">
                    <Link className="text-xl text-underline lh-1" to={`/excursion/${excursion.itineraryId}/${excursion.excursionId}`}>
                        {getTranslation(excursion.excursionName)}
                    </Link>

                    <div className="mt-2 mb-4">
                        {getTranslation(excursion.portName)}
                    </div>

                    <div>
                        {formatBasketDate(excursion.date)}
                    </div>

                    <div>
                        {excursion.passengers.map((passenger, index) =>
                            <div key={index}>{`${passenger.firstName} ${passenger.lastName}`}</div>)}
                    </div>
                    <div className="text-right">
                        <Link className="text-underline lh-1" to={`/excursion/${excursion.itineraryId}/${excursion.excursionId}/1`}>
                            {this.context.t("excursion_amend_button")}
                        </Link>
                    </div>
                </div>

                {/* cost info */}
                <div className="text-right font-bold lh-1">
                    {this.context.t("excursion_cost_label")} £{excursion.price}
                </div>
            </BasketItemContainer>
        )
    }
}

CartElementCruise.contextTypes = {
    t: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    return { configuration: state.configuration };
};

export default connect(mapStateToProps, {removeExcursionFromCart}) (CartElementCruise);