import {dateSort} from './dateTimeUtils';

export function flattenExcursions(cartElements) {
    let excursions = [];

    for (const itineraryId in cartElements) {
        for (const excursionId in cartElements[itineraryId]) {
            const cartElement = {...cartElements[itineraryId][excursionId]};
            cartElement.price = cartElement.price.toFixed(2);

            excursions.push(cartElement);
        }
    }

    return excursions;
}

export function flattenAndSortExcursions(cartElements) {
    return flattenExcursions(cartElements).sort(dateSort);
}

export function cartContainsResortExcursions(cartElements) {
    for (const itineraryId in cartElements) {
        for (const excursionId in cartElements[itineraryId]) {
            if(cartElements[itineraryId][excursionId]['type'] === "ResortExcursion") {
                return true;
            }
        }
    }

    return false;
}

export function cartContainsCruiseExcursions(cartElements) {
    for (const itineraryId in cartElements) {
        for (const excursionId in cartElements[itineraryId]) {
            if(cartElements[itineraryId][excursionId]['type'] === "CruiseExcursion") {
                return true;
            }
        }
    }

    return false;
}