import config from "../resources/config/configuration";

const INITIAL_STATE = {
    hubUrl: getConfigIfExists("hubUrl"),
    hubApiUrl: getConfigIfExists("hubApiUrl"),
    gamesUrl: getConfigIfExists("gamesUrl"),
    osmUrl: getConfigIfExists("osmUrl"),
    developmentMode: getConfigIfExists("developmentMode"),
    defaultLanguage: getConfigIfExists("defaultLanguage"),
    fallbackLanguage: getConfigIfExists("fallbackLanguage"),
    gaCode: getConfigIfExists("gaCode"),
    hubSettings: null,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'CLEAR_HUB_CONFIGURATION':
            return INITIAL_STATE;

        case 'SET_HUB_CONFIGURATION':
            return {...state, hubSettings: action.hubSettings};

        default:
            return state;
    }
}

function getConfigIfExists(key) {
    if(config[window.location.hostname] && config[window.location.hostname][key]) {
        return config[window.location.hostname][key];
    }

    // TODO - this should ideally only have 3 entries: hubUrl, hubApiUrl and default
    // all other values should be fetched from the corresponding hubs configuration as well as the theming information
    // remove other keys once they are provided from the hub
    switch (key) {
        case "hubUrl":
            return guessHubUrlFromHostname(window.location.hostname);

        case "hubApiUrl":
            return guessHubApiUrlFromHostname(window.location.hostname);

        case "gamesUrl":
            return window.location.protocol + "//" + window.location.host;

        case "developmentMode":
            return false;

        case "defaultLanguage":
            return window.navigator.language.split("-")[0];

        case "fallbackLanguage":
            return "en";

        case "gaCode":
            return "";

        default:
            return "";
    }
}

/*
    app.airline1.com
    hub.airline1.com

    demo.airline1.com
    demo-hub.airline.com
 */

function guessHubDomain(hostname) {
    const hostnameParts = hostname.split(".");
    if(hostnameParts.length > 1) {
        if(hostnameParts[0] === "app") {
            hostnameParts[0] = "hub";
        }
        else {
            hostnameParts[0] += "-hub";
        }
        return window.location.protocol + "//" + hostnameParts.join(".") + "/";
    }

    return false;
}

export function guessHubUrlFromHostname(hostname) {
    const guessedHubUrl = guessHubDomain(hostname);

    return guessedHubUrl || process.env.REACT_APP_HUB_URL;
}

export function guessHubApiUrlFromHostname(hostname) {
    const guessedHubUrl = guessHubDomain(hostname);

    if(guessedHubUrl) {
        return guessedHubUrl + "v1/";
    }

    return process.env.REACT_APP_HUB_API_URL;
}
