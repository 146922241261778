import React from "react";
import DetailImage from "../../components/DetailImage";
import FeedbackForm from "./FeedbackForm";
import {connect} from "react-redux";
import {fetchFromHub, postToHub} from "../../actions/networkActions";
import {PropTypes} from "prop-types";
import {getTranslation} from "../../translations/translationUtils";
import {DefaultButton} from "../../resources/styles";
import If from '../../components/If';

class Feedback extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: null,
            allInOne: false,
            canSend: false,
            sending: false,
            error: false,
            saved: false,
            conditions: null,
            conditionsAccepted: false,
            amountSubmitPressed: 0,
            combinedProfile: null
        };
        this._validated = this._validated.bind(this);
        this._sendFeedback = this._sendFeedback.bind(this);
        this._onConditionsAcceptChanged = this._onConditionsAcceptChanged.bind(this);
        this._feedbackData = null;
    }

    componentDidMount() {
        let id = null;

        if (this.props.match && this.props.match.params) {
            id = this.props.match.params.id;
        }

        if (this.props.profileId) {
            id = this.props.profileId;
            this.setState({allInOne: true});
        }

        if (id) {
            this.props.fetchFromHub(`ratings/profiles/` + id, this.updateProfile);
            this.props.fetchFromHub(`conditions/Rating`, this.updateConditions);
        }
    }

    updateProfile = (profile) => {
        this.setState({
            profile: profile,
        });

        // combinedProfile only used to sort items regardless of field or question
        let combinedProfile = profile;
        combinedProfile.items = [];

        for (let i = 0; i< profile.fields.length; i++) {
            const field = profile.fields[i];
            field.type = "field";
            combinedProfile.items.push(field);
        }

        for (let i = 0; i< profile.questions.length; i++) {
            const question = profile.questions[i];
            question.type = "question";
            combinedProfile.items.push(question);
        }

        combinedProfile.items.sort((a, b) => {
                if (a.sortOrder > b.sortOrder) return 1;
                if (b.sortOrder > a.sortOrder) return -1;
                return 0;
            }
        );
        this.setState({combinedProfile: combinedProfile});
    };

    updateConditions = (conditions) => {
        this.setState({
            conditions: conditions,
        })
    };

    _validated(isValid) {
        this.setState({canSend: isValid});
    }

    _onConditionsAcceptChanged(event) {
        this.setState({conditionsAccepted: event.target.checked});
    }

    _sendFeedback() {
        const newAmountSubmitPressed = this.state.amountSubmitPressed + 1;
        this.setState({amountSubmitPressed: newAmountSubmitPressed});

        if (this._feedbackData == null) {
            return false;
        }
        if (!this._isSubmitPossible()) {
            console.warn("Form is invalid - do NOT submit");
            return false;
        }

        let answer = this._feedbackData.getFeedbackResult();
        console.log('answer', answer);
        this.setState({sending: true, error: false});
        this.props.postToHub('ratings/answer', answer, this.sendSuccess, this.sendError);

        return true;
    }

    sendSuccess = (response) => {
        if (response.failed) {
            this.setState({sending: false, error: true});
        }
        else {
            this.setState({sending: false, saved: true});
        }
    };

    sendError = (response) => {
        console.error("answer saving error", response);
        this.setState({sending: false, error: true});
    };

    _renderSubmit() {
        if (this.state.sending) {
            return <i className="fa fa-spinner fa-pulse"></i>;
        }
        else if (this.state.saved) {
            return <div className="grid-x">
                <div className="cell small-2 medium-1">
                    <i className="fa fa-check-square feedbackResponseIcon"></i>
                </div>
                <div className="cell small-10 medium-11 align-self-middle">
                    <span>{this.context.t("feedback_thank_you_message")}</span>
                </div>
            </div>;
        }
        else {

            return (
                <DefaultButton
                       onClick={this._sendFeedback}>
                    {this.context.t("submitFeedback")}
                </DefaultButton>
            )
        }
    }

    _isSubmitPossible() {
        return this.state.canSend && (this.state.conditions && this.state.conditions.conditionText && this.state.profile.acceptTandC ? (this.state.conditions && this.state.conditions.conditionText && this.state.conditionsAccepted) : true );
    }


    render() {
        let profile = this.state.profile;
        if (profile === null) {
            return false;
        }

        let conditions = null;
        if (this.state.conditions != null && this.state.profile.acceptTandC) {
            conditions = this.state.conditions.conditionText;
        }

        const errorMessage = this.context.t("feedback_error_message");

        const checkboxPStyle = {
            marginBottom: "0"
        };

        if (this.state.allInOne) {
            return (
                <div>
                    <div className="feedbackForm">
                        <FeedbackForm ref={(d) => this._feedbackData = d} profile={profile}
                                      onValidChanged={this._validated}
                                      amountSubmitPressed={this.state.amountSubmitPressed}
                                      saved={this.state.saved}
                        />

                    </div>
                    <div className="feedbackResponse">
                        {this.state.error && (<div className="error-message">{errorMessage}</div>)}
                        {this._renderSubmit()}
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <div className="grid-x grid-padding-x">
                        <div className="cell small-12 medium-4 large-4">
                            <DetailImage image={this.props.configuration.hubUrl + "blobs/image?reference=" + profile.id + "&width=400"}/>
                        </div>
                        <div className="cell small-12 medium-8 large-8 text-left">
                            <div className="row">
                                <h1 dangerouslySetInnerHTML={{__html: getTranslation(profile.title)}}/>
                                <p dangerouslySetInnerHTML={{__html: getTranslation(profile.description)}}/>
                            </div>
                            <div className="feedbackForm">
                                <FeedbackForm ref={(d) => this._feedbackData = d}
                                              profile={profile}
                                              combinedProfile={this.state.combinedProfile}
                                              onValidChanged={this._validated}
                                              amountSubmitPressed={this.state.amountSubmitPressed}
                                              saved={this.state.saved}
                                />
                                <If test={conditions}>
                                    <div>
                                        <p>
                                            <span dangerouslySetInnerHTML={{__html: getTranslation(conditions)}}/>
                                        </p>
                                        <p style={checkboxPStyle}>
                                            <label><input type="checkbox" disabled={this.state.saved} className="whaleCheckbox" onClick={this._onConditionsAcceptChanged}/>{this.context.t("basket_accept_conditions")}</label>
                                        </p>
                                        <If test={this.state.amountSubmitPressed > 0 && !this.state.conditionsAccepted && !this.state.saved}>
                                            <div className="feedbackMandatoryMissing">{this.context.t("feedback_termsandconditions_must_be_accepted")}</div>
                                        </If>
                                    </div>
                                </If>
                            </div>

                            <div className="feedbackResponse">
                                {this.state.error && (<div className="error-message">{errorMessage}</div>)}
                                {this._renderSubmit()}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

    }
}

const mapStateToProps = state => {
    return {
        configuration: state.configuration,
    };
};

Feedback.contextTypes = {
    t: PropTypes.func.isRequired
};


export default connect(mapStateToProps, {fetchFromHub: fetchFromHub, postToHub: postToHub})(Feedback);