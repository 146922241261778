import React from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {getFromHubNew} from "../../actions/networkActions";
import currencyUtils from "../../utils/currencyUtils";
import {DefaultButtonLink} from "../../resources/styles";

class OrderConfirmation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            conditions: null,
        }
    }

    componentDidMount() {
        getFromHubNew("conditions/Photoshop", (conditions) => this.setState({conditions}));
    }

    render() {
        let afterConditions = this.state.conditions?.additionalConditionText?.defaultValue;

        if(!this.props.order) {
            return null;
        }

        return (
            <div className="row">
                <div className="text-3xl">
                    <h3>Your photography order is complete.</h3>

                    <div>
                        Total Price: &pound; {currencyUtils.convertToCurrency(this.props.order.totals.grandTotal)}
                    </div>

                    <div>Your booking reference number is <b>{this.props.order.orderId}</b></div>

                    <div>You can view your order details under {' '}
                        <Link to="/my_onboard_bookings">My Bookings</Link>
                    </div>

                    {afterConditions &&
                    <div dangerouslySetInnerHTML={{__html: afterConditions}}/>
                    }
                    
                </div>
                    <div>
                        <DefaultButtonLink to="/photomatch/overview">Continue Shopping</DefaultButtonLink>
                    </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        order: state.photomatch.order,
    };
};

export default connect(mapStateToProps)(OrderConfirmation);