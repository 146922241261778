import React, {useEffect, useState} from "react";
import moment from "moment";
import {connect} from "react-redux";
import RestaurantAvailabilityRestaurant from "./RestaurantAvailabilityRestaurant";
import RestaurantAvailabilityHeader from "./RestaurantAvailabilityHeader";
import styled from "styled-components";
import {device} from "../../resources/styles";

const TimesWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; 
    
    @media ${device.laptop} {
        justify-content: left;
    }
`;

function RestaurantAvailabilityOverview(props) {

    function useFetch(url) {
        const [data, setData] = useState(null);
        const [status, setStatus] = useState('Connecting...');

        useEffect(() => {
            async function fetchUrl() {
                try {
                    const response = await fetch(url);
                    const json = await response.json();
                    setData(json);
                } catch (e) {
                    console.error(e);
                    setStatus('Error');
                }
            }

            fetchUrl();
        }, [url]);
        return [data, status];
    }

    function useTemplateContent(id) {
        return useFetch(props.configuration.hubApiUrl + 'digitalsignage/content/' + id);
    }

    const [content] = useTemplateContent(props.match.params.id);
    if (!content) {
        return false;
    }

    const templateValues = JSON.parse(content.templateValues);

    function getRestaurantTimeString(restaurantString, time, templateValues) {
        if (time < 10) {
            timeString = "000" + time;
        } else if (time < 100) {
            timeString = "00" + time;
        } else if (time < 1000) {
            timeString = "0" + time;
        } else {
            timeString = time.toString();
        }

        restaurantTimeString = restaurantString + "timeslot." + timeString + ".timestamp";
        let timeStringKey = findKeyInTemplateValues(templateValues, restaurantTimeString);
        if (!timeStringKey) {
            restaurantTimeString = restaurantString + "timeslot." + timeString;
            timeStringKey = findKeyInTemplateValues(templateValues, restaurantTimeString);
            if (timeStringKey && timeStringKey.indexOf(".lock.") > 0) {
                timeStringKey = null;
            }
        }
        return timeStringKey;
    }

    function findKeyInTemplateValues(templateValues, keyToSearch) {
        let key = null;
        for (const property in templateValues) {
            if (property.indexOf(keyToSearch) > -1) {
                key = property;
                break;
            }
        }
        return key;
    }

    function findValueInTemplateValues(templateValues, keyToSearch) {
        let key = null;
        for (const property in templateValues) {
            if (property.indexOf(keyToSearch) > -1) {
                key = property;
                break;
            }
        }
        let value = null;
        if (key) {
            value = templateValues[key];
        }
        return value;
    }

    function createTimeSlot(time) {
        const now = moment();
        let hours = time.length === 3 ? time.substring(0, 1) : time.substring(0, 2);
        let timeSlot = now.hours(hours);
        timeSlot.minutes(time.slice(-2));
        timeSlot.seconds(0);
        timeSlot.millisecond(0);
        return timeSlot;
    }

    let hasRestaurants = true;
    let restaurantNumber = 1;
    let nextRestaurantNumber = null;
    let restaurantString = "";
    let nextRestaurantString = "";
    let restaurantLocationString = "";
    let restaurantIdString = "";
    let restaurantTypeString = "";
    let restaurantNameString = "";
    let timeString = "";
    let restaurantTimeString = "";
    const specialtyRestaurants = [];
    const openSeatingRestaurants = [];
    let TimeStamps = [];
    let restaurantsObject = null;
    let timeObject = null;

    while (hasRestaurants) {
        restaurantString = "restaurant" + restaurantNumber + ".";
        restaurantLocationString = restaurantString + "location";
        const restaurantLocationValue = findValueInTemplateValues(templateValues, restaurantLocationString);
        restaurantIdString = restaurantString + "id";
        const restaurantIdValue = findValueInTemplateValues(templateValues, restaurantIdString);
        restaurantTypeString = restaurantString + "type";
        const restaurantTypeValue = findValueInTemplateValues(templateValues, restaurantTypeString);
        restaurantNameString = restaurantString + "name";
        const restaurantNameValue = findValueInTemplateValues(templateValues, restaurantNameString);
        const restaurantLinkString = restaurantString + "link.privateonly";
        const restaurantLinkValue = findValueInTemplateValues(templateValues, restaurantLinkString);

        for (let i = 0; i <= 2400; i += 5) {
            const restaurantTimeKey = getRestaurantTimeString(restaurantString, i, templateValues);
            if (restaurantTimeKey && templateValues[restaurantTimeKey] && templateValues[restaurantTimeKey] !== "Off") {
                timeObject = {
                    time: createTimeSlot(timeString),
                    color: templateValues[restaurantTimeKey]
                };
                TimeStamps.push(timeObject);
            }
        }

        restaurantsObject = {
            restaurantName: restaurantNameValue,
            restaurantLocation: restaurantLocationValue,
            restaurantId: restaurantIdValue,
            restaurantLink: restaurantLinkValue,
            restaurantType: restaurantTypeValue,
            timeSlots: TimeStamps
        };

        if (restaurantsObject.restaurantType.toLowerCase() === "specialty") {
            specialtyRestaurants.push(restaurantsObject);
        } else {
            openSeatingRestaurants.push(restaurantsObject);
        }

        TimeStamps = [];

        nextRestaurantNumber = restaurantNumber + 1;
        nextRestaurantString = "restaurant" + nextRestaurantNumber + ".name";
        const nextRestaurantNameValue = findValueInTemplateValues(templateValues, nextRestaurantString);

        if (!nextRestaurantNameValue) {
            hasRestaurants = false;
        } else {
            restaurantNumber++;
        }
    }

    return (
        <div className="animated fadeIn">
            <div>
                <RestaurantAvailabilityHeader
                    key={templateValues}
                    templateValues={templateValues}
                />
                <h4>{templateValues.specialtyTitle}</h4>
                <TimesWrapper>
                    {
                        specialtyRestaurants.map((restaurant) => {
                            return(
                                <RestaurantAvailabilityRestaurant key = {restaurant.restaurantName} restaurant={restaurant}/>
                            )
                        })
                    }
                </TimesWrapper>
                <h4>{templateValues.openSeatingTitle}</h4>
                <TimesWrapper>
                    {
                        openSeatingRestaurants.map((restaurant) => {
                            return(
                                <RestaurantAvailabilityRestaurant key = {restaurant.restaurantName} restaurant={restaurant}/>
                            )
                        })
                    }
                </TimesWrapper>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        configuration: state.configuration,
    };
};

export default connect(mapStateToProps) (RestaurantAvailabilityOverview);