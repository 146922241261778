import React from "react";

class Player extends React.Component {
    _player = null;
    _el = null;

    componentDidMount() {
        // props like the source, or the sourcedesciption as a whole can be passed through
        // the can then be used to be called on the player itself
        const {source, type} = this.props;
        if (this._el) {
            this._player = new window.THEOplayer.Player(this._el, {
                libraryLocation:
                    "/THEOplayer/lib/"
                    // "https://cdn.myth.theoplayer.com/0404472c-babf-4493-b331-268e77603598/"
            });

            this._player.source = {
                sources: [
                    {
                        src: source,
                        type: type
                    }
                ]
            };
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this._player.source.sources[0].src !== nextProps.source) {
            this.updatePlayerSources(nextProps);
        }
    }

    componentWillUnmount() {
        if (this._player) {
            this._player.destroy();
        }
    }

    updatePlayerSources(props) {
        this._player.source = {
            sources: [
                {
                    src: props.source,
                    type: props.type
                }
            ]
        };
    }

    render() {
        
        let coverStyle;
        if(this.props.cover !== ""){
            coverStyle = {
                backgroundImage: "url('" +  this.props.cover + "')",
                backgoundColor: "transparent !important",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center"
            };
        }

        return (
            <div
                // vjs-16-9 THEOplayer are not necessary, but just added for demo purposes
                className="theoplayer-container video-js theoplayer-skin vjs-16-9 THEOplayer"
                // The ref prop here is key it returns the actual dom element and not the virtual react dom elements
                // Which is need by the player
                style={coverStyle}
                ref={el => (this._el = el)}
            />
        );
    }
}

export default Player;
