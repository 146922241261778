import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    addPackageToCart,
    clearOrder,
    emptyCart,
    fetchCart, placeOrder,
    removeFromCart
} from "../../actions/photoMatchActions";
import browserHistory from '../../utils/history'
import {
    isCartEmpty,
    createBulkItemForRemoval,
} from "../../utils/photoMatchUtils";
import currencyUtils from "../../utils/currencyUtils";
import PhotoMatchCartEntry from "../../components/photomatch/PhotoMatchCartEntry";
import CurrencyUtils from "../../utils/currencyUtils";
import PhotoMatchCartPackageEntry from "../../components/photomatch/PhotoMatchCartPackageEntry";
import BookButton from "../../components/BookButton";
import {
    DefaultButton,
    DefaultButtonLink,
    ModalContainer,
    ModalContent,
    PhotomatchButtonLink
} from "../../resources/styles";
import {getFromHubNew} from "../../actions/networkActions";
import Checkbox from "../../components/Checkbox";
import PhotoMatchPackage from "../../components/photomatch/PhotoMatchPackage";

class Cart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            conditionsAccept: false,
            conditions: null,
            orderResult: null,
            bookingAllowed: null,
            errorMessage: "",
            showUpsell: false,
            alreadyUpsold: false,
            upsellPackage: null,
            orderRequestSent: false,
        };
    }

    componentDidMount() {
        getFromHubNew("conditions/Photoshop", (conditions) => this.setState({conditions}));
        getFromHubNew("conditions/allowed/Photoshop", (bookingAllowed) => this.setState({bookingAllowed}));
        fetchCart();
        clearOrder();
    }

    componentDidUpdate() {
        if(this.state.orderRequestSent && this.props.order) {
            // do the clear cart and redirect
            emptyCart();
            browserHistory.push("/photomatch/orderconfirmation");
        }
    }

    _onConditionsAcceptChanged = () => {
        this.setState({conditionsAccept: !this.state.conditionsAccept});
    }


    _deletePackage(photoPackageName) {
        removeFromCart(createBulkItemForRemoval(photoPackageName));
    }

    _order() {
        return placeOrder(() => {
            this.setState({errorMessage: "There has been an error while placing your order, please try again or contact the photo team for assistance."});
        });
    }

    _validate = () => {
        if(!this.state.conditionsAccept) {
            this.setState({errorMessage: "Please accept the Terms & Conditions, before placing your order"});
            return false;
        }

        if(this.state.errorMessage) {
            this.setState({errorMessage: ""});
        }

        return true;
    };

    processOrder = () => {
        if(!this._validate()) {
            return;
        }

        if(!this.state.alreadyUpsold) {
            const upgradePackage = this.getUpgradePackage();
            if(upgradePackage) {
                this.setState({
                    showUpsell: true,
                    alreadyUpsold: true,
                    upsellPackage: upgradePackage,
                });

                return;
            }
        }

        this.submitOrder();
    };

    submitOrder = async() => {
        await this._order();
        this.setState({orderRequestSent: true});
    };

    getUpgradePackage = () => {
        if (!this.props.cart?.totals?.bulkUpgrade) {
            return null;
        }

        const upgradeId = this.props.cart.totals.bulkUpgrade;
        const upgradePackage = this.props.config.bulk.find(bulk => bulk.id === upgradeId);
        upgradePackage.upgradePrice = this.props.cart.totals.bulkUpgradePrice;

        return upgradePackage;
    };

    buyUpgrade = () => {
        addPackageToCart(this.state.upsellPackage.id);

        this.setState({
            showUpsell: false,
        });
    };

    finishOrderWithoutUpgrade = () => {
        this.submitOrder();

        this.setState({
            showUpsell: false,
        });
    };

    render() {
        // Booking not allowed
        if (this.state.bookingAllowed?.allowed === false) {
            return (
                <div className="row">
                    <h4>Order not possible.</h4>
                    <h4>{this.state.bookingAllowed.message}</h4>
                </div>
            );
        }

        // NO items in basket
        if (isCartEmpty(this.props.cart)) {
            return (
                <div className="row">
                    <h2>No items in basket.</h2>
                    <div>
                        <DefaultButtonLink to="/photomatch/myphotos">Continue Shopping</DefaultButtonLink>
                    </div>
                </div>
            );
        }

        let conditions = null;
        let salesMessage = null;
        if (this.state.conditions !== null) {
            conditions = this.state.conditions?.conditionText?.defaultValue;
            salesMessage = this.state.conditions?.salesMessage?.defaultValue;
        }

        const bulk = this.props.bulkPackage ? this.props.config.bulk.find(bulk => bulk.id === this.props.bulkPackage) : null;

        return (
            <div>
                <BulkUpgradeModal
                    show={this.state.showUpsell}
                    package={this.state.upsellPackage}
                    buyUpgrade={this.buyUpgrade}
                    finishOrder={this.finishOrderWithoutUpgrade}
                />

                <div className="row">
                    <h2>Basket</h2>
                    <div>
                        {
                            this.props.cart.photoGroups.map((photoGroup) => {
                                if (photoGroup.photos.length === 0) {
                                    return false;
                                }
                                return (
                                    <div key={photoGroup.id}>
                                        {
                                            photoGroup.photos.map((photo) => {
                                                return (<PhotoMatchCartEntry
                                                    key={photo.id}
                                                    photo={photo}
                                                    bulkPackage={bulk}/>)
                                            })
                                        }
                                    </div>
                                )
                            })
                        }

                        {this.props.cart && this.props.cart.totals && this.props.cart.totals.bulkAdded &&
                        <div>
                            <PhotoMatchCartPackageEntry />
                        </div>
                        }

                        <PhotomatchButtonLink onClick={emptyCart}>Empty Basket</PhotomatchButtonLink>

                        <h3>Total Price: &pound; {CurrencyUtils.convertToCurrency(this.props.cart.totals.grandTotal)}</h3>

                        {salesMessage &&
                        <div>
                            {salesMessage}
                        </div>
                        }

                        <div>
                            <div className="my-4">
                                <Checkbox
                                    label="Accept Conditions"
                                    checked={this.state.conditionsAccept}
                                    disabled={false}
                                    handleCheckboxChange={this._onConditionsAcceptChanged}
                                />
                            </div>

                            {this.state.errorMessage && <p className="error-message">{this.state.errorMessage}</p>}
                            <p>
                                To confirm the order, please press the "Order now" button.
                            </p>
                            <BookButton onClick={this.processOrder} allowBlockedBooking={false} />

                            <div className="inline-block ml-6">
                                <DefaultButtonLink to="/photomatch/myphotos">Continue Shopping</DefaultButtonLink>
                            </div>

                            <div dangerouslySetInnerHTML={{__html: conditions}}/>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        cart: state.photomatch.cart,
        order: state.photomatch.order,
        config: state.photomatch.config,
        bulkPackage: state.photomatch.cart?.totals?.bulkAdded || null,
    };
}

export default connect(mapStateToProps)(Cart);

function BulkUpgradeModal(props) {
    if (!props.show) {
        return null;
    }

    return (
        <ModalContainer onClick={props.onClose}>
            <ModalContent onClick={(e) => {e.stopPropagation()}}>
                <div className="bg-white p-4">
                    <div className="text-2xl mb-4">
                        Save more money! Upgrade to the next value package for only £{currencyUtils.convertToCurrency(props.package.upgradePrice)}?
                    </div>
                    <div>
                        <PhotoMatchPackage package={props.package} showButtons={false}/>
                        <div className="flex justify-between">
                            <DefaultButton
                                onClick={props.buyUpgrade}>
                                Yes please, upgrade me!
                            </DefaultButton>

                            <DefaultButton
                                onClick={props.finishOrder}>
                                No thanks.
                            </DefaultButton>
                        </div>
                    </div>
                </div>
            </ModalContent>
        </ModalContainer>
    );
}
