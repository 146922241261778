import React from "react";
import If from "../../components/If";
import DetailImage from "../../components/DetailImage";
import {convertToLongDate} from "../../utils/dateTimeUtils";
import {withTheme} from "styled-components";
import {connect} from "react-redux";
import {fetchFromHub, getFromMusement} from "../../actions/networkActions";
import {getTranslation} from "../../translations/translationUtils";
import PropTypes from "prop-types";
import VideoComponent from "../../components/VideoComponent";
import PdfItems from "../../components/PdfItems";
import {DefaultButtonLink} from "../../resources/styles";

class Detail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            detail: null,
            videos: null,
            pdfs: [],
            musementExcursions: [],
        };
    }

    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.fetchFromHub('itinerary/schedules/' + id, this.updateSchedule);
        this.props.fetchFromHub(`blobs/videos/` + id, this.postProcessVideos);
        this._updatePdfInfo(id);

        const locationState = this.props.location?.state;
        if(locationState?.arrivalTime && locationState?.departureTime && locationState?.portId) {
            // fetch from musement and update the state
            getFromMusement(`ships-events?arrival-time=${locationState.arrivalTime}&departure-time=${locationState.departureTime}&port-id=${locationState.portId}`, this.updateMusement);
        }
    }

    updateMusement = musementExcursions => {
        this.setState({musementExcursions})
    }

    _updatePdfInfo(id) {
        this.props.fetchFromHub(`blobs/pdfs/` + id, this.postProcessPdfInfo);
    }

    updateSchedule = (data) => {
        this.setState({
            detail: data
        });
    };

    postProcessVideos = (videos) => {
        const videosFull = [];
        for (let i = 0; i < videos.length; i++) {
            const fullVideo = videos[i];
            fullVideo.urlFrontend = videos[i].urlFrontend;
            videosFull.push(fullVideo);
        }

        this.setState({videos: videosFull});
    };

    postProcessPdfInfo = (pdfs) => {
        this.setState({pdfs: pdfs});
    };

    render() {
        let schedule = this.state.detail;

        if (schedule === null) {
            return false;
        }

        const arrives = getTranslation(schedule.arrives);
        const allOnBoard = getTranslation(schedule.allOnBoard);
        const departs = getTranslation(schedule.departs);


        let longDate = convertToLongDate(schedule.date);

        return (
            <div>
                <div className="grid-x grid-padding-x">
                    <div className="cell small-12 medium-4 large-4">
                        <DetailImage image={this.props.configuration.hubUrl + "blobs/image?reference=" + schedule.id}/>
                    </div>
                    <div className="cell small-12 medium-8 large-8 text-left">
                        <h1>
                            {getTranslation(schedule.title)}
                        </h1>
                        <p>
                        <span>
                                <b>{this.context.t("date")}:</b> {longDate}<br/>
                            </span>

                            <If test={arrives !== ""}>
                                <span>
                                    <b>{this.context.t("arrives")}:</b> {arrives}<br/>
                                </span>
                            </If>

                            <If test={allOnBoard !== ""}>
                                <span>
                                    <b>{this.context.t("all_on_board")}:</b> {allOnBoard}<br/>
                                </span>
                            </If>

                            <If test={departs !== ""}>
                                <span>
                                    <b>{this.context.t("departs")}:</b> {departs}<br/>
                                </span>
                            </If>
                        </p>

                        {this.state.musementExcursions.length > 0 &&
                            <div className="my-4">
                                <DefaultButtonLink to={{
                                    pathname: `/musement-river/excursions`,
                                    state: {
                                        excursions: this.state.musementExcursions,
                                        date: schedule.date,
                                    }
                                }}>
                                    {this.context.t("musement_view_excursions")}
                                </DefaultButtonLink>
                            </div>
                        }

                        {this.props.itineraryExcursionLinkPath &&
                            <DefaultButtonLink to={{
                                pathname: `${this.props.itineraryExcursionLinkPath}${schedule.date.replace(/\//g, "-")}`,
                                state: {
                                    portName: getTranslation(schedule.title),
                                    arrivalTime: arrives,
                                    departureTime: departs,
                                    portDate: schedule.date
                                }
                            }}>
                                    View Experiences
                            </DefaultButtonLink>
                        }

                        <If test={this.state.pdfs}>
                            <PdfItems items={this.state.pdfs}/>
                        </If>

                        <p>
                            <span dangerouslySetInnerHTML={{__html: getTranslation(schedule.description)}}/>
                        </p>

                        <If test={this.state.videos}>
                            <VideoComponent videoItems={this.state.videos}/>
                        </If>

                    </div>
                </div>
            </div>
        )
    }
}


Detail.contextTypes = {
    t: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
    return {
        configuration: state.configuration,
        itineraryExcursionLinkPath: state.configuration?.hubSettings?.uiSettings?.itineraryExcursionLinkPath
    };
};

export default connect(mapStateToProps, {fetchFromHub})(withTheme(Detail));
