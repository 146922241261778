import {fetchFromHub} from './networkActions';

export function updateItinerary() {
    return async function (dispatch) {
        dispatch(fetchFromHub('passengers/hotels', updateHotels));
        dispatch(fetchFromHub('passengers/cruises', updateCruises));
        dispatch(fetchFromHub('passengers/flights', updateFlights));
        dispatch(fetchFromHub('passengers/passengers', updatePassengers));
    };
}

const updateHotels = (hotels, dispatch) => {
    dispatch({
        type: 'SET_ITINERARY_HOTELS',
        hotels,
    });
};

const updateCruises = (cruises, dispatch) => {
    dispatch({
        type: 'SET_ITINERARY_CRUISES',
        cruises,
    });

    if(cruises && cruises.length > 0) {
        dispatch(fetchFromHub('passengers/cruises/itinerary', updateCruiseItinerary));
    }
};

const updateCruiseItinerary = (cruiseItinerary, dispatch) => {
    dispatch({
        type: 'SET_ITINERARY_CRUISE_ITINERARY',
        cruiseItinerary,
    })
};

const updateFlights = (flights, dispatch) => {
    const flightsOut = flights.filter(flight => flight.flightDirection === "OUT");
    const flightsIn = flights.filter(flight => flight.flightDirection === "IN");

    dispatch({
        type: 'SET_ITINERARY_FLIGHTS',
        flights: {
            flightsOut: flightsOut.length > 0 ? flightsOut : null,
            flightsIn: flightsIn.length > 0 ? flightsIn : null,
        },
    });
};

const updatePassengers = (passengers, dispatch) => {
    dispatch({
        type: 'SET_ITINERARY_PASSENGERS',
        passengers,
    });
};