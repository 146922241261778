import React from 'react';
import ReactPlayer from 'react-player';
import {updateSecondsPlayed} from "../actions/vodActions";
import {connect} from "react-redux";
import {PropTypes} from "prop-types";
import VodUtils from "../modules/vod/VodUtils";

class MaxicasterPlayer extends React.Component {
    constructor() {
        super();
        this.state = {
            playedSeconds: 0,
            lastPositionActivated: false,
            error: null,
        };
    }

    componentWillUnmount() {
        this.updateLastPosition()
    }

    seekToPreviousPosition = (positionSeconds) => {
        if (! this.state.lastPositionActivated && positionSeconds > 0) {
            this.player.seekTo(parseFloat(positionSeconds));
            this.setState({lastPositionActivated: true});
        }
    };

    onReady = () => {
        let lastPosition = 0;
        if (this.props.voddetails && this.props.voddetails.last_playback_status) {
            lastPosition = this.props.voddetails.last_playback_status.position_seconds;
        }
        this.seekToPreviousPosition(lastPosition);

        console.log("in onReadyTest lastPosition: ", lastPosition);
    };
    onError = (param) => {
        this.setState({error: param});
    };

    onProgress = state => {
        this.setState({playedSeconds: state.playedSeconds});
        // We only want to update time slider if we are not currently seeking
/*        if (!this.state.seeking) {
            this.setState(state)
        }*/
    };

    updateLastPosition() {
        if (this.props.voddetails && this.props.voddetails.id) {
            try {
                this.props.updateSecondsPlayed(this.props.voddetails.id, this.state.playedSeconds);
            } catch (e) {
                console.log(`Could not update playedSeconds: ${e}`);
            }
        }
    }

    ref = player => {
        this.player = player
    };

    render() {
        if (!this.props.vodassets) {
          return false;
        }

        let urlToPlay = this.props.vodassets.url;
        let playerType = "video";
        if(this.props.vodassets.url){
            urlToPlay = this.props.vodassets.url;
            if(this.props.vodassets.url.includes("audio.m3u8"))
                playerType = "audio";
        }

        let picUrl = "";
        if(playerType === "audio"){
            if(this.props.voddetails && this.props.voddetails.images[0].copies[0].url) {
                const protocol = VodUtils.getProtocol(this.props.vodconfiguration.url);
                picUrl = protocol + "//" + this.props.voddetails.images[0].copies[0].url;
            }
        }

        let title = "";
        if (this.props.voddetails) {
            title = this.props.voddetails.title;
        }

        const minutes = Math.floor(this.state.playedSeconds / 60);
        let seconds = Math.round(this.state.playedSeconds - minutes * 60);
        if (seconds < 10) {
            seconds = "0" + seconds;
        }

        let playerContainerStyle = {
            marginTop: "1em",
            maxWidth: "100%",
        };

        if (playerType === "audio") {
            playerContainerStyle.display = "inline-block";
            playerContainerStyle.backgroundImage = "url('"+picUrl+"')";
            playerContainerStyle.backgroundSize = "contain";
            playerContainerStyle.backgroundRepeat = "no-repeat";
            playerContainerStyle.backgroundPosition = "center";
            playerContainerStyle.boxShadow = "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px";
        };

        const useWithCredentials = this.props.vodconfiguration.useWithCredentials ? true : false;

        return (
            <div>
                <h1>{title}</h1>
                {/*<h4>{this.context.t("elapsedTime", {}, "Please translate!")}: {minutes}:{seconds} </h4>*/}
                <div style={playerContainerStyle}>
                <ReactPlayer id="reactPlayerTag" className="react-player"
                             width='100%'
                             ref={this.ref}
                             url={urlToPlay}
                             playsinline
                             autoPlay
                             controls
                             playing
                             onProgress={this.onProgress}
                             onReady={this.onReady}
                             onError={this.onError}
                             pip={false}
                             config={{
                                 file: {
                                     hlsOptions: {
                                         xhrSetup: function(xhr) {
                                             xhr.withCredentials = useWithCredentials
                                         }
                                     }
                                 }
                             }}
                />
                </div>
            </div>
        );
    }
}

MaxicasterPlayer.contextTypes = {
    t: PropTypes.func.isRequired
};


const mapStateToProps = state => {
    return {
        vodassets: state.voditem.vodassets,
        voddetails: state.voditem.voddetails,
        vodconfiguration: state.vodconfiguration,
    };
};

export default connect(mapStateToProps, { updateSecondsPlayed: updateSecondsPlayed}) (MaxicasterPlayer);
