import React, {Fragment} from 'react';
import {connect} from "react-redux";
import {fetchFromHub} from "../../actions/networkActions";
import {PropTypes} from "prop-types";
import If from "../../components/If";
import {getTranslation} from "../../translations/translationUtils";
import {formatYMDtoDMY, getDayFromDate} from "../../utils/dateTimeUtils";
import DailySchedule from "./DailySchedule";

// Function to hide column if all fields are empty
function isColumnEmpty(events, eventProperty) {
    // Define what contributes to an empty column
    const isEmptyCondition = (property) => {
        return property.trim() === '' || property.trim() === 'Late'
    }
    // Works with a single '.' in property (or none)
    if (eventProperty.includes('.')) {
        const eventPropAsArray = eventProperty.split('.');
        return events.map(event => event[eventPropAsArray[0]][eventPropAsArray[1]])
            .every(evprop => isEmptyCondition(evprop));
    } else return events.map(event => event[eventProperty])
        .every(evprop => isEmptyCondition(evprop) );
}

class Timetable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            initialize: true,
            events: [],
            showDeckHeader: false,
            showCategoryHeader: false,
        };
    }

    componentDidMount() {
        if (this.props.contentId) {
            this.props.fetchFromHub(`cms/articles/findEventsByArticleId/` + this.props.contentId, this.updateEvents);
        }
    }

    isEmpty = (object) => { for(var i in object) { return false; } return true; };

    updateEvents = (events) => {
        let filteredEvents = [];
        let showDeckHeader = false;
        let showCategoryHeader = false;
        for(let i = 0; i < events.length; i++) {
            if (!this.isEmpty(events[i].fromDate)) {
                filteredEvents.push(events[i]);
            }
            if(events[i].locationDetail?.defaultValue !== ""){
                showDeckHeader = true;
            }
            if(events[i].category?.defaultValue !== ""){
                showCategoryHeader = true;
            }
        }

        this.setState({
            events: filteredEvents,
            initialize: false,
            showDeckHeader,
            showCategoryHeader
        });
    };

    getReadableTime(timeString) {
        if (!timeString || timeString === "Late") {
            return "Late"
        } else {
            return timeString.split(':')[0] + ":" + (timeString.split(':')[1] === "0" ? "00" : timeString.split(':')[1])
        }
    }

    render() {

        if (this.props.scheduleLayout) {
            return <DailySchedule
                isTimetable={true}
                timetableEvents={this.state.events}
                title={this.props.title}
            />
        }

        return (
            <div>
                <div className="grid-x grid-padding-x">
                    <If test={!this.state.initialize && this.state.events.length === 0}>
                        <div className="cell medium-12 text-center">
                            <h4>{this.context.t("no_events")}</h4>
                        </div>
                    </If>

                    <If test={!this.state.initialize && this.state.events.length > 0}>
                    <div className="cell medium-12 text-center">
                        <table className="animated fadeIn">
                            {this.props.useExcursionLabels ?
                                <ExcursionsTimetableHeader events={this.state.events} /> :
                                this.props.usePortLabels ?
                                    <PortTimetableHeader /> :
                                    <TimetableHeader events={this.state.events} showDeckHeader={this.state.showDeckHeader} showCategoryHeader={this.state.showCategoryHeader}/>
                            }
                            <tbody>
                            {
                                this.state.events.map((event) => {
                                    let startTime = event.startTime;
                                    if(startTime === null) {
                                        startTime = "Late";
                                    }else if (event.startTimeDescription.defaultPlainValue !== "") {
                                        startTime = event.startTimeDescription.defaultPlainValue;
                                    }else{
                                        startTime = this.getReadableTime(event.startTime);
                                    }

                                    let endTime = event.endTime;
                                    if(endTime === null) {
                                        endTime = "Late";
                                    }else if (event.endTimeDescription.defaultPlainValue !== "") {
                                        endTime = event.endTimeDescription.defaultPlainValue;
                                    }else{
                                        endTime = this.getReadableTime(event.endTime);
                                    }
                                    return (
                                        <Fragment key={event.id}>
                                            {this.props.usePortLabels ?
                                                <PortTimetableBody event={event}/>
                                                :
                                                this.props.useExcursionLabels ?
                                                    <ExcursionTimetableBody event={event} events={this.state.events} />
                                                    :
                                                    <tr id={event.id} key={event.id}>
                                                        <td>{startTime}</td>
                                                        { !isColumnEmpty(this.state.events, 'endTime') && <td>{endTime}</td>}
                                                        <td>{getTranslation(event.title)}</td>
                                                        <If test={this.state.showDeckHeader === true}>
                                                            <td>{getTranslation(event.locationDetail)}</td>
                                                        </If>
                                                        <If test={this.state.showCategoryHeader === true}>
                                                            <td>{getTranslation(event.category)}</td>
                                                        </If>
                                                        { !isColumnEmpty(this.state.events, 'locationDetail.defaultValue') && <td>{getTranslation(event.location)}</td> }
                                                    </tr>
                                            }
                                        </Fragment>
                                    )
                                    })
                            }
                            </tbody>
                        </table>
                    </div>
                    </If>
                </div>
            </div>
        );
    }
}


function TimetableHeader({events, showCategoryHeader, showDeckHeader}, context) {
    return (
        <thead>
            <tr className="text-center">
                <th>{context.t("timetable_start")}</th>
                { !isColumnEmpty(events, 'endTime') && <th>{context.t("timetable_end")}</th> }
                <th>{context.t("timetable_title")}</th>
                <If test={showDeckHeader === true}>
                    <th>{context.t("timetable_deck")}</th>
                </If>
                <If test={showCategoryHeader === true}>
                    <th>{context.t("timetable_category")}</th>
                </If>
                { !isColumnEmpty(events, 'locationDetail.defaultValue') && <th>{context.t("timetable_location")}</th> }
            </tr>
        </thead>
    );
}

function ExcursionsTimetableHeader({events},context) {

    return (
        <thead>
        <tr className="text-center">
            <th>{context.t("meeting_time")}</th>
            { !isColumnEmpty(events, 'endTime') && <th>{context.t("departure_time")}</th>}
            <th>{context.t("experience")}</th>
            <th>{context.t("bus_or_group")}</th>
            { !isColumnEmpty(events, 'location.defaultValue') && <th>{context.t("meeting_location")}</th> }
        </tr>
        </thead>
    )
}

function PortTimetableHeader(props, context) {
    return (
        <thead>
        <tr className="text-center">
            <th>{context.t("date")}</th>
            <th>{context.t("day")}</th>
            <th>{context.t("port")}</th>
            <th>{context.t("arrival")}</th>
            <th>{context.t("departure")}</th>
        </tr>
        </thead>
    )
}

function PortTimetableBody({event}, context) {
    const removeLateText = (string) => {
        if (string && string.trim() === 'Late') {
            return ""
        } else return string
    }

    return (
        <tr id={event.id} key={event.id}>
            <td>{formatYMDtoDMY(event.fromDate)}</td>
            <td>{context.t(getDayFromDate(event.fromDate))}</td>
            <td>{event.title?.defaultPlainValue || event.location?.defaultPlainValue }</td>
            <td>{event.startTime}</td>
            <td>{removeLateText(event.endTime)}</td>
        </tr>
    )
}

function ExcursionTimetableBody({event, events}) {
    return(
        <tr id={event.id} key={event.id}>
            <td>{event.startTime}</td>
            { !isColumnEmpty(events, 'endTime') && <td>{event.endTime}</td>}
            <td>{getTranslation(event.title)}</td>
            <td>{getTranslation(event.locationDetail)}</td>
            { !isColumnEmpty(events, 'location.defaultValue') && <td>{getTranslation(event.location)}</td> }
        </tr>
    )
}

[Timetable, TimetableHeader,ExcursionsTimetableHeader, PortTimetableHeader, PortTimetableBody].forEach(component => {
    component.contextTypes = {
        t: PropTypes.func.isRequired
    };
});

const mapStateToProps = state => {
    return { configuration: state.configuration };
};

export default connect(mapStateToProps, {fetchFromHub: fetchFromHub})(Timetable);