import React from "react";
import browserHistory from "../utils/history";
import {connect} from "react-redux";
import styled, {withTheme} from 'styled-components';
import FooterLanguageSelect from "./FooterLanguageSelect";
import {StyledFooter} from "../resources/styles";
import DisabilityMode from "./DisabilityMode";

const CenteredContainer = styled.div`
    display: flex;
    height: ${props => props.theme.footerHeight || "50px" };
        
    div {
        flex: 1;
        text-align: center;
        display: flex;
        justify-content: center;
    }
    box-shadow: ${props => props.theme.footerBoxShadow || "" };
`;

const FooterButton = styled.button`
    color: ${props => props.theme.footerColor || "#ffffff" };
    font-size: 40px;
    line-height: normal;
    outline: none;
    opacity: ${props => props.theme.footerIconOpacity || "1" };
    display: flex;
    align-items: center;
`;

const FooterIcon = styled.img`
    max-width: 40px;
`;

class Footer extends React.Component {

    back = () => {
        browserHistory.goBack();
    };

    home = () => {
        browserHistory.push("/");
    };


    render() {
        if(this.props.footerType === "hidden") {
            return null;
        }

        if ( this.props.footerType === "dashboard" && !this.props.configuration.hubSettings.enableDisabilityMode ) {
            return <FooterLanguageSelect/>
        }

        return (
            <div>
                <StyledFooter>
                    <CenteredContainer>
                        { this.props.footerType === "dashboard" ?
                            <>
                                <div><FooterButton></FooterButton></div>
                                <div><FooterButton></FooterButton></div>
                            </>
                            :
                            <>
                                <div><FooterButton onClick={this.back}>{ (this.props.theme.altFooterBackIcon && <FooterIcon src={this.props.theme.altFooterBackIcon}/>) || <i className="fa fa-arrow-left"/>}</FooterButton></div>
                                <div><FooterButton onClick={this.home}>{ (this.props.theme.altFooterHomeIcon && <FooterIcon src={this.props.theme.altFooterHomeIcon}/>) || <i className="fa fa-home"/>}</FooterButton></div>
                            </>
                        }
                        <div>
                            <FooterButton>
                                { this.props.configuration.hubSettings.enableDisabilityMode &&
                                    <DisabilityMode
                                        toggleDisabilityMode={this.props.toggleDisabilityMode}
                                        disabilityOn={this.props.disabilityOn}
                                        alterMarginTop={this.props.alterMarginTop}
                                    />
                                }
                            </FooterButton>
                        </div>
                    </CenteredContainer>
                </StyledFooter>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        footerType: state.navigation.footerType,
        configuration: state.configuration,
    };
};


export default connect(mapStateToProps) (withTheme(Footer));