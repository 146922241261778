import React from 'react';
import {useSeanetGenres} from "../../hooks/useSeanetGenres";
import OverviewImageButtonPanel from "../../components/OverviewImageButtonPanel";
import OverviewImageButton from "../../components/OverviewImageButton";
import {withTheme} from "styled-components";
import {getSeanetUrl} from "../../actions/networkActions";

function Genres(props) {
    const genres = useSeanetGenres();

    if(genres === null) {
        return <div>
            loading...
        </div>
    }


    return (
        <OverviewImageButtonPanel>
            {Object.values(genres).map(genre =>
                <OverviewImageButton key={genre.id}
                                     link={"/seanetvod/genre/" + genre.id}
                                     title={genre.name}
                                     thumbnail={getSeanetUrl() + "sti/genre-images/thumb/" + genre.id + ".jpg"}
                                     heightWidthRatio={props.theme.vodCategoryHeightWidthRatio || "100%"}
                />
            )}

        </OverviewImageButtonPanel>
    );

}

export default  withTheme(Genres);
