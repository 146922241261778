import {getFromHubNew} from "../actions/networkActions";

export async function fetchTopViews(url) {
    const result = await getFromHubNew(url);

    if(result && result.length > 0) {
        const lowestDeck = result[0].level;
        let deviceDeck = false;
        let markerDeck = false;

        const data = {};
        result.forEach(map => {
            if(map.isDevicePresent) {
                deviceDeck = map.level;
            }
            if(map.hasWayFinding) {
                markerDeck= map.level;
            }
            data[map.level] = map;
        });

        return {
            data,
            activeDeck: "" +  (markerDeck || deviceDeck || lowestDeck),
        }
    }

    return false;
}

export async function fetchSideView(url) {
    const result = await getFromHubNew(url);
    if(result && result.length > 0) {
        return result[0];
    }

    return false;
}