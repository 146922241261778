import React from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import {PropTypes} from "prop-types";
import If from '../../components/If';
import Modal from "../../components/Modal";
import TextInputWithLabel from '../../components/TextInputWithLabel';
import {clearVodError} from "../../actions/vodActions";
import {loginByPassenger, logoutUser} from "../../actions/authenticationActions";
import {DefaultButton} from "../../resources/styles";
import RescoLogin from "./RescoLogin";
import RescoKioskLogin from "./RescoKioskLogin";
import HotelFriendLogin from "./HotelFriendLogin";

class Login extends React.Component {
    constructor() {
        super();
        this.state = {
            departureDate: moment().format('YYYY-MM-DD'),
            bookingReference: "",
            leadPassengerLastName: "",
            showError: false,
            errors: {},
        };
    }

    componentDidMount() {
        if (this.props.authErrorMessage) {
            this.props.logoutUser();
        }
    }

    componentWillUnmount() {
        this.props.clearVodError();
    }

    handleDateChange = (date) => {
        this.setState({
            departureDate: date
        })
    };

    handleBookingReferenceChange = (bookingReference) => {
        const trimmedBookingReference = bookingReference.trim();
        this.setState({
            bookingReference: trimmedBookingReference
        })
    };

    handleNameChange = (name) => {
        this.setState({
            leadPassengerLastName: name
        })
    };

    validateForm = (e) => {
        e.preventDefault();
        let errors = {};
        if (!this.state.departureDate) {
            errors.date = this.context.t("login_date_error");
        }

        if (!this.state.bookingReference) {
            errors.bookingReference = this.context.t("login_booking_reference_error");
        }

        if (!this.state.leadPassengerLastName) {
            errors.lastName = this.context.t("login_last_name_error");
        }

        this.setState({
            errors: errors,
        });

        if (Object.keys(errors).length === 0) {
            this._onLogin();
        }
    };

    render() {
        const pms = this.props.pmsConfiguration?.pms;
        if (pms === "Retina") {

            let retinaReLoginUrl = null;

            if (this.props.pmsConfiguration.reLoginUrl) {
                retinaReLoginUrl = this.props.pmsConfiguration.reLoginUrl;
            }

            return (

                <div className="grid-x grid-padding-x">
                    <div className="cell small-12">

                        <If test={retinaReLoginUrl}>
                            <p>{this.context.t("reLoginMessageRetina")} <a href={retinaReLoginUrl}>{this.context.t("click_here")}</a></p>
                        </If>

                        <If test={!retinaReLoginUrl}>
                            <p>{this.context.t("configuration_missing")} retinaLoginUrl</p>
                        </If>
                    </div>
                </div>

            )
        }

        if(pms === "HotelFriend") {
            return (
                <HotelFriendLogin />
            )
        }

        if(pms === "Resco") {
            return this.props.deviceType === "Kiosk" ?
                <RescoKioskLogin /> :
                <RescoLogin />;
        }


        let buttonDisabled = false;

        return (

            <div>
                <Modal show={this.props.isFetching}/>


                <form onSubmit={(e) => this.validateForm(e)}>
                    <div className="grid-container">
                        <div className="grid-x grid-padding-x align-center">
                            <div className="cell small-10 medium-4 large-4 text-left text-4xl text-uppercase">{this.context.t("accessApplication")}</div>
                        </div>

                        <div className="grid-x grid-padding-x grid-padding-y align-center">
                            <div className="cell small-10 medium-4 large-4 text-left">

                                <TextInputWithLabel
                                    label={this.context.t("departureDate")}
                                    _onChange={value => this.handleDateChange(value)}
                                    value={this.state.departureDate}
                                    type="date"
                                    min={moment().format('YYYY-MM-DD')}
                                    className="text-left"
                                    errorMessage={this.state.errors.date}
                                />

                            </div>
                        </div>
                        <div className="grid-x grid-padding-x align-center">
                            <div className="cell small-10 medium-4 large-4 text-left">

                                <TextInputWithLabel
                                    label={this.context.t("bookingReference")}
                                    value={this.state.bookingReference}
                                    _onChange={value => this.handleBookingReferenceChange(value)}
                                    placeholder="12345678"
                                    errorMessage={this.state.errors.bookingReference}
                                />
                            </div>
                        </div>

                        <div className="grid-x grid-padding-x grid-padding-y align-center">
                        <div className="cell small-10 medium-4 large-4 text-left">

                                <TextInputWithLabel
                                    label={this.context.t("leadPassengerSurname")}
                                    value={this.state.leadPassengerLastName}
                                    _onChange={value => this.handleNameChange(value)}
                                    placeholder={this.context.t("placeholderLastname")}
                                    errorMessage={this.state.errors.lastName}
                                />
                        </div>
                        </div>

                        <div className="grid-x grid-padding-x align-center">
                        <div className="cell small-10 medium-4 large-4 text-left">
                            <div>
                                <DefaultButton disabled={buttonDisabled}
                                        onClick={(e) => this.validateForm(e)}>
                                       {this.context.t("login")}
                               </DefaultButton>
                            </div>
                        </div>

                    </div>
                    </div>
                </form>

                <If test={this.props.authErrorMessage}>
                    <div className="grid-x grid-padding-x align-center">
                        <div className="cell small-10 medium-4 large-4 text-left">
                            <div className="margin-top-05 text-left errors" data-closable>{this.context.t("loginFailed")}.</div>
                        </div>
                    </div>
                </If>

            </div>
        );
    }

    async _onLogin() {
        const validPassenger = await this.props.loginByPassenger(this.state.departureDate, this.state.bookingReference, this.state.leadPassengerLastName);
        if (validPassenger) {
            this.props.history.push(
                this.props.location.state && this.props.location.state.from  ?
                this.props.location.state.from :
                '/my_holiday'
            );
        }
        else {
            this.setState({showError: true});

            let errors = {};
            errors.date = this.context.t("login_date_error");
            errors.bookingReference = this.context.t("login_booking_reference_error");
            errors.lastName = this.context.t("login_last_name_error");
            this.setState({errors: errors,});
        }
    }
}

Login.contextTypes = {
    t: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    return {
        isFetching: state.vod.isFetching,
        authErrorMessage: state.authentication.errorMessage,
        lang: state.i18nState.lang,
        pmsConfiguration: state.configuration.hubSettings.pmsConfiguration,
        deviceType: state.configuration.hubSettings.deviceType,
    };
};


export default connect(mapStateToProps, {clearVodError, loginByPassenger, logoutUser}) (Login);