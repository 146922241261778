import React from 'react';
import {formatDuration} from "../../utils/dateTimeUtils";

class ExcursionDuration extends React.Component {
    render() {

        if(!this.props.duration && !this.props.publishedDuration){
            return null;
        }

        return (
            <div className="">
                <img className="w-4 h-4 align-middle mr-2 inline" alt="clock icon" src={require('../../resources/images/clock.png')} />
                <span className="align-middle text-grey-darker">{ this.props.publishedDuration ? this.props.publishedDuration : formatDuration(this.props.duration)}</span>
            </div>
        )
    }
}

export default ExcursionDuration;