import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import {getFromSeanet, getSeanetUrl} from "../../actions/networkActions";
import ReactPlayer from 'react-player';

function MovieDetail(props) {
    const movieId = props.match.params.id;
    const token = useSelector(state => state.seanetVod.token);

    const [movieInfo, setMovieInfo] = useState(null);

    useEffect(() => {
        const fetchMovieInfo = async () => {
            const response = await getFromSeanet(movieId + "/metadata.json");
            setMovieInfo(response);
        }

        fetchMovieInfo();

    }, [movieId]);

    if(movieInfo === null || token === undefined) {
        return <div>
            loading ...
        </div>
    }


    const movieUrl = getSeanetUrl() + "v/hls/" + movieId + "/" + movieInfo.media.origin.media + "/index.m3u8";


    return (
        <div>
            <h2>
                {movieInfo.title}
            </h2>
            <div className="my-4">
                {movieInfo.tagline}
            </div>
            <div className="my-4">
                {movieInfo.synopses.overview}
            </div>
            <div className="my-4">
                <ReactPlayer id="reactPlayerTag" className="react-player"
                             width='100%'
                             url={movieUrl}
                             playsinline
                             autoPlay
                             controls
                             playing
                             pip={false}
                             config={{
                                 file: {
                                     hlsOptions: {
                                         xhrSetup: function(xhr) {
                                             xhr.setRequestHeader('Authorization', "Bearer " + token)
                                         }
                                     }
                                 }
                             }}
                />
            </div>
        </div>
    );
}

export default MovieDetail;