import {store} from '../App';

export function getTranslation(object) {
    const languageCode = getLocale();

    if (languageCode && object && object[languageCode]) {
        return object[languageCode];
    }

    if (object && (object.defaultValue || object.defaultValue === "")) {
        return object.defaultValue;
    }

    if (!object) {
        return "";
    }

    console.error("unable to get translation for", object);
    return "";
}

export function getLocale() {
    return store.getState().i18nState.lang;
}

export function getLocalizedImage(imageName) {
    const languageCode = getLocale();

    try {
        return require(`../resources/images/${languageCode}_${imageName}`)
    } catch (e) {
        return require(`../resources/images/${imageName}`);
    }
}

export function formatLocaleLong() {
    switch (getLocale()) {
        case 'en':
            return 'en-GB';
        case 'de':
            return 'de-DE';
        default:
            return 'en-GB';
    }
}

export function getSelectedCurrency() {
    switch (getLocale()) {
        case 'en':
            return 'GBP';
        case 'de':
            return 'EUR';
        default:
            return 'USD';
    }
}