import React from 'react';

class Price extends React.Component {
    render() {
        return (
            <div className={"text-primary-dark" + (this.props.classes ? " " + this.props.classes : "")}>
                <span className={(this.props.largePreText ? "text-lg" : "text-base") + (this.props.textBottom ? " align-bottom" : "")}>
                    {this.props.preText}
                </span>

                {!this.props.hidePoundSign &&
                <span className={"text-xl font-bold" + (this.props.textBottom ? " align-bottom" : "")}> £</span>
                }

                <span className={"text-3xl md:text-4xl font-bold" + (this.props.textBottom ? " align-bottom" : "")}>{isNaN(this.props.price) ? this.props.price : priceWithTwoDecimals(this.props.price)}</span>

                <span className={(this.props.largePostText ? "text-lg" : "text-base") + (this.props.textBottom ? " align-bottom" : "")}>
                    {this.props.postText}
                </span>
            </div>
        )
    }
}

export default Price;

function priceWithTwoDecimals(price) {
    return parseFloat(price).toFixed(2);
}