import React from 'react';
import PropTypes from 'prop-types';

class Modal extends React.Component {
    render() {
        // Render nothing if the "show" prop is false
        if(!this.props.show) {
            return null;
        }

        if (this.props.errormessage) {
            const myButtonId = "closeButtonId";

            return (
                <div className="digi-backdrop">
                    <div className="digi-modal">
                        <div className="digi-modal-close-button">
                        <button onClick={this.props.onClose} id={myButtonId}>
                            <i className="fa fa-times fadigi"/>
                        </button>
                        </div>

                    </div>
                    <div className="digi-modal-message-field">{this.props.errormessage}</div>
                </div>
            );
        }

        return (
            <div className="digi-backdrop">
                <div className="digi-modal">
                    <i className="fa fa-spinner fa-spin digi-spinner" />
                </div>
            </div>
        );
    }
}

Modal.propTypes = {
    onClose: PropTypes.func,
    show: PropTypes.bool,
    children: PropTypes.node
};

export default Modal;