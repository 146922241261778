const config = {

    "95.216.43.202": {
        "hubUrl": "http://95.216.43.202:8080/",
        "hubApiUrl": "http://95.216.43.202:8080/v1/",
        "developmentMode": false
    },

    "88.198.57.61": {
        "hubUrl": "https://tui-hub-test.digitech365.com/",
        "hubApiUrl": "https://tui-hub-test.digitech365.com/v1/",
        "developmentMode": false
    },

    "localhost": {
        "hubUrl": "http://hub.kontron/",
        "hubApiUrl": "http://hub.kontron/v1/",
        "developmentMode": true,
    },

    "192.168.0.150": {
        "hubUrl": "http://192.168.0.150:8080/",
        "hubApiUrl": "http://192.168.0.150:8080/v1/",
        "developmentMode": true,
    },

    "46.4.120.66": {
        "hubUrl": "http://46.4.120.66:8080/",
        "hubApiUrl": "http://46.4.120.66:8080/v1/",
        "developmentMode": false
    },

    "176.9.157.227": {
        "hubUrl": "http://176.9.157.227:8080/",
        "hubApiUrl": "http://176.9.157.227:8080/v1/",
        "gamesUrl": "http://176.9.157.227:444",
        "developmentMode": false,
    },

    "navigate.holiday": {
        "hubUrl": "https://hub.navigate.holiday/",
        "hubApiUrl": "https://hub.navigate.holiday/v1/",
        "developmentMode": false,
        "defaultLanguage": "de",
        "fallbackLanguage": "en",
    },

    "resort.navigate.holiday": {
        "hubUrl": "https://resort-hub.navigate.holiday/",
        "hubApiUrl": "https://resort-hub.navigate.holiday/v1/",
        "developmentMode": false,
    },

    "airline.navigate.holiday": {
        "hubUrl": "https://airline-hub.navigate.holiday/",
        "hubApiUrl": "https://airline-hub.navigate.holiday/v1/",
        "developmentMode": false,
    },

    "airport.navigate.holiday": {
        "hubUrl": "https://airport-hub.navigate.holiday/",
        "hubApiUrl": "https://airport-hub.navigate.holiday/v1/",
        "developmentMode": false,
        "gaCode": "UA-152934923-2",
    },

    "bus.navigate.holiday": {
        "hubUrl": "https://bus-hub.navigate.holiday/",
        "hubApiUrl": "https://bus-hub.navigate.holiday/v1/",
        "developmentMode": false,
    },

    "navigate-tui.com": {
        "hubUrl": "https://hub.navigate-tui.com/",
        "hubApiUrl": "https://hub.navigate-tui.com/v1/",
        "developmentMode": false
    },

    "10.10.160.22": {
        "hubUrl": "http://10.10.160.22:8090/",
        "hubApiUrl": "http://10.10.160.22:8090/v1/",
        "developmentMode": false
    },
    "10.10.161.22": {
        "hubUrl": "http://10.10.161.22:8080/",
        "hubApiUrl": "http://10.10.161.22:8080/v1/",
        "developmentMode": false
    },
    "10.10.161.37": {
        "hubUrl": "http://10.10.161.37:8080/",
        "hubApiUrl": "http://10.10.161.37:8080/v1/",
        "developmentMode": false
    },
    "don.navigate.holiday": {
        "gaCode": "UA-152934923-2",
    },

    "app.dsa.navigate-airport.com": {
        "hubUrl": "https://don-hub.navigate.holiday/",
        "hubApiUrl": "https://don-hub.navigate.holiday/v1/",
        "gaCode": "UA-152934923-2",
    },
}
export default config;