import React from 'react';
import {connect} from 'react-redux';
import {PropTypes} from 'prop-types';
import {formatItineraryDate} from '../utils/dateTimeUtils';
import {getTranslation} from '../translations/translationUtils';
import ItineraryHeader from "./ItineraryHeader";
import ItineraryDivider from "./ItineraryDivider";


class CruisesOverview extends React.Component {

    getCruisesInfo = () => {

        let cruiseDuration = 0;
        let cruiseName = "";
        const cruiseStart = this.props.cruises[0].fromDate;
        const shipName = this.props.cruises[0].ship.name;

        this.props.cruises.forEach((cruise) => {
            cruiseDuration += cruise.numberOfDays;
            cruiseName += getTranslation(cruise.name) + " & "
        });

        cruiseName = cruiseName.substring(0, cruiseName.length - 3);

        return {
            shipName,
            cruiseName,
            cruiseStart,
            cruiseDuration,
        };
    };

    render() {
        if(!this.props.cruises || this.props.cruises.length === 0) {
            return null;
        }

        const cruisesInfo = this.getCruisesInfo();

        return (
            <div className="my-4">
                <ItineraryHeader title="Cruise" icon="ship.svg"/>
                <div className="ml-6">
                    <div>
                        {cruisesInfo.cruiseName}
                    </div>
                    <div>
                        {formatItineraryDate(cruisesInfo.cruiseStart)}, {cruisesInfo.cruiseDuration} {' '}
                        {this.context.t("cruises_overview_nights_on")} {' '}
                        {cruisesInfo.shipName} {' '}
                    </div>

                    <div className="my-2 itinerary-timeline">
                        {this.props.itinerary.map((itineraryElement) =>
                            <ItineraryEntry
                                key={itineraryElement.id}
                                {...itineraryElement} />
                        )}
                    </div>
                </div>
                <ItineraryDivider />
            </div>
        );
    }
}

CruisesOverview.contextTypes = {
    t: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        cruises: state.itinerary.cruises,
        itinerary: state.itinerary.cruiseItinerary ? state.itinerary.cruiseItinerary : [],
    };
}

export default connect(mapStateToProps) (CruisesOverview);

class ItineraryEntry extends React.Component {
    getPortName = () => {
        if(this.props.type === "Sea") {
            return "AT SEA";
        }
        return this.props.port.name.defaultValue + ", " + this.props.port.countryName;

    };

    render() {
        return (
            <div className="flex">
                <div className="inline-block align-top mr-4 z-5">
                    <img className="w-4 h-4" alt="" src={require('../resources/images/itinerary_icons/pin.svg')} />
                </div>
                <div className="inline-block flex-grow mb-2">
                    Day {this.props.dayOfCruise} {' '}
                    <span className="uppercase">
                        {this.getPortName()}
                    </span>
                </div>
            </div>
        )
    }
}