import React from "react";
import {Link} from "react-router-dom";
import {defaultImageSrc} from "../utils/imageUtils";
import {truncateStringBeforeLastWord} from "../utils/stringUtils";
import {ItemContainer, ItemTitle, TileContainer} from '../resources/styles';
import ImageWithFallback from "./ImageWithFallback";
import {withTheme} from "styled-components";



class VodImageButton extends React.Component {

    // TODO - wire this up when implementing watchlists
    watchlist = (e) => {
        e.preventDefault();
        if(this.props.onClickStar) {
            this.props.onClickStar(this.props.item);
        }
    };

    render() {
        let imageUrl = this.props.thumbnail;

        if (!imageUrl) {
            imageUrl = defaultImageSrc();
        }

        let link = this.props.link;
        if (this.props.item) {
            if (this.props.item.externalUrl) {
                if (this.props.item.useExternalUrl) {
                    link = this.props.item.externalUrl;
                }
            }
        }

        return (
            <div className="cell">
                <TileContainer heightWidthRatio={this.props.heightWidthRatio} marginBottom="1.875rem">
                    <Link to={{pathname: link, state: this.props.extraState}}>
                        <ItemContainer>
                            <ImageWithFallback src={imageUrl} alt="poster"/>

                            <ItemTitle minHeight="3.5rem">
                                {this.props.truncateTitle ? truncateStringBeforeLastWord(this.props.title, 30) : this.props.title}
                            </ItemTitle>
                        </ItemContainer>
                    </Link>
                </TileContainer>
            </div>
        )
    }
}

export default withTheme(VodImageButton);