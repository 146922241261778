import React from "react";
import {DefaultButton} from "../resources/styles";
import {PropTypes} from "prop-types";

const TacBookButton = ({setObject}, context) => {
    return (
        <DefaultButton onClick={setObject}>
            {context.t("book_now")}
        </DefaultButton>
    );
}

TacBookButton.contextTypes = {
    t: PropTypes.func.isRequired,
}

export default TacBookButton;