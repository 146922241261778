import React from 'react';
import Player from "./THEOplayer";
import {connect} from "react-redux";
import {PropTypes} from "prop-types";
import {clearPlayoutAssets, fetchPlayoutAssets, fetchVodDetails, updateSecondsPlayed} from "../actions/vodActions";
import VodUtils from "../modules/vod/VodUtils";

class TheoPlayerComp extends React.Component {
    constructor() {
        super();
        this.state = {
            playedSeconds: 0,
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        this.props.clearPlayoutAssets();
        // this.updateLastPosition();
        if (this.player) {
            this.player._player.removeEventListener('timeupdate');
            this.player._player.removeEventListener('loadeddata');
        }
    }

    updateLastPosition() {
        if (this.props.voddetails) {
            this.props.updateSecondsPlayed(this.props.voddetails.id, this.state.playedSeconds);
        }
    }

    ref = player => {
        let that = this;

        if (player) {
            this.player = player;

            this.player._player.autoplay = true;
            this.player._player.addEventListener('timeupdate', function (e) {
                if (that && that.player) {
                    that.setState({playedSeconds: e.currentTime})
                }
            });

            this.player._player.addEventListener('loadeddata', function () {
                if (that) {
/*                    if (that.props.voddetails && that.props.voddetails.last_playback_status) {
                        const lastPosition = that.props.voddetails.last_playback_status.position_seconds;
                        that.player._player.currentTime = lastPosition;
                    }*/
                    if (that.props.vodassets) {
                        that.player._player.currentTime = 0;
                    }
                }
            });
        }
    };

    render() {
        if (!this.props.vodassets) {
            return false;
        }

        let title = "";
        if (this.props.voddetails) {
            title = this.props.voddetails.title;
        }

        let urlToPlay = "";
        let playerType = "video";
        if(this.props.vodassets.url){
            urlToPlay = this.props.vodassets.url;
            if(this.props.vodassets.url.includes("audio.m3u8"))
                playerType = "audio";
        }

        let minutes = Math.floor(this.state.playedSeconds / 60);
        let seconds = Math.round(this.state.playedSeconds - minutes * 60);
        if (seconds < 10) {
            seconds = "0" + seconds;
        }

        if (isNaN(minutes) || isNaN(seconds)) {
            minutes = "0";
            seconds = "00";
        }

        let playerContainerStyle = {
            marginTop: "1em",
            maxWidth: "750px",
        };

        let picUrl = "";
        if(playerType === "audio"){
            if(this.props.voddetails && this.props.voddetails.images[0].copies[0].url) {
                const protocol = VodUtils.getProtocol(this.props.vodconfiguration.url);
                picUrl = protocol + "//" + this.props.voddetails.images[0].copies[0].url;
            }
        }

        return (
            <div>
                <h1>{title}</h1>
                <h4>{this.context.t("elapsedTime", {}, "Please translate!")}: {minutes}:{seconds} </h4>
                <div style={playerContainerStyle}>
                    <Player source={urlToPlay}
                            type={"application/x-mpegurl"}
                            ref={this.ref}
                            cover={picUrl}
                    />
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        vodassets: state.voditem.vodassets,
        voddetails: state.voditem.voddetails,
        vodconfiguration: state.vodconfiguration,
    };
};


TheoPlayerComp.contextTypes = {
    t: PropTypes.func.isRequired
};

export default connect(mapStateToProps, {fetchPlayoutAssets: fetchPlayoutAssets, fetchVodDetails: fetchVodDetails, updateSecondsPlayed: updateSecondsPlayed, clearPlayoutAssets: clearPlayoutAssets}) (TheoPlayerComp);
