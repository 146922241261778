import React from 'react';
import styled from 'styled-components';
import If from './If';

const Container = styled.div`
    background-color: #F3F0EC;
    padding: 0.5rem;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
`;

const Paragraph = styled.div`
    margin-bottom: 1rem;
    line-height: 1.25;
`;

const TitleText = styled.div`
    font-weight: bold;
    margin-top: 1rem;
    margin-bottom: 0.25rem;
`;



class CartTCs extends React.Component {
    constructor() {
        super();
        this.tcRef = React.createRef();

        this.state = {
            showText: false,
        }
    }

    toggleShowText = () => {
        this.setState({
            showText: !this.state.showText,
        }, this.scrollToTCs);
    };

    scrollToTCs = () => {
        if(this.state.showText) {
            window.scrollTo({
                top:this.tcRef.current.offsetTop,
                behavior: "smooth"
            });
        }
    };

    render() {
        return (
            <Container>

                <Header onClick={() => {this.toggleShowText()}}>
                    <div className="text-uppercase" ref={this.tcRef}>Terms & Conditions</div>

                    <If test={this.state.showText}>
                        <div><i className="fa fa-chevron-up"></i></div>
                    </If>

                    <If test={!this.state.showText}>
                        <div><i className="fa fa-chevron-down"></i></div>
                    </If>
                </Header>
                <If test={this.state.showText}>
                    <div className="margin-top-1">
                        <TitleText>OUR AGREEMENT WITH YOU</TitleText>

                        <Paragraph>Our Agreement With You sets out what you are legally entitled to expect from us when
                            you buy an excursion from us and will not apply to any course of dealings between us
                            other than the excursion purchased in the destination and sold by the entity shown in
                            our leaflets. Please read these terms and conditions carefully, before you book, to see
                            how they affect your specific excursion arrangement made by us.</Paragraph>

                        <Paragraph>You are given the ability to obtain excursion, activity, attractions and ticket services
                            (hereinafter referred to as “excursions”), subject to the acceptance of these Booking
                            Terms and Conditions, in accordance to the prices, rates and conditions of cancellation
                            displayed in the leaflet at the moment of booking.</Paragraph>



                        <TitleText>1. Your Excursion Booking</TitleText>

                        <Paragraph>Whether you book alone or as a group, we will only deal with the lead booking name in
                            all subsequent correspondence, including changes, amendments and cancellations.
                            You must be 18 years old at the time of booking and possess the legal capacity and
                            authority to book as the lead name and enjoy an excursion with us and take up the
                            offers advertised by us if they are still available. You are responsible for ensuring the
                            accuracy of the personal details or any other information supplied in respect of yourself
                            and any other person travelling on the booking and for passing on any information
                            regarding the booking or any changes made in relation thereto, to all persons travelling
                            on such booking, including but not limited to information on schedule changes or
                            copies of booking confirmations.</Paragraph>

                        <Paragraph>It is your responsibility to ensure that you and your party abide by supplier’s terms and
                            conditions and the relevant health and safety instructions.</Paragraph>

                        <Paragraph>It is your responsibility to declare any health issues that may prevent you or any party
                            member from participating in the excursion or that may cause potential risk to
                            yourselves or others.</Paragraph>

                        <Paragraph>Some excursions may have certain restrictions based on age, health, weight and/or
                            height, which have been imposed by the supplier. For your own safety, we urge you to
                            abide by them.</Paragraph>

                        <Paragraph>It is your responsibility to ensure you and your party read all literature carefully for
                            details of the level of the excursion and the necessary experience (if any) and/or ability
                            required.</Paragraph>

                        <Paragraph>Group sizes may vary from 1 to 99 participants. If the excursion you choose does not
                            expressly indicate that it is suitable for less mobile people, it is unsuitable for people
                            with mobility issues.</Paragraph>

                        <Paragraph>Please be aware that some excursions do carry an element of risk and it is your
                            responsibility to ensure that the excursion is suitable for you and/or your party and that
                            you are fit enough to join in. We urge you to check that you have suitable and valid
                            insurance for your excursion and optional extras. It is your responsibility to declare any
                            material facts including known medical conditions to your insurers, as failure to do so
                            may result in a claim being reduced or declined.</Paragraph>

                        <Paragraph>It is a highly advised when you book with us that you and all other members of your
                            party, including infants and children, are adequately insured on holiday. Any person
                            who is under 18 years old must be accompanied by an adult on his or her excursion.
                            There may be other restrictions and conditions on some offers, but these are explained
                            in the details of those offers. Confirmation of your booking will be provided in a printed
                            ticket by our representative (optionally sent to an email address supplied by you
                            instead) if you do not receive this please contact us.</Paragraph>

                        <Paragraph>The contractual terms of Our Agreement apply between you and us because you have
                            chosen an excursion with us.</Paragraph>

                        <Paragraph>During the booking process, we will request personal data such as your name,
                            surname or credit card details. All of the data collected will be treated in accordance
                            with our <a href="/downloads/privacy_policy.pdf" target="_blank">Privacy Policy.</a></Paragraph>



                        <TitleText>2. The Price You Pay</TitleText>

                        <Paragraph>All prices we advertise are accurate at the date published, but we reserve the right to
                            change any of those prices from time to time.</Paragraph>
                        <Paragraph>The price of the excursion that you will pay to us when you confirm your booking
                        includes:
                            <ul>
                                <li>All services specified on the booking confirmation.</li>
                                <li>Corresponding VAT.</li>
                            </ul>
                        </Paragraph>

                        <Paragraph>The price does not include any service that is not specified in the booking confirmation.</Paragraph>


                        <Paragraph>
                            <span className="font-bold">Forms of payment we accept:</span>
                            <ul>
                                <li>Cash</li>
                                <li>Mastercard.</li>
                                <li>Visa.</li>
                                <li>Amex.</li>
                                <li>Sofort.</li>
                                <li>Ideal.</li>
                                <li>Mr. Cash.</li>
                                <li>Paypal.</li>
                            </ul>
                        </Paragraph>

                        <Paragraph>If payment is done by credit/debit card,, the full quoted amount will be deducted from
                            your credit or debit card. Payment transactions on our devices are encrypted by a
                            secure payment system endorsed by some of the world&#39;s leading banks and our site is
                            verified by Thawte.</Paragraph>

                        <Paragraph>This technically secure environment ensures that credit card details cannot be
                            intercepted and are not revealed to anyone other than to financial institutions required
                            to process payment instructions from the customer.</Paragraph>

                        <Paragraph>As regards invoicing, if you should request an invoice, you agree to receive an
                            electronic invoice, which you will receive via email in PDF format. Notwithstanding the
                            foregoing, you can revoke this consent by contacting the Customer Service Centre</Paragraph>


                        <TitleText className="text-underline font-italic">TUI Value Guarantee</TitleText>

                        <Paragraph>All TUI excursions are covered by our best Value Guarantee. This means that you can
                            be sure that you will not find the same great value excursions at a lower price
                            anywhere else. If you do, we will refund you the difference subject to the conditions
                            detailed below.</Paragraph>

                        <Paragraph>Value Guarantee Conditions: This guarantee applies to TUI excursions. To claim your
                            refund of the price difference, you must provide documented evidence of the cheaper
                            excursion more than 24 hours prior to the excursion start time. As documented
                            evidence we shall accept any published material where all conditions of the excursion
                            can be perfectly verified e.g. a current printed brochure or website screenshot including
                            valid URL. The excursion must be identical to that offered by TUI, and shall not be part
                            of a package, in terms of: itinerary, content, stops, length of excursion, bus quality,
                            language and number of languages spoken and service given. Excursions must
                            operate during the same calendar week (Mon - Sun). The Value Guarantee does not
                            apply to unlicensed/illegal traders and excludes special
                            offers.</Paragraph>

                        <Paragraph>The following services are not considered as excursions for
                        the purpose of the Value
                        Guarantee and therefore shall be excluded from the Value Guarantee : Flight Extras,
                        Flight tickets, VIP Airport Services, VIP Lounges, Transfers, Merchandise, Childcare
                        Extras, Rental Cars, Vehicle Rentals, Service Fees, Golf Extras, Wedding Extras, Hotel
                        Upgrades, Entertainment Games and/or Event Ticket
                        Deposits.</Paragraph>



                        <TitleText>3. If We Cancel Your Booking</TitleText>

                        <Paragraph>We aim to provide your excursion as booked. But if, for example,
                        there are not enough
                        people booked on your excursion, we may cancel it. We reserve the right to cancel
                        your excursion in any circumstances but if we cancel your excursion, you can either
                        have a refund or accept a replacement excursion from us of equivalent or similar
                        standard and price, if we are able to offer you one. Should you choose this option the
                        terms and conditions of your excursion will not change and these conditions will still
                        apply to your booking. In either case, we will always refund the difference in price if the
                        replacement excursion is of a lower standard and price.</Paragraph>



                        <TitleText>4. If We Cancel Your Booking Details</TitleText>

                        <Paragraph>We hope that we will not have to make any change to your excursion but we
                        sometimes do need to make changes. We reserve the right to do this at any time. We
                        will let you know about any important changes when you book. If you have already
                        booked, we will let you know as soon as we can, if there is time before your excursion.
                        Excursions timings shown by us may change. Your Confirmation ticket will show the
                        latest planned timings. Your actual timings will be shown on your ticket (including any
                        e-ticket itinerary), which you should check carefully as soon as you receive it.</Paragraph>

                        <TitleText>Major Changes To Your Excursion</TitleText>

                        <Paragraph>Occasionally, we have to make major changes to your excursion.</Paragraph>

                        <Paragraph>If we tell you about any of these changes after we have confirmed your booking, you
                            may either:
                            <ul>
                                <li>accept a replacement excursion from us of equivalent or similar standard and
                                    price, at the date of the change, if we are able to offer you one. We may offer
                                    you a replacement excursion from another company in our group. Should you
                                    choose this option the terms and conditions of your excursion will not change
                                    and these conditions still apply to your booking; or
                                    </li>
                                <li>cancel your excursion with us and receive a full refund of all money paid.
                                    We will always refund the difference in price if the replacement excursion is of a lower
                                    standard and price, at the date of the change.</li>
                            </ul>
                        </Paragraph>


                        <TitleText>If The Change Is Not Acceptable To You</TitleText>
                        
                        <Paragraph>If any major change indicated above is not acceptable to you, you can cancel your
                            excursion booking. In this case, we will refund all the money you have paid us.</Paragraph>
                        
                        <TitleText>Important Note – Events Beyond Our Control</TitleText>
                        
                        <Paragraph> beyond our control include: war, threat of war, riots, civil disturbances, terrorist
                            activity and its consequences, industrial disputes, natural and nuclear disasters, fire,
                            epidemics, health risks and pandemics, unavoidable and unforeseeable technical
                            problems with transport for reasons beyond our control or that of our suppliers; closed
                            or congested airports or ports, hurricanes and other actual or potential severe weather
                            conditions, and any other similar events.</Paragraph>



                        <TitleText>5. Our Responsibility For Your Excursion</TitleText>

                        <Paragraph>We will arrange for you to receive the services that make up the excursion that you
                            choose and that we confirm. These services will be provided either directly by us or
                            through independent local suppliers at destination contracted by us. We are
                            responsible for making sure that each part of the excursion you book with us is
                            provided to a reasonable standard and as was advertised by us (or as changed and
                            accepted by you).</Paragraph>

                        <Paragraph>We have taken all reasonable care to make sure that all the services which make up
                            your excursion advertised by us are provided by efficient and reputable businesses.
                            These businesses should follow local standards.</Paragraph>



                        <TitleText>6. Personal Injury</TitleText>

                        <Paragraph>If you suffer injury, illness or death directly as a result of the services provided as part
                            of your excursion we may make a payment to you. We will not make any payment if
                            your injury, illness or death was caused by an event or circumstances which the person
                            who caused it could not have predicted or avoided even if they had taken all necessary
                            and due care. We will not make any payment if your illness, injury or death was your
                            own fault.

                            <ol>
                                <li>
                                    You must tell us and the supplier involved about your injury or illness while you
                                    are in the resort. You should also write to our Customer Support team about your
                                    claim within three months of your excursion to allow us to investigate it properly and
                                    cooperate with us so as to enable us to carry out such investigation. Please include
                                    a letter about your injury or illness from your doctor if you can.
                                </li>
                                <li>
                                    You should transfer to us any rights you have against the supplier or any other
                                    person.
                                </li>
                                <li>
                                    You should cooperate fully with us if we or our insurers want to enforce those
                                    rights.
                                </li>
                                <li>
                                    Any payments we make may be limited in accordance with international
                                    conventions.
                                </li>
                            </ol>

                        </Paragraph>

                        <Paragraph>We ask you to transfer your rights to us so that we can claim back from suppliers any
                            payments we make to you, plus any legal or other costs. We will not make a profit from
                            this. If we get back from the supplier more than we have paid you plus these costs, we
                            will give the extra money to you.</Paragraph>

                        <Paragraph>If you or someone on your excursion booking is injured, falls ill or dies while taking part
                            in an activity which is not part of the excursion, or you need to incur unpredictable extra
                            expenses for which we are not liable because the event is beyond our control we will,
                            where appropriate and subject to our discretion, try to help if we can.</Paragraph>

                        
                        
                        
                        <TitleText>YOUR AGREEMENT WITH US</TitleText>
                        
                        <TitleText>7. Your Contract</TitleText>

                        <Paragraph>By asking us to confirm your booking, you are accepting on behalf of all persons under
                            the booking that the terms of this Agreement, constitute the entire agreement between
                            us with regard to your booking and your excursions arrangements. You also consent to
                            our processing personal information about you and other members of your party.
                            Where the context permits, reference to “you” and “your” will include you and all
                            persons under this booking.</Paragraph>

                        <Paragraph>It is your responsibility to comply with all passport, visa and other immigration
                        requirements. Your passport and travel documents must be intact; you may be refused
                        travel if they are damaged or have been tampered with. We do not accept any
                        responsibility if you cannot travel because you have not complied with these
                        requirements.</Paragraph>


                        <TitleText>8. If You Change Your Booking</TitleText>

                        <Paragraph>You must ensure all names and details are entered correctly at the time of booking.
                            You will receive a confirmation ticket once your booking is confirmed and must contact
                            us straight away if there is something that you need to correct, or if you don’t receive it
                            for any reason.</Paragraph>

                        <Paragraph>You will be able to reduce the number of people on the excursion as well as the
                            excursion date. For any changes you must contact our Customer Service team,</Paragraph>

                        <Paragraph>When changing your excursion details, the price of your excursion will be based on the
                            price that applies on the day you make the change. These prices may not be the same
                            as when you first made your booking.</Paragraph>

                        <Paragraph>Changes will not be allowed on those bookings which are stated as non-refundable at
                            the time of booking.</Paragraph>
                        
                        

                        
                        <TitleText>9. If You Cancel Your Booking</TitleText>
                        
                        <Paragraph>If you want to cancel your booking, or part of it, you must contact our customer service
                            team as soon as possible.</Paragraph>
                        
                        <Paragraph>You may be liable for the full cost of the excursion booked. This will be up to your
                            excursion supplier.</Paragraph>
                        
                        <Paragraph>All cancellations will be actioned and charged with the relevant cancellation fees as per
                            cancellation policy.</Paragraph>
                        
                        <Paragraph>Once your booking has been cancelled you can expect to receive a cancellation
                            confirmation immediately.  If this is not the case please contact us.</Paragraph>


                        <TitleText>10. If You Have A Complaint</TitleText>

                        <Paragraph>If you have a complaint about your excursion, you must immediately notify the supplier
                            of the service in question locally. If they are unable to resolve the problem immediately,
                            and a member of our staff is not available, you should contact us straight away by
                            phone/fax/email and we will endeavor to assist. If you are still not satisfied on your
                            return home, you must write to our Customer Support team within 28 days of the
                            excursion to allow your complaint to be investigated properly. Please write your
                            excursion confirmation number on your communication, and include your daytime and
                            evening telephone numbers. If you do not give us the opportunity to resolve any
                            problem locally by reporting it to the supplier, or calling and informing us, then we may
                            not be able to deal positively with any complaint.</Paragraph>



                        <TitleText>11. Conduct While The Excursion</TitleText>

                        <Paragraph>We reserve the right to refuse to accept you as a customer or continue dealing with you
                            if we, or another person in authority, believe your behavior is disruptive, causes
                            unnecessary inconvenience, is threatening or abusive, you damage property, you
                            upset, annoy, disturb, or put any other person in the excursion or our staff or agents in
                            any risk or danger, on the telephone, in writing or in person.</Paragraph>

                        <Paragraph>For the purposes of this section reference to “you” or “your” includes any other person
                            in your party.</Paragraph>




                        <TitleText>12. The Conditions Of Your Ticket</TitleText>

                        <Paragraph>When, as a part of the excursion, you have to travel by air or by sea, your journey may
                            be subject to certain international conventions such as the Warsaw Convention,
                            Montreal Convention or Athens Convention. You agree that the transport company’s
                            own ‘Conditions of Carriage’ will apply to you on that journey. When arranging this
                            transportation for you, we rely on the terms and conditions contained within these
                            international conventions and those ‘Conditions of Carriage&#39;. You acknowledge that all
                            of these terms and conditions form part of your contract with us as well as with the
                            transport company.</Paragraph>



                        <TitleText>13. Contacting You</TitleText>

                        <Paragraph>If you have requested any correspondence with us, we will communicate with you
                            using the email address you have provided. For example, to provide your e-
                            confirmation, e-ticket, e-cancellation, etc. We will assume that your email address is
                            correct and that you understand the risks associated with using this form of
                            communication. Please note that you may still have to contact us via our call center or
                            in writing as required in our terms and conditions.</Paragraph>



                        <TitleText>14. Legislation and courts of jurisdiction</TitleText>

                        <Paragraph>These Booking Terms and Conditions shall be governed by national applicable
                            legislation of the country where the excursion has taken place. Shall a conflict be taken
                            to court by the parties they hereby submit and consent, expressly renouncing to any
                            other jurisdiction, to the Courts of Law of the user&#39;s place of residence.</Paragraph>
                    </div>
                </If>

            </Container>
        );
    }
}

export default CartTCs;