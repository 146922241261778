import React from "react";
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import browserHistory from "../utils/history";
import {defaultImageSrc} from '../utils/imageUtils';
import {recordVisit} from '../actions/metricsActions';
import {BookNowButton, ItemContainer, ItemTitle, TileContainer, BookNowLabel} from '../resources/styles';
import {truncateStringBeforeLastWord} from "../utils/stringUtils";
import ImageWithFallback from "./ImageWithFallback";
import {withTheme} from "styled-components";

class ImageButton extends React.Component {

    handleLinkClick = (e, url) => {
        e.preventDefault();

        if(url && url.indexOf("http") === 0) {
            const parsedUrl = new URL(url);
            recordVisit(parsedUrl.href);

            const newWindow = window.open();
            newWindow.opener = null;
            newWindow.location = url;
        }
        else {
            // Prepend url with / if its not starting with http
            browserHistory.push(`${url[0] === "/" ? "" : "/" }${url}`, this.props.linkState);
        }
    };

    recordExternalClick = (e, url) => {
        const parsedUrl = new URL(url);
        recordVisit(parsedUrl.href);
    };

    render() {
        let imageUrl = this.props.thumbnail || defaultImageSrc();

        const bookable = (this.props.bookable && this.props.deviceType !== "DigitalSignage") ? true : false;

        let isExternalUrl = false;
        let url = this.props.link;

        if (this.props.item && this.props.item.externalUrl && this.props.item.useExternalUrl) {
            url = this.props.item.externalUrl;
            if(url.indexOf("http") > -1) {
                isExternalUrl = true;
            }
        }

        if (this.props.externalLink && this.props.externalLink !== "") {
            url = this.props.externalLink;
            isExternalUrl = true;
        }

        const linkContent = (
            <ItemContainer>
                <ImageWithFallback src={imageUrl} alt={this.props.title} />

                {this.props.showTitle !== false &&
                    <ItemTitle
                        textColor={this.props.textColor}
                        backgroundColor={this.props.backgroundColor}
                    >
                        {this.props.truncateTitle ? truncateStringBeforeLastWord(this.props.title, 30) : this.props.title}
                    </ItemTitle>
                }

                {this.props.showBookNow === true && this.props.deviceType !== "DigitalSignage" &&
                    <BookNowLabel>
                        Book now
                    </BookNowLabel>

                }
            </ItemContainer>
        );

        return (
            <TileContainer heightWidthRatio={this.props.heightWidthRatio || this.props.theme.tileHeightWidthRatio || "100%"}>
                {bookable &&
                    <BookNowButton>
                        Book Now
                    </BookNowButton>
                }

                {isExternalUrl ?
                    <a href={url} target="_blank"  rel="noopener noreferrer" onClick={(e) => this.recordExternalClick(e, url)}>{linkContent}</a> :
                    <Link to={url} onClick={(e) => this.handleLinkClick(e, url)}>
                        {linkContent}
                    </Link>
                }
            </TileContainer>
        )
    }
}

const mapStateToProps = state => {
    return {
        deviceType: state.configuration.hubSettings.deviceType,
    };
};

export default connect(mapStateToProps) (withTheme(ImageButton));