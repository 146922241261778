import React, {Component} from 'react';
import {connect} from 'react-redux';
import {extractImage, extractImageUrl, getFromPrice, isImageInCart} from "../../utils/photoMatchUtils";
import OverviewImageButtonPanel from "../../components/OverviewImageButtonPanel";
import {fetchCabinImages, fetchCart} from "../../actions/photoMatchActions";
import {DefaultButton, device} from "../../resources/styles";
import {imagePath} from "../../utils/imageUtils";
import styled from "styled-components";
import MyPhotosDetail from "../../components/photomatch/MyPhotosDetail";


class MyPhotos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDetails: false,
            selectedImage: null,
            errorMessage: null,

        };
    }

    componentDidMount() {
        if(!this.props.cart) {
            fetchCart();
        }
        fetchCabinImages();
    }

    showDetails = image => {
        this.setState({
            showDetails: true,
            selectedImage: image,
        });
    };

    closeDetails = () => {
        this.setState({
            showDetails: false,
        })
    };

    render() {
        // loading
        if(this.props.cabinImages === null) {
            return (
                <div>
                    <div className="row">
                        <div className="text-3xl text-center">
                            Looking for your photos, please wait
                        </div>
                    </div>
                </div>
            );
        }

        // no images found for user
        if (this.props.cabinImages?.length === 0) {
            const message = this.props.config?.keyvalues?.NO_PHOTOS_ERROR_MESSAGE || "Sorry, we didn't find any photos of you. Please visit the Photography team, and we will be able to help find your photos.";

            return (
                <div>
                    <div className="row">
                        <div className="text-3xl text-center">
                            {message}
                        </div>
                    </div>
                </div>
            );
        }


        // normal flow with images
        return (
            <div>
                {this.state.showDetails &&
                <MyPhotosDetail
                    onClose={this.closeDetails}
                    image={this.state.selectedImage}/>
                }

                <OverviewImageButtonPanel>
                    {
                        this.props.cabinImages.map((image) => {
                            let thumbnail = extractImageUrl(image.url);
                            let id = extractImage(image.id);

                            return (<div
                                className="cell mb-4" key={id}>
                                <button onClick={() => this.showDetails(image)}>
                                    <div className="cursor-pointer">
                                        <img className="thumbnail w-full" src={thumbnail} alt=""/>
                                    </div>
                                </button>
                                <PhotoMatchBottom
                                    image={image}
                                    onClick={() => this.showDetails(image)}
                                />
                            </div>)
                        })
                    }
                </OverviewImageButtonPanel>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        cabinImages: state.photomatch.cabinImages,
        cart: state.photomatch.cart,
        config: state.photomatch.config,

    };
}

export default connect(mapStateToProps)(MyPhotos);

function PhotoMatchBottom(props) {
    const formatPrice = (priceObj) => {
        if(priceObj.bold) {
            return (<span className="font-bold text-lg">{priceObj.price}</span>);
        }
        return (<span>{priceObj.price}</span>);
    };

    const itemInCart = isImageInCart(props.image.id);
    const buttonText = itemInCart ? "Added" : "Select Options";
    const priceObj = getFromPrice(props.image.id);
    return (
        <Container>
            <div>
                {itemInCart && <img alt="" src={imagePath("check.svg")} className="w-4 h-4 mr-4"/>}
                <DefaultButton disabled={itemInCart} onClick={() => {props.onClick(props.image)}}>
                        {buttonText}
                </DefaultButton>
            </div>
            <div>{!itemInCart && formatPrice(priceObj)}</div>
        </Container>
    )
}

const Container = styled.div `
    @media ${device.tablet} {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`;