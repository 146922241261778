import {connect} from "react-redux";
import OverviewImageButtonPanel from "../../../components/OverviewImageButtonPanel";
import * as React from "react";
import {fetchMoviesFromMaxicaster, updateMovie, watchlistAdd, watchlistRemove} from "../../../actions/vodActions";
import VodUtils from "../VodUtils";
import Modal from "../../../components/Modal";
import {setSearchtermMovies} from "../../../actions/vodSearchActions";
import {PropTypes} from "prop-types";
import VodImageButton from "../../../components/VodImageButton";
import {withTheme} from "styled-components";

class OverviewMovies extends React.Component {

    componentDidMount() {
        // document.addEventListener("scroll", this.handleScroll);
        if (this.props.match.params.genreid) {
            this.search(true, this.props.match.params.genreid);
        }else{
            this.search(true, null);
        }
    }

    componentWillUnmount() {
        document.removeEventListener("scroll", this.handleScroll);
    }

    async search(resetMovies, genreid) {
        await this.props.fetchFromMaxicaster(this.props.searchTerm, resetMovies, 100, genreid);
    }

    saveSearchTerm = (searchTerm) => {
        this.props.setSearchtermMovies(searchTerm);
    };

    handleScroll = () => {
        if (this.props.maxReached) {
            return;
        }

        if (this.props.isFetching) {
            return;
        }

        const scrollHeight = document.body.scrollHeight;
        const totalHeight = window.scrollY + window.innerHeight;

        if(totalHeight >= scrollHeight)
        {
            if (this.props.match.params.genreid) {
                this.search(false, this.props.match.params.genreid);
            }else{
                this.search(false, null);
            }
        }
    };

    async watchlist(movie) {
        if (movie.watchlisted) {

            const success = await this.props.watchlistRemove(movie.id, null);
            if (success) {
                movie.watchlisted = null;
                this.props.updateMovie(movie);
            }
        } else {

            const success = await this.props.watchlistAdd(movie.id, null);
            if (success) {
                movie.watchlisted = true;
                this.props.updateMovie(movie);
            }
        }
    };


    render() {

        if (! this.props.movies) {
            return false;
        }

        return (
            <div>
                <Modal show={this.props.isFetching}/>
{/*                    <div className="grid-x grid-padding-x align-left">
                        <div className="cell small-10 medium-6 large-6">

                            <div className="input-group digi-input-group-rounded">
                                <input className="input-group-field"
                                       type="text"
                                       value={this.props.searchTerm}
                                       onChange={(e) => this.saveSearchTerm( e.target.value)}
                                />
                                    <div className="input-group-button">
                                        <input type="submit" className="digi-button digi-info-button" value={this.context.t("search")} onClick={() => this.search(true)}/>
                                    </div>
                            </div>
                        </div>
                    </div>*/}
                <OverviewImageButtonPanel>
                    {
                        this.props.movies.map((movie) => {
                            return (

                                <VodImageButton key={movie.id}
                                                link={"/vod/detail/"+movie.id}
                                                title={movie.title}
                                                item={movie}
                                                onClickStar={() => this.watchlist(movie)}
                                                thumbnail={VodUtils.getPictureUrl(movie)}
                                                heightWidthRatio={ this.props.theme.vodMovieHeightWidthRatio || "150%" }/>
/*                                    <If test={movie.watchlisted === null} >
                                        <span><input type="submit" className="button digi-button" value={this.context.t("watchlistAdd")} onClick={() => this.watchlistAdd(movie)}/></span>
                                    </If>*/
                            )
                        })
                    }

                </OverviewImageButtonPanel>
            </div>
        )
    }
}

OverviewMovies.contextTypes = {
    t: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    return {
        movies: state.vod.movies,
        isFetching: state.vod.isFetching,
        searchTerm: state.vod.searchTermMovies,
        maxReached: state.vod.maxReached
    };
};

export default connect(mapStateToProps, {fetchFromMaxicaster: fetchMoviesFromMaxicaster, setSearchtermMovies: setSearchtermMovies, watchlistAdd: watchlistAdd, watchlistRemove: watchlistRemove, updateMovie: updateMovie})(withTheme(OverviewMovies));