import React from 'react';
import Player from "./THEOplayer";
import {PropTypes} from "prop-types";

class TheoPlayerTest extends React.Component {
    constructor() {
        super();
        this.state = {
            playedSeconds: 0,
        };
    }

    componentWillUnmount() {
        if (this.player) {
            this.player._player.removeEventListener('timeupdate');
        }
    }

    ref = player => {
        let that = this;

        if (player) {
            this.player = player;

            this.player._player.autoplay = true;
            this.player._player.addEventListener('timeupdate', function (e) {
                if (that) {
                    that.setState({playedSeconds: e.currentTime})
                }
            });

        }
    };

    render() {
        let type = "application/x-mpegurl";

        const minutes = Math.floor(this.state.playedSeconds / 60);
        let seconds = Math.round(this.state.playedSeconds - minutes * 60);
        if (seconds < 10) {
            seconds = "0" + seconds;
        }

        const nameStyle = {
            margin: "0 auto",
            fontSize: "small",
            textAlign: "center"
        };

        // urlToPlay = "https://amssamples.streaming.mediaservices.windows.net/622b189f-ec39-43f2-93a2-201ac4e31ce1/BigBuckBunny.ism/manifest(format=mpd-time-csf)";
        // type = "application/dash+xml";

        let urlToPlay = "//cdn.theoplayer.com/video/elephants-dream/playlist.m3u8";


        return (
            <div>
                <div style={nameStyle}>{this.context.t("elapsedTime", {}, "Please translate!")}: {minutes}:{seconds} </div>
                <Player source={urlToPlay}
                        type={type}
                        ref={this.ref}
                />
            </div>
        );
    }

}



TheoPlayerTest.contextTypes = {
    t: PropTypes.func.isRequired
};

export default (TheoPlayerTest);
