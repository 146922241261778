import React, {useState, useEffect} from 'react';
import {getFromHubNew} from "../../actions/networkActions";
import browserHistory from "../../utils/history";
import {DefaultButton} from "../../resources/styles";

function ProblemTracking(props) {
    const [loaded, setLoaded] = useState(false);
    const [problems, setProblems] = useState([]);
    const [feedbackProfilePath, setFeedbackProfilePath] = useState(null);

    useEffect(() => {
        const fetchProblems = async () => {
            const problems = await getFromHubNew("problem/report");
            setProblems(problems);
            setLoaded(true);
        }

        const fetchConfig = async () => {
            const config = await getFromHubNew("problem/report/config");
            if(config.feedbackProfilePath) {
                setFeedbackProfilePath(config.feedbackProfilePath);
            }
        }

        fetchConfig();
        fetchProblems();

    }, []);

    if (!loaded) {
        return null;
    }

    if (loaded && problems.length === 0) {
        return (
            <div>
                <h1>Problem Tracking</h1>
                <h4>You have not reported any problems yet </h4>
            </div>
        )
    }

    function getStatusLi(status) {
        let message = status;
        let className = "";
        switch (status) {
            case "InProgress":
                message = "In Progress";
                className = "li2Step";
                break;
            case "InvestigationRequired":
                message = "Investigation is required";
                className = "li2Step";
                break;
            case "Resolved":
                className = "resolved";
                break;
            default:
                className = "";
        }

        return <li className={className}>Status: {message}</li>
    }


    return (
        <div>
            <h1>Problem Tracking</h1>

            { problems.map((problem) =>
                <div key={problem.id}>
                    <ul className="problemReceived">
                        <div className="margin-bottom-1">
                            <li>Problem - {problem.problemCategory.name.defaultValue} Issue -
                                ID {problem.problemNumber}</li>
                            <li>Received at: {problem.receivedDateTime}</li>
                            {getStatusLi(problem.status)}
                        </div>
                    </ul>

                    { problem.status === "Resolved" && problem.solution && feedbackProfilePath &&
                        <div>
                            <DefaultButton
                                onClick={() => { browserHistory.push(feedbackProfilePath);}}>
                                Leave Feedback
                            </DefaultButton>
                        </div>
                    }
                </div>
            )}

        </div>
    )
}

export default ProblemTracking;