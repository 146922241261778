import React from 'react';

class ContentDividerWithIcon extends React.Component {
    render() {
        if(this.props.hidden) {
            return false;
        }

        return (
            <div className="mt-4 py-4 bg-primary-lightest">
                <img
                    className="align-middle w-8 h-8 mx-4 fill-current inline-block"
                    alt={this.props.iconAltText}
                    src={this.props.iconSrc} />

                <h2 className="align-middle inline-block m-0 text-xl">
                    {this.props.title}
                </h2>
            </div>
        )
    }
}

export default ContentDividerWithIcon;