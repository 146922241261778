import React from "react";
import VodUtils from "../VodUtils";
import browserHistory from "../../../utils/history";
import If from "../../../components/If";
import {VodImageSizeEnum} from "../VodEnum";
import {VodButton} from "../../../resources/styles";

class AudioEpisode extends React.Component {
    constructor() {
        super();
        this.state = {
            vod: null,
            show_detail: null
        }
    }

    componentDidMount() {
        if (this.props.vod) {
            this.setState({vod: this.props.vod});
        }
        if (this.props.show_detail) {
            this.setState({show_detail: this.props.show_detail});
        }
    }

    goToLink = (e) => {
        e.preventDefault();
        let targetLink;
        if (this.props.show_detail !== false){
            targetLink = "/vod/detail/" + this.state.vod.id;
        } else{
            targetLink = "/vod/" + this.state.vod.id;
        }

        browserHistory.push(targetLink);
    };

    render() {
        if (this.state.vod === null) {
            return false;
        }

        let title = this.state.vod.title;
        let ratingAge = "";
        if (this.state.vod.rating_age) {
            ratingAge = this.state.vod.rating_age;
        }
        const picUrl = VodUtils.getEpisodePictureUrl(this.state.vod, VodImageSizeEnum.LARGE, true);

        let durationSec = 0;

        if (this.state.vod.assets && this.state.vod.assets.movies && this.state.vod.assets.movies.length > 0) {
            const movieInfo = this.state.vod.assets.movies[0];
            if (movieInfo.duration_sec) {
                durationSec = movieInfo.duration_sec;
            }
        }
        const readableDuration = VodUtils.getReadableDuration(durationSec);

        const divStyle = {
            marginTop: "0.5em",
            // marginLeft: "0.5em",
            borderBottom: "solid 1px #707070",
            minHeight: "5em",
            display: "inline-block",
            width: "100%"
        };

        const episodeScreenCaptureContainer = {
            float: "left",
            marginBottom: "1em",
        };

        const picStyle = {
            width: "100%",
            border: "solid 1px white",
            boxShadow: "1px 2px 4px rgba(0, 0, 0, .5)"
        };


        const textLeft = {
            textAlign: "left",
            marginBottom: "0.5em",
        };

        const descriptionContainer = {
            marginLeft: "0.2em",
            marginBottom: "0.5em"
        };

        const mediumFontSize = {
            fontSize: "19pt"
        };

        const runTimeSpanStyle = {
            fontSize: "16pt"
        };

        return (
            <div style={divStyle}>
                <div className="grid-x">
                    <div className="cell small-12 medium-4 large-4" style={episodeScreenCaptureContainer}>
                        <img style={picStyle} alt="episodePic" src={picUrl}/>
                    </div>
                    <div className="cell small-12 medium-8 large-8 vod-episode-infocontainer" >
                        <div style={textLeft} >
                            <span style={mediumFontSize}>{title}</span>
                        </div>
                        <div style={descriptionContainer}>
                            <p style={runTimeSpanStyle}>Run Time: {readableDuration}</p>
                            <If test={ratingAge !== ""}>
                                <p>Rated: {ratingAge}</p>
                            </If>
                            <p>{this.state.vod.series_info.episode_descriptions.long}</p>
                            <VodButton onClick={this.goToLink}>
                                Play
                            </VodButton>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default (AudioEpisode)