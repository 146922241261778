import React, {useState} from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import {useSelector} from 'react-redux';
import {DefaultButton} from "../resources/styles";

function PdfViewer(props) {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [renderedPageNumber, setRenderedPageNumber] = useState(null);

    const isDigitalSignage = useSelector(state => state.configuration.hubSettings.isDigitalSignage);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    const isLoading = renderedPageNumber !== pageNumber;

    // this only covers the 1080 and 1920 cases when the component is rendered and will not resize if window width changes once rendered
    const width = window.innerWidth > 1080 ?
        isDigitalSignage ? 590 : 856
        : 670;

    return (
        <>
            <div className="my-4 flex" style={{justifyContent: "space-between"}}>
                <DefaultButton
                    type="button"
                    disabled={pageNumber <= 1}
                    onClick={previousPage}
                >
                    Previous
                </DefaultButton>
                <p>
                    Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                </p>
                <DefaultButton
                    type="button"
                    disabled={pageNumber >= numPages}
                    onClick={nextPage}
                >
                    Next
                </DefaultButton>
            </div>


            {/* Keep showing the old page for as long as the new page is loading to prevent scroll issues and content "flashing"*/}
            <Document file={props.filename} onLoadSuccess={onDocumentLoadSuccess}>
                {isLoading && renderedPageNumber ? (
                    <Page
                        key={renderedPageNumber}
                        className="prevPage"
                        pageNumber={renderedPageNumber}
                        width={width}
                    />
                ) : null}
                <Page
                    key={pageNumber}
                    pageNumber={pageNumber}
                    onRenderSuccess={() => setRenderedPageNumber(pageNumber)}
                    width={width}
                />
            </Document>

        </>
    );
}

export default PdfViewer;