import React from 'react';
import {useSelector} from 'react-redux';
import {getImageUrlById} from "../actions/networkActions";

function ExcursionTags(props) {
    const tags = useSelector(state => state.excursionTags);

    return (
        <div className="relative">
            <ul className="m-0 list-style-none">
                {props.attributes.map((attribute, index) => (
                    <li key={index} className={props.displayInline ? "mt-2 mr-4 inline-block" : "mt-2" } >
                        {/* icon if exists */}
                        {tags && tags[attribute] &&
                            <>
                            {
                            props.displayInline ?
                            <img src={getImageUrlById(tags[attribute], {width: 50})} alt=""/>
                            :
                            <img className="w-8 h-8 sm:w-4 sm:h-4" src={getImageUrlById(tags[attribute], {width: 64})} alt=""/>
                            }
                            </>
                        }

                        {/* label*/}
                        { !props.displayInline &&
                            <span className={tags?.[attribute] ? " ml-1" : "ml-5"}>
                                {attribute}
                            </span>
                        }
                    </li>)
                )}
            </ul>
        </div>
    )
}

export default ExcursionTags;
