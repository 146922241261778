import React from 'react';
import OverviewImageButtonPanel from "../../components/OverviewImageButtonPanel";
import OverviewImageButton from "../../components/OverviewImageButton";

import moviespic from "../../resources/images/movies.jpg";
import {connect} from "react-redux";
import {PropTypes} from "prop-types";
import {fetchFromHub} from "../../actions/networkActions";

class OverviewByType extends React.Component {
    constructor() {
        super();
        this.state = {
            token: null,
            types: [
                "movies",
                "series"
            ],
            movieArticles: null,
            seriesArticles: null
        };
    }

    componentDidMount() {
        this._retrieveArticles();
    }

    _retrieveArticles() {
        this.props.fetchFromHub(`cms/articles/findByHashtag?hashtag=vodmovieimage`, this.postProcessMovieArticle);
        this.props.fetchFromHub(`cms/articles/findByHashtag?hashtag=vodseriesimage`, this.postProcessSeriesArticle);
    }

    postProcessMovieArticle = (movieArticles) => {
        this.setState({movieArticles: movieArticles});
    }

    postProcessSeriesArticle = (seriesArticles) => {
        this.setState({seriesArticles: seriesArticles});
    }

    render() {

        let movieThumbnail = moviespic;
        if (this.state.movieArticles && this.state.movieArticles.length > 0) {
            movieThumbnail = this.props.configuration.hubUrl + "blobs/image?reference=" + this.state.movieArticles[0].id + "&width=400";
        }

        let seriesThumbnail = moviespic;
        if (this.state.seriesArticles && this.state.seriesArticles.length > 0) {
            seriesThumbnail = this.props.configuration.hubUrl + "blobs/image?reference=" + this.state.seriesArticles[0].id + "&width=400";
        }

        return (
            <div>
                    <OverviewImageButtonPanel>
{/*
                        <OverviewImageButton key="watchlist"
                                             link={"/vod/overview/watchlist"}
                                             title={this.context.t("watchlist")}
                                             thumbnail={moviespic}
                        />
*/}

                        <OverviewImageButton key="movies"
                                             link={"/vod/overview/genre/movies"}
                                             title={this.context.t("movies")}
                                             thumbnail={movieThumbnail}
                        />

                        <OverviewImageButton key="series"
                                             link={"/vod/overview/genre/series"}
                                             title={this.context.t("series")}
                                             thumbnail={seriesThumbnail}
                        />
                    </OverviewImageButtonPanel>
            </div>
        );
    }
}

OverviewByType.contextTypes = {
    t: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    return {
        maxicaster: state.maxicaster,
        configuration: state.configuration,
    };
};

export default connect(mapStateToProps, {fetchFromHub: fetchFromHub})(OverviewByType)