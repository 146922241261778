import React from 'react';
import {connect} from "react-redux";
import MyPhotoVariantsForm from "./MyPhotoVariantsForm";
import {extractImageUrl, getDetailImageFromCart, getVariantBlurb, isImageInCart} from "../../utils/photoMatchUtils";
import styled from "styled-components";
import {ModalContainer, ModalContent} from "../../resources/styles";

const Photo = styled.img`
    border: solid 1px lightgray;
    z-index: 1;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    max-height: 75vh;
    margin-left: auto;
    margin-right: auto;
    
    &:hover {
        border: solid 1px rgb(152, 226, 255);
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
`;

class MyPhotosDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imageDetails: getDetailImageFromCart(props.image.id),
        }
    }

    render() {
        const imageUrl = extractImageUrl(this.props.image.url);

        return (
            <ModalContainer onClick={this.props.onClose}>
                <ModalContent onClick={(e) => {e.stopPropagation()}}>
                    <div>
                        <Photo className="mb-4" src={imageUrl}/>
                    </div>
                    <div className="flex items-center" onClick={this.props.onClose}>
                        <MyPhotoVariantsForm
                            imageId={this.props.image.id}
                            isImageInCart={isImageInCart(this.props.image.id)}
                            onClose={this.props.onClose}
                            closeOnUpdate={this.props.closeOnUpdate}
                            variants={this.state.imageDetails ?
                                this.state.imageDetails.variants.map(variant => ({...variant, blurb: getVariantBlurb(variant.id)})) :
                                this.props.config.variants.map(variant => ({...variant, count: 0}))
                            }
                        />
                    </div>
                </ModalContent>
            </ModalContainer>
        );
    }
}

const mapStateToProps = state => {
    return {
        config: state.photomatch.config,
    };
};

export default connect(mapStateToProps)(MyPhotosDetail);

