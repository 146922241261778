export default function (state = {totalPrice: 0, nrExcursions: 0, excursions: null}, action) {
    switch (action.type) {
        case 'SET_BOOKINGS':
            state = {
                totalPrice: action.bookings.totalPrice,
                nrExcursions: action.bookings.nrExcursions,
                excursions: action.bookings.excursions
            };
            return state;

        default:
            return state;
    }
}
