import {SET_NUMBEROFPERSONS, SET_OBJECT} from "../constants/tac-booking-types.js";

export function updateSelectedItem (item) {
    return async function (dispatch) {
        dispatch({
            type: SET_OBJECT,
            item: item,
        });

    }
};
export function updateNumberOfPersons (number) {
    return async function (dispatch) {
        dispatch({
            type: SET_NUMBEROFPERSONS,
            number: number,
        });

    }
};


