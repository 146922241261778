import React from 'react';
import OverviewImageButtonPanel from "../../components/OverviewImageButtonPanel";
import OverviewImageButton from "../../components/OverviewImageButton";

import moviespic from "../../resources/images/movies.jpg";
import {connect} from "react-redux";
import {PropTypes} from "prop-types";

class OverviewKidsByType extends React.Component {
    constructor() {
        super();
        this.state = {
            token: null,
            types: [
                "movies",
                "series"
            ]
        };
    }

    render() {

        return (
            <div>
                    <OverviewImageButtonPanel>
                        <OverviewImageButton key="movies"
                                             link={"/vod/overview/kids/movies"}
                                             title={this.context.t("movies")}
                                             thumbnail={moviespic}
                        />
                    </OverviewImageButtonPanel>
            </div>
        );
    }
}

OverviewKidsByType.contextTypes = {
    t: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    return {
        maxicaster: state.maxicaster,
    };
};

export default connect(mapStateToProps)(OverviewKidsByType)