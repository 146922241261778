import {store} from "../App";

export function generateSrcsetForImage(fileName) {
    const lastDotIndex = fileName.lastIndexOf('.');
    const filePrefix = fileName.substr(0, lastDotIndex);
    const fileExtension = fileName.substr(lastDotIndex);

    return `${imagePath(`${filePrefix}_sm${fileExtension}`)} 580w,
            ${imagePath(`${filePrefix}_md${fileExtension}`)} 760w,
            ${imagePath(`${filePrefix}_lg${fileExtension}`)} 1080w,
            ${imagePath(`${filePrefix}_xl${fileExtension}`)} 1920w`;
}

export function imagePath(fileName) {
    return require(`../resources/images/${fileName}`);
}


export function defaultImageSrc() {
    return store.getState().configuration.hubSettings.defaultImageUrl;
}

export function getFullHubPath(path) {
    return store.getState().configuration.hubUrl.replace(/\/$/, '') + path;
}