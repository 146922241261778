import {SET_NUMBEROFPERSONS, SET_OBJECT, TacModuleTypeEnum} from "../constants/tac-booking-types.js";

export default function (state = {module: null, objectId: null, numberOfPersons: 1}, action) {
    switch (action.type) {
        case SET_OBJECT:
            return { ...state,
                module: action.item.module,
                objectId: action.item.objectId,
                numberOfPersons: action.item.module === TacModuleTypeEnum.SPA ? 1 : action.item.numberOfPersons
            };
        case SET_NUMBEROFPERSONS:
            return { ...state,
                numberOfPersons: action.number,
            };

        default:
            return state;
    }
}
