import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import TextInputWithLabel from "../../components/TextInputWithLabel";
import {DefaultButton, ModalContainer, ModalContent} from "../../resources/styles";
import {getFromHubNew, getResourceImageUrl, postToHubNew} from "../../actions/networkActions";
import {store} from "../../App";
import {SET_AUTHENTICATION, SET_SESSION_ID} from "../../constants/authentication-action-types";
import Loading from "../../components/Loading";

function RescoLogin(props) {
    const [submitting, setSubmitting] = useState(false);
    const [cardNr, setCardNr] = useState("");
    const [cabinNr, setCabinNr] = useState("");
    const [pin, setPin] = useState("");
    const [error, setError] = useState("");
    const [article, setArticle] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loginResult, setLoginResult] = useState(null);
    const [showPinChangeModal, setShowPinChangeModal] = useState(false);
    const pinRequired = useSelector(state => state.configuration.hubSettings?.pmsConfiguration?.pinLoginEnabled === "true");

    useEffect(() => {
        const fetchData = async () => {
            const articles = await getFromHubNew("cms/articles/findByHashtag?hashtag=loginFormExtra");
            if(articles.length === 0) {
                setLoading(false);
                return;
            }
            setArticle(articles[0]);
            setLoading(false);
        }

        fetchData();

    }, []);

    if(loading) {
        return (
            <Loading/>
        )
    }

    const submit = async (e) => {
        e.preventDefault();
        if(submitting) {
            return;
        }

        if(cardNr === "" || cabinNr === "" || (pinRequired && pin === "")) {
            setError("Please make sure to fill out all the fields.");
            return;
        }

        const payload = {
            accountNumber: cardNr,
            location: cabinNr,
        }
        if(pinRequired) {
            payload.pinNo = pin;
        }

        setSubmitting(true);
        setError("");

        const submitUrl = pinRequired ? "authentication/loginByAccountIdCabinNoAndPinNo" : "authentication/loginByPms"
        const result = await postToHubNew(submitUrl, payload);

        setSubmitting(false);

        if(result) {
            if(pinRequired) {
                const pmsInfoObject = JSON.parse(result.pms.information);
                const isPinChangeRequired = pmsInfoObject.isPinChangeRequired;

                if(isPinChangeRequired) {
                    store.dispatch({type: SET_SESSION_ID, sessionId: result.sessionId});

                    setLoginResult(result);
                    setShowPinChangeModal(true);
                    return;
                }
            }

            store.dispatch({type: SET_AUTHENTICATION, authentication: result});
        }
        else {
            setError("Login failed, please check the details you have entered")
        }
    }

    return (
        <div>
            <form onSubmit={(e) => submit(e)}>
                <div className="grid-container">
                    {article &&
                    <div className="grid-x grid-padding-x align-center">
                        <div className="cell small-10 medium-4 large-4 text-left">
                            <div className="text-sm">
                                <p dangerouslySetInnerHTML={{__html: article.description.defaultValue}} />
                            </div>
                            <div className="mt-4">
                                <img style={{width: "100%"}} src={getResourceImageUrl(article.id, {width: 420})} alt="onboard card example" />
                            </div>
                        </div>
                    </div>
                    }

                    <div className="grid-x grid-padding-x align-center my-4">
                        <div className="cell small-10 medium-4 large-4 text-left">
                            <TextInputWithLabel
                                label="Account Number"
                                value={cardNr}
                                _onChange={value => setCardNr(value)}
                                placeholder="12345678"
                            />
                        </div>
                    </div>

                    <div className="grid-x grid-padding-x align-center my-4">
                        <div className="cell small-10 medium-4 large-4 text-left">
                            <TextInputWithLabel
                                label="Cabin Number"
                                value={cabinNr}
                                _onChange={value => setCabinNr(value)}
                                placeholder="1234"
                            />
                        </div>
                    </div>

                    {pinRequired &&
                    <div className="grid-x grid-padding-x align-center my-4">
                        <div className="cell small-10 medium-4 large-4 text-left">
                            <TextInputWithLabel
                                label="PIN Number"
                                value={pin}
                                _onChange={value => setPin(value)}
                                placeholder="0101"
                            />
                        </div>
                    </div>
                    }

                    {error &&
                    <div className="grid-x grid-padding-x align-center mt-4">
                        <div className="cell small-10 medium-4 large-4 text-left errors">
                            {error}
                        </div>
                    </div>}

                    <div className="grid-x grid-padding-x align-center mt-4">
                        <div className="cell small-10 medium-4 large-4 text-left">
                            <DefaultButton type="submit" disabled={submitting}>
                                Login
                            </DefaultButton>
                        </div>
                    </div>
                </div>
            </form>

            {showPinChangeModal &&
            <PinChangeModal
                onClose={() => setShowPinChangeModal(false)}
                oldPin={pin}
                loginResult={loginResult}
            />
            }
        </div>
    );
}

export default RescoLogin;

function PinChangeModal(props) {
    const [pin, setPin] = useState("");
    const [error, setError] = useState("");

    const validate = () => {
        const re = /^[0-9]{4}$/;
        return re.test(pin);
    }

    const setNewPin = async () => {
        if(!validate()) {
            setError("validation");
            return;
        }

        const payload = {
            "currentPin": props.oldPin,
            "newPin": pin,
        }

        const result = await postToHubNew("pms/changePin", payload)

        if(result === "") {
            store.dispatch({type: SET_AUTHENTICATION, authentication: props.loginResult});
        }
        else {
            setError("general")
        }
    }

    return (
        <ModalContainer>
            <ModalContent>
                <div>
                    <div className="bg-white p-4">
                        <div className="text-lg border-b">
                            Change your PIN number to continue
                        </div>

                        <div className="mt-4 text-lg">
                            <TextInputWithLabel
                                label="Your new PIN Number"
                                value={pin}
                                _onChange={value => setPin(value)}
                                placeholder="0101"
                            />
                        </div>

                        <div className="mt-4">
                            Your PIN number needs to be 4 digits long.
                        </div>
                        <div>
                            You can change or reset your PIN number at any time by visiting My Account.
                        </div>
                        {error &&
                        <div className="errors mt-4">
                            {error === "validation" ? "Please make sure your new pin is 4 digits." : "There has been an error with changing your PIN."}
                        </div>}
                    </div>
                    <div className="justify-between flex mt-4">
                        {error !== "general" &&
                        <DefaultButton onClick={setNewPin}>
                            Confirm
                        </DefaultButton>
                        }

                        <DefaultButton onClick={props.onClose}>
                            Back
                        </DefaultButton>
                    </div>
                </div>
            </ModalContent>
        </ModalContainer>
    )
}