import {CLEAR_NAVIGATION, SET_FOOTER_TYPE} from "../constants/navigation-action-types";

const INITIAL_STATE = {
    footerType: "standard"
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_FOOTER_TYPE:
            return { ...state, footerType: action.footerType };
        case CLEAR_NAVIGATION:
            return INITIAL_STATE;
        default:
            return state;
    }
}
