import React from 'react'
import {Link} from 'react-router-dom';
import {PropTypes} from 'prop-types';

class CartEmpty extends React.Component {
    render() {
        return (
            <div>
                <h1 className="text-uppercase">{this.context.t("basket_header")}</h1>

                <div>
                    {this.context.t("basket_empty")}
                </div>
                <div>
                    {this.context.t("return_to")} <Link className="text-underline" to="/">{this.context.t("home")}</Link>
                </div>
            </div>
        )
    }
}


CartEmpty.contextTypes = {
    t: PropTypes.func.isRequired
};

export default CartEmpty;