import React from "react";
import DetailImage from "../../components/DetailImage";
import {connect} from "react-redux";
import {PropTypes} from 'prop-types';
import {fetchFromHub, postToHub} from "../../actions/networkActions";
import Feedback from "./Feedback";
import Checkbox from "../../components/Checkbox";
import {getTranslation} from "../../translations/translationUtils";

class FeedbackAllInOne extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            categories: [],
            profiles: null,
        };
    }

    componentDidMount() {
        this.props.fetchFromHub(`cms/categories/findByHashtag?hashtag=allInOneFeedback`, this.postProcessCategory);
        this.props.fetchFromHub(`ratings/profiles?allInOne=true`, this.updateProfiles);
    }

    postProcessCategory = (categories) => {
        this.setState({categories: categories});
    };

    updateProfiles = (profiles) => {
        for(let i = 0; i < profiles.length; i++) {
            profiles[i].giveFeedback = false;
        }

        this.setState({
            profiles: profiles,
        })
    };

    toggleProfile (profileId, event) {
        let profiles = this.state.profiles;
        for(let i = 0; i < profiles.length; i++) {
            if (profiles[i].id === profileId) {
                profiles[i].giveFeedback = !profiles[i].giveFeedback;
            }
        }

        this.setState({profiles: profiles});
    }


    render() {
        let profiles = this.state.profiles;
        if (profiles === null) {
            return false;
        }

        let thumbnail = null;
        if (this.state.categories && this.state.categories.length > 0) {
            thumbnail = this.props.configuration.hubUrl + "blobs/image?reference=" + this.state.categories[0].id + "&width=400";
        }

        return (
            <div>
                <div className="grid-x grid-padding-x">
                    <div className="cell small-12 medium-4 large-4">
                        <DetailImage image={thumbnail}/>
                    </div>
                    <div className="cell small-12 medium-8 large-8 text-left">

                        {
                            profiles.map((profile) => {

                                return (
                                    <div key={profile.id}>
                                        <div className="feedbackTitle">{getTranslation(profile.title)}</div>
                                        <div className="feedbackDescription" dangerouslySetInnerHTML={{__html: getTranslation(profile.description)}}/>

                                        <div>
                                            <div className="inline-block">
                                                <Checkbox value="Yes" label={this.context.t("yes")} checked={profile.giveFeedback} handleCheckboxChange={(event) => this.toggleProfile(profile.id, event)} />
                                            </div>
                                            <div className="inline-block margin-left-1">
                                                <Checkbox value="No" label={this.context.t("no")} checked={!profile.giveFeedback} handleCheckboxChange={(event) => this.toggleProfile(profile.id, event)} />
                                            </div>
                                        </div>

                                        {profile.giveFeedback && <Feedback profileId={profile.id}/>}
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
            </div>
        )
    }
}

FeedbackAllInOne.contextTypes = {
    t: PropTypes.func.isRequired
};


const mapStateToProps = state => {
    return {
        configuration: state.configuration,
        lang: state.i18nState.lang
    };
};

export default connect(mapStateToProps, {fetchFromHub: fetchFromHub, postToHub: postToHub})(FeedbackAllInOne);