import React from 'react';
import {connect} from 'react-redux';
import {PropTypes} from 'prop-types';
import ItineraryHeader from "./ItineraryHeader";

class ItineraryPassengers extends React.Component {
    formatPassengerCount = () => {
        const counts = {};
        this.props.passengers.forEach(passenger => {
            if(!counts[passenger.type]) {
                counts[passenger.type] = 1;
            }
            else {
                counts[passenger.type]++;
            }
        });

        return Object.getOwnPropertyNames(counts).map(type => `${counts[type]}x ${type}`).join(", ");
    };

    render() {
        if(!this.props.passengers) {
            return null;
        }

        return (
            <div>
                <ItineraryHeader title="Guests" icon="person.svg"/>
                <div className="ml-6">
                    <div>{this.formatPassengerCount()}</div>

                    <ul className="m-0 ml-4">
                        { this.props.passengers.map(passenger => (
                            <li key={passenger.id}>
                               {passenger.firstName} {passenger.lastName} {passenger.type !== "Adult" ? `(${passenger.type})` : ""}
                            </li>
                        ))}
                    </ul>
                </div>

            </div>
        );
    }
}

ItineraryPassengers.contextTypes = {
    t: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        passengers: state.itinerary.passengers,
    }
}

export default connect(mapStateToProps) (ItineraryPassengers);
