import React from 'react';
import {connect} from 'react-redux';
import CartElementCruise from './CartElementCruise';
import CartElementResort from './CartElementResort';

import {flattenExcursions} from '../utils/cartUtils';


class CartElements extends React.Component {


    getExcursions = (itineraryId = null) => {
        //if excursions were passed as a prop, don't use the ones from the store
        if(this.props.paidExcursions) {
            return flattenExcursions(this.props.paidExcursions);
        }

        //nothing in the cart, return empty array
        if(this.props.cartExcursions === null) {
            return [];
        }

        //looking for all cart elements
        if(!itineraryId) {
            return flattenExcursions(this.props.cartExcursions);
        }

        //looking for cart elements belonging to one itinerary
        let cartEntries = {};
        cartEntries[itineraryId] = this.props.cartExcursions[itineraryId];
        return flattenExcursions(cartEntries);
    };

    render() {
        const excursions = this.getExcursions(this.props.itineraryId);

        return (
            <div className="text-grey-darker">
                {excursions.map((excursion) => {
                    const CartElementComponent = excursion.type === "ResortExcursion" ? CartElementResort : CartElementCruise;
                    return (
                        <CartElementComponent
                            key={`${excursion.itineraryId}|${excursion.excursionId}`}
                            excursion={excursion}
                        />
                    )
                }
                )}
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    if(ownProps.cartType === 'booking') {
        return {
            cartExcursions: state.bookings.excursions,
        };
    }
    return {
        cartExcursions: state.cart.excursions,
    };
}

export default connect(mapStateToProps) (CartElements);