import axios from 'axios';
import {SET_CHANNELS, SET_CHANNELS_ERROR} from "../constants/channel-action-types";
import {REQUEST_FINISHED, REQUEST_STARTED} from "../constants/vod-action-types";
import {extractErrorMessage, getApiVersion, getDeviceUuid, getMaxicasterUrl, getToken} from "./vodActions";

export function fetchChannelsFromMaxicaster() {
    return async function (dispatch, getStore) {

        dispatch({type: SET_CHANNELS, channels: null});
        dispatch({type: REQUEST_STARTED});

        let url = getMaxicasterUrl(getStore()) + "channels/getChannelData&token="+getToken(getStore())+"&device_uuid="+getDeviceUuid(getStore(), dispatch)+"&api_version="+getApiVersion(getStore())+"&languages=eng";

        url += "&include=streams,channel_logos,epg_info,epg_programs&languages=eng";

        return axios({
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then(function (response) {
                if (response.data.code === 200) {
                    dispatch({type: SET_CHANNELS, channels: response.data.data});
                    dispatch({type: REQUEST_FINISHED});
                } else {
                    dispatch({type: SET_CHANNELS, errormessage: extractErrorMessage(response)});
                }
            })
            .catch(function (error) {
                dispatch({type: SET_CHANNELS_ERROR, errormessage: error.message});
            });
    }
}

