import {store} from "../App";
import CurrencyUtils from "./currencyUtils";

export function getDetailImageFromCart(detailImageId, newCart = null) {
    let detailImageInCart = null;
    const cart = newCart || store.getState().photomatch.cart;

    if(!cart || !cart.photoGroups) {
        return null;
    }

    for (let i = 0; i < cart.photoGroups.length && !detailImageInCart; i++) {
        for (let j = 0; j < cart.photoGroups[i].photos.length; j++) {
            if (detailImageId === cart.photoGroups[i].photos[j].id) {
                detailImageInCart = cart.photoGroups[i].photos[j];
                break;
            }
        }
    }

    return detailImageInCart;
}


export function extractImageUrl(url, params) {
    if(url === null) {
        return null;
    }
    url = extractImage(url);
    const queryString = params ? `?${params.join("&")}` : "";
    return `${store.getState().configuration.hubApiUrl}photomatch/image/${url}${queryString}`;
}

export function extractDetailImageUrl(url) {
    if(url === null) {
        return null;
    }
    url = extractImage(url);
    return `${store.getState().configuration.hubApiUrl}photomatch/detailImage/${url}`;
}

export function extractImage(path) {
    path = path.replace(/\//g, ':');
    return path;
}

export function isImageInCart(imageId) {
    const detailImage = getDetailImageFromCart(imageId);
    return !!detailImage;
}

export function getFromPrice(photoId) {
    const config = store.getState().photomatch.config;
    const {photoGroupId, photoGroup, photoGroupFromCart} = getPhotoGroup(photoId);

    const offerDetails = getMissedOfferDetails(photoGroupId);

    if (offerDetails) {
        if(photoGroup.forZ > 0) {
            return {
                price: "£" + photoGroup.forZ,
                bold: false,
            };
        }
        else {
            return {
                price: "Get it for FREE",
                bold: true,
            };
        }
    }

    if(photoGroup.freeCreditAmount > 0) {
        if(photoGroupFromCart.freeCredits && photoGroupFromCart.freeCredits.remaining > 0) {
            return {
                price: "from £0",
                bold: false,
            };
        }

        const cheapestVariantAdditional = config.variants.reduce((min, variant) => variant.additional < min ? variant.additional : min, 9999);
        return {
            price: "from £" + cheapestVariantAdditional,
            bold: false,
        };
    }

    const cheapestVariantPrice = config.variants.reduce((min, variant) => variant.price < min ? variant.price : min, 9999);
    return {
        price: "from £" + cheapestVariantPrice,
        bold: false,
    };
}

function getPhotoGroup(photoId) {
    const config = store.getState().photomatch.config;
    const folderPath = photoId.substring(0, Math.max(photoId.lastIndexOf("/"), photoId.lastIndexOf("\\")));
    const photoGroupId = getKeyByValue(config.folders.find(folder => folder.id === folderPath).photoGroups, true);
    const photoGroup = config.photoGroups.find(group => group.id === photoGroupId);

    const photoGroupFromCart = store.getState().photomatch.cart.photoGroups.find(group => group.id === photoGroupId);

    return {
        photoGroupId,
        photoGroup,
        photoGroupFromCart,
    }
}

function getMissedOfferDetails(photoGroupId) {
    const cart = store.getState().photomatch.cart;

    if(!cart) {
        return null;
    }

    const offerDetails = cart.photoGroups.find(group => group.id === photoGroupId);
    if(offerDetails.missedOffer && offerDetails.missedOfferRemainingTriggerCount === 0) {
        return offerDetails;
    }

    return null;
}

function getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
}

export function getVariantBlurb(variantId) {
    return store.getState()
        .photomatch.config.variants
        .find(variant => variant.id === variantId).blurb;
}

export function calculatePriceForVariants(variants, photoId) {
    const {photoGroup, photoGroupFromCart} = getPhotoGroup(photoId);
    const imageFromCart = getDetailImageFromCart(photoId);

    // find the free variant
    const freeCreditVariant = photoGroup.freeCreditVariant;
    let freeVariantAvailable = false;

    if(freeCreditVariant) {
        if(imageFromCart && imageFromCart.isFreeVariant) {
            freeVariantAvailable = true;
        }
        if(!imageFromCart && photoGroupFromCart.freeCredits && photoGroupFromCart.freeCredits.remaining > 0) {
            freeVariantAvailable = true;
        }
    }

    // loop over all the variants and add their prices - making sure to skip the free variant as it has already been added
    let useFullPrice = !photoGroupFromCart.freeCredits;
    let sum = 0;
    variants.forEach(variant => {
        if(variant.count === 0) {
            return;
        }

        if(freeVariantAvailable) {
            // if it is free credit variant, first one is free all others for additional, otherwise all of them are additional price
            sum += variant.id === freeCreditVariant ?
                variant.count > 1 ? (variant.count - 1) * variant.additional : 0 :
                variant.count * variant.additional;
        }
        else {
            if(useFullPrice) {
                sum += variant.price;
                useFullPrice = false;
                if(variant.count > 1) {
                    sum += (variant.count - 1) * variant.additional;
                }
            }
            else {
                sum += variant.count * variant.additional;
            }
        }
    });

    const priceWillBeLower = photoGroupFromCart.missedOffer && photoGroupFromCart.missedOfferRemainingDiscountedCount > 0;

    return {
        price: "£" + CurrencyUtils.convertToCurrency(sum),
        priceWillBeLower,
    };
}

export function isCartEmpty(cart) {
    if (cart && cart.totals && (cart.totals.grandTotal > 0 || cart.totals.photoCount > 0)) {
        return false;
    }
    return true;
}

export function createAddToCartObject(image, product, amount) {

    const addToCartRequestArray = [];

    let cartItem = createCartItem(image);
    if (product && amount) {
        cartItem = addProduct(cartItem, product, amount);
    }

    addToCartRequestArray.push(cartItem);

    const cartObject = {
        "photos": addToCartRequestArray
    };

    return cartObject;
}

export function createCartItem(image) {
    const item = {
        "id" : image.id
    };
    return item;
}

function addProduct(cartItem, product, amount) {
    const item = {
        "id" : cartItem.id,
        "variants":[
            {
                "id": product.id,
                "count": Number(amount)
            },

        ]
    };
    return item;
}

export function createBulkItemForRemoval(photoPackageName) {
    const bulk = {
        "bulk": photoPackageName
    };
    return bulk;
}

export function packageInCart(photoPackageName) {
    const cart = store.getState().photomatch.cart;
    return cart?.totals?.bulkName === photoPackageName;
}
