import React from 'react';

class TextInputWithLabel extends React.Component {
    constructor(props) {
        super(props);
        this.scrollRef = React.createRef();
    }

    scrollToRef = () => {
        if(window.screen.width < 1024) {
            window.scrollTo({
                top:this.scrollRef.current.offsetTop,
                behavior: "smooth"
            });
        }
    };

    render() {
        const inputClassNames = `input-field ${(this.props.errorMessage ? " border-red" : "")}`;
        return (
            <div ref={this.scrollRef}>
                <div className={this.props.classNames || "w-full sm:w-1/2 xl:w-1/3"}>
                    <label className={this.props.labelClassNames || "input-label font-semibold"}>
                        {this.props.label}<br/>
                        <input
                            disabled={this.props.disabled}
                            className={inputClassNames}
                            type={this.props.type || "text"}
                            value={this.props.value}
                            onChange={e => this.props._onChange(e.target.value)}
                            placeholder={this.props.placeholder}
                            onFocus={this.scrollToRef}
                        />
                    </label>
                </div>
                <div>
                    { this.props.errorMessage &&
                        <span className="errors">{this.props.errorMessage}</span>
                    }
                </div>
            </div>
        )
    }
}

export default TextInputWithLabel;