import {getFromSeanet} from "../actions/networkActions";
import {useEffect} from "react";
import {useSelector} from "react-redux";
import {saveSeanetVodData} from "../actions/seanetVodActions";

export const useSeanetGenres = (genreId) => {
    const seanetVodData = useSelector(state => state.seanetVod.data);
    const dataValidUntil = useSelector(state => state.seanetVod.dataValidUntil);

    useEffect(() => {
        const fetchSeanetVod = async () => {
            const masterData = await getFromSeanet("master.json");

            const genres = {};

            for (const entry of masterData.index) {
                for (const genre of entry.genres) {
                    if(!genres[genre.id]) {
                        genres[genre.id] = {
                            id: genre.id,
                            name: genre.name,
                            movies: []
                        };
                    }

                    genres[genre.id].movies.push({
                        title: entry.title,
                        path: entry.path,
                        image: entry.preferredCover,
                    });
                }
            }

            saveSeanetVodData(genres);

        }

        if(new Date().getTime() > dataValidUntil || !seanetVodData) {
            fetchSeanetVod();
        }

    }, [seanetVodData, genreId, dataValidUntil]);

    if(genreId) {
        return seanetVodData?.[genreId];
    }

    return seanetVodData;
};