import React, {useEffect, useState} from 'react';
import {defaultImageSrc} from "../utils/imageUtils";
import defaultPic from '../resources/images/default.png';
import {DetailedImage} from "./DetailImage";

function ImageWithFallback(props) {
    const [src, setSrc] = useState(props.src);
    const [nrFailed, setNrFailed] = useState(0);

    useEffect(() => {
        setNrFailed(0);
    }, [props.src]);

    const handleFail = (e) => {
        e.target.onerror = null;
        if(nrFailed === 0) {
            setSrc(defaultImageSrc());
        }
        else {
            setSrc(defaultPic);
        }

        setNrFailed(nrFailed + 1);
    }

    const sourceImage = nrFailed === 0 ? props.src : src;

    return (
        <DetailedImage
            {...props}
            src={sourceImage}
            onError={handleFail}
        />
    );
}

export default ImageWithFallback;