import React from "react";
import DetailImage from "../../components/DetailImage";
import {getTranslation} from "../../translations/translationUtils";
import {PropTypes} from "prop-types";
import {connect} from "react-redux";
import {fetchFromHub} from "../../actions/networkActions";
import styled from "styled-components";

const ColorDiv = styled.div`
    padding: 0.5em 0.5em 1em;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 1.5em;
    min-height: 100%;
    background: ${ props => props.index % 2 === 0 ? 
            (props.theme.specialLayoutBackgroundColorOne || "#B2E7F8") 
            : 
            (props.theme.specialLayoutBackgroundColorTwo || "rgb(0, 61, 105)") 
    };
    color: ${ props => props.index % 2 === 0 ? 
            (props.theme.specialLayoutColorOne || props.theme.bodyColor) 
            : 
            (props.theme.specialLayoutColorTwo || "white")
    };
    @media (min-width: 500px) {
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
    }
`;

class LayoutDoubleColumnImageDiffColors extends React.Component {
    constructor() {
        super();
        this.state = {
            category: null,
            items: []
        };
    }

    componentDidMount() {
        let categoryId = "";

        if (this.props.category) {
            categoryId = this.props.category.id;
        }

        this.setState({type: categoryId});
        this.props.fetchFromHub(`cms/categories/findById/`+categoryId, this.postProcessCategory);
        this.props.fetchFromHub(`cms/articles/findByCategoryId/`+categoryId+`?withDescriptions=true`, this.postProcess);
    }

    postProcessCategory = (category) => {
        this.setState({category: category});
    };

    postProcess = (items) => {
        this.setState({items: items});
    };

    render() {

        const textStyle = {
            paddingLeft: 0,
            flexGrow: 1
        };

        let title = "";
        if (this.state.category) {
            title = getTranslation(this.state.category.title);
        }

        return (
            <div>
                    <div className="row">
                        <div className="small-up-1 ">
                            <div className="column overview-item">
                                <h1 className="bold-header">{title}</h1>
                            </div>
                        </div>
                    </div>

                        <div className="small-up-1 medium-up-2 specialmessagesflexcontainer">
                            {
                                this.state.items.map((item, index) => {

                                    const description = getTranslation(item.description);

                                    return (
                                            <div key={item.id} className="small-12 medium-12 specialmessageflexitem">
                                                <div className="specialmessageitem">
                                                    <div className="small-4 medium-4 specialmessageimage">
                                                        <DetailImage divAdditionalClasses={'specialmessageimagemargin'} hideOnError={true} image={this.props.configuration.hubUrl + "blobs/image?reference=" + item.id + "&width=400"}/>
                                                    </div>
                                                    <div className="small-8 medium-8" style={textStyle}>
                                                        <ColorDiv index={index}>
                                                            <div dangerouslySetInnerHTML={{__html: description}}/>
                                                        </ColorDiv>
                                                    </div>
                                                </div>
                                            </div>
                                    )
                                })
                            }
                        </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        configuration: state.configuration,
    };
};

LayoutDoubleColumnImageDiffColors.contextTypes = {
    t: PropTypes.func.isRequired
};

export default connect(mapStateToProps, {fetchFromHub: fetchFromHub})(LayoutDoubleColumnImageDiffColors);