import {VodImageSizeEnum, VodImageTypeEnum} from "./VodEnum";
import {store} from "../../App";

export default class VodUtils {

    static getPictureUrl = function(vodItem, size = VodImageSizeEnum.SMALL) {
        let pic = null;
        let url = null;

        const picArray = VodUtils.getArrayOfPics(vodItem);
        if (picArray.length > 0) {
            if (size === VodImageSizeEnum.LARGE) {
                pic = VodUtils.getLargestPicture(picArray);
            } else {
                pic = VodUtils.getSmallestPicture(picArray);
            }
        }
        if (pic) {
            const prot = VodUtils.getProtocol(store.getState().vodconfiguration.url);
            url = prot + "//" + pic.url;
        }
        return url;
    };

    static getEpisodePictureUrl = function(vodItem, size = VodImageSizeEnum.LARGE) {
        let pic = null;
        let url = null;
        const picArray = VodUtils.getArrayOfPics(vodItem, true);
        if (picArray.length > 0) {
            if (size === VodImageSizeEnum.LARGE) {
                pic = VodUtils.getLargestPicture(picArray, true);
            } else {
                pic = VodUtils.getSmallestPicture(picArray, true);
            }
        }
        if (pic) {
            const prot = VodUtils.getProtocol(store.getState().vodconfiguration.url);
            url = prot + "//" + pic.url;
        }
        return url;
    };

    static getArrayOfPics = function(vodItem, screencapture = false) {
        let arrayOfPics = [];

        if (vodItem.channel_logos && vodItem.channel_logos.length > 0) {
            const logoArr = vodItem.channel_logos[0];
            if (logoArr.copies && logoArr.copies.length > 0) {
                arrayOfPics = logoArr.copies;
            }
        }

        // logos for channels
        if (vodItem.live_ingest_source) {
            if (vodItem.live_ingest_source.channel_logos && vodItem.live_ingest_source.channel_logos.length > 0) {
                const logoArr = vodItem.live_ingest_source.channel_logos[0];
                if (logoArr.copies && logoArr.copies.length > 0) {
                    arrayOfPics = logoArr.copies;
                }
            }
        }

        if (vodItem.images && vodItem.images.length > 0) {
            let images = [];
            for (let i = 0; i < vodItem.images.length; i++) {
                if (screencapture  && (vodItem.images[i].type).toUpperCase() === VodImageTypeEnum.SCREENCAPTURE) {
                    images = vodItem.images[i];
                }

                if (!screencapture  && (vodItem.images[i].type).toUpperCase() === VodImageTypeEnum.POSTER) {
                    images = vodItem.images[i];
                }
            }

            if (images.length === 0) {
                images = vodItem.images[0];
            }

            if (images.copies && images.copies.length > 0) {
                arrayOfPics = images.copies;
            }
        }

        // logos for vods
        if (vodItem.live_ingest_source && vodItem.live_ingest_source) {
            if (vodItem.live_ingest_source.vod_logos && vodItem.live_ingest_source.vod_logos.length > 0) {
                const logoArr = vodItem.live_ingest_source.vod_logos[0];
                if (logoArr.copies && logoArr.copies.length > 0) {
                    arrayOfPics = logoArr.copies;
                }
            }
        }

        return arrayOfPics;
    };

    static getSmallestPicture = function(arrayOfPics) {
        let smallestSize = 1000000;
        let smallestPic = null;
        for (let i = 0; i < arrayOfPics.length; i++) {
            const pic = arrayOfPics[i];
            if (pic.filesize < smallestSize){
                smallestSize = pic.filesize;
                smallestPic = pic;
            }
        }
        return smallestPic;
    };

    static getLargestPicture = function(arrayOfPics) {
        let largestSize = 0;
        let largestPic = null;
        for (let i = 0; i < arrayOfPics.length; i++) {
            const pic = arrayOfPics[i];
            if (pic.filesize > largestSize && pic.filesize < 200000){
                largestSize = pic.filesize;
                largestPic = pic;
            }
        }
        return largestPic;
    };

    static getReadableDuration(seconds) {
        let minutes = Math.floor(seconds / 60);
        let hours = "";
        if (minutes > 59) {
            hours = Math.floor(minutes / 60);
            hours = (hours >= 10) ? hours : "0" + hours;
            minutes = minutes - (hours * 60);
            minutes = (minutes >= 10) ? minutes : "0" + minutes;
        }

        seconds = Math.floor(seconds % 60);
        seconds = (seconds >= 10) ? seconds : "0" + seconds;
        if (hours !== "") {
            return hours + ":" + minutes + " hrs";
        }
        return minutes + " mins";
    }

    static getProtocol(maxicasterUrl) {
        if (!maxicasterUrl) {
            return "https:";
        }
        if (maxicasterUrl.indexOf('https') > -1) {
            return "https:";
        } else {
            return "http:";
        }
    }

    static checkAndReplaceProtocol(urlToPlay, vodConfigurationUrl) {
        if ((urlToPlay.indexOf("http:") > -1) && VodUtils.getProtocol(vodConfigurationUrl) === "https:") {
            urlToPlay = urlToPlay.replace("http:", "https:");
        }
        return urlToPlay;
    }
}