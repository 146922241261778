export const SET_FETCHING = "SET_FETCHING";
export const AUDIO_REQUEST_ENDED = "AUDIO_REQUEST_ENDED";
export const SET_VOD_AUTHENTICATION = "SET_VOD_AUTHENTICATION";
export const SET_VOD_AUTHENTICATION_CONTINUE_FETCHING = "SET_VOD_AUTHENTICATION_CONTINUE_FETCHING";
export const CLEAR_VOD_AUTHENTICATION = "CLEAR_VOD_AUTHENTICATION";
export const ADD_AUDIO_STREAM = "ADD_AUDIO_STREAM";
export const UPDATE_AUDIO_STREAM = "UPDATE_AUDIO_STREAM";

export const SET_AUDIO_GENRES = "SET_AUDIO_GENRES";

export const SET_AUDIO_WATCHLIST = "SET_AUDIO_WATCHLIST";
export const REMOVE_FROM_WATCHLIST = "REMOVE_FROM_WATCHLIST";

export const SET_AUDIO_ALBUMS = "SET_AUDIO_ALBUMS";
export const AUDIO_REQUEST_STARTED = "AUDIO_REQUEST_STARTED";

export const REQUEST_FINISHED = "REQUEST_FINISHED";
export const MAX_AUDIOS_REACHED = "MAX_AUDIOS_REACHED";
export const SET_AUDIO_ERROR = "SET_AUDIO_ERROR";
export const CLEAR_AUDIO_ERROR = "CLEAR_AUDIO_ERROR";
export const SET_SEARCHTERM_AUDIOS = "SET_SEARCHTERM_AUDIOS";