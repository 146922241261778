import axios from "axios/index";
import {
    CLEAR_AUTHENTICATION,
    SET_AUTHENTICATION,
    SET_AUTHENTICATION_ERROR
} from "../constants/authentication-action-types";
import {CLEAR_VOD_AUTHENTICATION, REQUEST_FINISHED, REQUEST_STARTED} from "../constants/vod-action-types";
import {updateBookings, updateCart} from "./cartActions";
import moment from 'moment';
import {updateItinerary} from './itineraryActions';
import {CLEAR_PM} from "../constants/photomatch-action-types";

export function logoutUser() {
    return async function (dispatch, getStore) {
        dispatch({type: CLEAR_AUTHENTICATION});
        dispatch({type: "CLEAR_ITINERARY"});
        dispatch({type: CLEAR_PM});
    }
}

export function loginByPassenger(departureDate, bookingReference, lastName) {

    if (departureDate.indexOf("-") > 0) {
        let prevDepDate = moment(departureDate, "YYYY-MM-DD");
        departureDate = prevDepDate.format("DD/MM/YYYY");
    }

    let request = {
        bookingReference,
        lastName,
        departureDate,
    };

    return async function (dispatch, getStore) {
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };

        dispatch({type: REQUEST_STARTED});

        return axios({
            method: 'POST',
            url: getStore().configuration.hubApiUrl + 'authentication/loginByPassenger',
            headers,
            data: JSON.stringify(request)
        })
        .then(function (response) {
            dispatch({type: SET_AUTHENTICATION, authentication: response.data});
            dispatch(updateItinerary());
            dispatch(updateCart());
            dispatch(updateBookings());
            dispatch({type: REQUEST_FINISHED});
            return true;
        })
        .catch(function (error) {
            dispatch({type: CLEAR_AUTHENTICATION});
            dispatch({type: SET_AUTHENTICATION_ERROR, errormessage: error.message});
            dispatch({type: CLEAR_VOD_AUTHENTICATION});
            dispatch({type: REQUEST_FINISHED});
            return false;
        });
    }
}

export function loginByPms(token, accountNumber, location) {

    let request = null;
    if (token) {
        request = {
            token,
        };
    }

    if (accountNumber && location) {
        request = {
            accountNumber,
            location
        };
    }

    if (!request) {
        console.error("neither token or account credentials supplied to loginByToken");
        return false;
    }

    return async function (dispatch, getStore) {
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };

        dispatch({type: REQUEST_STARTED});

        return axios({
            method: 'POST',
            url: getStore().configuration.hubApiUrl + 'authentication/loginByPms',
            headers,
            data: JSON.stringify(request)
        })
            .then(function (response) {
                dispatch({type: SET_AUTHENTICATION, authentication: response.data});
                dispatch({type: REQUEST_FINISHED});
                return true;
            })
            .catch(function (error) {
                dispatch({type: CLEAR_AUTHENTICATION});
                dispatch({type: SET_AUTHENTICATION_ERROR, errormessage: error.message});
                dispatch({type: REQUEST_FINISHED});
                return false;
            });
    }
}