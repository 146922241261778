import React, {Component} from 'react';
import {connect} from 'react-redux';
import browserHistory from "../../utils/history";
import Loading from "../../components/Loading";
import {getFromHubNew, getResourceImageUrl} from "../../actions/networkActions";
import {fetchCart, fetchConfig, fetchConsent} from "../../actions/photoMatchActions";
import OverviewImageButtonPanel from "../../components/OverviewImageButtonPanel";
import ImageButton from "../../components/ImageButton";


class Overview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            photoPackagesArticle: null,
            photoContentCategory: null,
            myOrdersArticle: null,
            myPhotoArticle: null,
            consentArticle: null,
        };
    }

    componentDidMount() {
        // fetch config
        fetchConfig();

        // if consent is null (not yet given or declined), redirect user to consent page
        if(this.props.consent === null) {
            browserHistory.replace("/photomatch/consent");
            return;
        }

        // fetch cart, consent and tiles info
        fetchCart();
        fetchConsent();
        this.fetchTilesInfo();
    }

    componentDidUpdate() {
        if(this.props.consent === null) {
            browserHistory.replace("/photomatch/consent");
            return;
        }
    }


    fetchTilesInfo = () => {
        getFromHubNew(
            'cms/articles/findByHashtag?hashtag=photopackages',
            r => this.setState({photoPackagesArticle: r.length > 0 ? r[0] : false})
        );

        getFromHubNew(
            'cms/articles/findByHashtag?hashtag=myphotos',
            r => this.setState({myPhotoArticle: r.length > 0 ? r[0] : false})
        );

        getFromHubNew(
            'cms/articles/findByHashtag?hashtag=photoorders',
            r => this.setState({myOrdersArticle: r.length > 0 ? r[0] : false})
        );

        getFromHubNew(
            'cms/articles/findByHashtag?hashtag=photoconsent',
            r => this.setState({consentArticle: r.length > 0 ? r[0] : false})
        );

        getFromHubNew(
            'cms/categories/findByHashtag?hashtag=photocontent',
            r => this.setState({photoContentCategory: r.length > 0 ? r[0] : false})
        );
    }

    render() {
        const consentGiven = this.props.consent;

        if(
            consentGiven === undefined ||
            this.state.photoPackagesArticle === null ||
            this.state.photoContentCategory === null ||
            this.state.myOrdersArticle === null ||
            this.state.myPhotoArticle === null ||
            this.state.consentArticle === null
        ) {
            return (
                <Loading/>
            )
        }

        const myPhotosTitle = this.state.myPhotoArticle?.title?.defaultValue || "My Photos";
        const myPhotosThumbnail = this.state.myPhotoArticle ?
            getResourceImageUrl(this.state.myPhotoArticle.id, {width: 400}):
            null;

        const photoPackageTitle = this.state.photoPackagesArticle?.title?.defaultValue || "Packages";
        const photoPackageThumbnail = this.state.photoPackagesArticle ?
            getResourceImageUrl(this.state.photoPackagesArticle.id, {width: 400}):
            null;

        const photoContentId = this.state.photoContentCategory?.id;
        const photoContentTitle = this.state.photoContentCategory?.title?.defaultValue;
        const photoContentThumbnail = photoContentId ? getResourceImageUrl(photoContentId, {width: 400}) : null;

        const myOrdersTitle = this.state.myOrdersArticle?.title?.defaultValue || "My Orders";
        const myOrdersThumbnail = this.state.myOrdersArticle ?
            getResourceImageUrl(this.state.myOrdersArticle.id, {width: 400}):
            null;

        const consentTile = this.state.consentArticle?.title?.defaultValue || "Consent";
        const consentThumbnail = this.state.consentArticle ?
            getResourceImageUrl(this.state.consentArticle.id, {width: 400}):
            null;

        return (
            <div className="row">
                <div>
                    <OverviewImageButtonPanel>
                        {/* My Photos Tile */}
                        {consentGiven &&
                        <div className="cell">
                            <ImageButton link={"/photomatch/myphotos"}
                                 title={myPhotosTitle}
                                 thumbnail={myPhotosThumbnail}
                            />
                        </div>
                        }

                        {/* Photo Packages Tile */}
                        {consentGiven &&
                        <div className="cell">
                            <ImageButton link={"/photomatch/packages"}
                                 title={photoPackageTitle}
                                 thumbnail={photoPackageThumbnail}
                            />
                        </div>
                        }

                        {/* Photo Content Tile */}
                        {consentGiven && photoContentId &&
                        <div className="cell">
                            <ImageButton link={`/content/overview/${photoContentId}`}
                                 title={photoContentTitle}
                                 thumbnail={photoContentThumbnail}
                            />
                        </div>
                        }

                        {/* My Orders Tile */}
                        {consentGiven &&
                        <div className="cell">
                            <ImageButton link={"/photomatch/orders"}
                                 title={myOrdersTitle}
                                 thumbnail={myOrdersThumbnail}
                            />
                        </div>
                        }

                        {/* Consent Tile*/}
                        <div className="cell">
                            <ImageButton link={"/photomatch/consent"}
                                 title={consentTile}
                                 thumbnail={consentThumbnail}
                            />
                        </div>

                    </OverviewImageButtonPanel>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        consent: state.photomatch?.consent,
    };
}


export default connect(mapStateToProps)(Overview);