import React from 'react';
import Price from "./Price";

function ExcursionPrices(props) {
    return (
        <>
            {props.adultPrice && <Price price={props.adultPrice} postText=" per adult"/>}
            {props.childPrice !== "N/A" &&
            <Price hidePoundSign={props.hidePoundSign} price={props.childPrice} postText={` per child ${props.ageRange}`}/>
            }
        </>
    );
}

export default ExcursionPrices;