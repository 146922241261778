import React from 'react';
import {connect} from 'react-redux';
import {fetchFromHub} from '../../actions/networkActions';
import VodImageButton from '../../components/VodImageButton';
import OverviewImageButtonPanel from '../../components/OverviewImageButtonPanel';
import {recordPageview} from "../../utils/gaUtils";

// TODO rename the VodImageButton to reflect it is more genarelized now and used here also

class Categories extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            categories: [],
            linkPrefix: "",
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if(this.props.match.params.countryId !== prevProps.match.params.countryId || this.props.match.params.cityId !== prevProps.match.params.cityId) {
            this.fetchData();
        }
    }

    fetchData = () => {
        const analyticsPageTitle = (this.props.location.state && this.props.location.state.pageTitle) ? this.props.location.state.pageTitle : "Musement category - default";
        recordPageview(window.location.pathname + window.location.search, analyticsPageTitle);

        const countryId = this.props.match.params.countryId;
        const cityId = this.props.match.params.cityId;

        let url = `musement/countries`;
        if (countryId) {
            url += `/${countryId}/cities`;
        }

        if (cityId) {
            url += `/${cityId}/activities`;
        }

        this.props.fetchFromHub(url, this.updateCategories);
    };
    updateCategories = (hubCategories) => {
        const categories = hubCategories.map((category) => {
            if (!category.cover_image_url && category.hub) {
                category.cover_image_url = this.props.hubUrl + 'blobs/image?reference=' + category.hub.id + '&width=400';
            }
            return category;
        });
        this.setState({
            categories,
        });
    };

    render() {
        const countryId = this.props.match.params.countryId;
        const cityId = this.props.match.params.cityId;
        let linkPrefix = "/musement/";

        if (countryId) {
            linkPrefix += `${countryId}/`;
        }

        if (cityId) {
            linkPrefix = `/musement/activity/`;
        }

        return (
            <OverviewImageButtonPanel>
                {this.state.categories.map(category => (
                    <VodImageButton
                        heightWidthRatio="100%"
                        truncateTitle={true}
                        key={category.id || category.uuid}
                        link={`${linkPrefix}${category.id || category.uuid}`}
                        title={category.name || category.title}
                        extraState={{pageTitle: "Musement category - " + category.name || category.title}}
                        thumbnail={category.cover_image_url}
                    />
                ))}

            </OverviewImageButtonPanel>
        );
    }
}

const mapStateToProps = state => {
    return {
        hubUrl: state.configuration.hubUrl,
    };
};

export default connect(mapStateToProps, {fetchFromHub})(Categories);
