import React from "react";
import DateButton from "../../components/DateButton";
import {fetchFromHub, getFromMusement} from "../../actions/networkActions";
import {PropTypes} from "prop-types";
import {connect} from "react-redux";
import OverviewImageButtonPanel from "../../components/OverviewImageButtonPanel";
import Loading from "../../components/Loading";
import {formatDMYtoYMD} from "../../utils/dateTimeUtils";
import cloneDeep from "lodash/cloneDeep";
import {createSubtitle} from "../../utils/itineraryUtils";
import {getTranslation} from "../../translations/translationUtils";

class Overview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            schedules: null,
            musementLoaded: false,
            musementSchedules: null,
        };
    }

    //---

    componentDidMount() {
        this.props.fetchFromHub(`itinerary/schedules`, this.updateItinerary);
    }

    updateItinerary = (schedules) => {
        this.setState({schedules});

        // Fetch musement info
        const pmsObject = JSON.parse(this.props.pmsInformation || "null");
        const embarkDate = formatDMYtoYMD(pmsObject?.embarkDate);

        if((this.props.sessionName === "Retina" || this.props.sessionName === "HotelFriend" ) && embarkDate) {
            getFromMusement(`cruise/ships/${this.props.shipId}/itinerary/${embarkDate}`, this.updateMusement, this.handleMusementError);
        }
        else {
            this.setState({musementLoaded: true, musementSchedules: []});
        }
    }
    //---

    updateMusement = (result) => {
        const musementDates = result.dates;

        // clone the existing schedules then map and add the musement info if there is a match in the name and the date (either arrival or departure) of the tile
        const schedules = cloneDeep(this.state.schedules).map(entry => {
            const matchedEntry = musementDates.find(
                musementDate =>
                    musementDate.arrival_time && musementDate.departure_time &&
                    (musementDate.departure_time.startsWith(formatDMYtoYMD(entry.date)) || musementDate.arrival_time.startsWith(formatDMYtoYMD(entry.date))) &&
                    entry.title.defaultPlainValue.toLowerCase().startsWith(musementDate.port.name.toLowerCase())
            );

            if(matchedEntry) {
                entry.musementPortId = matchedEntry.port.id;
                entry.musementArrivalTime = matchedEntry.arrival_time;
                entry.musementDepartureTime = matchedEntry.departure_time;
            }

            return entry;
        });

        this.setState({schedules, musementSchedules: result, musementLoaded: true});
    }

    handleMusementError = (response) => {
        console.log("musement error", response);
        this.setState({musementSchedules: [], musementLoaded: true});

    }

    // ---

    render() {
        if (this.state.schedules === null || !this.state.musementLoaded) {
            return (
                <Loading/>
            )
        }


        return (
            <div>
                <OverviewImageButtonPanel>
                    {
                        this.state.schedules.map((day, index) =>
                            <div key={day.id} className="cell">
                                <DateButton link={"/itinerary/detail"}
                                            id={day.id}
                                            date={day.date}
                                            disabled={day.atSea}
                                            index={index}
                                            title={getTranslation(day.title) || day.title.defaultValue}
                                            subtitle={createSubtitle(day)}
                                            thumbnail={day.image}
                                            linkState={{
                                                portId: day.musementPortId,
                                                arrivalTime: day.musementArrivalTime,
                                                departureTime: day.musementDepartureTime,
                                            }}
                                />
                            </div>
                        )}
                </OverviewImageButtonPanel>
            </div>
        );
    }

    //--


}

const mapStateToProps = state => {
    return {
        configuration: state.configuration,
        sessionName: state.authentication?.sessionName,
        pmsInformation: state.authentication?.pms?.information,
        shipId: state.configuration?.hubSettings?.uiSettings?.shipId,
    };
};

Overview.contextTypes = {
    t: PropTypes.func.isRequired
};

export default connect(mapStateToProps, {fetchFromHub: fetchFromHub})(Overview);
