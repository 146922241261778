import React from "react";
import {connect} from "react-redux";
import {defaultImageSrc} from "../../utils/imageUtils";
import Modal from "../../components/Modal";
import {fetchGenresFromMaxicaster} from "../../actions/vodActions";
import OverviewImageButtonPanel from "../../components/OverviewImageButtonPanel";
import OverviewImageButton from "../../components/OverviewImageButton";
import VodUtils from "./VodUtils";
import {withTheme} from "styled-components";

class GenreOverview extends React.Component {

    componentDidMount() {
        this.search();
    }

    async search() {
        await this.props.fetchGenresFromMaxicaster(this.props.match.params.type);
    }

    render() {
        if (! this.props.genres) {
            return false;
        }


        function compare(a,b) {
            if (a.name < b.name)
                return -1;
            if (a.name > b.name)
                return 1;
            return 0;
        }

        this.props.genres.sort(compare);


            return (
                <div>
                    <Modal show={this.props.isFetching}/>

                    <OverviewImageButtonPanel>
                        {
                            this.props.genres.map((genre => {
                                    const protocol = VodUtils.getProtocol(this.props.vodconfiguration.url);

                                    let logoUrl = defaultImageSrc();
                                    if (genre.images && genre.images.length > 0) {
                                        if (genre.images[0].length > 0 && genre.images[0][0].copies) {
                                            const firstImages = genre.images[0][0].copies;
                                            const genreImage = VodUtils.getSmallestPicture(firstImages);
                                            if (genreImage) {
                                                logoUrl = protocol + "//" + genreImage.url;
                                            }
                                        }
                                    }

                                    if(genre.vods && genre.vods.length !== 0){
                                        return (
                                            <OverviewImageButton key={genre.id}
                                                         link={"/vod/overview/" + this.props.match.params.type + "/" + genre.id}
                                                         title={genre.name}
                                                         thumbnail={logoUrl}
                                                         heightWidthRatio={this.props.theme.vodCategoryHeightWidthRatio || "75%"}
                                            />

                                        )
                                    } else {
                                        return null;
                                    }
                                }
                            ))
                        }

                    </OverviewImageButtonPanel>
                </div>
            )
    }
}


const mapStateToProps = state => {
    return {
        genres: state.vod.genres,
        isFetching: state.vod.isFetching,
        vodconfiguration: state.vodconfiguration,
    };
};

export default connect(mapStateToProps, {fetchGenresFromMaxicaster: fetchGenresFromMaxicaster})(withTheme(GenreOverview));