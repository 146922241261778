import React from 'react';
import {connect} from 'react-redux';
import {PropTypes} from 'prop-types';
import {fetchFromHub} from "../../actions/networkActions";
import {getTranslation} from '../../translations/translationUtils';
import OverviewImageButtonPanel from '../../components/OverviewImageButtonPanel';
import OverviewImageButton from "../../components/OverviewImageButton.js";

class ActivityOverview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            categories: [],
            items: [],
            loaded: false,
        }
    }

    componentDidMount() {
        this.props.fetchFromHub(`activitycategory`, this.updateCategories);
        this.props.fetchFromHub(`activity`, this.updateItems);
    }

    updateCategories = (categories) => {
        this.setState({
            categories: categories,
            loaded: true,
        });
    };

    updateItems = (items) => {
        this.setState({
            items: items,
            loaded: true,
        });
    };


    render() {
        if (!this.state.loaded) {
            return (<div>{this.context.t("loading")}</div>);
        }

        if (this.state.categories.length === 0 && this.state.items.length === 0) {
            return ( <div>{this.context.t("no_activities_available")}</div>);
        }

        return (
            <div>
                <OverviewImageButtonPanel>
                    {this.state.categories.map((item) => {
                        const title = getTranslation(item.title);
                        const thumbnailUrl = `${this.props.configuration.hubUrl}blobs/image?reference=${item.id}&width=400`;
                        const itemUrl = `/activity/category/${item.id}` ;

                        return (
                            <OverviewImageButton
                                heightWidthRatio="100%"
                                truncateTitle={true}
                                key={item.id}
                                link={itemUrl}
                                title={title}
                                thumbnail={thumbnailUrl}
                            />);
                    })}

                    {this.state.items.map((item) => {
                        const title = getTranslation(item.title);
                        const thumbnailUrl = `${this.props.configuration.hubUrl}blobs/image?reference=${item.id}&width=400`;
                        const itemUrl = `/activity/detail/${item.id}` ;
                        const bookable = item.externalId ? true : false;

                        return (
                            <OverviewImageButton
                                heightWidthRatio="100%"
                                truncateTitle={true}
                                key={item.id}
                                link={itemUrl}
                                title={title}
                                thumbnail={thumbnailUrl}
                                bookable={bookable}
                            />);
                    })}
                </OverviewImageButtonPanel>
            </div>
        );
    }
}

ActivityOverview.contextTypes = {
    t: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    return {
        configuration: state.configuration,
    };
};


export default connect(mapStateToProps, {fetchFromHub: fetchFromHub})(ActivityOverview);