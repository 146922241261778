import {
    REDUX_INIT,
    SET_VOD_APIVERSION,
    SET_VOD_APPIDENTIFIER,
    SET_VOD_DEMOSYSTEM,
    SET_VOD_PAYMENTENABLED,
    SET_VOD_URL,
    SET_VOD_USEWITHCREDENTIALS
} from "../constants/vodconfiguration-action-types";

const INITIAL_STATE = {
    url: process.env.REACT_APP_MAXICASTER_URL,
    appIdentifier: process.env.REACT_APP_MAXICASTER_APP_IDENTIFIER,
    apiVersion: process.env.REACT_APP_MAXICASTER_API_VERSION,
    paymentEnabled: process.env.REACT_APP_MAXICASTER_PAYMENT_ENABLED,
    isDemoSystem: process.env.REACT_APP_MAXICASTER_ISDEMOSYSTEM,
    useWithCredentials: process.env.REACT_APP_MAXICASTER_USEWITHCREDENTIALS,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case REDUX_INIT:
            return INITIAL_STATE;

        case SET_VOD_URL:
            return {...state, url: action.url}

        case SET_VOD_APPIDENTIFIER:
            return {...state, appIdentifier: action.appIdentifier}

        case SET_VOD_APIVERSION:
            return {...state, apiVersion: action.apiVersion}

        case SET_VOD_PAYMENTENABLED:
            return {...state, paymentEnabled: action.paymentEnabled}

        case SET_VOD_DEMOSYSTEM:
            return {...state, isDemoSystem: action.isDemoSystem}

        case SET_VOD_USEWITHCREDENTIALS:
            return {...state, useWithCredentials: action.useWithCredentials}

        default:
            return state;
    }
}
