import * as React from "react";
import {connect} from "react-redux";
import Modal from "../../../components/Modal";
import {loginToMaxicaster} from "../../../actions/vodActions";
import {PropTypes} from "prop-types";
import {fetchChannelsFromMaxicaster} from "../../../actions/channelActions";
import {VodImageSizeEnum} from "../VodEnum";
import VodUtils from "../VodUtils";
import moment from "moment";
import styled from "styled-components";
import {device} from "../../../resources/styles";
import {SET_VOD_ASSET, SET_VOD_DETAILS} from "../../../constants/voditem-action-types";
import browserHistory from "../../../utils/history";

const ChannelTable = styled.div `
    > div {
        display: flex;
        flex-direction: column;
    }
`;

const EPGRow = styled.div `
    background: ${props => props.theme.headerBackground || "#444444"};
    color: ${props => props.theme.headerColor || "#ffffff"};
    box-shadow: 0 1px 3px rgba(0,0,0,  0.2), 0 1px 2px rgba(0,0,0, 0.2);

    div.modal {
            position: fixed;
            justify-content: center;
            align-items: center;
            z-index: 999;
            top: 0;
            left: 0;
            width:100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.4);
        }
        
    div.display-flex {
        display: flex;
    }
        
    div.display-none {
        display: none;
    }        
    
`;

const ChannelRow = styled.div `
    width: 100%;
    height: 7em;
    display: flex;
    padding-bottom: 1em;
`;

const ChannelLogo = styled.div `
    flex: 1;
    background: white;
    box-shadow: 0 1px 3px rgba(0,0,0,  0.2), 0 1px 2px rgba(0,0,0, 0.2);
    border-radius: 5px 0px 0px 5px;
     
    > div {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;        
    }
    
    > div > img {
        max-width: 70%;
        max-height: 70%;
        object-fit: contain;
    }
    
    @media screen and (max-width: 1000px) {
        max-width: 300px;
    }
       
`;

const ProgramRow = styled.div `
    flex: 1;

    div.program-container:nth-child(n+2) {
        display: none;
    }
    
    div.program-container:nth-child(1) {
        border-radius: 0 5px 5px 0;
        height: 100%;
        width: 100%;
    }
    
    @media ${device.tablet} {
        flex: 11;
        display: flex;
        
        div.program-container:nth-child(n+2) {
            display: flex;
        }            
        
        div.program-container:nth-child(1) {
            width: auto;
            border-radius: 0;
        }        
        
        div.program-container:nth-last-child(1) {
            border-radius: 0 5px 5px 0;
        }
    }
    
`;

const ProgramContainer = styled.div `
    height: 100%;
    padding: 0.55em;
    overflow: hidden;
    width: 100%;
    
    div {
        height: 100%;
        overflow: hidden;
    }
    
    div > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: auto;
    }
    
`;

const ModalEpg = styled.article `
    background: ${props => props.theme.headerBackground || "#444444"};
    width: 90%;
    border-radius: 5px;
    color: ${props => props.theme.headerColor || "#ffffff"};
    padding: 2em;
    
    div {
        display: flex;
        justify-content: space-between;
    }  
    
    @media ${device.laptop} {
        width: 60%;
    }
    
`;


class OverviewChannels extends React.Component {
    constructor() {
        super();
        this.state = {};
        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
        if( this.props.maxicasterToken === null){
            this.props.loginToMaxicaster();
        } else {
            this.props.fetchChannelsFromMaxicaster();
        }
    }

    componentDidUpdate(prevProps){
        if(this.props.maxicasterToken !== null && this.props.isFetching === false && prevProps.maxicasterToken !== this.props.maxicasterToken){
            this.props.fetchChannelsFromMaxicaster();
        }
    }

    toggleModal(id) {
        const visibility = !this.state[`modal-${id}`];
        this.setState({
            [`modal-${id}`]: visibility
        });
    };

    createTimeslot = (channel) => {
        let program = [];
        // epgTill - hours
        let epgTill = 4;
        let epgTillUnix = epgTill * 60 * 60;

        let startP = channel.epg && channel.epg.programs && channel.epg.programs.length > 0 && channel.epg.programs[0].start_time ? channel.epg.programs[0].start_time : Math.floor(Date.now() / 1000);
        let durationT = startP + epgTillUnix;

        let endP;
        let wholeRowTime;
        for (let i = 0; i < channel.epg.programs.length; i++) {
            if (durationT <= channel.epg.programs[i].end_time) {
                endP = channel.epg.programs[i].end_time;
                break;
            }
        }
        wholeRowTime = endP - startP;

        for (let i = 0; i < channel.epg.programs.length; i++) {
            if(endP < channel.epg.programs[i].end_time){
                break;
            }
            let start_time =  moment.unix(channel.epg.programs[i].start_time);
            let start_time_formatted =  moment(start_time).format("HH:mm");
            let end_time =  moment.unix(channel.epg.programs[i].end_time);
            let end_time_formatted =  moment(end_time).format("HH:mm");
            let difference = channel.epg.programs[i].end_time - channel.epg.programs[i].start_time;
            let breite = difference / wholeRowTime * 93;
            let style = {
                width: breite + "%",
            };
            if (window.innerWidth < 768) {
                style.width = "100%";
            }
            let programmId = channel.id + i.toString();

            program.push(<EPGRow  className={'program-container'} key={start_time} style={style} onClick={() => this.toggleModal(programmId)}>
                <ProgramContainer>
                    <div>
                        <div>
                            <span>{`${start_time_formatted}`}</span>
                            <i className={'fa fa-info'}></i>
                        </div>
                        <span>{`${channel.epg.programs[i].name}`}</span>
                    </div>
                </ProgramContainer>
                <div className={'modal ' + (this.state[`modal-${programmId}`] === true ? 'display-flex' : 'display-none')} id={programmId}>
                    <ModalEpg>
                        <div>
                            <h1>{`${channel.epg.programs[i].name}`}</h1>
                            <i className={'fa fa-close'}></i>
                        </div>
                        <p>{start_time_formatted} - {end_time_formatted}</p>
                        <p>{`${channel.epg.programs[i].description}`}</p>
                    </ModalEpg>
                </div>
            </EPGRow>)
        }
        return program
    };

     setChannel = (channelId) => {
         const channelToStream = this.props.channels.find(channel => channel.id === parseInt(channelId));
         if (channelToStream && channelToStream.streams && channelToStream.streams.length > 0) {
             const vodAsset = {
                 url: channelToStream.streams[0].url
             }
             this.props.setVodAsset(vodAsset);
         }

         const vodDetails = {
             title: channelToStream.name
         }
         this.props.setVodDetails(vodDetails);

         browserHistory.push("/vod/channel/"+channelId);
     }

    render() {

        if (! this.props.channels) {
            return false;
        }

        return (
            <div>
                <Modal show={this.props.isFetching}/>
                <h1>TV Guide</h1>
                <p>Choose From Our Live TV Channels Available Below</p>
                <ChannelTable>
                    <div>
                    {
                        this.props.channels.map((channel) => {


                            if (!channel.streams || channel.streams.length < 1) {
                                return false;
                            }

                           let channelLogo = VodUtils.getPictureUrl(channel, VodImageSizeEnum.LARGE);
                           return (
                               <ChannelRow key={channel.id}>
                                   <ChannelLogo>
                                       <div style={{display: "flex"}} onClick={() => this.setChannel(channel.id)}>
                                           <img src={channelLogo} alt={channel.name}/>
                                       </div>
                                   </ChannelLogo>
                                   <ProgramRow>
                                       {this.createTimeslot(channel)}
                                   </ProgramRow>
                               </ChannelRow>
                           )
                        })
                    }
                    </div>
                </ChannelTable>
            </div>
        )
    }

}
OverviewChannels.contextTypes = {
    t: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    return {
        channels: state.channel.channels,
        isFetching: state.vod.isFetching,
        maxicasterToken: state.vod.maxicastertoken
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setVodAsset: (value) => dispatch({type: SET_VOD_ASSET, vodassets: value }),
        setVodDetails: (value) => dispatch({type: SET_VOD_DETAILS, voddetails: value }),
        fetchChannelsFromMaxicaster: () => dispatch(fetchChannelsFromMaxicaster()),
        loginToMaxicaster: () => dispatch(loginToMaxicaster())
    };
};

export default connect(mapStateToProps, mapDispatchToProps) (OverviewChannels)
