/*
 * TODO - decide what data should be stored to the local storage.
 * at the moment complete state is serialized and saved to local storage
 * it might make more sense to just store some parts and
 * rebuild the rest via network requests
*/

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state');
        if (serializedState === null) {
            return undefined;
        }

        return JSON.parse(serializedState);
    } catch (error) {
        return undefined;
    }
};

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify({
            modules: state.modules,
            authentication: state.authentication,
            vod: state.vod,
            voditem: state.voditem,
            itinerary: state.itinerary,
            cart: state.cart,
            bookings: state.bookings,
            navigation: state.navigation,
            i18nState: state.i18nState,
            musement: state.musement,
            photomatch: state.photomatch,
            tacBookings: state.tacBookings,
        });
        localStorage.setItem('state', serializedState);
    } catch (error) {
        //TODO - decide how to handle errors - if needed
    }
};