import {applyMiddleware, createStore} from 'redux';
import thunk from "redux-thunk";
import {composeWithDevTools} from "redux-devtools-extension";
import allReducers from '../reducers';
import {loadState, saveState} from './localStorage';
import throttle from 'lodash/throttle';

const configureStore = () => {
    let persistedState = loadState();

    // i18nRedux library does not provide a way to set the default state nicely
    // this is a workaround to set it to whatever language the browser is in by default IF the user did not have a language preference set up in the local storage already
    if(persistedState === undefined) {
        persistedState = {
            i18nState: {lang: window.navigator.language.split("-")[0],translations:{},forceRefresh:false},
        };
    } else {
        if(!persistedState.i18nState) {
            persistedState = {
                ...persistedState,
                i18nState: {lang:window.navigator.language.split("-")[0],translations:{},forceRefresh:false},
            }
        }
    }

    const store = createStore(
        allReducers,
        persistedState,
        composeWithDevTools(applyMiddleware(thunk))
    );

    //prevent saving state to local storage more than once every 2 seconds
    store.subscribe(throttle(() => {
        saveState(store.getState());
    }, 2000));

    return store;
};


export default configureStore;
