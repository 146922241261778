import React from "react";
import {defaultImageSrc} from "../utils/imageUtils";
import ImageWithFallback from "./ImageWithFallback";
import styled from "styled-components";

export const DetailedImage = styled.img`
    border-radius: ${props => props.theme.tileBorderRadius || "0px"};
`;

export default class DetailImage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hide: false,
        }
    }

    hide = () => {
        this.setState({hide:true});
    };

    render() {
        let image = this.props.image;
        if (!image) {
            image = defaultImageSrc();
        }

        if(this.state.hide) {
            return null;
        }

        let divAdditionalClasses = null;
        if (this.props.divAdditionalClasses) {
            divAdditionalClasses = this.props.divAdditionalClasses;
        } else {
            divAdditionalClasses = null;
        }

        return (
            <div className={`${divAdditionalClasses ? divAdditionalClasses : ""}`}>
                {this.props.hideOnError &&
                    <DetailedImage className="thumbnail" src={image} alt="" onError={(e)=>{e.target.onerror = null; this.hide()}}/>
                }

                {!this.props.hideOnError &&
                    <ImageWithFallback className="thumbnail" src={image} alt=""/>
                }
            </div>
        )
    }
}