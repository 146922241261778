import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import {Link} from "react-router-dom";
import {addPhotoToCart, removePhotoFromCart} from "../../actions/photoMatchActions";
import {calculatePriceForVariants} from "../../utils/photoMatchUtils";
import {imagePath} from "../../utils/imageUtils";
import styled from "styled-components";
import {DefaultButton} from "../../resources/styles";

const Container = styled.div `
    width: 100%;
    
    @media screen and (min-width: 1900px) {
        width: 420px;
        margin-left: 1rem;
    }
    
    .variants-form { 
        background: white;
        padding: 0.5rem;
        
        @media screen and (min-width: 1900px) {
            padding: 1rem;
        }
    }
    .border-bottom {
        border-bottom: 2px solid #a7a7a7;
    }
    
    .font-lg {
        font-size: 1.125rem;
   }
`;

const VariantContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    user-select: none;
    
    .upsell-buttons {
        width: 6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;
    }
    
    .upsell-button {
        background: #1e2c5c;
        color: white;
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .upsell-count {
        flex-grow: 1;
        text-align: center;
    }
    
    .upsell-icon {
        width: 1rem;
        height: 1rem;
    }
    
    .quantity-disabled {
        background: #5e5e5e;
    }
`;

const FooterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    margin-bottom: 3rem;
    
    @media screen and (min-width: 1900px) {
        margin-bottom: 0;
    }
`;


class MyPhotoVariantsForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            variants: cloneDeep(props.variants),
            showConfirmation: false,
        };
    }

    updateQuantity = (id, quantity) => {
        const updatedVariants = this.state.variants.map((variant) => {
            if(variant.id === id) {
                variant.count = variant.count + quantity;
            }
            return variant;
        });

        this.setState({
            variants: updatedVariants,
        });
    };

    updateCart = () => {
        if(this.props.isImageInCart || this.state.variants.some(variant => variant.count > 0)) {
            const variants = this.state.variants.map(variant => ({id: variant.id, count: variant.count}));
            addPhotoToCart(this.props.imageId, variants);

            if(this.props.closeOnUpdate) {
                this.props.onClose();
            }
            else {
                this.setState({
                    showConfirmation: true,
                });
            }
        }
    };

    removeFromCart = () => {
        removePhotoFromCart(this.props.imageId);
        this.props.onClose()
    }


    render() {
        const variantsPrice = calculatePriceForVariants(this.state.variants, this.props.imageId);
        return (
            <Container onClick={(e) => {e.stopPropagation()}}>
                <div className="variants-form">
                    {this.state.showConfirmation &&
                    <div>
                        <div className="flex items-center">
                            <img alt="" src={imagePath("check.svg")} className="w-4 h-4 mr-4"/>
                            <div>ITEM ADDED TO BASKET</div>
                        </div>
                        <div>
                            Select "Continue" to continue shopping or {' '}
                            <Link className="underline" to="/photomatch/cart">
                                Go To Basket
                            </Link>
                            {' '} and check out now</div>
                    </div>
                    }
                    {!this.state.showConfirmation &&
                    <div>
                        <div className="border-bottom font-lg">ADD TO BASKET</div>

                        <div className="border-bottom">
                            {
                                this.state.variants.map((variant) => (
                                    <Variant
                                        key={variant.id}
                                        variant={variant}
                                        updateQuantity={this.updateQuantity}
                                    />
                                ))
                            }
                        </div>

                        <div className="font-bold mt-2">
                            Price: {variantsPrice.price}
                        </div>
                        {variantsPrice.priceWillBeLower &&
                        <div>
                            *Discount applied will be shown in basket
                        </div>
                        }
                    </div>
                    }
                </div>
                <FooterContainer className="flex justify-between mt-1 mb-3 xl-mb-0">
                    {this.state.showConfirmation &&
                    <DefaultButton onClick={this.props.onClose}>Continue</DefaultButton>
                    }

                    {!this.state.showConfirmation &&
                    <DefaultButton
                        onClick={this.updateCart}>
                        Add to Basket
                    </DefaultButton>
                    }

                    {this.props.isImageInCart && !this.state.showConfirmation &&
                    <DefaultButton
                        onClick={this.removeFromCart}>
                        Remove from basket
                    </DefaultButton>
                    }

                    {!this.state.showConfirmation &&
                    <DefaultButton onClick={this.props.onClose}>Close</DefaultButton>
                    }
                </FooterContainer>
            </Container>
        );
    }
}

export default MyPhotoVariantsForm;




class Variant extends React.Component {
    getButtonClassname(disabled) {
        return `upsell-button ${disabled ? ' quantity-disabled' : ''}`
    }

    onClick(disabled, quantity) {
        if(!disabled) {
            this.props.updateQuantity(this.props.variant.id, quantity);
        }
    }

    render() {
        const variant = this.props.variant;
        const minusDisabled = variant.count < 1;
        const plusDisabled = (variant.digital || variant.isDigital) && variant.count === 1;

        return (
            <VariantContainer>
                <div className="upsell-buttons">
                    <div
                        className={this.getButtonClassname(minusDisabled)}
                        onClick={() => this.onClick(minusDisabled, -1)}>
                        <img src={imagePath("minus.svg")} className="upsell-icon" alt="minus"/>
                    </div>
                    <div className="upsell-count">
                        {variant.count}
                    </div>
                    <div
                        className={this.getButtonClassname(plusDisabled)}
                        onClick={() => this.onClick(plusDisabled, 1)}>
                        <img src={imagePath("plus.svg")} className="upsell-icon" alt="plus"/>
                    </div>
                </div>
                <div>
                    {variant.blurb}
                </div>
            </VariantContainer>
        )
    }
}