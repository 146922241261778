import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import browserHistory from "../../utils/history";
import ContentHeaderImage from './ContentHeaderImage';
import PriceInfoBox from './PriceInfoBox';
import ExcursionDuration from './ExcursionDuration';
import ExcursionAttributes from '../../components/ExcursionAttributes';
import ContentDividerWithIcon from './ContentDividerWithIcon';
import ModalGallery from './ModalGallery';
import {formatPortDate} from '../../utils/dateTimeUtils';
import ImportantInformationEntry from './ImportantInformationEntry';
import {defaultImageSrc, imagePath} from '../../utils/imageUtils';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import If from '../../components/If';
import SoldOutBox from './SoldOutBox';
import GalleryToggleButton from "./GalleryToggleButton";
import {PropTypes} from "prop-types";

import ExcursionTags from "../../components/ExcursionTags";
import BookingCode from "./BookingCode";
import DepartureTime from "./DepartureTime";
import { isEmpty } from 'lodash';
import styled from 'styled-components';


const ExcursionTitle = styled.h1 `
    color: ${props => props.theme.excursionTitleColor || '#A28B6F'};
    font-weight: 400;
    font-size: 1.875rem;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
`;

const DescriptionTitle = styled.h2 `
    text-transform: uppercase;
    color: ${props => props.theme.headerColor || '#6B207D'};
    font-size: 1.375rem;
    font-weight: 400;
`;

const TableHeaderContainer = styled.div`
  background-color: ${props => props.theme.headerColor || '#6B207D'};
  color: ${props => props.theme.bodyBackground || '#F3F3F3'};
`;

class ExcursionDetail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showImagesModal: false,
        }
    }

    toggleImagesModal = () => {
        this.setState({
            showImagesModal: !this.state.showImagesModal
        });
    };

    render() {
        const {arrivalTime, departureTime} = this.props.location.state || {};
        const excursionAttributes = this.props.excursion.tags?.map(tag => tag.name) || [];
        const {duration, publishedDuration} = this.props.excursion || {};

        const excursionFacts = [];
        this.props.excursion.date && excursionFacts.push(`${formatPortDate(this.props.excursion.date)}`);
        (arrivalTime && departureTime) && excursionFacts.push(`From ${this.props.location.state?.portName?.toUpperCase()} where the ship is in port: ${arrivalTime} - ${departureTime}`)

        return (
            <div>
                <div className="container mt-4 mb-8 mx-auto">
                    <ExcursionTitle>{this.props.excursion.name.defaultValue}</ExcursionTitle>

                    <div className="my-4">
                        <ContentHeaderImage
                            altText={this.props.excursion.name.defaultValue + " - header image"}
                            resourceId={this.props.excursion.id}
                            category="cruiseExcursion"
                            width="1048"
                        >
                            <GalleryToggleButton
                                toggleGallery={this.props.images && this.props.images.length > 1 && this.toggleImagesModal}
                            />
                        </ContentHeaderImage>
                    </div>

                    <ModalGallery
                        show={this.state.showImagesModal}
                        onClose={this.toggleImagesModal}
                    >
                        <ImageGallery
                            items={this.props.images}
                            lazyLoad={true}
                            showIndex={false}
                            defaultImage={defaultImageSrc()}
                            showPlayButton={false}
                        />
                    </ModalGallery>

                    <div className="sm:flex sm:flex-row-reverse my-8">
                        {/*right container - pricebox and key facts*/}
                        <div className="sm:w-1/2 md:w-1/3 xl:w-1/4">
                            <If test={!isEmpty(this.props.excursion.price)}>
                                {/* show sold out message or price box depending if excursion is available or not */}
                                <If test={this.props.currentAvailability > 0}>
                                    <PriceInfoBox
                                        classes="w-full text-primary-dark border border-primary-light p-4 bg-white"
                                        adultPrice={this.props.excursion.price.adult}
                                        childPrice={this.props.excursion.price.child}
                                        minimumAge={this.props.excursion.minimumAge}
                                        buttonText="Book Experience"
                                        currentAvailability={this.props.currentAvailability}
                                        _onClick={() => browserHistory.push(`/onboard/bookexcursion/${this.props.excursion.id}`)}
                                        hideButton={this.props.deviceType === "DigitalSignage"}
                                    />
                                </If>

                                <If test={this.props.currentAvailability <= 0}>
                                    <SoldOutBox />
                                </If>
                            </If>

                            <div className="mt-6">
                                {this.props.excursion.day ? (
                                    <TableHeaderContainer className="uppercase p-4">
                                        {this.context.t('duration')}
                                    </TableHeaderContainer>
                                ):(
                                    <div className="uppercase p-4 bg-primary-light text-primary-dark">
                                        Key facts
                                    </div>
                                    )}
                                <div className="bg-primary-lightest p-4">
                                    {excursionAttributes.length > 0 &&
                                    <div className="pb-2 border-b">
                                        <ExcursionTags attributes={excursionAttributes} />
                                    </div>
                                    }
                                    <div className={(duration || publishedDuration) ? "py-2 border-b" : ""}>
                                        <ExcursionDuration duration={duration} publishedDuration={publishedDuration} />
                                    </div>
                                    {excursionFacts.length > 0 &&(
                                    <div className="pt-2">
                                        <ExcursionAttributes attributes={excursionFacts}/>
                                    </div>
                                    )}

                                    <div className={`text-primary ${excursionFacts.length > 0 && "border-t mt-2"} py-2`}>
                                        <BookingCode bookingCode={this.props.excursion.bookingCode} />
                                        <DepartureTime fromTime={this.props.excursion.timeOfDay} />
                                    </div>

                                </div>
                            </div>
                        </div>

                        {/*left container - about the excursion & excursion itinerary*/}
                        <div className="mt-8 sm:mt-0 sm:w-1/2 md:w-2/3 xl:w-3/4 sm:pr-4">
                            <div className="text-primary-dark mb-4">
                                <DescriptionTitle>{this.context.t("about_the_experience")}</DescriptionTitle>
                                <ExcursionDescription description={this.props.descriptions.Description.defaultWhitelistedValue} setHtmlDirectly={true} />
                            </div>

                            <div className="text-grey-darker my-4">
                                <ImportantInformationEntry
                                    title={this.context.t("on_the_way")}
                                    content={this.props.descriptions.WalkingInfo?.defaultWhitelistedValue}
                                />
                            </div>

                            <ContentDividerWithIcon
                                iconAltText="info icon"
                                iconSrc={imagePath('infoiconamb.svg')}
                                title={this.context.t('please_note')}
                                hidden={!(this.props.descriptions.Notes)}
                            />

                            <div className="text-grey-darker my-4">
                                <ImportantInformationEntry
                                    title={""}
                                    content={this.props.descriptions.Notes?.defaultWhitelistedValue}
                                />
                            </div>

                            {this.props.excursion.waiverPdfUrl &&
                            <div className="my-4">
                                <a href={`${this.props.hubBaseUrl}${this.props.excursion.waiverPdfUrl.url}`}
                                   className="underline text-primary"
                                   target="_blank"
                                   rel="noopener noreferrer">
                                    Link to Waiver
                                </a>
                            </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ExcursionDetail.contextTypes = {
    t: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        hubBaseUrl: state.configuration.hubUrl.replace(/\/$/, ""),
        tags: state.tags,
        deviceType: state.configuration.hubSettings.deviceType,
    }
}
export default connect(mapStateToProps)(withRouter(ExcursionDetail));


function ExcursionDescription(props) {
    if(props.setHtmlDirectly) {
        return (
            <div className="text-grey-darker my-2" dangerouslySetInnerHTML={{__html: props.description}} />
        )
    }
    return (
        <div className="text-grey-darker my-2">
            {props.description}
        </div>
    );
}
