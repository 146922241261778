import {getFromHubNew} from "../actions/networkActions";
import {useEffect, useState} from "react";
import {defaultImageSrc} from "../utils/imageUtils";
import {useSelector} from "react-redux";

export const useExcursionImages = (excursionId) => {
    const hubUrl = useSelector(state => state.configuration.hubUrl);
    const [images, setImages] = useState([]);

    useEffect(() => {
        const fetchImages = async () => {
            const imagesData = await getFromHubNew(`blobs/images/${excursionId}`);

            if (!imagesData || imagesData.length === 0) {
                setImages([{
                    thumbnail: `${defaultImageSrc()}&width=200`,
                    original: `${defaultImageSrc()}`,
                }]);
            } else {
                setImages(imagesData.map(image => {
                    return {
                        thumbnail: `${hubUrl}${image.url.substring(1)}&width=200`,
                        original: `${hubUrl}${image.url.substring(1)}`,
                    }
                }));
            }
        }

        fetchImages();
    }, [hubUrl, excursionId]);

    return images;
};