import {combineReducers} from 'redux';
import {i18nState} from "redux-i18n"

import configurationReducers from './configurationReducers.js';
import modulesReducer from "./modulesReducer";
import clientReducer from "./clientReducer";
import vodReducer from "./vodReducer";
import vodItemReducer from "./vodItemReducer";
import authenticationReducers from "./authenticationReducers";
import itineraryReducers from "./itineraryReducers";
import cartReducers from "./cartReducers";
import bookingsReducers from "./bookingsReducer";
import navigationReducers from "./navigationReducers";
import vodConfigurationReducers from "./vodConfigurationReducers";
import audioReducer from './audioReducer';
import channelReducer from "./channelReducer";
import wayfinderReducer from "./wayfinderReducer";
import excursionTagsReducers from "./excursionTagsReducers";
import tacBookingReducer from "./tacBookingReducer.js";
import photoMatchReducer from "./photoMatchReducer";
import seanetVodReducer from "./seanetVodReducer";

const allReducers = combineReducers({
    i18nState: i18nState,
    authentication: authenticationReducers,
    configuration: configurationReducers,
    vodconfiguration: vodConfigurationReducers,
    modules: modulesReducer,
    clientinfo: clientReducer,
    vod: vodReducer,
    seanetVod: seanetVodReducer,
    voditem: vodItemReducer,
    itinerary: itineraryReducers,
    cart: cartReducers,
    bookings: bookingsReducers,
    navigation: navigationReducers,
    audios: audioReducer,
    channel: channelReducer,
    wayfinder: wayfinderReducer,
    excursionTags: excursionTagsReducers,
    tacBookings: tacBookingReducer,
    photomatch: photoMatchReducer,
});

export default allReducers;