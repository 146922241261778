import React from 'react';
import {connect} from "react-redux";
import {PropTypes} from "prop-types";
import {fetchPlayoutAssets, fetchVodDetails} from "../../actions/vodActions";
import VodUtils from "./VodUtils";
import Modal from "../../components/Modal";
import DetailImage from "../../components/DetailImage";
import If from "../../components/If";
import {Link} from "react-router-dom";
import {VodImageSizeEnum} from "./VodEnum";
import {VodButton} from "../../resources/styles";
import styled from "styled-components";

const VodTextDiv = styled.div`
    margin-bottom: 1rem;
`;

class VodDetail extends React.Component {

    componentDidMount() {
        if (this.props.match.params.id) {
            this.props.fetchPlayoutAssets(this.props.match.params.id);
            setTimeout(this.props.fetchVodDetails, 500, this.props.match.params.id);
        }
    }

    render() {

        if (!this.props.voddetails) {
            return false;
        }

        let durationMinutes = null;
        if (this.props.vodassets) {
            durationMinutes = VodUtils.getReadableDuration(this.props.vodassets.duration_sec);
        }

        let actorsString = "";
        if (this.props.voddetails.actors && this.props.voddetails.actors.length > 0) {
            for (let i = 0; i < this.props.voddetails.actors.length; i++) {
                if (actorsString !== "") {
                    actorsString += ", " + this.props.voddetails.actors[i].name;
                } else {
                    actorsString += this.props.voddetails.actors[i].name;
                }
            }
        }

        let directorHeader = "Director";
        if (this.props.voddetails.directors && this.props.voddetails.directors.length > 1) {
            directorHeader = "Directors";
        }

        let directorsString = "";
        if (this.props.voddetails.directors && this.props.voddetails.directors.length > 0) {
            for (let i = 0; i < this.props.voddetails.directors.length; i++) {
                if (directorsString !== "") {
                    directorsString += ", " + this.props.voddetails.directors[i].name;
                } else {
                    directorsString += this.props.voddetails.directors[i].name;
                }
            }
        }

        let description = "";
        if (this.props.voddetails.descriptions && this.props.voddetails.descriptions.long) {
            description = this.props.voddetails.descriptions.long;
        }

        const imageUrl = VodUtils.getPictureUrl(this.props.voddetails, VodImageSizeEnum.LARGE);

        const divButtonStyle = {
            marginTop: "1em"
        };

        return (
            <div className="grid-x grid-padding-x">
                <Modal show={this.props.isFetching}/>
                <div className="cell small-offset-2 small-8 medium-offset-0 medium-4 large-4">
                    <DetailImage
                        image={imageUrl}/>
                </div>
                <div className="cell small-12 medium-8 large-8 text-left">
                    <h1>
                        <div dangerouslySetInnerHTML={{__html: this.props.voddetails.title}}/>
                    </h1>
                    <If test={this.props.voddetails.rating}>
                        <div>
                            <b>Rated:</b> {this.props.voddetails.rating}<br/>
                        </div>
                    </If>
                    <If test={durationMinutes}>
                        <div>
                            <b>Run Time:</b> {durationMinutes}<br/>
                        </div>
                    </If>


                    <div style={divButtonStyle}>
                        <p>
                            <Link to={`/vod/${this.props.voddetails.id}`}>
                                <VodButton>
                                    Play
                                </VodButton>
                            </Link>
                        </p>
                    </div>

                    <If test={description}>
                        <VodTextDiv>
                            <h4>
                                Summary
                            </h4>
                            <div>
                                <div dangerouslySetInnerHTML={{__html: description}}/>
                            </div>
                        </VodTextDiv>
                    </If>

                    <If test={actorsString !== ""}>
                        <VodTextDiv>
                            <h4>
                                Actors
                            </h4>
                            <div>
                                <div dangerouslySetInnerHTML={{__html: actorsString}}/>
                            </div>
                        </VodTextDiv>
                    </If>


                    <If test={directorsString !== ""}>
                        <VodTextDiv>
                            <h4>
                                {directorHeader}
                            </h4>
                            <div>
                                <div dangerouslySetInnerHTML={{__html: directorsString}}/>
                            </div>
                        </VodTextDiv>
                    </If>

                </div>
            </div>

        )

    }

}

const mapStateToProps = state => {
    return {
        vodassets: state.voditem.vodassets,
        voddetails: state.voditem.voddetails,
    };
};

VodDetail.contextTypes = {
    t: PropTypes.func.isRequired
};


export default connect(mapStateToProps, {fetchPlayoutAssets: fetchPlayoutAssets, fetchVodDetails: fetchVodDetails}) (VodDetail);
