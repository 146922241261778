import React from 'react';
import {connect} from "react-redux";
import {fetchConsent} from "../../actions/photoMatchActions";
import browserHistory from "../../utils/history";
import {postToHubNew} from "../../actions/networkActions";
import {DefaultButton, DefaultButtonLink} from "../../resources/styles";



class Consent extends React.Component {
    componentDidMount() {
        if(this.props.consent === undefined) {
            fetchConsent();
        }
    }

    componentDidUpdate(prevProps) {
        // only trigger the redirect when consent changes from null to true (on initial giving of consent)
        if(prevProps.consent === null && this.props.consent === true) {
            browserHistory.replace("/photomatch/overview");
        }
    }


    optIn = () => {
        postToHubNew(`photo/passenger/consent/true`,{}, this.handleSuccess)
    }

    optOut = () => {
        postToHubNew(`photo/passenger/consent/false`,{}, this.handleSuccess)
    }

    handleSuccess = () => {
        fetchConsent();
    }


    render() {
        let permissionStatus;
        if (this.props.consent === null) {
            permissionStatus = "FIRST ACCESS";
        } else if (this.props.consent === true) {
            permissionStatus = "OPTED-IN";
        } else {
            permissionStatus = "OPTED-OUT";
        }

        if(this.props.consent === undefined) {
            return (
                <div>
                    Loading...
                </div>
            )
        }

        return (
            <div className="row">
                <h1>MY PHOTO: HOW IT WORKS</h1>
                <p>Freestyle Photos use facial recognition technology to match the candid photographs our team take around the ship to your security photo we took at check-in. We need your permission to do this, so if you’d like to see any photos we take during your cruise you’ll need to opt-in below. You can change this permission at any time during your cruise.</p>

                <h4>YOUR PERMISSION STATUS: {permissionStatus}</h4>

                {this.props.consent === null &&
                <div>
                    <p>
                        This is the first time you’ve accessed My Photo.
                    </p>
                    <p>
                        If you’d like to carry on and view your photographs, please select Accept and Proceed.
                    </p>
                    <div>
                        <DefaultButton onClick={this.optIn}>
                            Accept and Proceed
                        </DefaultButton>
                    </div>
                    <p>
                        If you’d prefer us not to match your photographs to your account, please select Opt Out.
                    </p>
                    <div>
                        <DefaultButton onClick={this.optOut}>
                            Opt Out
                        </DefaultButton>
                    </div>
                </div>
                }

                {this.props.consent !== null &&
                <div>
                    <p>
                        Welcome back.
                    </p>
                    <p>
                        To change your status, please select Change Status.
                    </p>
                    <DefaultButton onClick={this.props.consent ? this.optOut : this.optIn}>
                        Change Status
                    </DefaultButton>

                    {this.props.consent === true &&
                    <div>
                        <DefaultButtonLink to={"/photomatch/myphotos"}>
                                Go To My Photos
                        </DefaultButtonLink>
                    </div>
                    }
                </div>
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        consent: state.photomatch.consent,
    };
};

export default connect(mapStateToProps) (Consent);