import {
    ADD_VOD_MOVIE,
    CLEAR_VOD_AUTHENTICATION,
    CLEAR_VOD_ERROR,
    MAX_MOVIES_REACHED,
    REMOVE_FROM_WATCHLIST,
    REQUEST_FINISHED,
    REQUEST_STARTED,
    SET_DEVICE_UUID,
    SET_FETCHING,
    SET_SEARCHTERM_MOVIES,
    SET_SEARCHTERM_SERIES,
    SET_VOD_AUTHENTICATION,
    SET_VOD_AUTHENTICATION_CONTINUE_FETCHING,
    SET_VOD_ERROR,
    SET_VOD_GENRES,
    SET_VOD_MOVIES,
    SET_VOD_SERIE,
    SET_VOD_SERIES,
    SET_VOD_WATCHLIST,
    UPDATE_VOD_MOVIE,
    UPDATE_VOD_SERIES
} from "../constants/vod-action-types";

const INITIAL_STATE = {
    maxicastertoken: null,
    movies: [],
    genres: [],
    watchlistitems: [],
    serie: null,
    series: null,
    episodes: [],
    errormessage: null,
    isFetching: false,
    searchTermMovies: "",
    searchTermSeries: "",
    maxReached: false,
    deviceUuid: null
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_FETCHING:
            return { ...state, isFetching: action.isFetching}
        case REQUEST_STARTED:
            return { ...state, isFetching: true };
        case SET_VOD_AUTHENTICATION:
            return { ...state, maxicastertoken: action.maxicastertoken, isFetching: false };
        case SET_VOD_AUTHENTICATION_CONTINUE_FETCHING:
            return { ...state, maxicastertoken: action.maxicastertoken };
        case SET_DEVICE_UUID:
            return {...state, deviceUuid: action.deviceUuid};
        case SET_VOD_MOVIES:
            return { ...state, movies: action.movies, isFetching: false, maxReached: false };

        case SET_VOD_GENRES:
            return { ...state, genres: action.genres, isFetching: false};

        case SET_VOD_WATCHLIST:
            return { ...state, watchlistitems: action.watchlistitems, isFetching: false };

        case REMOVE_FROM_WATCHLIST:
            return { ...state,
                watchlistitems: state.watchlistitems.filter(
                    item => item.id !== action.watchlistitem.id
                ),
            };

        case ADD_VOD_MOVIE:
            return { ...state, movies: [...state.movies, action.movie], isFetching: false };

        case UPDATE_VOD_MOVIE:
            return { ...state,
                movies: state.movies.map(
                    (movie, i) => {
                        if (movie.id === action.movie.id) {
                            return action.movie;
                        } else {
                            return movie;
                        }
                    }
                )
            };

        case SET_VOD_SERIES:
            return { ...state, series: action.series, isFetching: false };

        case UPDATE_VOD_SERIES:
            return { ...state,
                series: state.series.map(
                    (serie, i) => {
                        if (serie.series_info.series_id === action.serie.series_info.series_id) {
                            return action.serie;
                        } else {
                            return serie;
                        }
                    }
                )
            };

        case SET_VOD_SERIE:
            return { ...state, serie: action.serie, isFetching: false };


        case REQUEST_FINISHED:
            return { ...state, isFetching: false };

        case MAX_MOVIES_REACHED:
            return { ...state, maxReached: true };

        case SET_SEARCHTERM_MOVIES:
            return { ...state, searchTermMovies: action.searchTermMovies };

        case SET_SEARCHTERM_SERIES:
            return { ...state, searchTermSeries: action.searchTermSeries };

        case SET_VOD_ERROR:
            return { ...state, isFetching: false, errormessage: action.errormessage, maxicastertoken: null };

        case CLEAR_VOD_ERROR:
            return { ...state, errormessage: null };
        case CLEAR_VOD_AUTHENTICATION:
            return INITIAL_STATE;
        default:
            return state;
    }
}
