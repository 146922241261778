import React from "react";
import {getTranslation} from "../../translations/translationUtils";
import {PropTypes} from "prop-types";
import {connect} from "react-redux";
import {fetchFromHub} from "../../actions/networkActions";
import styled from "styled-components";
import DetailImage from "../../components/DetailImage";

const OfferDiv = styled.div`
    background: ${props => props.theme.specialLayoutBackgroundColorOne || "#B2E7F8"};
    color: ${props => props.theme.specialLayoutColorOne || props.theme.bodyColor};
    ${ props => props.theme.alternateSingleColumnBackgroundColors && {
      background: props.index % 2 === 0 ? 
              (props.theme.specialLayoutBackgroundColorTwo || "rgb(0, 61, 105)") 
              : 
              (props.theme.specialLayoutBackgroundColorOne || "#B2E7F8"),
      color: props.index % 2 === 0 ? 
              (props.theme.specialLayoutColorTwo || "white") 
              : 
              (props.theme.specialLayoutColorOne || props.theme.bodyColor)
    }};
    padding: 0.5em 0.5em 1em;
    margin-bottom: 1em;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    min-height: 100%;
`;

class LayoutSingleColumnImage extends React.Component {
    constructor() {
        super();
        this.state = {
            category: null,
            items: [],
            itemsLoaded: false,
        };
    }

    componentDidMount() {
        let categoryId = "";

        if (this.props.category) {
            categoryId = this.props.category.id;
        }

        this.setState({type: categoryId});
        this.props.fetchFromHub(`cms/categories/findById/`+categoryId, this.postProcessCategory);
        this.props.fetchFromHub(`cms/articles/findByCategoryId/`+categoryId+`?withDescriptions=true`, this.updateItems);
    }

    postProcessCategory = (category) => {
        this.setState({category: category});
    };

    updateItems = (items) => {
        this.setState({
            itemsLoaded: true,
            items
        });
    };

    render() {
        const textStyle = {
            paddingLeft: 0,
            flexGrow: 1
        };

        let title = "";
        if (this.state.category) {
            title = getTranslation(this.state.category.title);
        }

        return (
            <div>
                <div className="row">
                    <div className="small-up-1 ">
                        <div className="column overview-item">
                            <h1 className="bold-header">{title}</h1>
                        </div>
                    </div>
                </div>

                <div className="small-up-1">
                    {
                        this.state.itemsLoaded && this.state.items.map((item, index) => {

                            const description = getTranslation(item.description);

                            return (
                                <div key={item.id}>
                                    <h3 className="specialoffer-header">{getTranslation(item.title)}</h3>
                                    <div className="specialofferitem">
                                        <div className="small-4 medium-4 specialofferimage">
                                            <DetailImage divAdditionalClasses={'specialofferimagemargin'} hideOnError={true} image={this.props.configuration.hubUrl + "blobs/image?reference=" + item.id + "&width=400"}/>
                                        </div>
                                        <div className="small-8 medium-8" style={textStyle}>
                                            <OfferDiv index={index}>
                                                <div dangerouslySetInnerHTML={{__html: description}}/>
                                            </OfferDiv>
                                        </div>
                                    </div>
                                </div>
                            )}
                        )
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        configuration: state.configuration,
    };
};

LayoutSingleColumnImage.contextTypes = {
    t: PropTypes.func.isRequired
};

export default connect(mapStateToProps, {fetchFromHub: fetchFromHub})(LayoutSingleColumnImage);