import React from 'react';
import { getResourceImageUrl } from '../../actions/networkActions';
import ImageWithFallback from "../ImageWithFallback";

class ContentHeaderImage extends React.Component {
    render() {
        return (
            <div className="container-2-1">
                <ImageWithFallback
                    className="container-2-1-img cover-fit"
                    alt={this.props.altText}
                    src={getResourceImageUrl(this.props.resourceId, {width: this.props.width, category: this.props.category})}
                />
                {this.props.children}
            </div>
        )
    }
}

export default ContentHeaderImage;