import React, {Component} from 'react';
import {PropTypes} from 'prop-types';

class Loading extends Component {
    render() {
        return (
            <div>
                <div className="animated fadeIn">
                    <div>
                        {this.context.t("loading")}
                    </div>
                </div>
            </div>
        )
    }
}

Loading.contextTypes = {
    t: PropTypes.func.isRequired
};


export default Loading;