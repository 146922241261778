import React, {useState} from 'react';
import {DefaultButton, DefaultLink} from "../../resources/styles";
import TextInputWithLabel from "../../components/TextInputWithLabel";
import {postToHubNew} from "../../actions/networkActions";

function Pin(props) {

    return (
        <div>
            <ChangePin />

            <div className="mt-8">
                <ResetPin />
            </div>

            <div className="mt-8">
                <DefaultLink to="/">Back to dashboard</DefaultLink>
            </div>
        </div>
    );
}

export default Pin;

function ChangePin(props) {
    const [newPin, setNewPin] = useState("");
    const [oldPin, setOldPin] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);

    const changePin = async () => {
        const re = /^[0-9]{4}$/;
        if (!re.test(oldPin) || !re.test(newPin)) {
            setError("Please make sure that both PINs are 4 digits");
            return;
        }

        const payload = {
            "currentPin": oldPin,
            "newPin": newPin,
        }

        const result = await postToHubNew("pms/changePin", payload);

        if (result === "") {
            setSuccess(true);
            setNewPin("");
            setOldPin("");
        } else {
            setError("There has been an error.")
        }
    }

    return (
        <>
            <h3>Change Your PIN Number</h3>
            <div>
                <div className="w-full sm:w-1/2 mt-4">
                    <TextInputWithLabel
                        label="Current PIN Number"
                        value={oldPin}
                        _onChange={value => setOldPin(value)}
                        placeholder="0101"
                    />
                </div>

                <div className="w-full sm:w-1/2 mt-4">
                    <TextInputWithLabel
                        label="New PIN Number"
                        value={newPin}
                        _onChange={value => setNewPin(value)}
                        placeholder="0101"
                    />
                </div>

                <div className="mt-4">
                    <DefaultButton onClick={changePin}>
                        Confirm
                    </DefaultButton>
                </div>

                {success &&
                <div className="mt-4">
                    PIN change successful - you can now log in using your new PIN number
                </div>
                }

                {error &&
                <div className="errors">
                    {error}
                </div>}
            </div>
        </>
    )
}

function ResetPin(props) {
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);

    const resetPin = async () => {
        const result = await postToHubNew("pms/resetPin", "");

        if (result === "") {
            setSuccess(true);
        } else {
            setError("There has been an error.")
        }
    }
    return (
        <>
            <h3>Reset Your PIN Number</h3>
            <div>
                <div>
                    Tap on the button below to reset your PIN number to default.
                </div>
                <div>
                    You will need to log in and change your PIN again.
                </div>
                <div className="mt-4">
                    <DefaultButton onClick={resetPin}>
                        Reset PIN
                    </DefaultButton>
                </div>
            </div>

            {success &&
            <div className="mt-4">
                PIN reset successful - you can now log in using your default PIN number
            </div>
            }

            {error &&
            <div className="errors">
                {error}
            </div>}
        </>
    )
}