import React, {Component} from 'react';
import browserHistory from "../utils/history";
import {getFromHubNew} from "../actions/networkActions";

class QRCodeArticle extends Component {

    componentDidMount() {
        const hash = this.props.match.params.hash;
        getFromHubNew(`cms/articles/findByHashtag?hashtag=${hash}`, this.handleRedirect);
    }

    handleRedirect = (response) => {
        if(response.length === 0) {
            browserHistory.replace("/")
        }
        else {
            browserHistory.replace('/content/detail/' + response[0].id)
        }
    }

    render() {
        return (
            <div>
                <div className="animated fadeIn">
                    <div className="row">
                        Loading...
                    </div>
                </div>
            </div>
        )
    }
}

export default QRCodeArticle;