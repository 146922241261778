import React from "react";
import {connect} from "react-redux";
import CurrencyUtils from "../../utils/currencyUtils";
import BasketImage from "./BasketImage";
import {createBulkItemForRemoval, extractImageUrl} from "../../utils/photoMatchUtils";
import {PhotomatchCartEntry} from "../../resources/styles";
import {removeFromCart} from "../../actions/photoMatchActions";

class PhotoMatchCartPackageEntry extends React.Component {

    _deleteItem = () => {
        removeFromCart(createBulkItemForRemoval(this.props.cart.totals.bulkName))
    };


    render() {

        return (
            <div className="my-4 relative">
                <PhotomatchCartEntry>
                    {this.props.bulkPackage &&
                    <div className="mr-4">
                        <BasketImage image={extractImageUrl(this.props.bulkPackage.image)}/>
                    </div>
                    }

                    <div>
                        {this.props.cart.totals.bulkName} (&pound; {CurrencyUtils.convertToCurrency(this.props.cart.totals.bulkTotal)})
                    </div>
                    <div className="absolute right-0 top-0 mr-4 mt-2">
                        <button className="cursor-pointer" type="button" onClick={this._deleteItem}>
                            <i className="fa fa-times text-lg" />
                        </button>
                    </div>

                </PhotomatchCartEntry>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        cart: state.photomatch.cart,
        bulkPackage: state.photomatch.config.bulk.find(element => element.id === state.photomatch.cart.totals.bulkName),
    };
};

export default connect(mapStateToProps)(PhotoMatchCartPackageEntry);