import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {fetchOrders} from "../../actions/photoMatchActions";
import currencyUtils from "../../utils/currencyUtils";
import {formatYMDtoDMY} from "../../utils/dateTimeUtils";

class Orders extends React.Component {

    componentDidMount() {
        fetchOrders();
    }

    render() {
        if (!this.props.photoMatchEnabled || !this.props.orders) {
            return false;
        }

        if(this.props.orders.length === 0) {
            return <div>
                There are currently no photo orders for your account
            </div>
        }


        return (
            <div>
                <h4>Your Photo Orders</h4>
                <table>
                    <thead>
                    <tr>
                        <th>Date</th>
                        <th className="sm-hidden">Reference</th>
                        <th>Status</th>
                        <th className="sm-hidden">Items</th>
                        <th>Amount</th>
                        <th>Download</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.props.orders.map((order) => {
                            return (<tr key={order.orderId} id={order.orderId}>
                                <td>{formatYMDtoDMY(order.date)}</td>
                                <td className="sm-hidden">{order.orderId}</td>
                                <td>{order.status.replace(/_/g, " ")}</td>
                                <td className="sm-hidden">{order.totals.grandCount}</td>
                                <td>&pound; {currencyUtils.convertToCurrency(order.totals.grandTotal)}</td>
                                <td>
                                    {order.digital ?
                                        this.props.deviceType === "Kiosk"  ?
                                            "Download not available on kiosk" :
                                            order.digital.statusNotReady ?
                                                "Please visit the photo team to get the download link" :
                                                <Link className="text-black underline" to={{pathname: "/photomatch/orderDetail", state: order}}>Go to downloads</Link>
                                        :
                                        ""
                                    }
                                </td>
                            </tr>)
                        })
                    }
                    </tbody>
                </table>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        orders: state.photomatch.orders,
        deviceType: state.configuration.hubSettings.deviceType,
        photoMatchEnabled: state.configuration?.hubSettings?.uiSettings?.photoMatchEnabled,
    };
};

export default connect(mapStateToProps)(Orders);