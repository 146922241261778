import React from "react";
import {Link} from 'react-router-dom'
import {connect} from 'react-redux';
import {PropTypes} from 'prop-types';
import {formatBasketDate} from '../../utils/dateTimeUtils';
import CruisesOverview from '../../components/CruisesOverview';
import ItineraryHotels from '../../components/ItineraryHotels';
import {getTranslation} from '../../translations/translationUtils';
import {flattenAndSortExcursions, cartContainsCruiseExcursions, cartContainsResortExcursions} from '../../utils/cartUtils';

class BookingConfirmation extends React.Component {
    render() {
        const confirmationMessage = this.props.location.state.paymentMethod === "Card" ? this.context.t("confirmation_thank_you_card") : this.context.t("confirmation_thank_you_cash");
        const paymentMethodText = this.props.location.state.paymentMethod === "Card" ? this.context.t("basket_payment_card") : this.context.t("basket_payment_cash");

        return (
            <div className="checkout-container">
                <h1>{this.context.t("confirmation_header")}</h1>

                {cartContainsCruiseExcursions(this.props.location.state.excursions) &&
                <div className="margin-vertical-1">
                    {this.context.t("confirmation_cruise_excursion_text")}
                </div>
                }

                {cartContainsResortExcursions(this.props.location.state.excursions) &&
                <div className="margin-vertical-1">
                    <div>
                        {this.context.t("confirmation_payment_method")}{" "}
                        <span className="font-bold text-uppercase">
                            {paymentMethodText}
                        </span>
                    </div>
                    <div>
                        {confirmationMessage}
                    </div>
                </div>
                }

                <div className="my-2">
                    <div>
                        {this.context.t("confirmation_booking_reference")} {this.props.location.state.hubResponse.groupReference}
                    </div>

                    <div>
                        {this.context.t("confirmation_email")} {this.props.location.state.confirmationMail}
                    </div>

                    {this.props.location.state.mobileNumber &&
                    <div>
                        {this.context.t("confirmation_mobile_number")} {this.props.location.state.mobileNumber}
                    </div>}

                    {this.props.leadPassenger &&
                    <div>
                        {this.context.t("confirmation_lead_passenger")} {this.props.leadPassenger}
                    </div>}
                </div>

                <CruisesOverview addTitleBorder={true}/>
                <ItineraryHotels addTitleBorder={true}/>


                <div className="text-2xl border-b text-uppercase margin-vertical-1">{this.context.t("confirmation_your_excursions")}</div>
                {flattenAndSortExcursions(this.props.location.state.excursions).map(excursion =>
                    <BookingConfirmationEntry key={excursion.excursionId} excursion={excursion} />
                )}


                <div className="text-2xl text-right padding-horizontal-05 font-bold">
                    {this.context.t("confirmation_total_cost")} £{this.props.location.state.total}
                </div>

                <div className="margin-vertical-1">
                    {this.context.t("confirmation_my_excursions_pretext")}{" "}
                    <Link className="text-underline" to="/my_bookings">{this.context.t("confirmation_my_excursions")}</Link>
                </div>
            </div>
        )
    }
}

BookingConfirmation.contextTypes = {
    t: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    const leadPassengers = state.itinerary.passengers.filter((passenger) => passenger.lead === true);
    const leadPassenger = leadPassengers && leadPassengers.length > 0 ?
        `${leadPassengers[0].firstName} ${leadPassengers[0].lastName}` :
        "";

    return {
        leadPassenger
    }
};

export default connect(mapStateToProps) (BookingConfirmation)

class BookingConfirmationEntry extends React.Component {
    render() {
        return (
            <div className="margin-vertical-1 padding-horizontal-05">
                <div className="border-b">
                    <div>
                        {getTranslation(this.props.excursion.excursionName)}
                    </div>

                    <div>
                        {getTranslation(this.props.excursion.portName)}
                    </div>

                    <div>
                        {formatBasketDate(this.props.excursion.date)}
                    </div>

                    {this.props.excursion.passengers.map(passenger => {
                        return (
                            <div key={passenger.id}>
                                {`${passenger.firstName} ${passenger.lastName}`}
                            </div>
                        );
                    })}

                </div>

                <div className="font-bold text-right">
                    {this.context.t("confirmation_excursion_cost")} £{this.props.excursion.price}
                </div>
            </div>
        );
    }
}

BookingConfirmationEntry.contextTypes = {
    t: PropTypes.func.isRequired
};