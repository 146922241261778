import React from "react";
import If from "./If";

export default class AudioComponent extends React.Component {


    render() {
        let audioItems = this.props.audioItems;
        if (!audioItems) {
            return false;
        }

        return (
            <If test={this.props.audioItems && this.props.audioItems.length > 0}>
                <div>
                    {
                        this.props.audioItems.map((audioItem) => {
                            let title = "";
                            if (audioItem.name && audioItem.name.length > 0) {
                                title = audioItem.name.substring(0, audioItem.name.lastIndexOf("."));
                            }
                            return (
                                <div key={audioItem.id} className="cell small-12 medium-8 large-8 text-left">
                                    <div className="audio-description">{title}:</div>
                                    <audio controls
                                           src={audioItem.absoluteUrl}
                                    >
                                        Your browser does not support the <code>audio</code> element.
                                    </audio>
                                </div>
                            )
                        })
                    }
                </div>
            </If>
        )
    }
}