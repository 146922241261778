import React from "react";
import {connect} from "react-redux";
import {DefaultButtonLink} from "../../resources/styles";
import {extractImageUrl} from "../../utils/photoMatchUtils";
import PhotoMatchPackage from "../../components/photomatch/PhotoMatchPackage";

class PhotoMatchPackages extends React.Component {
    render() {
        return (
            <div className="row">
                <div>
                    <DefaultButtonLink to="/photomatch/myphotos">My photos</DefaultButtonLink>
                </div>

                <h3 className="text-bold">Photo packages</h3>

                {
                    this.props.config.bulk.map((photoPackage) => {
                        return (<PhotoMatchPackage package={photoPackage} key={photoPackage.id} showButtons={true}/>)
                    })
                }


                <h3 className="text-bold">Special offers</h3>
                {
                    this.props.config.photoGroups.map((offer) => {
                        return (<PhotoMatchOffer offer={offer} key={offer.id} />)
                    })
                }
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        config: state.photomatch.config
    };
};

export default connect(mapStateToProps)(PhotoMatchPackages);



function PhotoMatchOffer(props) {
    const offer = props.offer;

    return (
        <div key={offer.id} className="mb-1 grid-x grid-padding-x grid-padding-y">
            <div className="cell medium-4 large-3">
                {offer.image &&
                <img alt="special offer" src={extractImageUrl(offer.image)}/>
                }
            </div>

            <div className="cell medium-8 large-9">
                <h4>{offer.blurb}</h4>
                <div>{offer.offBlurb}</div>
                <div>{offer.description}</div>
            </div>
        </div>
    )
}