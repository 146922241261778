export const SET_FETCHING = "SET_FETCHING";
export const REQUEST_STARTED = "REQUEST_STARTED";
export const SET_VOD_AUTHENTICATION = "SET_VOD_AUTHENTICATION";
export const SET_VOD_AUTHENTICATION_CONTINUE_FETCHING = "SET_VOD_AUTHENTICATION_CONTINUE_FETCHING";
export const CLEAR_VOD_AUTHENTICATION = "CLEAR_VOD_AUTHENTICATION";
export const SET_DEVICE_UUID = "SET_DEVICE_UUID";
export const SET_VOD_MOVIES = "SET_VOD_MOVIES";
export const ADD_VOD_MOVIE = "ADD_VOD_MOVIE";
export const UPDATE_VOD_MOVIE = "UPDATE_VOD_MOVIE";

export const SET_VOD_GENRES = "SET_VOD_GENRES";

export const SET_VOD_WATCHLIST = "SET_VOD_WATCHLIST";
export const REMOVE_FROM_WATCHLIST = "REMOVE_FROM_WATCHLIST";

export const SET_VOD_SERIES = "SET_VOD_SERIES";
export const UPDATE_VOD_SERIES = "UPDATE_VOD_SERIES";

export const SET_VOD_SERIE = "SET_VOD_SERIE";
export const UPDATE_VOD_SERIE = "UPDATE_VOD_SERIE";

export const REQUEST_FINISHED = "REQUEST_FINISHED";
export const MAX_MOVIES_REACHED = "MAX_MOVIES_REACHED";
export const SET_VOD_ERROR = "SET_VOD_ERROR";
export const CLEAR_VOD_ERROR = "CLEAR_VOD_ERROR";
export const SET_SEARCHTERM_MOVIES = "SET_SEARCHTERM_MOVIES";
export const SET_SEARCHTERM_SERIES = "SET_SEARCHTERM_SERIES";