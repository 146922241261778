import React from "react";
import uuid from "uuidv4";

function Dropdown(props) {
    const selectUuid = uuid();
    return (
        <div className="small-12 medium-8 text-left">
            <label htmlFor={selectUuid} className="text-base">
                {props.isRequired && <span>* </span>}{props.title}
            </label>
            <select
                id={selectUuid}
                className={`tui_ValueSelector ${props.error ? "feedbackValueSelectorMissing" : ""}`}
                value={props.selectedOption}
                onChange={(e) => props.handleChange(e.target.value)}
                disabled={props.disabled}>
                <option key="-1" value="" disabled>
                    {props.pleaseSelectLabel}
                </option>
                {props.options.map(option =>
                    <option key={option.value} value={option.value}>{option.label}</option>
                )}
            </select>
            {props.error &&
            <div className="feedbackMandatoryMissing">
                {props.error}
            </div>
            }
        </div>

    )
}

export default Dropdown;