import React from 'react';
import {connect} from 'react-redux';
import {PropTypes} from "prop-types";
import Modal from "../../components/Modal";
import {loginByPms} from "../../actions/authenticationActions";
import If from "../../components/If";

class RetinaLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showError: false,
            errors: {},
        };
    }

    componentDidMount() {
        const token = this.props.match.params.token;
        const id = this.props.match.params.id;
        const room = this.props.match.params.room;
        if (!token && (!id || !room )) {
            //TODO: push to whereever TUI wants
            console.log("Token NOT provided");
            return;
        }

        this._onLogin(token, id, room);
    }

    async _onLogin(token, id, room) {
        const validPassenger = await this.props.loginByPms(token, id, room);
        if (validPassenger) {
            this.props.history.replace('/retina/loginsuccess');
        }
        else {
            this.setState({showError: true});

            let errors = {};
            this.setState({errors: errors,});
        }
    }

    render() {

        if (this.state.showError) {

            return (

                <div>
                    <Modal show={this.props.isFetching}/>

                    <div className="grid-x grid-padding-x align-center">
                        <div className="cell small-10 medium-4 large-4 text-left">
                            <div className="margin-top-05 text-left errors"
                                 data-closable>{this.context.t("loginFailedRetina")}
                                 <If test={this.props.authentication.errorMessage}>
                                     {this.props.authentication.errorMessage}
                                 </If>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }

}

RetinaLogin.contextTypes = {
    t: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    return {
        authentication: state.authentication,
    };
};


export default connect(mapStateToProps, {loginByPms}) (RetinaLogin);