import React from "react";
import {getTranslation} from "../../translations/translationUtils";
import {PropTypes} from "prop-types";
import {connect} from "react-redux";
import {fetchFromHub} from "../../actions/networkActions";
import styled from "styled-components";

const NewsDiv = styled.div`
    width: 99.5%;
    padding-bottom: 2%;
    border-bottom: 1.25px solid rgba(10, 10, 10, 0.1);
    margin-bottom: 3%;
`;

class LayoutSimple extends React.Component {
    constructor() {
        super();
        this.state = {
            category: null,
            items: []
        };
    }

    componentDidMount() {
        let categoryId = "";

        if (this.props.category) {
            categoryId = this.props.category.id;
        }

        this.setState({type: categoryId});
        this.props.fetchFromHub(`cms/categories/findById/`+categoryId, this.postProcessCategory);
        this.props.fetchFromHub(`cms/articles/findByCategoryId/`+categoryId+`?withDescriptions=true`, this.postProcess);
    }

    postProcessCategory = (category) => {
        this.setState({category: category});
    };

    postProcess = (items) => {
        this.setState({items: items});
    };

    render() {

        let title = "";
        if (this.state.category) {
            title = getTranslation(this.state.category.title);
        }


        return (
            <div>
                    <div className="row">
                        <div>
                            <div className="column overview-item">
                                <h1 className="bold-header">{title}</h1>
                            </div>
                        </div>
                    </div>

                        <div>
                            {
                                this.state.items.map((items) => {

                                        return (
                                                <div key={items.id} >
                                                    <div>
                                                        <div>
                                                            <NewsDiv className="news-item">
                                                                <h3 className="bold-header">{getTranslation(items.title)}</h3>
                                                                <div dangerouslySetInnerHTML={{__html: getTranslation(items.description)}}/>
                                                            </NewsDiv>
                                                        </div>
                                                    </div>
                                                </div>
                                        )
                                    }
                                )
                            }
                        </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        configuration: state.configuration,
    };
};

LayoutSimple.contextTypes = {
    t: PropTypes.func.isRequired
};

export default connect(mapStateToProps, {fetchFromHub: fetchFromHub})(LayoutSimple);