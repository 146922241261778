import {SET_CART} from "../constants/cart-action-types";

const INITIAL_STATE = {totalPrice: 0, nrExcursions: 0, excursions: null};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_CART:
            return { ...state,
                totalPrice: action.cart.totalPrice,
                nrExcursions: action.cart.nrExcursions,
                excursions: action.cart.excursions
            };

        default:
            return state;
    }
}
