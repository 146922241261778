import React, {useEffect, useRef, useState} from 'react';
import {getFromHubNew, postToHubNew} from "../../actions/networkActions";
import {store} from "../../App";
import {SET_AUTHENTICATION} from "../../constants/authentication-action-types";
import styled from 'styled-components';
import {DefaultButtonLink} from "../../resources/styles";
import Loading from "../../components/Loading";
import {useSelector} from "react-redux";
import DownIcon from "../../resources/images/kiosk_login/DownIcon";
import RightIcon from "../../resources/images/kiosk_login/RightIcon";

const KioskLoginBody = styled.div`
    display: flex;
    height: 80vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const CenteredContainer = styled.div`
    width: 100%;
    background-color: ${ props => props.theme.wayfinderBackground || "#71caf2" };
    padding: 2.5rem;
    box-shadow: ${props => props.boxShadow || props.theme.boxShadow || "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"};
    border: ${props => props.tileBorder || props.theme.tileBorder || "solid 1px lightgray"};    
`;

const Columns = styled.div`
    display: flex;
    justify-content: space-around;
`;

const StepColumn = styled.div`
    text-align: center;
    font-size: 1.6rem;
    font-weight: bold;
    width: 100%;
    img {
        width: 340px;
        height: 220px;
    }
`;

const ErrorMessage = styled.div`
    font-size: 1.5rem;
    font-weight: bold;
    color: red;    
    padding: 1rem;
    text-align: center;
`;

const KioskLoginFooter = styled.div`
    position: fixed;
    bottom: 1rem;
`;

const DownArrowContainer = styled.div`
    position: fixed;
    left: 50%;
    bottom: 1rem;
    transform: translateX(-50%);
`;

function RescoKioskLogin(props) {
    const accountId = useRef("");
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState("");

    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);

    const hubBaseUrl = useSelector(state => state.configuration.hubUrl.replace(/\/$/, ""));

    useEffect(() => {
        const fetchImagesList = async (articleId) => {
            const articleImages = await getFromHubNew(`blobs/images/${articleId}`);
            setImages(articleImages);
        }

        const fetchData = async () => {
            const articles = await getFromHubNew("cms/articles/findByHashtag?hashtag=loginFormKiosk");
            if(articles.length === 0) {
                setLoading(false);
                return;
            }

            await fetchImagesList(articles[0].id);

            setLoading(false);
        }

        fetchData();
    }, []);



    useEffect(() => {
        async function submit() {
            if(submitting) {
                return;
            }

            const payload = {
                "swipe": true,
                "accountNumber": accountId.current,
            };

            setSubmitting(true);
            setError("");

            const result = await postToHubNew('authentication/loginByAccountId', payload);

            setSubmitting(false);

            if(result) {
                store.dispatch({type: SET_AUTHENTICATION, authentication: result});
            }
            else {
                setError("Login failed: we couldn't find your account number. Please speak to reception.");
            }
            accountId.current = "";
        }

        function onKeyup(e) {
            // submit on enter, append to accountId otherwise
            if (e.keyCode === 13) {
                submit();
            }
            else {
                accountId.current += e.key;
            }
        }

        // key up listener
        window.addEventListener('keyup', onKeyup);

        // cleanup of key up listener
        return () => window.removeEventListener('keyup', onKeyup);
    }, [submitting]);

    if(loading) {
        return (
            <Loading/>
        )
    }

    return (
        <div>
            <KioskLoginBody>
                    <CenteredContainer>
                        <h1 className="text-center">Please log in by scanning your card to continue</h1>
                        <Columns>
                            <StepColumn>
                                <h2>Step 1</h2>
                                <p>Find the barcode on your ship card</p>
                                {images.length > 0 ?
                                    <img alt="Ship card" src={`${hubBaseUrl}${images[0].url}&width=400`}/> :
                                    <img alt="Ship card" src={require("../../../src/resources/images/kiosk_login/guestcard.png")}/>
                                }
                            </StepColumn>
                            <RightIcon />
                            <StepColumn>
                                <h2>Step 2</h2>
                                <p>Scan it below the screen</p>
                                {images.length > 1 ?
                                    <img className="thumbnail" alt="Ship card" src={`${hubBaseUrl}${images[1].url}&width=400`}/> :
                                    <img className="thumbnail" alt="Scan card" src={require("../../../src/resources/images/kiosk_login/scancard.jpg")}/>
                                }
                            </StepColumn>
                        </Columns>
                    </CenteredContainer>
                    {error &&
                        <ErrorMessage>
                            {error}
                        </ErrorMessage>
                    }
            </KioskLoginBody>
            <KioskLoginFooter>
                <DefaultButtonLink to="/">Return to Home</DefaultButtonLink>
                <DownArrowContainer>
                    <DownIcon />
                </DownArrowContainer>
            </KioskLoginFooter>
        </div>
    );
}

export default RescoKioskLogin;