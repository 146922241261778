import React from 'react';
import {connect} from "react-redux";
import {PropTypes} from 'prop-types';
import OverviewImageButton from "../../components/OverviewImageButton";
import OverviewImageButtonPanel from "../../components/OverviewImageButtonPanel";
import {fetchFromHub} from "../../actions/networkActions";
import Loading from "../../components/Loading";

class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            images: [],
        }
    }

    componentDidMount() {
        this.props.fetchFromHub('cms/articles/findByHashtag?hashtag=musteringTiles', this.updateArticle);
    }

    updateArticle = (response) => {
        if(response.length === 0) {
            this.setState({
                loading: false,
            });

            return;
        }

        const articleId = response[0].id;

        this.props.fetchFromHub(`blobs/images/${articleId}`, this.updateImages);
    }

    updateImages = images => {
        this.setState({
            images,
            loading: false,
        });
    }

    render() {
        const nrImages = this.state.images.length;
        const videoThumbnail = nrImages > 0 ? `${this.props.hubUrl}${this.state.images[0].url.substring(1)}&width=400` : "";
        const statusThumbnail = nrImages > 1 ? `${this.props.hubUrl}${this.state.images[1].url.substring(1)}&width=400` : "";
        const scheduleThumbnail = nrImages > 2 ? `${this.props.hubUrl}${this.state.images[2].url.substring(1)}&width=400` : "";


        if(this.state.loading) {
            return <Loading/>
        }

        return (
            <OverviewImageButtonPanel>
                <OverviewImageButton
                    link="/muster/video"
                    title={this.context.t("tile_video")}
                    thumbnail={videoThumbnail}
                />

                <OverviewImageButton
                    link="/muster/status"
                     title={this.context.t("tile_status")}
                     thumbnail={statusThumbnail}
                />

                {nrImages > 2 &&
                <OverviewImageButton
                    link="/muster/schedule"
                    title={this.context.t("tile_schedule")}
                    thumbnail={scheduleThumbnail}
                />
                }

            </OverviewImageButtonPanel>
        );
    }
}

Home.contextTypes = {
    t: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        hubUrl: state.configuration.hubUrl,
    };
};

export default connect(mapStateToProps, {fetchFromHub})(Home);