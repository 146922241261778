import ReactGA from 'react-ga';
import {store} from "../App";

export function recordPageview(location, title = "") {
    const gaCode = store.getState().configuration.gaCode;

    // if no tracking code has been set don't do anything as the tracking will not be initialized
    if(!gaCode) {
        return;
    }

    if(title) {
        ReactGA.pageview(location, [], title);
    }
    else {
        ReactGA.pageview(location);
    }

}