import React from 'react';
import styled from 'styled-components';
import {getFeatureImageFilename} from "../../utils/musementUtils";

const StyledFeature = styled.div`
    display: flex;
    padding: 0.25rem;
    align-items: center;
    
    
    img {
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
    }
`;

function Feature(props) {
    return (
        <StyledFeature>
            <img src={require('../../resources/images/musement/feature_icons/' + getFeatureImageFilename(props.code))} alt="" />
            <span>{props.name}</span>
        </StyledFeature>
    );
}

export default Feature;