import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import {fetchSideView, fetchTopViews} from "../utils/wayfinderUtils";
import CabinFinderForm from "./CabinFinderForm";

const Container = styled.div`
    position: relative;
    background-color: ${ props => props.theme.wayfinderBackground || "#71caf2" };
    margin: 1rem 0.9375rem;
    padding: 1.5rem 1rem;
    border: ${ props => props.theme.wayfinderBorder || "1px solid #cacaca" };
    border-radius: ${ props => props.theme.wayfinderBorderRadius || "0" };
    box-shadow: ${ props => props.theme.wayfinderBoxShadow || "none" };
    
    .deck-select {
        margin-top: 16px;
        display: flex;
        align-items: center;
    }
    
    .deck-markers {
        position: absolute;
        bottom: 4px; 
        display: flex;
        gap: 4px;
    }
    
    .active {
        border: 2px solid ${ props => props.theme.activeButtonBorderColor || "white" };
    }
    
    .cabin-finder {
        margin-top: 1rem;
        text-align: center;
    }
    
    .cabin-finder-toggle {
        display: inline-block;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    
    .legend {
        position: absolute;
        right: 0;
        display: flex;
    }
    
    .legend-entry {
        margin-right: 24px;
        background: ${ props => props.theme.specialLayoutBackgroundColorOne || "#dbf1fb" };
        color: ${ props => props.theme.specialLayoutColorOne || "white" };
        padding: 4px 8px;
        display: flex;
        gap: 8px;
        align-items: center;
        font-size: 0.75rem;
    }
    
    
`;

const MarkerDot = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${props => props.destination ? "orange" : "red"};
`

const DeckButton = styled.button`
    flex:1;
    display:flex;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
    border-radius: 8px;
    margin: 0 10px;
    background: ${ props => props.theme.specialLayoutBackgroundColorOne || "#dbf1fb" };
    color: ${ props => props.theme.specialLayoutColorOne || "white" };
    font-weight: bold;
`;


function DSWayfinder(props) {
    const {wayfinderProfilesString} = useSelector(state => {return {
        wayfinderProfilesString: state.wayfinder.profiles.length > 0 ?
            "?wayfinderProfileId=" + state.wayfinder.profiles.join("&wayfinderProfileId=") :
            "",
    }});

    const [activeDeck, setActiveDeck] = useState("1");
    const [showCabinFinder, setShowCabinFinder] = useState(false);
    const [topViews, setTopViews] = useState(null);
    const [sideView, setSideView] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");

    // load deckplans on mount
    useEffect(() => {
        async function getTopViews() {
            const topViews = await fetchTopViews(`wayfinder/maps/topview/marker${wayfinderProfilesString}`);

            setActiveDeck(topViews.activeDeck);
            setTopViews(topViews.data);
        }

        async  function getSideView() {
            const sideView = await fetchSideView(`wayfinder/maps/sideview/marker${wayfinderProfilesString}`);
            setSideView(sideView);
        }

        setErrorMessage("");
        getSideView();
        getTopViews();
    }, [wayfinderProfilesString]);


    const searchForCabin = async (search) => {
        async function getTopViews() {
            const topViews = await fetchTopViews(`cabinfinder/topview/${search}`);

            if(topViews) {
                setActiveDeck(topViews.activeDeck);
                setTopViews(topViews.data);
            }
            else {
                setErrorMessage("The cabin could not be found, please search for another cabin");
            }
        }

        async function getSideView() {
            const sideView = await fetchSideView(`cabinfinder/sideview/${search}`);
            if(sideView) {
                setSideView(sideView);
            }
            else  {
                setErrorMessage("The cabin could not be found, please search for another cabin");
            }
        }

        setErrorMessage("");
        getSideView();
        getTopViews();
    }

    if(topViews === null || sideView === null) {
        return null;
    }

    return (
        <Container>
            <div className="legend">
                <div className="legend-entry">
                    <MarkerDot />
                    <div>You are here</div>
                </div>
                <div className="legend-entry">
                    <MarkerDot destination={true}/>
                    <div>Destination(s)</div>
                </div>
            </div>
            {sideView &&
            <div style={{textAlign: "center"}} >
                <img alt="wayfinder side view" src={`data:image/png;base64,${sideView.map}`}/>
            </div>
            }
            {topViews &&
            <>
                <div className="deck-select">
                    <span style={{fontWeight: "bold"}}>Deck plan:</span>
                    {Object.keys(topViews).map(level =>
                        <DeckButton key={level}
                            className={`${level===activeDeck ? "active" : ""}`}
                            onClick={() => setActiveDeck(level)}
                        >
                            {level}
                            <div className="deck-markers">
                                {topViews[level].hasWayFinding && <MarkerDot destination={true}/>}
                                {topViews[level].isDevicePresent && <MarkerDot/> }
                            </div>
                        </DeckButton>
                    )}
                </div>

                <div className="cabin-finder">
                    <DeckButton className="cabin-finder-toggle" onClick={() => setShowCabinFinder(!showCabinFinder)}>
                        Cabin Finder
                    </DeckButton>
                    {errorMessage &&
                    <div className="errors mt-4">
                        {errorMessage}
                    </div>
                    }
                    {showCabinFinder &&
                        <CabinFinderForm search={searchForCabin}/>
                    }
                </div>

                <div style={{textAlign: "center"}} className="mt-4">
                    <img src={`data:image/png;base64,${topViews[activeDeck].map}`} alt="wayfinder top view"/>
                </div>
            </>
            }
        </Container>
    );
}

export default DSWayfinder;