import React from 'react';
import styled from 'styled-components';

const StyledAttribute = styled.div`
    display: flex;
    padding: 0.25rem;
    align-items: center;
    
    
    img {
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
    }
`;

class ExcursionAttributes extends React.Component {
    render() {
        if(this.props.icon) {
            return (
                <div>
                    {this.props.attributes.map((attribute, index) => (
                        <StyledAttribute key={index}>
                            <img src={require(`../resources/images/musement/${this.props.icon}`)} alt="" />
                            <span>{attribute}</span>
                        </StyledAttribute>)
                    )}
                </div>
            )
        }

        return (
            <ul>
                {this.props.attributes.map((attribute, index) => {
                    if(!attribute) return null;
                    
                    return(
                        <li key={index}>
                            {attribute}
                        </li>
                    );
                }
                )}
            </ul>
        )
    }
}

export default ExcursionAttributes;