import React from 'react';
import {RadioWrapper} from "../resources/styles";

class RadioButton extends React.Component {

    toggleRadioChange = () => {
        this.props._onChange();
    };

    render()
    {
        return (
            <RadioWrapper>
                <label className={"flex inline-flex items-center"+ (this.props.isDisabled ? " disabled" : "")}>
                    <input
                        type="radio"
                        value={this.props.label}
                        onChange={this.toggleRadioChange}
                        checked={this.props.isSelected}
                        disabled={this.props.isDisabled}
                    />
                    <span className="radio-indicator"></span>
                    {this.props.label}
                </label>
            </RadioWrapper>
        )
    }
}

export default RadioButton;