import React, {useEffect, useState} from 'react';
import {PropTypes} from "prop-types";
import {useSelector} from "react-redux";
import browserHistory from "../../utils/history";
import Dropdown from "../../components/Dropdown";
import TextInputWithLabel from "../../components/TextInputWithLabel";
import {getFromHubNew, postToHubNew} from "../../actions/networkActions";
import {getTranslation} from "../../translations/translationUtils";
import {DefaultButton} from "../../resources/styles";


function ProblemForm(props, context) {
    const [categories, setCategories] = useState([]);

    // TODO - this should be made pms agnostic before in use with all clients
    const informationObject = JSON.parse(useSelector(state => state.authentication?.pms?.information || '{"location":""}'));
    const cabinNumber = informationObject.location;

    useEffect(() => {
        const fetchCategories = async () => {
            const problemCategories = await getFromHubNew("problem/categories");
            setCategories(problemCategories);
        }

        fetchCategories();
    }, []);

    const [problemDescription, setProblemDescription] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [validationError, setValidationError] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const [savedProblem, setSavedProblem] = useState(null);

    if(categories.length === 0) {
        return null;
    }

    const handleSubmit = async () => {
        if(selectedCategory === "") {
            setValidationError("Category is mandatory, please select it and resubmit.");
            return;
        }

        if(problemDescription === "") {
            setValidationError("Problem description is mandatory, please add it and resubmit.");
            return;
        }

        const request = {
            description: problemDescription,
            category: {
                id: selectedCategory
            }
        };
        setSubmitting(true);

        const result = await postToHubNew('problem/report', request);

        setSubmitting(false);

        // TODO - handle error cases that might happen once they are known and have a better check for success
        if(result) {
            setSavedProblem(result);
        }
        else {
            setValidationError("There has been an issue with submitting your problem, please try again");
        }
    }
    
    if(savedProblem) {
        return <SavedProblem problem={savedProblem} />
    }

    return (
        <div>
            <h1>Report A Problem</h1>
            <p>Please fill out the form below in as much detail as possible so that we can assist you with your problem.</p>
            <div className="grid-x">
                <div className="cell small-10 medium-4 large-4 text-left">
                    <div className="my-2">
                        <TextInputWithLabel
                            label={context.t("problem_cabin_label")}
                            labelClassNames="text-base"
                            value={cabinNumber}
                            disabled={true}
                            _onChange={() => {}}
                        />
                    </div>

                    <div className="my-2">
                        <Dropdown
                            title={context.t("problem_category_label")}
                            isRequired={true}
                            disabled={false}
                            pleaseSelectLabel="Please select"
                            handleChange={setSelectedCategory}
                            selectedOption={selectedCategory}
                            options={categories.map(category => ({
                                    value: category.id,
                                    label:getTranslation(category.name)
                                })
                            )}
                        />
                    </div>

                    <div className="my-2">
                        <label className="text-base" htmlFor="problem-description">*{context.t("problem_description_label")}</label>
                        <textarea
                            id="problem-description"
                            rows="5"
                            cols="20"
                            placeholder={context.t("problem_description_placeholder")}
                            value={problemDescription}
                            onChange={e => {
                                if(e.target.value.length < 500) {
                                    setProblemDescription(e.target.value);
                                }
                            }}
                        />
                    </div>

                    {validationError &&
                    <div className="my-2 errors">
                        {validationError}
                    </div>}

                    <div className="my-2">
                        <DefaultButton onClick={handleSubmit} disabled={submitting}>
                            {context.t("problem_submit_label")}
                        </DefaultButton>
                    </div>
                </div>
            </div>
        </div>
    );
}
ProblemForm.contextTypes = {
    t: PropTypes.func.isRequired,
}

export default ProblemForm;

function SavedProblem(props) {
    return (
        <div>
            <div className="my-4">
                <h2>Problem Details Received</h2>
                <p>We have received the details of your problem and will be in contact to assist you.</p>
            </div>

            <div className="my-4">
                <ul className="problemReceived">
                    <li>Problem - {props.problem.problemCategory.category} Issue - ID  {props.problem.problemNumber}</li>
                    <li>Received at:  {props.problem.receivedDateTime}</li>
                    <li>Status: {props.problem.status}</li>
                </ul>
            </div>

            <div className="my-4">
                <span>Problem Description</span>
                <p>{props.problem.description}</p>
            </div>

            <div className="my-4">
                <p>Please check for updates via:</p>
                <DefaultButton
                    onClick={() => {browserHistory.push('/problem/tracking');}}>
                    Problem Tracking
                </DefaultButton>
            </div>
        </div>
    )
}