import {SET_CLIENTINFO} from "../constants/client-action-types"

export default function (state = [], action) {
    switch (action.type) {
        case SET_CLIENTINFO:
            return action.payload;
        default:
            return state;
    }
}
