import * as React from "react";
import {connect} from "react-redux";
import Modal from "../../../components/Modal";
import OverviewImageButtonPanel from "../../../components/OverviewImageButtonPanel";
import VodUtils from "../VodUtils";
import {fetchWatchlistFromMaxicaster, removeFromWatchlist, watchlistRemove} from "../../../actions/vodActions";
import {PropTypes} from "prop-types";
import VodImageButton from "../../../components/VodImageButton";

class OverviewWatchlist extends React.Component {

    componentDidMount() {
        this.search();
    }

    async search() {
        this.props.fetchFromMaxicaster();
    }

    async watchlistRemove(watchlistItem) {
        //TODO: check if VoD or series
        const removeSuccess = await this.props.watchlistRemove(watchlistItem.id, null);
        if (removeSuccess) {
            this.props.removeFromWatchlist(watchlistItem);
            // this.props.fetchFromMaxicaster();
        }
    };


    render() {

        if (! this.props.watchlistItems) {
            return false;
        }


        if (!this.props.isFetching && this.props.watchlistItems.length === 0) {
            return <div>
                <h3 className="text-center">{this.context.t("searchNoResult")}</h3>
            </div>;
        }

        return (
            <div>
                <Modal show={this.props.isFetching}/>
                <OverviewImageButtonPanel>
                    {
                        this.props.watchlistItems.map((watchlistItem) => {

                            return (
                                    <VodImageButton key={watchlistItem.id}
                                                     link={"/vod/detail/"+watchlistItem.id}
                                                     title={watchlistItem.title}
                                                     item={watchlistItem}
                                                     onClickStar={() => this.watchlistRemove(watchlistItem)}
                                                     thumbnail={VodUtils.getPictureUrl(watchlistItem)}/>
                            )
                        })
                    }

                </OverviewImageButtonPanel>
            </div>
        )
    }

}

OverviewWatchlist.contextTypes = {
    t: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    return {
        watchlistItems: state.vod.watchlistitems,
        isFetching: state.vod.isFetching,
    };
};

export default connect(mapStateToProps, {fetchFromMaxicaster: fetchWatchlistFromMaxicaster, watchlistRemove: watchlistRemove, removeFromWatchlist: removeFromWatchlist}) (OverviewWatchlist)