import React, {useState, useEffect} from 'react';
import {useGet} from "../../hooks/useGet";
import Checkbox from "../../components/Checkbox";
import {DefaultButton} from "../../resources/styles";
import {withTheme} from "styled-components";
import Loading from "../../components/Loading";
import {getFromHubNew, getResourceImageUrl, postToHubNew} from "../../actions/networkActions";
import {getTranslation} from "../../translations/translationUtils";
import CurrencyUtils from "../../utils/currencyUtils";
import {formatDuration} from "../../utils/dateTimeUtils";
import {recordVisit} from "../../actions/metricsActions";
import ImageWithFallback from "../../components/ImageWithFallback";

function OnboardExcursionBook(props) {
    const excursion = useGet(`excursions/${props.match.params.id}`);
    const conditionTexts = useGet("conditions/Excursion");
    const conditions = useGet("conditions/allowed/Excursion");
    const [guests, setGuests] = useState(null);
    const [tcCheckbox, setTcCheckbox] = useState(false);
    const [bookError, setBookError] = useState("");
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        const fetchGuests = async () => {
            const guestsResponse = await getFromHubNew(`excursions/findApplicableGuests/${props.match.params.id}`);
            setGuests(guestsResponse.map(guest => ({...guest, selected: false})));
        }

        fetchGuests();
    }, [props.match.params.id]);
    

    const [bookingReference, setBookingReference] = useState("");

    if(excursion.data === null || conditionTexts.data === null || conditions.data === null || guests === null) {
        return (
            <Loading/>
        )
    }

    // TODO - handle the error case - look in excursion.error, guests.error,...
    const currentAvailability = excursion.data.currentAvailability === undefined ? 99 : excursion.data.currentAvailability;
    const nrSelected = guests.filter(guest => guest.selected).length;


    const book = async () => {
        if(!tcCheckbox) {
            setBookError("You need to agree to Terms & Conditions before booking.");
            return;
        }

        if(submitting) {
            return;
        }

        const selectedGuests = guests
            .filter(guest => guest.selected)
            .map(guest => ({
                    accountNumber: guest.accountNumber,
                    firstName: guest.firstName,
                    lastName: guest.lastName,
                })
            );

        if(selectedGuests.length === 0) {
            setBookError("You need to select at least one passenger.");
            return;
        }

        setSubmitting(true);
        const result = await postToHubNew(
            `excursions/book/${props.match.params.id}`,
            {
                excursionId: props.match.params.id,
                guests: selectedGuests,
            });

        setSubmitting(false);

        if(result.failed) {
            // show error msg
            setBookError(result.systemInformation);
        }
        else {
            recordVisit(`excursions/book/${props.match.params.id}`);
            setBookingReference(result.bookingReference);
        }

    }

    // conditions failed
    if (conditions.data !== null && conditions.data.messages?.length > 0) {
        return (
            <div>
                <div className="grid-x grid-padding-x">
                    <div className="cell small-12 medium-4 large-4">
                        <ImageWithFallback className="thumbnail"
                             src={getResourceImageUrl(excursion.data.id, {width: 400})}
                             alt="Excursion cover"
                        />
                    </div>
                </div>
                <div className="cell small-12 medium-8 large-8">
                    <div>
                        {
                            conditions.data.messages.map((message, index) =>
                                <h2 key={index}>{message}</h2>
                            )
                        }
                    </div>
                </div>
            </div>)
    }

    const calculateTotal = () => {
        return guests.reduce((sum, guest) => guest.selected ? sum + guest.price : sum,0);
    }

    // confirmation page
    if(bookingReference) {
        return (
            <div className="grid-x grid-padding-x">
                <div className="cell small-12 medium-4 large-4">
                    <ImageWithFallback className="thumbnail"
                         src={getResourceImageUrl(excursion.data.id, {width: 400})}
                         alt="Excursion cover"
                    />
                </div>
                <div className="cell small-12 medium-8 large-8">
                    <h4>
                        Your experience
                        <span className="font-bold"> {getTranslation(excursion.data.name)} </span>
                        is successfully booked.
                    </h4>

                    <div className="my-4">
                        <div>
                            Date: {excursion.data.date}
                        </div>
                        {excursion.data.startTime &&
                        <div>
                            Time: {excursion.startTime}
                        </div>}

                        {excursion.data.duration &&
                            <div>
                                Duration: {formatDuration(excursion.data.duration)}
                            </div>
                        }
                        <div>
                            Persons: {guests.filter(guest => guest.selected).length}
                        </div>

                        <div>
                            Total: £{CurrencyUtils.convertToCurrency(calculateTotal())}
                        </div>
                    </div>

                    <div className="my-4">
                        Your booking reference number is <span className="font-bold">{bookingReference}</span>.
                    </div>

                    {/* TODO - make this be a link */}
                    <div className="my-4">
                        You can view this booking under ‘My Bookings’ on the Home Page
                    </div>

                    <div className="my-4">
                        <div dangerouslySetInnerHTML={{__html: conditionTexts.data.additionalConditionText?.defaultValue}}/>
                    </div>
                </div>
            </div>
         )
    }


    const handleCheckboxChange = (accountNumber) => {
        const  updatedGuests = guests.map(guest => ({
            ...guest,
            selected: accountNumber === guest.accountNumber ? !guest.selected : guest.selected
        }));

        setGuests(updatedGuests);

        const nrSelectedUpdated = updatedGuests.filter(guest => guest.selected).length;
        if(nrSelectedUpdated > currentAvailability) {
            setBookError(`Too many passengers selected. Only ${currentAvailability} spot${currentAvailability === 1 ? "" : "s"} available.`);
        }
        else {
            if(bookError !== "") {
                setBookError("");
            }
        }
    }

    return (
        <div className="grid-x grid-padding-x">
            <div className="cell small-12 medium-4 large-4">
                <ImageWithFallback className="thumbnail"
                     src={getResourceImageUrl(excursion.data.id, {width: 400})}
                     alt="Excursion cover"
                />
            </div>

            <div className="cell small-12 medium-8 large-8">
                <div className="text-lg">
                    Please use the checkbox to select or unselect participants for
                    <span className="font-bold"> {getTranslation(excursion.data.name)}</span> on
                    <span className="font-bold"> {excursion.data.date}</span>.
                </div>

                {currentAvailability < guests.length && (
                <p className="text-red my-4">{`Only ${currentAvailability} ${currentAvailability > 1 ? "spots" : "spot"} available`}</p>
                )}

                <div className="my-4">
                    {guests.map(guest =>
                        <div className="my-1" key={guest.accountNumber}>
                            <Checkbox
                                label={guest.accountName}
                                checked={guest.selected}
                                disabled={guest.disabled ? guest.disabled : false}
                                handleCheckboxChange={() => handleCheckboxChange(guest.accountNumber)}
                            />
                        </div>
                    )}
                </div>

                <div className="text-lg my-4">
                    Total: £{CurrencyUtils.convertToCurrency(calculateTotal())}
                </div>

                <div className="my-4">
                    <Checkbox
                        label="I accept the Terms & Conditions"
                        checked={tcCheckbox}
                        disabled={false}
                        handleCheckboxChange={() => setTcCheckbox(!tcCheckbox)}
                    />
                </div>

                {bookError &&
                <div className="text-red my-4">
                    {bookError}
                </div>}

                <DefaultButton disabled={submitting || currentAvailability < nrSelected || currentAvailability <= 0} onClick={book}>
                        Book Experience
                </DefaultButton>

                <div className="my-4" dangerouslySetInnerHTML={{__html: conditionTexts.data.conditionText?.defaultValue}}></div>

            </div>

        </div>)
}

export default withTheme(OnboardExcursionBook);