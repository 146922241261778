import React from 'react';
import {connect} from 'react-redux';
import {currentlyFlyingBack} from '../utils/dateTimeUtils';

class ItineraryFlightOnlyMsg extends React.Component {
    render() {
        const hasHotel = this.props.itinerary.hotels && this.props.itinerary.hotels.length > 0;
        const hasCruise = this.props.itinerary.cruises && this.props.itinerary.cruises.length > 0;

        if (hasHotel || hasCruise) {
            return null;
        }

        const flyingBack = currentlyFlyingBack(this.props.itinerary.flights.flightsIn);

        return (
            <div className="margin-vertical-1">
                {flyingBack ?
                    "We hope you enjoyed your stay." :
                    "As a flight-only passenger, we hope you have a pleasant stay at your destination."
                }
            </div>
        )

    }
}


function mapStateToProps(state) {
    return {
        itinerary: state.itinerary,
    };
}

export default connect(mapStateToProps) (ItineraryFlightOnlyMsg);

