import {getFromHubNew, postToHubNew} from "./networkActions";
import {store} from "../App";
import {
    CLEAR_PM_ORDER,
    SET_PM_CABINIMAGES,
    SET_PM_CART,
    SET_PM_CONFIG,
    SET_PM_CONSENT,
    SET_PM_DETAIL_IMAGE, SET_PM_ORDER, SET_PM_ORDERS
} from "../constants/photomatch-action-types";
import {getDetailImageFromCart} from "../utils/photoMatchUtils";

export function fetchConfig() {
    getFromHubNew("photomatch/config", saveConfig);
}

const saveConfig = (config) => {
    store.dispatch({type: SET_PM_CONFIG, config: config});
};

export function fetchConsent() {
    getFromHubNew("photo/passenger/consent", saveConsent);
}

const saveConsent = ({consent}) => {
    store.dispatch({type: SET_PM_CONSENT, consent})
}

export function fetchCart() {
    getFromHubNew("photomatch/cart", saveCart);
}

export function addPhotoToCart(photoId, variants) {
    const photoData = {
        id: photoId,
    };

    if(variants) {
        photoData.variants = variants;
    }

    const payload = {photos: [photoData]};

    postToHubNew("photomatch/addToCart", payload, saveCart);
}

export function addPackageToCart(packageId) {
    const payload = {bulk: packageId};

    postToHubNew("photomatch/addToCart", payload, saveCart);
}

export function removePhotoFromCart(photoId) {
    const payload = {
        photos: [
            {id: photoId}
        ]
    };

    removeFromCart(payload);
}

export function removeFromCart(payload) {
    postToHubNew("photomatch/removeFromCart", payload, saveCart);
}

export function emptyCart() {
    postToHubNew("photomatch/emptyCart","", saveCart);
}

const saveCart = (cart) => {
    store.dispatch({type: SET_PM_CART, cart});

    if (store.getState().photomatch.detailImage) {
        const newDetailImage = getDetailImageFromCart(store.photomatch.detailImage.id, cart);
        store.dispatch({ type: SET_PM_DETAIL_IMAGE, detailImage: newDetailImage});
    }
}

export function fetchCabinImages() {
    getFromHubNew("photomatch/cabinImages", saveCabinImages);
}

const saveCabinImages = (cabinImagesResponse) => {
    const photos = cabinImagesResponse.photos || null;
    store.dispatch({type: SET_PM_CABINIMAGES, cabinImages: photos});
};

export function placeOrder(errorCallback) {
    return postToHubNew("photomatch/order", "", saveOrder, errorCallback)
}

const saveOrder = (order) => {
    store.dispatch({ type: SET_PM_ORDER, order});
};

export function clearOrder() {
    store.dispatch({type:  CLEAR_PM_ORDER});
}


export function fetchOrders() {
    return getFromHubNew("photomatch/orders", saveOrders);
}

const saveOrders = (orders) => {
    store.dispatch({ type: SET_PM_ORDERS, orders});
}
