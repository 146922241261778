import React, {useState} from "react";
import styled, {withTheme} from "styled-components";

const StyledCabinFinder = styled.div`
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    
    .keypad {
        display: grid;
        grid-template-rows: repeat(4, 1fr);
        grid-template-columns: repeat(3, 1fr);
        
        button {
            background: ${ props => props.theme.specialLayoutBackgroundColorOne || "#dbf1fb" };
            color: ${ props => props.theme.specialLayoutColorOne || "white" };
            padding: 1.25rem;
            margin-bottom: 0.25rem;
            margin-right: 0.5rem;
            line-height: 1;
            border-radius: 8px;
            user-select: none;
            cursor: pointer;
        }
    }
    
    input {
        width: 150px;
        text-align: center;
        padding: 1rem; 
        border: 1px solid #cacaca;
        font-size: 1rem;
        color: #8a8a8a;
        background-color: #fefefe;
        box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
    }
    
    .btn-search {
        font-size: 1.5rem;
        background: ${ props => props.theme.buttonBackground || "red" };
        color: ${ props => props.theme.buttonColor || "white" };
        padding: 1rem;
        text-align: center;
        cursor: pointer;
        -webkit-appearance: none;
        transition: all .25s ease-out;
        border: 1px solid transparent;
        border-radius: 5px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        color: #fff;
    }
    
    button:disabled, button[disabled] {
        border: 1px solid #999999;
        background-color: #cccccc;
        color: #666666;
    }
`;


function CabinFinderForm(props) {
    const [search, setSearch] = useState("");
    const handleClick = (value) => {
        if(value === "clear") {
            setSearch("");
            return;
        }

        if(value === "backspace") {
            setSearch(search.substring(0, search.length - 1));
            return;
        }

        if((search + value).length < 6) {
            setSearch(search + value);
        }

    }

    const handleSearch = () => {
        if(isValidCabinNr(search)) {
            props.search(search);
        }
    }

    // currently we allow any string that is 4 or 5 characters
    const isValidCabinNr = cabinNr => {
        const cabinNrLength = cabinNr.length;
        return cabinNrLength > ( props.theme.cabinFinderMinimumLength || 3 ) && cabinNrLength < ( props.theme.cabinFinderMaximumLength || 6 );
    }


    return (
        <StyledCabinFinder>
            <div className="mr-2" style={{fontWeight: "bold"}}>
                Cabin Number:
            </div>
            <div className="keypad">
                <button onClick={() => handleClick("1")}>1</button>
                <button onClick={() => handleClick("2")}>2</button>
                <button onClick={() => handleClick("3")}>3</button>
                <button onClick={() => handleClick("4")}>4</button>
                <button onClick={() => handleClick("5")}>5</button>
                <button onClick={() => handleClick("6")}>6</button>
                <button onClick={() => handleClick("7")}>7</button>
                <button onClick={() => handleClick("8")}>8</button>
                <button onClick={() => handleClick("9")}>9</button>
                <button onClick={() => handleClick("clear")}>C</button>
                <button onClick={() => handleClick("0")}>0</button>
                <button onClick={() => handleClick("backspace")}><span>&#8592;</span></button>
            </div>
            <div className="mr-4">
                <input type="text"
                       value={search}
                       disabled={true}
                />
            </div>
            <div>
                <button className="btn-search" disabled={!isValidCabinNr(search)} onClick={handleSearch}>
                    Search
                </button>
            </div>
        </StyledCabinFinder>
    )
}

export default withTheme(CabinFinderForm);