import React from "react";
import styled from "styled-components";

let commonLightStyles = {
    width: "1.2rem",
    height: "1.2rem",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "100%",
    fontSize: "0.8rem",
    color: "white",
    fontWeight: "bolder",
    display: "flex",
    marginBottom: 0,
};

if (window.matchMedia("(orientation: landscape)")) {
    commonLightStyles.width = "1rem";
    commonLightStyles.height = "1rem";
    commonLightStyles.fontSize = "0.6rem"
}

const Green = styled.div`
    ${commonLightStyles}
    background-color: #A0C800;
    &:before {
        content: 'G';
    }
`;

const Yellow = styled.div`
    ${commonLightStyles}
    background-color: #FCB712;

    &:before {
        content: 'A';
    }
`;

const Red = styled.div`
    ${commonLightStyles}
    background-color: #EF0000;

    &:before {
        content: 'R';
    }
`;

function TrafficLight(props) {
    if (props.trafficLightColor === "Green") {
        return (
            <Green />
        );
    } else if (props.trafficLightColor === "Yellow") {
        return(
            <Yellow />
        );
    } else {
        return(
            <Red />
        );
    }
}

export default TrafficLight;