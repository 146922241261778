import React from 'react';
import {CheckboxWrapper} from "../resources/styles";

class Checkbox extends React.Component {


    getLabelContent() {
        if(this.props.children) {
            return (this.props.children);
        }
        return (
            (<span>
                {this.props.label}
                {this.props.disabledReason ? " - " + this.props.disabledReason : ""}
            </span>)
        );
    }
    render()
    {
        return (
            <CheckboxWrapper>
                <label className={"flex-container inline-flex items-center select-none" + (this.props.disabled ? " disabled" : "")}>
                    <input
                        type="checkbox"
                        value={this.props.value || this.props.label}
                        onChange={() => {this.props.handleCheckboxChange();}}
                        checked={this.props.checked}
                        disabled={this.props.disabled}
                    />
                    <span className="checkbox-indicator"></span>
                    {this.getLabelContent()}
                </label>
            </CheckboxWrapper>
        )
    }
}

export default Checkbox;