import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {fetchFromHub, postToHub} from "../../actions/networkActions";
import {getFullHubPath} from "../../utils/imageUtils";
import {DefaultButton, DefaultButtonLink} from "../../resources/styles";

class Video extends Component {
    playerRef = null;
    supposedCurrentTime = 0;

    state = {
        status: "idle",
        showConfirmation: false,
        videoFinished: false,
        passengers: [],
        acceptConditions: false,
        errorMessage: "",
        videoUrl: "",
    };

    componentDidMount() {
        this.props.fetchFromHub('muster/passenger', this.updatePassengers);
        this.props.fetchFromHub("cms/articles/findByHashtag?hashtag=safetyvideo", this.fetchVideoUrl)
    }

    updatePassengers = ({passengers}) => {
        this.setState({
            passengers: passengers.map(passenger => ({...passenger, selected: false})),
        });
    };

    fetchVideoUrl = articles => {
        if(!articles || !articles.length) {
            return;
        }

        this.props.fetchFromHub(`blobs/videos/${articles[0].id}`, this.updateVideoUrl)
    };

    updateVideoUrl = videos => {

        if(!videos || !videos.length) {
            // TODO set a flag to indicate an error with setup
            return;
        }

        this.setState({
            videoUrl: getFullHubPath(videos[0].url),
        });
    }



    onTimeUpdate = () => {
        if(!this.playerRef.seeking) {
            this.supposedCurrentTime = this.playerRef.currentTime;
        }
    }

    onSeek = () => {
        const allowedSeekAmount = 0.01;
        const delta = this.playerRef.currentTime - this.supposedCurrentTime;
        if(delta > allowedSeekAmount) {
            this.playerRef.currentTime = this.supposedCurrentTime;
        }
    }

    onEnd = () => {
        // if video was playing in full screen try to exit full screen, and then mark video as finished
        // otherwise just mark it as finished straight away
        if(document.fullscreenElement) {
            if (document.exitFullscreen) {
                document.exitFullscreen()
                    .then(() => this.setState({videoFinished: true}))
                    .catch(() => this.setState({videoFinished: true}));
            }
            else {
                this.setState({videoFinished: true});
            }
        }
        else {
            this.setState({
                videoFinished: true,
            });
        }
    }

    handlePassengerChange = id => {
        const passengers = this.state.passengers.map(passenger =>
            passenger.id !== id ? passenger : {...passenger, selected: !passenger.selected}
        );

        this.setState({
            passengers
        });
    }

    handleConfirmationChange = () => {
        this.setState(
            {
                acceptConditions: !this.state.acceptConditions,
            }
        )
    }

    submitForm = () => {
        const passengers = this.state.passengers
            .filter(passenger => passenger.selected)
            .map(passenger => ({accountId: passenger.accountNumber, isVideoWatched: true}));

        if(passengers.length === 0) {
            this.setState({
                errorMessage: "Please select at least one passenger",
            });
            return;
        }

        if(!this.state.acceptConditions) {
            this.setState({
                errorMessage: "Please read and accept terms and conditions",
            });
            return;
        }

        this.props.postToHub('muster/passenger/updateVideoSeen', {passengers}, this.showSuccess, this.showError);

    }

    showSuccess = () => {
        this.setState({
            status: "success",
        });
    }

    showError = () => {
        this.setState({
            status: "error",
        });
    }

    render() {
        if(this.state.passengers.length === 0 || this.state.videoUrl === "") {
            return (
                <div>
                    Loading...
                </div>
            )
        }

        const alreadyWatched = this.state.passengers.filter(passenger => passenger.isVideoWatched);
        const notYetWatched = this.state.passengers.filter(passenger => !passenger.isVideoWatched);
        if(this.state.status === "error") {
            return (
                <div>
                    <div>
                        There has been an error with submitting your data. Please try again or seek help at the reception.
                    </div>
                    <div>
                        <Link to="/muster/home">
                            Back to muster home
                        </Link>
                    </div>
                </div>
            );
        }


        return (
            <div>
                <div>
                    <h1>Muster Video</h1>
                    <p>Please watch the entire video below. When the video finishes, you can update your Muster Status.</p>
                </div>

                <div>
                    {this.state.videoUrl &&
                    <video
                        onTimeUpdate={this.onTimeUpdate}
                        onSeeking={this.onSeek}
                        onEnded={this.onEnd}
                        className="movie"
                        width="100%"
                        controls
                        preload="metadata"
                        autoPlay
                        ref={el => (this.playerRef = el)}>
                        <source src={this.state.videoUrl} type="video/mp4"/>
                        Your browser does not support HTML5 video.
                    </video>
                    }
                </div>


                {this.state.videoFinished && alreadyWatched.length > 0 && this.state.status !== "success" &&
                <div className="mt-4">
                    Already viewed by:
                    {alreadyWatched.map(passenger =>
                        <div key={passenger.id}>
                            - {passenger.name}
                        </div>
                    )}
                </div>
                }


                {this.state.videoFinished && notYetWatched.length > 0 && this.state.status !== "success" &&
                    <VideoConfirmationForm
                        notYetWatched={notYetWatched}
                        handlePassengerChange={this.handlePassengerChange}
                        handleConfirmationChange={this.handleConfirmationChange}
                        errorMessage={this.state.errorMessage}
                        submitForm={this.submitForm}
                    />
                }

                {this.state.videoFinished && notYetWatched.length === 0 &&
                <div className="mt-4">
                    <DefaultButtonLink to="/muster/status">
                        View Muster Status
                    </DefaultButtonLink>
                </div>
                }

                {this.state.status === "success" &&
                <div>
                    <div className="my-4">
                        Muster video watched
                    </div>
                    <DefaultButtonLink to="/muster/status">
                        View Muster Status
                    </DefaultButtonLink>
                </div>}

            </div>
        )
    }
}

export default connect(null, {fetchFromHub, postToHub})(Video);


class VideoConfirmationForm extends React.Component {
    formRef = null;

    componentDidMount() {
        this.formRef.scrollIntoView();
    }

    render() {
        return (
            <div className="mt-4" ref={el => (this.formRef = el)}>
                <div>
                    I confirm that:
                </div>

                <div className="mt-2">
                    {this.props.notYetWatched.map(passenger =>
                        <div key={passenger.id} className="ml-1">
                            <input
                                id={`checkbox-${passenger.id}`}
                                type="checkbox"
                                className="mr-1"
                                onClick={() => this.props.handlePassengerChange(passenger.id)}
                            />

                            <label htmlFor={`checkbox-${passenger.id}`}
                                   className="fontSize-1-rem">
                                {passenger.name}
                            </label>
                        </div>
                    )}
                </div>

                <div>
                    <p>watched the muster video</p>
                    <p>Please review and accept the terms and conditions to proceed.</p>
                </div>
                <div className="ml-1">
                    <input  id="confirmation-checkbox"
                            type="checkbox"
                            className="mr-1"
                            onClick={this.props.handleConfirmationChange}
                    />
                    <label htmlFor="confirmation-checkbox"
                           className="fontSize-1-rem ml-1">
                        Accept Conditions
                    </label>
                </div>

                {this.props.errorMessage &&
                <div className="mt-4 text-red">
                    {this.props.errorMessage}
                </div>}

                <div className="mt-4">
                    <DefaultButton onClick={this.props.submitForm}>Submit</DefaultButton>
                </div>

                <div>
                    <p className="font-bold">
                        Terms & Conditions
                    </p>
                    <p>
                        By accepting the terms and conditions, you acknowledge that you have viewed the Safety Video
                        and understand that this information is a requirement of maritime law (SOLAS).
                        If you have any questions, please visit Reception where we can assist you.
                    </p>
                </div>
            </div>
        )
    }
}
