// TODO decide if this is needed
export function clearHubConfiguration() {
    return async function (dispatch) {
        dispatch({type: "CLEAR_HUB_CONFIGURATION"});
    }
}

export function setHubConfiguration(hubSettings) {
    return async function (dispatch) {
        dispatch({
            type: "SET_HUB_CONFIGURATION",
            hubSettings,
        })
    }
}
