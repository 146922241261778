import React from "react";
import styled from "styled-components";
import TrafficLight from "./TrafficLight";

const TimesItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-left: ${props => props.position === 1 ? "none" : "1px solid black"};
    font-size: 0.8rem;
    width: 25%;
    animation: fadein 0.5s;
    p {
        margin-bottom: 0.5rem;
    }
`;

const Hidden = styled.div`
    display: none;
`;


export default function RestaurantAvailabilityTimeSlot(props) {

    const timeFormat = "HH:mm";

    if (!props.timeSlot) {
        return false;
    }

    if (props.position > 0 && props.position < (props.shownTimeSlots + 1)) {
        return (
            <TimesItem position={props.position}>
                <p>{props.timeSlot.time.format(timeFormat)}</p>
                <TrafficLight trafficLightColor={props.timeSlot.color}></TrafficLight>
            </TimesItem>
        )
    } else return (
        <Hidden />
    )
}