import React from 'react';
import styled from 'styled-components';

const DarkText = styled.div`
    color: ${props => props.theme.headerColor || '#6B207D'};
`;

class ImportantInformationEntry extends React.Component {
    render () {
        if (!this.props.content) {
            return false;
        }

        return (
            <div>
                {this.props.title && <DarkText className="uppercase mt-2"> {this.props.title} </DarkText> }
                <DarkText className="text leading-normal" dangerouslySetInnerHTML={{__html: this.props.content}} />
            </div>
        );
    }
}

export default ImportantInformationEntry;