import React from "react";
import {connect} from "react-redux";
import MaxicasterPlayer from "../../components/MaxicasterPlayer";
import TheoPlayerComp from "../../components/TheoPlayerComp";

class PlayerSelection extends React.Component {
    constructor() {
        super();
        this.state = {
            errorMessage: null,
        };
    }

    componentDidMount() {
    }

    render() {

        if (this.state.errorMessage) {
            return (
                <h3>{this.state.errorMessage}</h3>

            )
        }

        if (this.props.vodconfiguration.isDemoSystem) {
            return (
                    <MaxicasterPlayer />
            );
        } else {
            return (
                    <TheoPlayerComp />
            );
        }
    }
}

const mapStateToProps = state => {
    return {
        vodassets: state.voditem.vodassets,
        vodconfiguration: state.vodconfiguration,
    };
};

export default connect(mapStateToProps, null )(PlayerSelection);
