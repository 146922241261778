import * as React from "react";
import {connect} from "react-redux";
import Modal from "../../../components/Modal";
import OverviewImageButtonPanel from "../../../components/OverviewImageButtonPanel";
import VodUtils from "../VodUtils";
import {fetchSeriesFromMaxicaster, updateSeries, watchlistAdd, watchlistRemove} from "../../../actions/vodActions";
import {setSearchtermSeries} from "../../../actions/vodSearchActions";
import {PropTypes} from "prop-types";
import VodImageButton from "../../../components/VodImageButton";
import {withTheme} from "styled-components";

class OverviewSeries extends React.Component {

    componentDidMount() {
        if (this.props.match.params.genreid) {
            this.search(this.props.match.params.genreid);
        } else {
            this.search();
        }
    }

    async search(genreid) {
        this.props.fetchFromMaxicaster(this.props.searchTerm, genreid);
    }

    saveSearchTerm = (searchTerm) => {
        this.props.setSearchtermSeries(searchTerm);
    };

    async watchlist(serie) {
        if (serie.watchlisted) {

            const success = await this.props.watchlistRemove(null, serie.series_info.series_id);
            if (success) {
                serie.watchlisted = null;
                this.props.updateSeries(serie);
            }
        } else {

            const success = await this.props.watchlistAdd(null, serie.series_info.series_id);
            if (success) {
                serie.watchlisted = true;
                this.props.updateSeries(serie);
            }
        }
    };


    render() {

        if (! this.props.series) {
            return false;
        }

        return (
            <div>
                <Modal show={this.props.isFetching}/>
{/*                <div className="grid-x grid-padding-x align-left">
                    <div className="cell small-10 medium-6 large-6">

                        <div className="input-group digi-input-group-rounded">
                            <input className="input-group-field"
                                   type="text"
                                   value={this.props.searchTerm}
                                   onChange={(e) => this.saveSearchTerm( e.target.value)}
                            />
                            <div className="input-group-button">
                                <input type="submit" className="digi-button digi-info-button" value={this.context.t("search")} onClick={() => this.search(true)}/>
                            </div>
                        </div>
                    </div>
                </div>*/}
                <OverviewImageButtonPanel>
                    {
                        this.props.series.map((serie) => {
                            let id = serie.series_info.series_id;

                            let link = "/vod/overview/serie/";
                            if (serie.series_info.seasons && serie.series_info.seasons.length === 1) {
                                // if there is only 1 season available, create a link that directly shows the episodes (instead of only 1 season)
                                id = serie.series_info.seasons[0].season;
                                link = "/vod/overview/serie/"+serie.series_info.series_id+"/";
                            }

                            return (
                                <VodImageButton key={serie.series_info.series_id}
                                    link={link+id}
                                    title={serie.title}
                                    item={serie}
                                    onClickStar={() => this.watchlist(serie)}
                                    thumbnail={VodUtils.getPictureUrl(serie)}
                                    heightWidthRatio={this.props.theme.vodSeriesHeightWidthRatio || "100%"}/>
                            )
                        })
                    }

                </OverviewImageButtonPanel>
            </div>
        )
    }

}
OverviewSeries.contextTypes = {
    t: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    return {
        series: state.vod.series,
        isFetching: state.vod.isFetching,
        searchTerm: state.vod.searchTermSeries,
    };
};

export default connect(mapStateToProps, {fetchFromMaxicaster: fetchSeriesFromMaxicaster, setSearchtermSeries: setSearchtermSeries, watchlistAdd: watchlistAdd, watchlistRemove: watchlistRemove, updateSeries: updateSeries}) (withTheme(OverviewSeries));