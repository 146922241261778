import {SET_FOOTER_TYPE} from "../constants/navigation-action-types";
import {store} from "../App";

export function setFooterTypeForPath(path) {
    const footerType = getFooterTypeForPath(path);
    store.dispatch({type: SET_FOOTER_TYPE, footerType});
}

function getFooterTypeForPath(path) {
    switch (path) {
        case "/":
            return "dashboard";
        case "/login":
            return store.getState().configuration.hubSettings.deviceType === "Kiosk" ? "hidden" : "standard";
        default:
            return "standard";

    }
}