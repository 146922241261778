import React from "react";
import {withTheme} from "styled-components";

const RightIcon = ({theme}) => {

  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 50" width="150">
        <path fill={ theme.kioskLoginIconColor || "#092a5e" }
              className="a"
              d="M23.47,41.68A4.64,4.64,0,0,0,31,45.86l29.08-18a5,5,0,0,0,0-8.86L31,1a5.9,5.9,0,0,0-3.09-1,4.28,4.28,0,0,0-2.07.53,5,5,0,0,0-2.35,4.61v7.2H0v22.2H23.47ZM4.52,30V16.86H28V5.2a2.32,2.32,0,0,1,.07-.59,2.65,2.65,0,0,1,.54.25l29.08,18c.47.3.6.54.61.59s-.14.29-.61.59L28.6,42a2.65,2.65,0,0,1-.54.25A2.32,2.32,0,0,1,28,41.7V30Z"/>
      </svg>
  );

}

export default withTheme(RightIcon);