import React from 'react';
import {PropTypes} from "prop-types";
import {useGet} from "../../hooks/useGet";
import styled from 'styled-components'
import Loading from "../../components/Loading";
import PhotoMatchOrders from "../../modules/photomatch/Orders";
import {useSelector} from "react-redux";

function OnboardBookings(props, context) {
    const bookings = useGet("pms/accounts/appointments");
    const photoMatchEnabled = useSelector(state => state.configuration?.hubSettings?.uiSettings?.photoMatchEnabled);
    const groupBookingsByStatus = useSelector(state => state.configuration?.hubSettings?.uiSettings?.groupBookingsByStatus);

    if(bookings.data === null) {
        return (
            <Loading/>
        )
    }

    if(bookings.data.length === 0) {
        return (
            <div className="text-lg my-8">
                {context.t("no_onboard_bookings")}
            </div>
        )
    }

    const groupedBookings = {};
    let combinedBookings = [];

    if(groupBookingsByStatus) {
        // group the bookings by status first, then merge them back in one array and add divider elements between them
        for (const booking of bookings.data) {
            //treat reservation as confirmed
            if(booking.bookingStatus === "R") {
                booking.bookingStatus = "C";
            }

            groupedBookings[booking.bookingStatus] ?
                groupedBookings[booking.bookingStatus].push(booking) :
                groupedBookings[booking.bookingStatus] = [booking];
        }

        if(groupedBookings.C) {
            combinedBookings.push({isDivider: true, label: context.t("confirmed")}, ...groupedBookings.C);
        }
        if(groupedBookings.W) {
            combinedBookings.push({isDivider: true, label: context.t("waitlist")}, ...groupedBookings.W);
        }
        if(groupedBookings.X) {
            combinedBookings.push({isDivider: true, label: context.t("cancelled")}, ...groupedBookings.X);
        }
    }

    return (
        <div>
            <h4>{context.t("onboard_bookings_title")}</h4>

            {groupBookingsByStatus ?
                <GroupedBookingsTable bookings={combinedBookings} /> :
                <BasicBookingsTable bookings={bookings.data} />
            }

            {photoMatchEnabled &&
                <PhotoMatchOrders />
            }
        </div>
    );
}

OnboardBookings.contextTypes = {
    t: PropTypes.func.isRequired,
}

export default OnboardBookings;

const ThemedBookingsTable = styled.table`
    tbody, tbody tr.divider.blank{
        background-color: ${ props => props.theme.bookingsTableBackgroundColor || "transparent" }; 
    }
    
    tbody tr.divider.header {
        background-color: ${props => props.theme.tableHeadingBackground || props.theme.subheaderBackground || "black"};
        color: ${props => props.theme.tableHeadingColor || props.theme.subheaderColor || "white"};
        font-weight: bold;
    }
    
    tbody tr.divider.columns{
        background-color: ${props => props.theme.bookingsTableHeaderColumnsBackgroundColor || "white"};
    }
`;

function GroupedBookingsTable({bookings}, context) {
    return (
        <div>
            <ThemedBookingsTable>
                <tbody>
                {bookings.length === 0 &&
                <tr>
                    <td colSpan="5">
                        {context.t("no_onboard_bookings")}
                    </td>
                </tr>
                }
                {
                    bookings.map((booking, index) =>
                    booking.isDivider ?
                        <React.Fragment key={index}>
                            <tr className="divider blank">
                                <td colSpan="5"></td>
                            </tr>
                            <tr className="divider header">
                                <td colSpan="5">
                                    {booking.label}
                                </td>
                            </tr>
                            <tr className="divider header columns">
                                <th>{context.t("date")}</th>
                                <th>{context.t("from_time")}</th>
                                <th>{context.t("to_time")}</th>
                                <th>{context.t("description")}</th>
                                <th>{context.t("excursion_code")}</th>
                            </tr>
                        </React.Fragment>
                        :
                        <tr key={index}>
                            <td>{booking.date}</td>
                            <td>{booking.fromTime}</td>
                            <td>{booking.toTime}</td>
                            <td>{booking.text}</td>
                            <td>{booking.excursionCode}</td>
                        </tr>
                    )
                }
                </tbody>
            </ThemedBookingsTable>
        </div>
    )
}

GroupedBookingsTable.contextTypes = {
    t: PropTypes.func.isRequired,
}

function BasicBookingsTable({bookings}, context) {
    return (
        <div>
            <table>
                <thead>
                <tr>
                    <th>{context.t("date")}</th>
                    <th>{context.t("from_time")}</th>
                    <th>{context.t("to_time")}</th>
                    <th>{context.t("description")}</th>
                </tr>
                </thead>
                <tbody>
                {bookings.length === 0 &&
                <tr>
                    <td colSpan="4">
                        {context.t("no_onboard_bookings")}
                    </td>
                </tr>
                }
                {
                    bookings.map((booking, index) => {
                        return (<tr key={index}>
                            <td>{booking.date}</td>
                            <td>{booking.fromTime}</td>
                            <td>{booking.toTime}</td>
                            <td>{booking.text}</td>
                        </tr>)
                    })
                }
                </tbody>
            </table>
        </div>
    )
}

BasicBookingsTable.contextTypes = {
    t: PropTypes.func.isRequired,
}