import React from 'react';
import {removeHtmlStyles} from "../utils/stringUtils";

class Description extends React.Component {
    render() {
        return (
            <div>
                <p dangerouslySetInnerHTML={{__html: removeHtmlStyles(this.props.textContent)}} />
            </div>
        );
    }
}

export default Description;