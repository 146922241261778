import React from "react";
import styled from "styled-components";

const TimesHeader = styled.p`
    font-size: 1rem;
    margin: 0.5em 0;
    font-weight: bold;
    text-align: center;
    line-height: 1.6;
`;

const ArrowButton = styled.button`
    margin: 0 1rem;
`;

const InactiveArrowButton = styled.button`
    margin: 0 1rem;
    opacity: 0.15;
`;

const RestaurantAvailabilityTimesHeader = (props) => {
    const firstTimeSlot = props.timeSlotState[0];
    const lastTimeSlot = props.timeSlotState[props.timeSlotState.length - 1];

    return (
        <TimesHeader>
            { !firstTimeSlot || firstTimeSlot.position === 1 ?
                <InactiveArrowButton>
                    <i className="fa fa-chevron-left"></i>
                </InactiveArrowButton>
                :
                <ArrowButton onClick={() => props.handleSetPosition(+1)}>
                    <i className="fa fa-chevron-left"></i>
                </ArrowButton>
            }
            <span>{props.fromTime} - {props.toTime}</span>
            { !lastTimeSlot || lastTimeSlot.position === props.shownTimeSlots ?
                <InactiveArrowButton>
                    <i className="fa fa-chevron-right"></i>
                </InactiveArrowButton>
                :
                <ArrowButton onClick={() => props.handleSetPosition(-1)}>
                    <i className="fa fa-chevron-right"></i>
                </ArrowButton>
            }
        </TimesHeader>
    )
}

export default RestaurantAvailabilityTimesHeader;