import React from "react";
import {PropTypes} from "prop-types";
import styled from 'styled-components';
import CruisesOverview from '../../components/CruisesOverview';
import ItineraryHotels from '../../components/ItineraryHotels';
import ItineraryFlights from '../../components/ItineraryFlights';
import ItineraryPassengers from '../../components/ItineraryPassengers';
import ItineraryFlightOnlyMsg from '../../components/ItineraryFlightOnlyMsg';
import {device} from "../../resources/styles";

const FlexContainer = styled.div`
    @media ${device.laptop} {
        display: flex;
        
        > div {
            flex: 1;
        }
        
        > div:first-child {
            margin-right: 1rem;
        }
        
        > div:last-child {
            margin-left: 1rem;
        }
    }
    
`;

class Itinerary extends React.Component {

    render() {
        return (
            <div>
                <div className="text-2xl">
                    {this.context.t("itineraryTitle")}
                </div>

                <FlexContainer>
                    <div>
                        {/* your flights*/}
                        <ItineraryFlights/>

                        {/* your cruise */}
                        {/* TODO - adapt to match the new layout*/}
                        <CruisesOverview classes="border-b padding-vertical-05"/>
                    </div>
                    <div>
                        {/* your hotels */}
                        <ItineraryHotels/>

                        {/* your passengers*/}
                        <ItineraryPassengers/>
                    </div>
                </FlexContainer>





                {/* generic message for flight only passengers */}
                <ItineraryFlightOnlyMsg/>

            </div>
        )
    }
}

Itinerary.contextTypes = {
    t: PropTypes.func.isRequired
};

export default Itinerary;
