import React from "react";
import Invoices from "./Invoices";
import {connect} from "react-redux";
import {fetchFromHub} from "../../actions/networkActions";
import {DefaultLink} from "../../resources/styles";

class Account extends React.Component {
    constructor() {
        super();
        this.state = {
            invoices: null
        };
    }

    componentDidMount() {
        if (this.props.authentication && this.props.isAuthenticated) {
            this.fetchInvoices();
        }
    }

    fetchInvoices() {
        if (this.state.invoices === null) {

            if (this.props.isAuthenticated) {
                this.props.fetchFromHub('pms/invoices', this.updateInvoices);
            }
        }
    }

    updateInvoices = (data) => {
        this.setState({invoices: data});
    };

    render() {

        return (

            <div className="grid-x grid-padding-x">
                <div className="cell small-12">
                    <Invoices invoices={this.state.invoices}/>
                </div>
                {this.props.deviceType !== "Kiosk" && this.props.pinRequired &&
                <DefaultLink className="cell small-12" to="/my_account/pin">
                    Need to change or reset your PIN number?
                </DefaultLink>
                }
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        isAuthenticated: state.authentication.isAuthenticated,
        authentication: state.authentication,
        pinRequired: state.configuration.hubSettings?.pmsConfiguration?.pinLoginEnabled === "true",
    };
};
export default connect(mapStateToProps, {fetchFromHub})(Account);