import {fetchFromHub, postToHub} from './networkActions';
import {SET_BOOKINGS} from "../constants/booking-action-types";
import {SET_CART} from "../constants/cart-action-types";

export function updateCart() {
    return fetchFromHub('passengers/cart', updateCartStore);
}


// used
// when user logs in
// when page is reloaded (router mounted)
// when booking is made (payment finished)
// when booking is refunded
export function updateBookings() {
    return fetchFromHub('passengers/bookings', updateBookingsStore);
}

//--

export function removeExcursionFromCart(excursionType, itineraryId, excursionId) {
    // get all passengers belonging to the excursion we want to remove
    return async function (dispatch, getStore) {
        const bookingId = getStore().cart.excursions[itineraryId][excursionId].bookingInformation;
        const passengers = getStore().cart.excursions[itineraryId][excursionId].passengers.map((passenger) => {
            if(excursionType === "ResortExcursion") {
                return {
                    type: excursionType,
                    excursion: excursionId,
                    date: itineraryId,
                    passenger: passenger.id,
                    action: "remove",
                }
            }
            
            return {
                type: excursionType,
                bookingInformation: bookingId,
                passenger: passenger.id,
                action: "remove",
            }
        });

        // post the changes to the hub
        dispatch(postToHub('passengers/cart', passengers, triggerUpdateCart));
    };
}


export function removeBookings(bookingIds, paymentReference, successCallback, errorCallback) {
    const refundData = {
        paymentReference,
        ids: bookingIds,
    };

    return async function (dispatch, getStore) {
        dispatch(postToHub('passengers/bookings/cancel', refundData, successCallback, errorCallback));
    };
}


//--

const triggerUpdateCart = (result, dispatch) => {
    // TODO - here will go the error handling based on the response from hub
    dispatch(updateCart());
};


//--

const updateCartStore = (cartElements, dispatch) => {
    const cart = parseCartEntries(cartElements);
    dispatch({
        type: SET_CART,
        cart: cart,
    });
};

const updateBookingsStore = (bookingEntries, dispatch) => {
    const bookings = parseCartEntries(bookingEntries);
    dispatch({
        type: SET_BOOKINGS,
        bookings: bookings,
    });
};

const parseCartEntries = (cartElements, parseBookingData = false) => {
    let totalPrice = 0;
    let nrExcursions = 0;
    let excursions = {};

    cartElements.forEach((cartElement) => {
        // only take the data we will need/use
        let itineraryId = "";
        let portName = {};
        let portCountry = "";
        let date = "";
        let resortId = "";

        if(cartElement.type === "ResortExcursion") {
            date = cartElement.date;
            itineraryId = cartElement.date;
            portName = cartElement.excursion.destination.name;
            resortId = cartElement.excursion.destination.id;
        }
        else {
            itineraryId = cartElement.itinerary.id;
            portName = cartElement.excursion.port.name;
            portCountry = cartElement.excursion.port.countryName;
            date =  cartElement.itinerary.date;
        }

        const excursionId = cartElement.excursion.id;
        const passengerData = {
            ...cartElement.passenger,
            cartElementId: cartElement.id,
            price: cartElement.price,
        };

        const excursionData = {
            type: cartElement.type,
            excursionId,
            itineraryId,
            resortId,
            excursionName: cartElement.excursion.name,
            portName,
            portCountry,
            price: cartElement.price,
            passengers: [passengerData],
            bookingInformation: cartElement.bookingInformation,
            date,
        };

        // update price of all entries in cart
        totalPrice += cartElement.price;

        // add the current cart element to the excursions object
        if (!excursions.hasOwnProperty(itineraryId)) {
            // itinerary entry does not yet exist, create it... and add add excursion data
            nrExcursions++;
            excursions[itineraryId] = {};
            excursions[itineraryId][excursionId] = excursionData;
        }
        else {
            if (excursions[itineraryId].hasOwnProperty(excursionId)) {
                // excursion already exists, add the passenger and update the price of the excursion
                excursions[itineraryId][excursionId].price += cartElement.price;
                excursions[itineraryId][excursionId].passengers.push(passengerData);
            }
            else {
                // excursion does not exist yet, add it
                nrExcursions++;
                excursions[itineraryId][excursionId] = excursionData;
            }
        }
    });

    return {
        totalPrice: totalPrice.toFixed(2),
        nrExcursions,
        excursions,
    };
};


