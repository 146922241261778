import {requestStarted, requestSuccessful, requestFailed} from './actions';
import { reducer } from './reducer';
import {useEffect, useReducer} from "react";
import {getFromHubNew} from "../actions/networkActions";

export const useGet = (url, reloadTriggerCount = 1) => {
    const [state, dispatch] = useReducer(reducer, {
        isLoading: true,
        data: null,
        error: null,
    });

    useEffect(() => {
        let isCancelled = false;

        const fetchData = async () => {
            dispatch(requestStarted());

            try {
                if(url === null || url === undefined) {
                    dispatch(requestSuccessful({data: null}));
                }
                else {
                    const data = await getFromHubNew(url);

                    if(!isCancelled) {
                        dispatch(requestSuccessful({data}));
                    }
                }
            } catch (e) {
                console.log("error happened", e);
                if(!isCancelled) {
                    dispatch(requestFailed({error: e.message}));
                }
            }
        };

        fetchData();

        // basic cleanup
        // TODO - implement cancellation of the underlying network request if need be
        return () => {
            isCancelled = true;
        };
    }, [url, reloadTriggerCount]);

    return state;
};