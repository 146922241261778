import React from 'react';
import {Route, Redirect} from 'react-router-dom'
import {useSelector} from 'react-redux';

function ConditionalAuthRoute({component: Component, ...rest}) {
    const authentication = useSelector(state => state.authentication);
    const shouldRequireLogin = useSelector(state =>
        state.configuration.hubSettings &&
        state.configuration.hubSettings.uiSettings &&
        state.configuration.hubSettings.uiSettings.loginRequired
    );

    return (
        <Route
            {...rest}
            render={(props) => shouldRequireLogin && !authentication.isAuthenticated
                ? <Redirect to={{pathname: '/login', state:{from: window.location.pathname}} } />
                : <Component {...props} />
            }
        />
    );
}

export default ConditionalAuthRoute;