import React, {useEffect, useState} from 'react';
import {getFromHubNew} from "../../actions/networkActions";
import Loading from "../../components/Loading"
import {useSelector} from "react-redux";
import OverviewImageButton from "../../components/OverviewImageButton";
import OverviewImageButtonPanel from "../../components/OverviewImageButtonPanel";
import {PropTypes} from "prop-types";


function ProblemOverview(props, context) {
    const hubUrl = useSelector(state => state.configuration.hubUrl);
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const articles = await getFromHubNew("cms/articles/findByHashtag?hashtag=problemReportingTiles");
            if(articles.length === 0) {
                setLoading(false);
                return;
            }
            const images = await getFromHubNew(`blobs/images/${articles[0].id}`);
            setImages(images);
            setLoading(false);
        }

        fetchData();

    }, []);

    if(loading) {
        return (
            <Loading/>
        )
    }

    const nrImages = images.length;
    const addProblemThumbnail = nrImages > 0 ? `${hubUrl}${images[0].url.substring(1)}&width=400` : "";
    const problemTrackingThumbnail = nrImages > 1 ? `${hubUrl}${images[1].url.substring(1)}&width=400` : "";

    return (
        <OverviewImageButtonPanel>
            <OverviewImageButton
                link="/problem/new"
                title={context.t("tile_add_problem")}
                thumbnail={addProblemThumbnail}
            />

            <OverviewImageButton
                link="/problem/tracking"
                title={context.t("tile_problem_tracking")}
                thumbnail={problemTrackingThumbnail}
            />
        </OverviewImageButtonPanel>
    );
}

ProblemOverview.contextTypes = {
    t: PropTypes.func.isRequired,
}

export default ProblemOverview;