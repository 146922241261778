import {
    CLEAR_AUTHENTICATION,
    SET_AUTHENTICATION,
    SET_AUTHENTICATION_ERROR,
    SET_SESSION_ID
} from "../constants/authentication-action-types";

const INITIAL_STATE = {sessionId: null, isAuthenticated: false, errorMessage: null};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case CLEAR_AUTHENTICATION:
            return INITIAL_STATE;
        case SET_AUTHENTICATION:
            return action.authentication;
        case SET_AUTHENTICATION_ERROR:
            return {...state, errorMessage: action.errormessage};
        case SET_SESSION_ID:
            return {...state, sessionId: action.sessionId};
        default:
            return state;
    }
}
