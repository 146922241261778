import React from 'react';
import {connect} from 'react-redux';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import {defaultImageSrc} from "../../utils/imageUtils";
import {fetchFromHub} from '../../actions/networkActions';
import VideoComponent from "../../components/VideoComponent";
import AudioComponent from "../../components/AudioComponent";
import LocationAndTimes from "../../components/LocationAndTimes";
import Description from "../../components/Description";


class ExtendedContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            images:null,
            imagesLoaded: false,
        }
    }

    componentDidMount() {
        this.props.fetchFromHub(
            `blobs/images/${this.props.contentId}`,
            this.updateImages
        )
    }

    updateImages = (imagesResponse) => {
        const images = imagesResponse.map((image, index) => {
            return {
                thumbnail: `${this.props.hubBaseUrl}${image.url}&width=200`,
                original: `${this.props.hubBaseUrl}${image.url}`,
            };
        }).slice(1);

        this.setState({
            images,
            imagesLoaded: true,
        });
    };

    render () {
        const contentHasImage = this.state.imagesLoaded && this.state.images && this.state.images.length > 0;
        const contentHasMultipleImages = contentHasImage && this.state.images.length > 1;

        return (
            <div>
                <div className="grid-x grid-padding-x">
                    <div className="cell small-12 large-5 large-padding-right-1">
                        {contentHasImage &&
                        <ImageGallery
                            additionalClass="excursion-image-gallery"
                            items={this.state.images}
                            lazyLoad={true}
                            defaultImage={defaultImageSrc()}
                            showPlayButton={false}
                            showThumbnails={false}
                            useBrowserFullscreen={true}
                            showIndex={contentHasMultipleImages}
                            showFullscreenButton={contentHasMultipleImages}
                        />
                        }
                    </div>

                    <div className="cell small-12 large-7 text-left">
                        <h1>
                            <div dangerouslySetInnerHTML={{__html: this.props.title}}/>
                        </h1>
                        <Description textContent={this.props.description}/>

                        { (this.props.whereAndWhen && (this.props.whereAndWhen.locationInformation || this.props.whereAndWhen.timeInformation)) &&
                            <LocationAndTimes whereAndWhen={this.props.whereAndWhen} />
                        }

                        <AudioComponent audioItems={this.props.audioItems}/>

                        <VideoComponent videoItems={this.props.videoItems}/>

                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        hubBaseUrl: state.configuration.hubUrl.replace(/\/$/, ""),
    }
}

export default connect(mapStateToProps, {fetchFromHub}) (ExtendedContent);