export const VodImageSizeEnum = {
    "LARGE":"large",
    "SMALL":"small"
}
Object.freeze((VodImageSizeEnum))

export const VodImageTypeEnum = {
    "SCREENCAPTURE" : "SCREENCAPTURE",
    "POSTER" : "POSTER"
}
Object.freeze((VodImageTypeEnum))