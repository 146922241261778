import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getFromHubNew} from "../actions/networkActions";
import {DefaultButton, DefaultButtonLink} from "../resources/styles";

class BookButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pmsStatus: null,
        };
    }

    componentDidMount() {
        if(this.props.isAuthenticated) {
            getFromHubNew('pms/account/status', this.updatePmsStatus);
        }
    }

    updatePmsStatus = pmsStatus => {
        this.setState({pmsStatus});
    }

    // TODO handle the error scenarios if needed

    render() {
        if(!this.props.isAuthenticated) {
            return (
                <div>
                    <DefaultButtonLink to={{pathname:"/login", state: {forward_to: window.location.pathname}}}>
                        {"Log in to book"}
                    </DefaultButtonLink>
                </div>
            )
        }

        if(this.state.pmsStatus === null) {
            return false;
        }

        const pmsStatus = this.state.pmsStatus;

        let pmsMessage = "";
        const showMessage = pmsStatus.showBlockedMessage && pmsStatus.isBlocked;
        if(showMessage) {
            if(this.props.allowBlockedBooking) {
                pmsMessage = pmsStatus.isBlockedForMuster ?
                    "This is the message that is shown when user is blocked because of mustering reasons AND the booking is still allowed to happen" :
                    "This is the message that is shown when user is blocked but NOT because of mustering reasons AND the booking is still allowed to happen"
            }
            else {
                pmsMessage = pmsStatus.isBlockedForMuster ?
                    "This is the message that is shown when user is blocked because of mustering reasons AND the booking is NOT allowed to happen" :
                    "This is the message that is shown when user is blocked but NOT because of mustering reasons AND the booking is NOT allowed to happen"
            }
        }

        // TODO - if this styling needs to be more generic and not just block vs inline-block move this to separate function/classes
        return (
            <div style={{display: `${!showMessage && this.props.onClick ? "inline-block" : "block"}`}}>
                {showMessage &&
                <p className="error-message">
                    {pmsMessage}
                </p>
                }

                {/* Button for everything that works with a link (excursions, dining, activities)*/}
                {
                    (!pmsStatus.isBlocked || this.props.allowBlockedBooking) && this.props.link &&
                    <div>
                        <DefaultButtonLink to={this.props.link}>
                                {this.props.buttonText || "Book now"}
                        </DefaultButtonLink>
                    </div>
                }

                {/* Button for photo - which works with a callback function for onclick*/}
                {!pmsStatus.isBlocked && this.props.onClick &&
                <DefaultButton onClick={this.props.onClick}>
                    Order now
                </DefaultButton>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.authentication.isAuthenticated,
    };
}

export default connect(mapStateToProps)(BookButton);