// helper function to truncate strings longer then certain number of characters
// the truncated string will  be cut off at a word boundary before the limit and will end with ...
export function truncateStringBeforeLastWord(string, limit) {
    if(!string) {
        return string;
    }

    if (string.length < limit) {
        return string;
    }

    return string.substring(0, limit).split(' ').slice(0, -1).join(' ') + "...";
}

export function truncateStringLongerThan(string, limit) {
    if(!string) {
        return string;
    }

    if (string.length < limit) {
        return string;
    }

    return string.substring(0, limit) + "...";
}

export function removePdfExtension(string) {
    if(!string) {
        return string;
    }

    if (string.substring(string.length - 4).toLowerCase() === '.pdf') {
        return string.slice(0, -4);
    }

    return string;
}

export function removeHtmlStyles(string) {
    return string
        .replace(/[\r\n]+/g," ")
        .replace(/<span [^<]*?font-weight: bold;">(.*?)<\/span>/g,'<strong class="text-base">$1</strong>')
        .replace(/<font.*?>(.*?)<\/font>/g, '$1')
        .replace(/style=".*?">/g, '>')
}

export function capitalizeFirstLetter(text) {
    if (text && text.length > 0) {
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    }
    return '';
}
