import {REQUEST_FAILED, REQUEST_STARTED, REQUEST_SUCCESSFUL} from "./constants";

export const requestStarted = () => ({
    type: REQUEST_STARTED,
});

export const requestSuccessful = ({ data }) => ({
    type: REQUEST_SUCCESSFUL,
    data,
});

export const requestFailed = ({ error }) => ({
    type: REQUEST_FAILED,
    error,
});