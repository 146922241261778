import React from 'react';
import ReactPlayer from 'react-player';

function TvChannel(props) {
    return (
        <div>

            <div className="my-4 text-xl font-bold">
                {props.location.state.title}
            </div>

            {/* TODO - decide how to handle autoplay (either in combination with muted or some custom prompt to ask user for permission,...)*/}
            <ReactPlayer
                width='100%'
                height="auto"
                url={props.location.state.urlToPlay}
                playsinline
                // muted
                // autoPlay
                controls
                playing
                pip={false}
            />

        </div>
    );
}

export default TvChannel;