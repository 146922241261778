import React from "react";
import {connect} from "react-redux";
import {extractImageUrl, packageInCart} from "../../utils/photoMatchUtils";
import {addPackageToCart} from "../../actions/photoMatchActions";
import currencyUtils from "../../utils/currencyUtils";
import {DefaultButton, DefaultButtonLink} from "../../resources/styles";

class PhotoMatchPackage extends React.Component {

    addPackage = () => {
        if(packageInCart(this.props.package.id)) {
            return;
        }

        addPackageToCart(this.props.package.id);
    };

    render() {
        const photoPackage = this.props.package;
        const packetInCart = packageInCart(photoPackage.id);
        const buttonText = packetInCart ? "Added" : "Add to Basket";

        return (
            <div key={photoPackage.id} className="row grid-x grid-padding-x grid-padding-y mb-1">
                <div className="medium-4 large-3 cell">
                    {photoPackage.image &&
                    <img className="mb-1" alt="bulk package" src={extractImageUrl(photoPackage.image)}/>
                    }
                </div>

                <div className="medium-8 large-9 cell">
                    <div className="text-xl">{photoPackage.title}</div>
                    <div>{photoPackage.blurb}</div>
                    <div>&pound; {currencyUtils.convertToCurrency(photoPackage.price)}</div>

                    <div className="text-sm text-italic">{photoPackage.description}</div>

                    {this.props.showButtons &&
                    <div>
                        <DefaultButton disabled={packetInCart}
                                onClick={this.addPackage}>
                            {buttonText}
                        </DefaultButton>

                        {packetInCart &&
                            <div className="ml-4 inline-block">
                                <DefaultButtonLink to="/photomatch/cart">View Basket</DefaultButtonLink>
                            </div>
                        }
                    </div>
                    }
                </div>
            </div>
        )
    }
}

// need to subscribe to cart changes in order to trigger re-rendering when something is added/removed from cart
const mapStateToProps = (state) => {
    return {
        cart: state.photomatch.cart
    };
};

export default connect(mapStateToProps)(PhotoMatchPackage);