import * as React from "react";
import {connect} from "react-redux";
import Modal from "../../../components/Modal";
import OverviewImageButtonPanel from "../../../components/OverviewImageButtonPanel";
import VodUtils from "../VodUtils";
import {loginToMaxicaster} from "../../../actions/vodActions";
import {fetchAudioStreamsFromMaxicaster} from "../../../actions/audioActions";
import {PropTypes} from "prop-types";
import VodImageButton from "../../../components/VodImageButton";

class OverviewAudio extends React.Component {

    componentDidMount() {
        if( this.props.maxicasterToken === null){
            this.props.loginToMaxicaster();
        } else {
            this.props.fetchFromMaxicaster();
        }
    }

    componentDidUpdate(prevProps){
        if(this.props.maxicasterToken !== null && this.props.isFetching === false && prevProps.maxicasterToken !== this.props.maxicasterToken){
            this.props.fetchFromMaxicaster();
        }
    }

    render() {

        if (! this.props.audios) {
            return false;
        }

        return (
            <div>
                <Modal show={this.props.isFetching}/>
                <OverviewImageButtonPanel>
                    {
                        this.props.audios.map((audio) => {
                            let id = audio.series_info.series_id;

                            let link = "/vod/overview/audio/";
                            if (audio.series_info.seasons && audio.series_info.seasons.length === 1) {
                                // if there is only 1 season available, create a link that directly shows the episodes (instead of only 1 season)
                                id = audio.series_info.seasons[0].season;
                                link = "/vod/overview/audio/"+audio.series_info.series_id+"/";
                            }

                            return (
                                <VodImageButton key={audio.series_info.series_id}
                                                     link={link+id}
                                                     title={audio.title}
                                                     item={audio}
                                                     onClickStar={() => this.watchlist(audio)}
                                                     thumbnail={VodUtils.getPictureUrl(audio)}/>
                            )
                        })
                    }

                </OverviewImageButtonPanel>
            </div>
        )
    }

}
OverviewAudio.contextTypes = {
    t: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    return {
        audios: state.audios.audios,
        isFetching: state.vod.isFetching,
        maxicasterToken: state.vod.maxicastertoken
    };
};

export default connect(mapStateToProps, {fetchFromMaxicaster: fetchAudioStreamsFromMaxicaster, loginToMaxicaster}) (OverviewAudio)