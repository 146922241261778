import React from 'react';
import {useGet} from "../../hooks/useGet";
import Loading from "../../components/Loading";
import CabinMessage from "../../components/cabinmessages/CabinMessage";

function CabinMessages(props) {
    const {isLoading, error, data} = useGet("cabinmessage/get/app");

    const messages = data?.messages
    console.log(isLoading, error, messages);

    if(isLoading) {
        return <Loading/>
    }

    if(error) {
        return <div>
            There has been an error: {error}
        </div>
    }

    if(!messages || messages.length === 0) {
        return <div>
            No messages.
        </div>
    }

    return (
        messages.map((message, index) => <CabinMessage message={message} key={message.id} index={index}/>)
    );
}

export default CabinMessages;




