import React from 'react';
import styled from 'styled-components';
import {device} from "../resources/styles";

const StyledDivider = styled.div`
    border-bottom: 2px solid #092A5E;
    
    @media ${device.laptop} {
        display: none;
    }
`;

function ItineraryDivider(props) {
    return (
        <StyledDivider className="ml-6"></StyledDivider>
    );
}

export default ItineraryDivider;