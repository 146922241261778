import React, {useState} from 'react';
import {PropTypes} from "prop-types";
import TextInputWithLabel from "../../components/TextInputWithLabel";
import {DefaultButton} from "../../resources/styles";
import {postToHubNew} from "../../actions/networkActions";
import {store} from "../../App";
import {SET_AUTHENTICATION} from "../../constants/authentication-action-types";

function HotelFriendLogin(props, context) {
    // TODO add translation values in .pot and .po files
    // TODO add validation and date picker if it makes sense
    const [submitting, setSubmitting] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [error, setError] = useState("");

    // users can only type numbers, the / are automatically added and removed for them
    const updateDOBifValid = (value) => {
        const strippedValue = value.replace(/\//g, "");
        const strippedLength = strippedValue.length;
        const oldValueLength = dateOfBirth.replace(/\//g,"").length;
        const lastChar = strippedValue.slice(-1);

        // prevent users typing anything but numbers
        if(strippedLength > 0 && isNaN(parseInt(lastChar))) {
            return;
        }

        // prevent anything too long
        if(strippedLength > 8) {
            return;
        }

        //if old stripped length === new stripped length this means they were removing a /
        if(strippedLength === oldValueLength) {
            setDateOfBirth(formatDate(strippedValue.slice(0,-1)))
        }
        else {
            setDateOfBirth(formatDate(strippedValue))
        }
    }

    // TODO - make this formatting nicer
    // function that adds / at the correct spots
    const formatDate = (value) => {
        const valueLength = value.length;
        if(valueLength < 2 ) {
            return value;
        }
        if(valueLength < 4) {
            return `${value.slice(0,2)}/${value.slice(2)}`
        }
        else {
            return `${value.slice(0,2)}/${value.slice(2,4)}/${value.slice(4)}`
        }
    }

    const submit = async (e) => {
        e.preventDefault();
        if(submitting) {
            return;
        }

        const payload = {
            firstName,
            lastName,
            dob: dateOfBirth,
        }

        setSubmitting(true);
        setError("");

        const result = await postToHubNew('authentication/loginByPms', payload);

        setSubmitting(false);

        if(result) {
            store.dispatch({type: SET_AUTHENTICATION, authentication: result});
        }
        else {
            setError("Login failed, please check the details you have entered");
        }
    }

    return (
        <div>
            <form onSubmit={(e) => submit(e)}>
                <div className="grid-container">
                    <div className="grid-x grid-padding-x align-center my-4">
                        <div className="cell small-10 medium-6 large-4 text-left">
                            <TextInputWithLabel
                                label={context.t("hf_login_first_name")}
                                value={firstName}
                                _onChange={value => setFirstName(value)}
                                placeholder="John"
                                classNames= "w-full"
                                />
                        </div>
                    </div>

                    <div className="grid-x grid-padding-x align-center my-4">
                        <div className="cell small-10 medium-6 large-4 text-left">
                            <TextInputWithLabel
                                label={context.t("hf_login_last_name")}
                                value={lastName}
                                _onChange={value => setLastName(value)}
                                placeholder="Smith"
                                classNames= "w-full"
                                />
                        </div>
                    </div>

                    <div className="grid-x grid-padding-x align-center my-4">
                        <div className="cell small-10 medium-6 large-4 text-left">
                            <TextInputWithLabel
                                label={context.t("hf_login_dob")}
                                value={dateOfBirth}
                                _onChange={value => updateDOBifValid(value)}
                                placeholder="01/01/1970"
                                classNames= "w-full"
                            />
                        </div>
                    </div>

                    {error &&
                    <div className="grid-x grid-padding-x align-center mt-4">
                        <div className="cell small-10 medium-6 large-4 text-left errors">
                            {error}
                        </div>
                    </div>}

                    <div className="grid-x grid-padding-x align-center mt-4">
                        <div className="cell small-10 medium-6 large-4 text-left">
                            <DefaultButton type="submit" disabled={submitting}>
                                Login
                            </DefaultButton>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

HotelFriendLogin.contextTypes = {
    t: PropTypes.func.isRequired
};

export default HotelFriendLogin;