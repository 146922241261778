import React from 'react';
import {connect} from 'react-redux';
import {PropTypes} from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import browserHistory from "../../utils/history";
import Checkbox from '../../components/Checkbox';
import CartElements from '../../components/CartElements';
import {postToHub} from '../../actions/networkActions';
import {logoutUser} from '../../actions/authenticationActions';
import TextInputWithLabel from '../../components/TextInputWithLabel';
import {updateBookings, updateCart} from '../../actions/cartActions';
import CartTCs from '../../components/CartTCs';
import CartEmpty from '../../components/CartEmpty';
import {cartContainsResortExcursions} from '../../utils/cartUtils';
import {DefaultButton} from '../../resources/styles';

class Basket extends React.Component {
    constructor() {
        super();
        this.state = {
            email: "",
            confirmEmail: "",
            mobileNumber: "",
            importantInfoChecked: false,
            preferredPaymentCard: true,
            errors: {},
        };
    }

    toggleImportantInfoCheckbox() {
        this.setState({
            importantInfoChecked: !this.state.importantInfoChecked
        });
    }

    processForm = (e) => {
        e.preventDefault();
        let errors = {};

        if (!this.isEmail(this.state.email)) {
            errors.email = this.context.t("basket_email_error");
        }

        if (this.state.email !== this.state.confirmEmail) {
            errors.confirm = this.context.t("basket_email_missmatch");
        }

        if (!this.state.importantInfoChecked) {
            errors.importantInfo = this.context.t("basket_tc_error");
        }

        if (Object.keys(errors).length > 0) {
            this.setState({
                errors: errors,
            });
        }
        else {
            this._order();
        }
    };

    //--

    _order() {
        // store the content of the cart before paying (to be able to show the user what was paid)
        const paymentMethod = this.state.preferredPaymentCard ? "Card" : "Cash";

        const confirmationData = {
            total: this.props.cartTotalPrice,
            excursions: this.props.cartExcursions,
            mobileNumber: this.state.mobileNumber,
            confirmationMail: this.state.confirmEmail,
            paymentMethod,
        };


        let request = {
            confirmationMail: this.state.confirmEmail,
            mobileNumber: this.state.mobileNumber,
            paymentReference: paymentMethod.toUpperCase(),
        };

        this.props.postToHub("passengers/cart/order", request, (response) => {
            this.onOrderCompleted(response, confirmationData);
        });
    }

    onOrderCompleted = (hubResponse, confirmationData) => {
        this.props.updateCart();
        this.props.updateBookings();

        browserHistory.push({
            pathname: '/confirmation',
            state: {...confirmationData, hubResponse}
        });
    };

    //--

    isEmail = (email) => {
        const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        return re.test(String(email).toLowerCase());
    };

    togglePaymentMethod = () => {
        this.setState({
            preferredPaymentCard: !this.state.preferredPaymentCard,
        });
    };

    //--

    render() {
        if (isEmpty(this.props.cartExcursions)) {
            return (
                <CartEmpty/>
            );
        }

        return (
            <div>
                <div className="checkout-container">
                    <h1 className="text-uppercase">{this.context.t("basket_header")}</h1>

                    <div>

                        <CartElements/>

                        {/* total cost */}
                        <div className="text-right font-bold text-xl padding-horizontal-05">
                            {this.context.t("basket_total_cost")} £{this.props.cartTotalPrice}
                        </div>
                    </div>

                    <h1 className="text-uppercase">{this.context.t("basket_contact_details")}</h1>

                    <div>

                        {/* contact details form */}
                        <form className="margin-vertical-1">

                            <div className="px-2 sm:px-4 mb-8">
                                <div className="my-2">
                                    <TextInputWithLabel
                                        label={this.context.t("basket_email")}
                                        type="text"
                                        value={this.state.email}
                                        _onChange={value => this.setState({email: value})}
                                        placeholder=""
                                        errorMessage={this.state.errors.email}
                                    />
                                </div>

                                <div className="my-2">
                                    <TextInputWithLabel
                                        label={this.context.t("basket_confirm_email")}
                                        type="text"
                                        value={this.state.confirmEmail}
                                        _onChange={value => this.setState({confirmEmail: value})}
                                        placeholder=""
                                        errorMessage={this.state.errors.confirm}
                                    />
                                </div>

                                <div className="my-2">
                                    <TextInputWithLabel
                                        label={this.context.t("basket_mobile_number_label")}
                                        type="text"
                                        value={this.state.mobileNumber}
                                        _onChange={value => this.setState({mobileNumber: value})}
                                        placeholder=""
                                    />
                                    {this.context.t("basket_mobile_number_tip")}
                                </div>

                            </div>

                            {cartContainsResortExcursions(this.props.cartExcursions) &&
                            <div>
                                <div>
                                    {this.context.t("basket_payment_label")}
                                </div>
                                <div>
                                    <div className="my-1">
                                        <Checkbox
                                            label={this.context.t("basket_payment_card")}
                                            checked={this.state.preferredPaymentCard}
                                            handleCheckboxChange={() => this.togglePaymentMethod()}
                                        />
                                    </div>

                                    <div className="my-1">
                                        <Checkbox
                                            label={this.context.t("basket_payment_cash")}
                                            checked={!this.state.preferredPaymentCard}
                                            handleCheckboxChange={() => this.togglePaymentMethod()}
                                        />
                                    </div>
                                </div>
                                <div>
                                    {this.context.t("basket_payment_tip")}
                                </div>
                            </div>
                            }

                            {/* terms & conditions*/}
                            <div className="margin-vertical-1">
                                <div>
                                    <Checkbox
                                        label={this.context.t("basket_accept_conditions")}
                                        checked={this.state.importantInfoChecked}
                                        handleCheckboxChange={() => this.toggleImportantInfoCheckbox()}
                                    />
                                </div>
                                <div className={this.state.errors.importantInfo ? "errors" : "tos-text"}>
                                    {this.state.errors.importantInfo}
                                </div>
                            </div>

                            {/* "submit" button */}
                            <div>
                                <DefaultButton onClick={this.processForm}>
                                    {this.context.t("basket_continue")}
                                </DefaultButton>
                            </div>

                        </form>


                        {/* TERMS and conditions*/}
                        {/* TODO - translations of long texts such as Terms & Conditions*/}
                        <CartTCs/>
                    </div>
                </div>
            </div>
        )
    }
}

Basket.contextTypes = {
    t: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        authentication: state.authentication,
        cartExcursions: state.cart.excursions,
        cartTotalPrice: state.cart.totalPrice,
    };
}

const mapDispatchToProps = {
        postToHub,
        logoutUser,
        updateCart,
        updateBookings,
};

export default connect(mapStateToProps, mapDispatchToProps)(Basket);

