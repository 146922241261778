import React from "react";
import ImageButton from "../../components/ImageButton";
import If from "../../components/If";
import OverviewImageButtonPanel from "../../components/OverviewImageButtonPanel";
import {fetchFromHub} from "../../actions/networkActions";
import {connect} from "react-redux";
import {getTranslation} from "../../translations/translationUtils";
import FullScreenContent from './FullScreenContent';
import ClassicContent from './ClassicContent';
import ExtendedContent from './ExtendedContent';
import UnfilteredHtmlContent from './UnfilteredHtmlContent';
import Timetable from './Timetable';
import {setWayfinderProfiles} from "../../actions/wayfinderActions";
import {recordPageview} from "../../utils/gaUtils";
import ExcursionTimetable from "./ExcursionTimetable";
import DailySchedule from "./DailySchedule";
import PortTimetable from "./PortTimetable";
import TimetableAsSchedule from "./TimetableAsSchedule";

class Detail extends React.Component {
    contentComponents = {
        FullScreen: FullScreenContent,
        Classic: ClassicContent,
        Extended: ExtendedContent,
        UnfilteredHtml: UnfilteredHtmlContent,
        Timetable: Timetable,
        ExcursionTimetable: ExcursionTimetable,
        ItineraryTimetable: PortTimetable,
        AgendaTimetable: TimetableAsSchedule,
        DailySchedule: DailySchedule
    };

    constructor(props) {
        super(props);
        this.state = {
            content: null,
            articles: null,
            pdfs: [],
            audios: [],
            videos: [],
            filteredArticles: []
        };
    }

    componentDidMount() {
        this._updateAllResources(this.props.match.params.id);
    }

    componentDidUpdate(prevProps) {
        if(prevProps.match.params.id !== this.props.match.params.id) {
            this._updateAllResources(this.props.match.params.id);
        }
    }

    _updateAllResources = (id) => {
        this._updateContent(id);
        this._updatePdfInfo(id);
        this._updateAudios(id);
        this._updateVideos(id);
    };

    _updateContent(id) {
        this.props.fetchFromHub(`cms/articles/` + id, this.postProcessUpdate);
    }

    _updatePdfInfo(id) {
        this.props.fetchFromHub(`blobs/pdfs/` + id, this.postProcessPdfInfo);
    }

    _updateAudios(id) {
        this.props.fetchFromHub(`blobs/audios/` + id, this.postProcessAudios);
    }

    _updateVideos(id) {
        this.props.fetchFromHub(`blobs/videos/` + id, this.postProcessVideos);
    }

    postProcessUpdate = (content) => {
        const wayfinderProfileIds = content.wayfinderProfile ? [content.wayfinderProfile.id] : [];
        setWayfinderProfiles(wayfinderProfileIds);

        recordPageview(window.location.pathname + window.location.search, "Article - " + getTranslation(content.category?.title) + " - " + getTranslation(content.title));
        this.setState({content: content});
        this._updateArticles(content);
    };

    postProcessPdfInfo = (pdfs) => {
        this.setState({pdfs});
    };

    postProcessAudios = (audios) => {
        const audiosFull = [];
        for (let i = 0; i < audios.length; i++) {
            const fullAudio = audios[i];
            fullAudio.absoluteUrl = this.props.configuration.hubUrl + audios[i].url;
            audiosFull.push(fullAudio);
        }

        this.setState({audios: audiosFull});
    };

    postProcessVideos = (videos) => {
        const videosFull = [];
        for (let i = 0; i < videos.length; i++) {
            const fullVideo = videos[i];
            fullVideo.urlFrontend = videos[i].urlFrontend;
            videosFull.push(fullVideo);
        }

        this.setState({videos: videosFull});
    };

    _updateArticles(r) {
        if(r.showAdditionalArticles === true){
            this.props.fetchFromHub(`cms/articles/findByCategoryId/` + r.additionalArticlesCategory.id, this.setArticles);
        }else {
            this.setState({articles: [], filteredArticles: []});
        }
    }


    setArticles = (articles) => {
        this.setState({
            articles: articles,
            filteredArticles: articles
        })
    };



    render() {
        let content = this.state.content;
        let filteredArticles = this.state.filteredArticles;

        if (content === null) {
            return false;
        }

        const Content = this.contentComponents[content.layoutType];

        const title = getTranslation(content.title);
        const description = getTranslation(content.description);
        const whereAndWhen = content.whereAndWhen;
        const newAudios = this.state.audios;
        const videos = this.state.videos;


        return (
            <div>
                <Content
                    contentId={content.id}
                    title={title}
                    description={description}
                    image={this.props.configuration.hubUrl + "blobs/image?reference=" + content.id + "&width=400"}
                    pdfItems={this.state.pdfs}
                    audioItems={newAudios}
                    videoItems={videos}
                    whereAndWhen={whereAndWhen}
                />

                    <If test={filteredArticles.length !== 0}>
                        <OverviewImageButtonPanel>
                                {
                                    this.state.filteredArticles.map((article) => {
                                        let title = getTranslation(content.title);
                                        if (article.id !== content.id) {
                                            title = getTranslation(article.title);
                                        }
                                        const description = getTranslation(content.description);


                                        return (
                                            <If test={article.id !== content.id}>
                                                <div key={article.id}
                                                     className="cell">
                                                    <ImageButton link={"/content/detail/" + article.id}
                                                                 title={title}
                                                                 thumbnail={this.props.configuration.hubUrl + "blobs/image?reference=" + article.id + "&width=400"}/>
                                                    <If test={description !== null && description.length > 0}>
                                                        <div style={{paddingBottom: "26px"}}
                                                             dangerouslySetInnerHTML={{__html: getTranslation(article.overviewDescription)}}/>
                                                    </If>
                                                </div>
                                            </If>
                                        )
                                    })
                                }
                        </OverviewImageButtonPanel>
                    </If>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return { configuration: state.configuration };
};

export default connect(mapStateToProps, {fetchFromHub: fetchFromHub})(Detail);