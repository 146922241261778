import React from 'react';
import styled from 'styled-components';

const StyledHeader = styled.div`
    display: flex;
    align-items: center;
    font-size: 1rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    
    
    img {
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
    }
    
`;

function ItineraryHeader(props) {
    return (
        <StyledHeader>
            <img src={require('../resources/images/itinerary_icons/' + props.icon)} alt="" />
            <span className="text-uppercase">{props.title}</span>
        </StyledHeader>
    );
}

export default ItineraryHeader;