export function getFeatureImageFilename(feature) {
    switch (feature) {
        case "istant":
        case "skip":
        case "fast":
        case "free":
        case "official":
        case "ADDVA_ENTRAN":
        case "ADDVA_EXCLUS":
        case "ADDVA_GUIDE":
        case "ADDVA_KIDS":
        case "ADDVA_LOCAL":
        case "ADDVA_MEAL":
        case "ADDVA_PRITOU":
        case "ADDVA_SMALL":
        case "ADDVA_SUBJEC":
        case "ADDVA_TOURAU":
        case "ADDVA_VOUCH":
            return `${feature}.svg`;

        default:
            return "default_icon.svg";

    }
}

export function groupTaxonomies(rawTaxonomies) {
    const taxonomies = {};
    rawTaxonomies.forEach(item => {
        if(!taxonomies[item.type]) {
            taxonomies[item.type] = [];
        }

        taxonomies[item.type].push(item.name);
    });

    return taxonomies;
}

export function stripHtmlTags(text) {
    if(!text) {
        return "";
    }

    if (typeof text !== 'string' && !(text instanceof String)) {
        return "";
    }

    return text.replace(/<[^>]*>/g, '');
}