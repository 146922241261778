export default function (state = null, action) {
    switch (action.type) {
        case 'CLEAR_TAGS':
            return null;

        case 'SET_TAGS':
            return action.tags;

        default:
            return state;
    }
}
