const INITIAL_STATE = {profiles: []};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'CLEAR_WAYFINDER_PROFILES':
            return INITIAL_STATE;

        case 'SET_WAYFINDER_PROFILES':
            return {  profiles: action.profiles};

        default:
            return state;
    }
}