import React, {useState} from 'react';
import styled from 'styled-components';
import {postToHubNew} from "../../actions/networkActions";
import MessageIcon from "./MessageIcon";
import {formatTimestampToShortText} from "../../utils/dateTimeUtils";

const StyledMessage = styled.div`
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
        background-color: ${props => props.index % 2 === 0 ? "#fff" : "#f5f5f5"};
        margin: 1rem 0;
        position: relative;
        
        
        .unreadMarker {
            width: 0.6rem;
            height:  0.6rem;
            border-radius: 50%;
            background-color: ${ props => props.theme.activeButtonBorderColor || "#ccc" };
            position: absolute;
            top: 4px;
            right: 4px;
        }
        
        .messageDetails {
            padding: 1rem;
        }
`;

const MessageHeader = styled.div`
    padding: 1rem;
    color: ${props => props.status !== "Read" && props.type === "Urgent" ? props.theme.cabinMessagesUrgentColor : props.theme.cabinMessagesReadColor }
    
    &:hover {
        border: solid 1px rgb(152, 226, 255);
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    
    &:hover {
        background-color: ${props => props.theme.cabinMessagesHoverBackgroundColor};
        color: ${props => props.theme.cabinMessagesHoverTextColor};
        
        .messageIcon {
            color: white;
        }
    }
    
    .messageIcon {
        display: flex;
        color: ${ props => props.status === "Read" || props.type === "Normal" ?
    props.theme.cabinMessagesReadColor || "#aaa" :
    props.type === "Urgent" ?
        props.theme.cabinMessagesUrgentColor || "#aaa" :
        props.theme.cabinMessagesImportantColor || "#aaa"
}
    }
`;

function CabinMessage({message, index}) {
    const [isOpen, setIsOpen] = useState(false);
    const [messageStatus, setMessageStatus] = useState(message.recipientMessageStatus);

    const handleToggle = async () => {
        //check if not yet read (and not open) and if so post to hub that this has been read
        if(!isOpen && messageStatus === "Sent") {
            const result = await postToHubNew(`cabinmessage/read/app/${message.sourceMessageId}`, {});

            if(result === "") {
                setMessageStatus("Read");
            }
            else {
                console.log("TODO - show to the user that confirming to the hub that the message is read has failed");
            }

            setIsOpen(true);
        }
        else {
            setIsOpen(prevOpen => !prevOpen);
        }
    }

    return (
        <StyledMessage isOpen={isOpen} index={index}>
            <div className="cursor-pointer select-none" onClick={handleToggle}>
                {messageStatus !== "Read" &&
                <div className="unreadMarker"></div>
                }
                <MessageHeader className="flex items-center" type={message.messageType} status={messageStatus}>
                    <div className="messageIcon">
                        <MessageIcon type={message.messageType} status={messageStatus} />
                    </div>
                    <div className={`flex-1 ml-6 ${messageStatus === "Read" ? "" : "font-bold" }`}>
                        <div className="text-sm">
                            {formatTimestampToShortText(message.createdAt)}
                        </div>

                        <div>
                            {message.messageTitle.defaultValue}
                        </div>
                    </div>
                    <div>
                        <i className={`fa fa-chevron-${isOpen ? "up" : "down"}`}></i>
                    </div>
                </MessageHeader>
            </div>
            {isOpen &&
            <div className="messageDetails">
                {message.messageContent.defaultValue}
            </div>
            }

        </StyledMessage>
    )
}
export default CabinMessage;