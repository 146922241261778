import React, {useEffect, useState} from 'react';
import {getFromHubNew, getResourceImageUrl} from "../../actions/networkActions";
import Loading from "../../components/Loading";
import OverviewImageButtonPanel from "../../components/OverviewImageButtonPanel";
import OverviewImageButton from "../../components/OverviewImageButton";

function ShoreExcursions(props) {
    const [itinerary, setItinerary] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const itineraryData = await getFromHubNew("itinerary/schedules");
            setItinerary(itineraryData);
            setLoading(false);
        }

        fetchData();
    }, []);

    if(loading) {
        return (
            <Loading/>
        )
    }

    return (
        <div>
            <OverviewImageButtonPanel>
                {
                    itinerary.map((day) =>
                        <OverviewImageButton
                            heightWidthRatio="100%"
                            key={day.id}
                            link={`/shoreexcursions/${day.date.replace(/\//g, "-")}`}
                            title={(
                                <div className="my-2">
                                    <div>{day.date}</div>
                                    <div style={{textTransform: "none"}} className="text-base">
                                        {day.title.defaultValue}
                                    </div>
                                </div>
                            )}
                            thumbnail={getResourceImageUrl(day.id, {width: 400})}
                        />
                    )
                }
            </OverviewImageButtonPanel>
        </div>
    );
}

export default ShoreExcursions;