import React from 'react';
import {connect} from 'react-redux';
import {PropTypes} from 'prop-types';
import {setLanguage} from 'redux-i18n'
import styled from 'styled-components';

import {StyledFooter} from "../resources/styles";

const ToggleButton = styled.button`
    color: ${props => props.theme.footerColor || "#ffffff" };
    border-bottom: 1px solid  ${props => props.theme.footerColor || "#ffffff" };
    padding: 0.375rem 0;
`;

const ToggleLabel = styled.span`
    padding: 0.375rem 0;
    margin-right: 1rem;
`;

const LanguageList = styled.div`
    color: ${props => props.theme.footerBackground || "rgba(12, 12, 12, 0.75)" };
    border-top: ${props => props.theme.footerBorderTop || "none" };
    background: ${props => props.theme.footerColor || "#ffffff" };
`;

const LanguageButton = styled.button`
    color: ${props => props.theme.footerBackground || "rgba(12, 12, 12, 0.75)" };
    width: 100%;
    cursor: pointer;
    padding: 0.5rem 0;
    
    &:hover {
        background-color: rgba(20, 20, 20, 0.01);
    }
    
`;

const LanguageButtonInner = styled.div`
    border-bottom: 1px solid ${props => props.theme.footerBackground || "rgba(12, 12, 12, 0.75)" };
    padding-bottom: 0.375rem;

    ${LanguageButton}:hover & {
        padding-bottom: 0.3125rem;
        border-bottom: 2px solid ${props => props.theme.footerBackground || "rgba(12, 12, 12, 0.75)" };
    }
    
`;

const Container = styled.div`
    display: flex;
    height: ${props => props.theme.footerHeight || "50px" };
    align-items: center;
    justify-content: center;
    box-shadow: ${props => props.theme.footerBoxShadow || "" };
`;

class FooterLanguageSelect extends React.Component {

    constructor() {
        super();

        this.state = {
            listOpen: false,
        };
    }

    toggleListOpen = () => {
        this.setState({
            listOpen: !this.state.listOpen,
        });
    };

    onChangeLang = (lang) => {
        this.props.setLanguage(lang);
        this.setState({
            listOpen: false,
        });
    };

    render() {
        if(!this.props.hubLanguages || !this.props.hubLanguages.length || this.props.hubLanguages.length < 2) {
            return null;
        }

        const selectedLanguage = this.props.hubLanguages.find((language) => language.code === this.props.language);
        const selectedLanguageLabel = selectedLanguage ? selectedLanguage.name : "";
        return (
            <StyledFooter>
                {this.state.listOpen &&
                    <LanguageList>
                        <div className="grid-container">
                            <div>
                                <div className="grid-x justify-center grid-padding-x small-up-2 medium-up-3 large-up-4 py-2">
                                    {this.props.hubLanguages.map((language) =>
                                            <div key={language.code} className="cell my-1" onClick={() => this.onChangeLang(language.code)}>
                                                <LanguageButton>
                                                    <LanguageButtonInner>
                                                        {language.name}
                                                    </LanguageButtonInner>
                                                </LanguageButton>
                                            </div>)
                                    }
                                </div>
                            </div>
                        </div>
                    </LanguageList>
                }
                <Container>
                    <ToggleLabel>{this.context.t("choose_language_label")}</ToggleLabel>
                    <ToggleButton onClick={this.toggleListOpen}>
                        {selectedLanguageLabel} <i className={`ml-2 fa fa-chevron-${this.state.listOpen ? "up" : "down"}`}/>
                    </ToggleButton>
                </Container>
            </StyledFooter>
        );
    }
}

const mapStateToProps = state => {
    return {
        hubLanguages: state.configuration.hubSettings && state.configuration.hubSettings.languages && state.configuration.hubSettings.languages.length ?
                state.configuration.hubSettings.languages :
                null,
        language: state.i18nState.lang,
    };
};

FooterLanguageSelect.contextTypes = {
    t: PropTypes.func.isRequired
};

export default connect(mapStateToProps, {setLanguage}) (FooterLanguageSelect);