import React from 'react';
import {connect} from 'react-redux';
import {PropTypes} from 'prop-types';
import {formatItineraryDate, getNrNightsDiff} from '../utils/dateTimeUtils';
import ItineraryHeader from "./ItineraryHeader";
import ItineraryDivider from "./ItineraryDivider";

class ItineraryHotels extends React.Component {
    render() {
        if (!this.props.hotels || this.props.hotels.length === 0) {
            return null;
        }

        return (
            <div>
                <ItineraryHeader title="Hotel" icon="hotel.svg"/>
                <div className="ml-6">
                    {this.props.hotels.map(hotel => {
                        return (
                            <HotelElement key={hotel.id} hotel={hotel}/>
                        )
                    })}
                </div>
                <ItineraryDivider/>
            </div>
        );
    }
}

ItineraryHotels.contextTypes = {
    t: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        hotels: (state.itinerary.hotels ? state.itinerary.hotels : null),
    };
}

export default connect(mapStateToProps) (ItineraryHotels);


//TODO - add translations when hub supports them for hotel information
class HotelElement extends React.Component {
    render() {
        if(!this.props.hotel) {
            return null;
        }

        return (
            <div className="margin-bottom-1">
                <span className="text-uppercase">{this.props.hotel.hotelName}</span>
                <span>, {this.props.hotel.resortName}, {this.props.hotel.subResortName}, {this.props.hotel.touristRegionName}</span>
                <ul>
                    <li>
                        {getNrNightsDiff(this.props.hotel.fromDate, this.props.hotel.toDate)} nights from {formatItineraryDate(this.props.hotel.fromDate)} - {formatItineraryDate(this.props.hotel.toDate)}
                    </li>
                </ul>
            </div>
        )
    }
}