import React from "react";
import styled, {withTheme} from "styled-components";

const AnimatePulse = styled.div`
    width: 50px;
    animation: pulse 1.5s infinite;
    @keyframes pulse {
        from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
        }
        
        50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
        }
        
        to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
        }
    }
`;

const DownIcon = ({theme}) => {

  return (
      <AnimatePulse>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46.88 62.74">
          <defs>
          </defs>
          <path fill={ theme.kioskLoginIconColor || "#092a5e" }
                class="a"
                d="M5.2,23.47A4.64,4.64,0,0,0,1,31L19,60.06a5,5,0,0,0,8.86,0L45.88,31a5.9,5.9,0,0,0,1-3.09,4.28,4.28,0,0,0-.53-2.07,5,5,0,0,0-4.61-2.35h-7.2V0H12.34V23.47ZM16.86,4.52H30V28H41.68a2.32,2.32,0,0,1,.59.07,2.65,2.65,0,0,1-.25.54L24,57.68c-.3.47-.54.6-.59.61s-.29-.14-.59-.61L4.84,28.6a3.32,3.32,0,0,1-.25-.54A2.32,2.32,0,0,1,5.18,28H16.86Z"/>
        </svg>
      </AnimatePulse>
  );
}

export default withTheme(DownIcon);