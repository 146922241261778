import React, {useState} from 'react';

function ModalGallery(props) {
    const [isMouseDown, setIsMouseDown] = useState(false);

    if(!props.show) {
        return null;
    }

    const preventClickPropagation = (e) => {
        e.stopPropagation();
    };

    const onMouseDown = (e) => {
        setIsMouseDown(true);
    };

    const onMouseUp = (e) => {
        setIsMouseDown(false);
    };

    const handleBgClick = (e) => {
        if(!isMouseDown) {
            props.onClose();
        }
    };

    return (
        <div className="fixed inset-0 z-50 overflow-auto bg-smoke-darker flex items-center" onClick={handleBgClick}>
            <div className="relative w-full md:max-w-md xl:max-w-xl m-auto">
                <div
                    className="text-grey-darker m-2 cursor-pointer absolute z-20 right-0 top-0"
                    onClick={props.onClose}
                >

                    <svg className="w-6 h-6 inline fill-current stroke-current" viewBox="0 0 12 12">
                        <path d="M6 4.834L1.423.256C.645-.52-.521.646.256 1.423L4.834 6 .256 10.577c-.777.778.39 1.944 1.167 1.167L6 7.166l4.577 4.578c.778.777 1.944-.39 1.167-1.167L7.166 6l4.578-4.577c.777-.778-.39-1.944-1.167-1.167L6 4.834z"/>
                    </svg>
                </div>
                <div onMouseDown={onMouseDown} onMouseUp={onMouseUp} onClick={preventClickPropagation}>
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default ModalGallery;