import React from "react";
import styled from "styled-components";

const DisabilityContainer = styled.div`
    i { padding-left: 0.5rem;};
`;

const ArrowContainer = styled.div`
    margin-left: 1rem;
`;

const DisabilityMode = ({disabilityOn, toggleDisabilityMode, alterMarginTop}) => {

    return (
        <DisabilityContainer>
            <i className="fa fa-wheelchair"></i>
            <i
                className={ !disabilityOn ? "fa fa-toggle-off" : "fa fa-toggle-on" }
                onClick={toggleDisabilityMode}>
            </i>
            { disabilityOn &&
                <ArrowContainer>
                    <i
                        className="fa fa-arrow-down"
                        onClick={() => alterMarginTop(50)}
                    >
                    </i>
                    <i
                        className="fa fa-arrow-up"
                        onClick={() => alterMarginTop(-50)}
                    >
                    </i>
                </ArrowContainer>
            }
        </DisabilityContainer>
    );
};

export default DisabilityMode;