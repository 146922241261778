import React from 'react';
import _union from 'lodash/union';
import ExcursionEntry from "./ExcursionEntry";
import Checkbox from "../Checkbox";
import RadioButton from "../RadioButton";
import {formatPortDate} from "../../utils/dateTimeUtils";
import If from '../If';


class PresalesExcursionsList extends React.Component {
    constructor(props) {
        super(props);

        const excursionTypes = _union(...this.props.excursions.map(excursion => excursion.excursionTypes)).sort();
        let typeFilterOptions = {};
        for (const excursionType of excursionTypes) {
            typeFilterOptions[excursionType] = {selected: false, available: true};
        }

        this.state = {
            openFilter: "",
            filteredExcursions: this.props.excursions,
            typeFilter: {
                options: typeFilterOptions,
            },
            durationFilter: {
                options: [
                    {
                        label: "all",
                        minDuration: 0,
                        maxDuration: 1440,
                        disabled: false,
                    },
                    {
                        label: "2h or less",
                        minDuration: 0,
                        maxDuration: 120,
                        disabled: false,
                    },
                    {
                        label: "2h to 5h",
                        minDuration: 120,
                        maxDuration: 300,
                        disabled: false,
                    },
                    {
                        label: "5h or more",
                        minDuration: 300,
                        maxDuration: 1440,
                        disabled: false,
                    }
                ],
                selectedOption: "all",
                minDuration: 0,
                maxDuration: 1440
            },
        };
    }

    componentDidMount() {
        this.filterExcursions();
    }


    toggleFilterVisibility = (filterName) => {
        if(filterName === this.state.openFilter) {
            this.setState({
                openFilter: ""
            });
        } else {
            this.setState({
                openFilter: filterName
            })
        }
    };

    filterExcursions = () => {
        const filteredByType = this.props.excursions.filter((excursion) => {
            return this.matchesTypeCriteria(excursion);
        });

        const filteredExcursions = this.props.excursions.filter((excursion) => {
            return this.excursionMatchesFilterCriteria(excursion);
        });

        //check which type filters should be disabled
        //this could be optimized if performance becomes a problem
        let typeFilterOptions = this.state.typeFilter.options;
        for (const type in typeFilterOptions) {
            if(!typeFilterOptions[type].selected) {
                const optionDisabled = filteredExcursions.filter((excursion) => excursion.excursionTypes.indexOf(type) >= 0).length === 0;
                typeFilterOptions[type].disabled = optionDisabled;
            }
        }

        //check which duration filters should be disabled
        //this could be optimized if performance becomes a problem
        this.state.durationFilter.options.map((durationOption) => {
            durationOption.disabled = !filteredByType.some((filteredExcursion) => filteredExcursion.duration <= durationOption.maxDuration && filteredExcursion.duration >= durationOption.minDuration)
            return durationOption;
        });

        this.setState({
            filteredExcursions: filteredExcursions,
            typeFilter: {
                ...this.state.typeFilter,
                options: typeFilterOptions,
            },
        });
    };

    excursionMatchesFilterCriteria(excursion) {
        return this.matchesTypeCriteria(excursion) && this.matchesDurationCriteria(excursion);
    }

    matchesTypeCriteria(excursion) {
        let showExcursion = true;

        for (const type in this.state.typeFilter.options) {
            if(this.state.typeFilter.options[type].selected && excursion.excursionTypes.indexOf(type) < 0) {
                showExcursion = false;
                break;
            }
        }

        return showExcursion;
    }

    matchesDurationCriteria(excursion) {
        //check if excursion duration is between minDuration and maxDuration
        if(excursion.duration > this.state.durationFilter.maxDuration ||
            excursion.duration < this.state.durationFilter.minDuration) {
            return false;
        }

        return true;
    }

    toggleTypeFilterOption = (type) => {
        let typeFilterOptions = this.state.typeFilter.options;
        typeFilterOptions[type].selected = !typeFilterOptions[type].selected;
        this.setState({
            typeFilter: {
                ...this.state.typeFilter,
                options: typeFilterOptions,
            },
        }, this.filterExcursions);

    };

    handleDurationFilterChange = (selectedOption) => {
        this.setState({
            durationFilter: {
                ...this.state.durationFilter,
                selectedOption: selectedOption.label,
                minDuration: selectedOption.minDuration,
                maxDuration: selectedOption.maxDuration
            },
        }, this.filterExcursions);
    };

    clearAllFilters = () => {
        let typeFilterOptions = this.state.typeFilter.options;
        for(const type in typeFilterOptions) {
            typeFilterOptions[type].selected = false;
        }

        this.setState({
            typeFilter: {
                ...this.state.typeFilter,
                options: typeFilterOptions,
            },
            durationFilter: {
                ...this.state.durationFilter,
                selectedOption: "all",
                minDuration: 0,
                maxDuration: 1440
            },
        }, this.filterExcursions)
    };

    render() {
        const maxDuration = this.props.excursions.reduce((a, b) => Math.max(a,b.duration), 0);
        const minDuration = this.props.excursions.reduce((a, b) => Math.min(a,b.duration), 9999);

        return (
            <div className="text-black">
                <If test={!this.props.hideHeadersAndFilters}>
                    {/*basic itinerary info*/}
                    <div className="container mx-auto my-4 text-primary-dark">
                        <h1 className="excursion capitalize">
                            {this.props.excursions.length}{" "}
                            experiences from{" "}
                            {this.props.locationState?.portName?.toUpperCase()}
                        </h1>
                        <div className="text-2xl">
                            {formatPortDate(this.props.locationState?.portDate) || ""}
                        </div>
                        <div className="text-2xl">
                            Time in port: {this.props.locationState?.arrivalTime} - {this.props.locationState?.departureTime}
                        </div>
                    </div>
                    {/*filter headers*/}
                    <div className="excursion-filters-container">
                        <div className="container mx-auto flex">


                            <FilterHeader
                                filterId="type"
                                filterName={`Experience type`}
                                openFilter={this.state.openFilter}
                                toggleVisibility={this.toggleFilterVisibility}
                            />

                            <FilterHeader
                                filterId="duration"
                                filterName="Duration"
                                openFilter={this.state.openFilter}
                                toggleVisibility={this.toggleFilterVisibility}
                            />
                        </div>
                    </div>
                    {/*filter content*/}
                    <div>
                        {/*type filter*/}
                        {
                            this.state.openFilter === "type" &&
                            <ExcursionFilter
                                filterBy={`Experience type`}
                                closeFilter={() => this.toggleFilterVisibility("type")}
                                clearFilter={this.clearAllFilters}
                            >
                                <ExcursionTypeFilter
                                    excursionTypes={this.state.typeFilter.options}
                                    handleCheckboxChange={this.toggleTypeFilterOption}
                                />
                            </ExcursionFilter>
                        }
                        {/*duration filter*/}
                        {
                            this.state.openFilter === "duration" &&
                            <ExcursionFilter
                                filterBy={`Experience duration`}
                                closeFilter={() => this.toggleFilterVisibility("duration")}
                                clearFilter={this.clearAllFilters}
                            >
                                <ExcursionDurationFilter
                                    minDuration={minDuration}
                                    maxDuration={maxDuration}
                                    options={this.state.durationFilter.options}
                                    handleRadioChange={this.handleDurationFilterChange}
                                    selectedRadio={this.state.durationFilter.selectedOption}
                                />
                            </ExcursionFilter>
                        }
                    </div>
                </If>
                

                {/*excursions*/}
                <div className="bg-primary-lightest">
                    <div className="container mx-auto py-8">
                        {this.state.filteredExcursions.map((excursion, index)  =>
                            <ExcursionEntry
                                key={excursion.id}
                                first={(index === 0)}
                                {...excursion}
                                locationState={this.props.locationState}
                                buttonIsLink={!this.props.hideHeadersAndFilters}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default PresalesExcursionsList;

class ExcursionFilter extends React.Component {
    render() {
        return (
            <div>
                {/*filter header*/}
                <div className="container mx-auto">
                    <div className=" py-4 border-b">
                        Filter by <span className="font-bold">{this.props.filterBy}</span>
                        <div
                            className="float-right text-primary-light cursor-pointer"
                            onClick={this.props.closeFilter}
                        >
                            <svg className="w-4 h-4 fill-current stroke-current" viewBox="0 0 12 12">
                                    <path d="M6 4.834L1.423.256C.645-.52-.521.646.256 1.423L4.834 6 .256 10.577c-.777.778.39 1.944 1.167 1.167L6 7.166l4.577 4.578c.778.777 1.944-.39 1.167-1.167L7.166 6l4.578-4.577c.777-.778-.39-1.944-1.167-1.167L6 4.834z"/>
                            </svg>
                        </div>
                    </div>
                </div>
                {/*filter content*/}
                <div className="container mx-auto">
                    <div className="px-4">
                        {this.props.children}
                    </div>
                </div>
                {/*filter footer*/}
                <div className="bg-primary-lightest">
                    <div className="container mx-auto py-4 text-right">
                        <div className="uppercase cursor-pointer" onClick={this.props.clearFilter}>clear</div>
                    </div>
                </div>
            </div>
        )
    }
}

class ExcursionTypeFilter extends React.Component {

    render() {
        return (
            <div>
                <ul className="list-style-none flex flex-wrap">
                    {Object.keys(this.props.excursionTypes).map((type) =>
                        <li className="my-2 h-6 w-full sm:w-1/3" key={type}>
                            <Checkbox
                                label={type}
                                checked={this.props.excursionTypes[type].selected}
                                disabled={this.props.excursionTypes[type].disabled}
                                handleCheckboxChange={() => this.props.handleCheckboxChange(type)}
                                key={type}
                            />
                        </li>
                    )}
                </ul>
            </div>
        )
    }
}

class ExcursionDurationFilter extends React.Component {
    render() {
        return (
            <div>
                <ul className="list-style-none sm:flex sm:justify-around">
                    {this.props.options.map((option, index) =>
                        <li className="my-2 h-6" key={index}>
                            <RadioButton
                                label={option.label}
                                _onChange={() => this.props.handleRadioChange(option)}
                                minval={option.minDuration}
                                maxval={option.maxDuration}
                                isSelected={this.props.selectedRadio === option.label}
                                isDisabled={option.disabled}
                            />
                        </li>
                    )}
                </ul>
            </div>
        )
    }
}

class FilterHeader extends React.Component {
    render() {
        const filterOpen = this.props.openFilter === this.props.filterId;

        return (
            <div
                className={"pt-4 flex-1 text-center uppercase border-l-2 border-r border-white cursor-pointer select-none" +
                (filterOpen ? " text-primary bg-white" : " text-white")}
                onClick={() => this.props.toggleVisibility(this.props.filterId)}>
                <div className="mb-4">
                    {this.props.filterName}
                </div>
                <div className="font-bold">
                    {filterOpen ? " \uFE3F " : "\uFE40"}
                </div>
            </div>
        )
    }
}