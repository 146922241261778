import React from 'react';
import PdfItems from '../../components/PdfItems';
import DetailImage from "../../components/DetailImage";
import VideoComponent from "../../components/VideoComponent";
import AudioComponent from "../../components/AudioComponent";
import LocationAndTimes from '../../components/LocationAndTimes';
import Description from "../../components/Description";

class ClassicContent extends React.Component {
    render() {
        return (
            <div>
                <div className="grid-x grid-padding-x">
                    <div className="cell small-12 medium-4 large-4">
                        <DetailImage
                            image={this.props.image}/>
                    </div>
                    <div className="cell small-12 medium-8 large-8 text-left">
                        <h1>
                            <div dangerouslySetInnerHTML={{__html: this.props.title}}/>
                        </h1>
                        <Description textContent={this.props.description}/>
                        { (this.props.whereAndWhen && (this.props.whereAndWhen.locationInformation || this.props.whereAndWhen.timeInformation)) &&
                            <LocationAndTimes whereAndWhen={this.props.whereAndWhen} />
                        }
                        <PdfItems items={this.props.pdfItems}/>

                        <AudioComponent audioItems={this.props.audioItems}/>

                        <VideoComponent videoItems={this.props.videoItems}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default ClassicContent;