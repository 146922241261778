import {
    CLEAR_PM, CLEAR_PM_ORDER,
    SET_PM_CABINIMAGES,
    SET_PM_CART,
    SET_PM_CONFIG, SET_PM_CONSENT,
    SET_PM_DETAIL_IMAGE,
    SET_PM_FETCHING,
    SET_PM_ORDER,
    SET_PM_ORDERS,
} from "../constants/photomatch-action-types";

const INITIAL_STATE = {
    isFetching: false,
    config: [],
    cabinImages: null,
    cart: null,
    detailImage: null,
    order: null,
    orders: [],
    consent:undefined,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_PM_FETCHING:
            return { ...state, isFetching: action.isFetching};

        case SET_PM_CONFIG:
            return { ...state, config: action.config, isFetching: false};

        case SET_PM_CABINIMAGES:
            return { ...state, cabinImages: action.cabinImages, isFetching: false};

        case SET_PM_CART:
            return { ...state, cart: action.cart, isFetching: false};

        case SET_PM_CONSENT:
            return {...state, consent: action.consent};

        case SET_PM_DETAIL_IMAGE:
            return { ...state, detailImage: action.detailImage};

        case SET_PM_ORDER:
            return { ...state, order: action.order, isFetching: false};

        case CLEAR_PM_ORDER:
            return {...state, order: null};

        case SET_PM_ORDERS:
            return { ...state, orders: action.orders, isFetching: false};

        case CLEAR_PM:
            return INITIAL_STATE;

        default:
            return state;
    }
}