import {
    CLEAR_VOD_ASSET,
    CLEAR_VOD_DETAILS,
    CLEAR_VOD_ITEM,
    SET_VOD_ASSET,
    SET_VOD_DETAILS
} from "../constants/voditem-action-types"

const INITIAL_STATE = {vodassets: null, voddetails: null};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_VOD_ASSET:
            return { ...state, vodassets: action.vodassets };
        case SET_VOD_DETAILS:
            return { ...state, voddetails: action.voddetails };
        case CLEAR_VOD_ASSET:
            return { ...state, vodassets: {} };
        case CLEAR_VOD_DETAILS:
            return { ...state, voddetails: {} };
        case CLEAR_VOD_ITEM:
            return INITIAL_STATE;
        default:
            return state;
    }
}
