import * as React from "react";
import Modal from "../../../components/Modal";
import {connect} from "react-redux";
import VodEpisode from './AudioEpisode';
import {fetchEpisodesFromMaxicaster, updateSerie, watchlistAdd, watchlistRemove} from "../../../actions/vodActions";

class AudioSeason extends React.Component {

    componentDidMount() {
        this.props.fetchFromMaxicaster(this.props.match.params.serieid, this.props.match.params.season);
    }

    goToLink(episodeId) {
        let targetLink =  "/vod/detail/"+episodeId;
        this.props.history.push(targetLink);
    }

    async watchlist(episode) {
        if (episode.watchlisted) {

            const success = await this.props.watchlistRemove(episode.id, null);
            if (success) {
                episode.watchlisted = null;

                const serie = this.setEpisodeWatchlistForSerie(this.props.serie, episode);

                this.props.updateSerie(serie);
            }
        } else {

            const success = await this.props.watchlistAdd(episode.id, null);
            if (success) {
                episode.watchlisted = true;

                const serie = this.setEpisodeWatchlistForSerie(this.props.serie, episode);

                this.props.updateSerie(serie);
            }
        }
    };

    setEpisodeWatchlistForSerie(serie, episodeModified) {
        let seasons = serie.series_info.seasons;
        let episodes = seasons[0].episodes;
        for (let i = 0; i < episodes.length; i++) {
            const episode = episodes[i];
            if (episode.id === episodeModified.id) {
                episode.watchlisted = episodeModified.watchlisted;
                break;
            }
        }
        return serie;

    }

    render() {

        if (! this.props.serie) {
            return false;
        }


        const serie = this.props.serie;
        let seasons = serie.series_info.seasons;
        let episodes = seasons[0].episodes;

        let title = serie.title;

        const titleStyle = {
            marginBottom: "1em",
            fontSize: "20pt"
        }


        return (
            <div className="grid-x">
                <Modal show={this.props.isFetching}/>

                <div className="small-12 medium-12 large-12 text-left">
                    <div style={titleStyle}>{title}</div>

                    {
                        episodes.map((episode => {
                            return (
                                <VodEpisode key={episode.id} show_detail={false} vod={episode}/>
                            )
                        }))
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        serie: state.vod.serie,
        isFetching: state.vod.isFetching,
    };
};

export default connect(mapStateToProps, {fetchFromMaxicaster: fetchEpisodesFromMaxicaster, watchlistAdd: watchlistAdd, watchlistRemove: watchlistRemove, updateSerie: updateSerie}) (AudioSeason)