import React from "react";
import {connect} from 'react-redux';
import styled from "styled-components";

const VideoDescription = styled.p`
    margin: 1rem 0 0.5rem;
`;

class VideoComponent extends React.Component {

    render() {
        let videoItems = this.props.videoItems;
        if (!videoItems) {
            return false;
        }

        if(this.props.deviceType === "Kiosk" || this.props.deviceType === "DigitalSignage") {
            return null;
        }

        return (
            <div>
                {
                    this.props.videoItems.map((videoItem) => {
                        return (
                            <div key={videoItem.id} className="cell small-12 medium-8 large-8 text-left">
                                { (videoItem.description && videoItem.description !== "") &&
                                    <VideoDescription>{videoItem.description}</VideoDescription>
                                }
                                <video className="content-video-item" controls preload="auto"
                                       src={videoItem.urlFrontend}
                                >
                                    Your browser does not support the <code>video</code> element.
                                </video>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        deviceType: state.configuration.hubSettings.deviceType,
    };
};

export default connect(mapStateToProps) (VideoComponent)