import React from "react";
import {getTranslation} from "../translations/translationUtils";
import {removeHtmlStyles} from "../utils/stringUtils";

class LocationAndTimes extends React.Component {

    render() {
    // remove whereAndWhen once hub changes are done
        return (
            <div>
                {this.props.whereAndWhen && this.props.whereAndWhen.locationInformation &&
                    <>
                        <h3>Location</h3>
                        <p>{this.props.whereAndWhen.locationInformation}</p>
                    </>
                }
                {this.props.item && this.props.item.times.defaultPlainValue !== "" &&
                    <>
                        <h3>Location</h3>
                        <p>{getTranslation(this.props.item.location)}</p>
                    </>
                }
                {this.props.whereAndWhen && this.props.whereAndWhen.timeInformation &&
                    <>
                        <h3>Times</h3>
                        <p>{this.props.whereAndWhen.timeInformation}</p>
                    </>
                }
                {this.props.item && this.props.item.times.defaultPlainValue !== "" &&
                    <>
                        <h3>Times</h3>
                        <p dangerouslySetInnerHTML={{ __html: removeHtmlStyles(getTranslation(this.props.item.times)) }} />
                    </>
                }
            </div>
        );
    }
}

export default LocationAndTimes;