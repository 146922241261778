import React from 'react';

class UnfilteredHtmlContent extends React.Component {
    render () {
        return (
            <div>
                TODO
            </div>
        );
    }
}

export default UnfilteredHtmlContent;