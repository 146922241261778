import axios from 'axios';
import {CLEAR_VOD_ASSET, CLEAR_VOD_ITEM, SET_VOD_ASSET, SET_VOD_DETAILS} from "../constants/voditem-action-types";
import {
    ADD_VOD_MOVIE,
    CLEAR_VOD_AUTHENTICATION,
    CLEAR_VOD_ERROR,
    MAX_MOVIES_REACHED,
    REMOVE_FROM_WATCHLIST,
    REQUEST_FINISHED,
    REQUEST_STARTED,
    SET_DEVICE_UUID,
    SET_FETCHING,
    SET_VOD_AUTHENTICATION,
    SET_VOD_ERROR,
    SET_VOD_GENRES,
    SET_VOD_MOVIES,
    SET_VOD_SERIE,
    SET_VOD_SERIES,
    SET_VOD_WATCHLIST,
    UPDATE_VOD_MOVIE,
    UPDATE_VOD_SERIES
} from "../constants/vod-action-types";
import {CLEAR_AUTHENTICATION} from "../constants/authentication-action-types";
import uuid from 'uuidv4';
import VodUtils from "../modules/vod/VodUtils"


export function getMaxicasterUrl(store) {
    return store.vodconfiguration.url;
};

export function getApiVersion(store) {
    return store.vodconfiguration.apiVersion;
};


export function useWithCredentials(store) {
    const useWithCredentials = store.vodconfiguration.useWithCredentials ? true : false;
    return useWithCredentials;
};

export function getDeviceUuid(store, dispatch) {
    let deviceUuid = store.vod.deviceUuid;

    if (!deviceUuid) {
        deviceUuid = uuid();
        dispatch({type: SET_DEVICE_UUID, deviceUuid: deviceUuid});
    }
    return deviceUuid;
}

export function getToken(store) {
    return store.vod.maxicastertoken;
}

export function clearVodError() {
    return {type: CLEAR_VOD_ERROR};
}

export function loginToMaxicaster(departureDate, bookingReference, lastName) {

    return async function (dispatch, getStore) {
        dispatch({type: REQUEST_STARTED});

        if (getStore().configuration.developmentMode && departureDate === "") {
            bookingReference = "tui1";
            lastName = "super";
            departureDate = "14/07/2018";
        }


        const appIdentifier = getStore().vodconfiguration.appIdentifier;

        bookingReference = "pax";
        lastName = "kleW73";

        const deviceUuid = getDeviceUuid(getStore(), dispatch);

        const url = getMaxicasterUrl(getStore()) + "customers/loginApp?username="+bookingReference+"&password="+lastName+"&device_uuid="+ deviceUuid+"&device_platform=Android&device_model=DeviceInAirline&device_os_version=6&app_identifier="+appIdentifier+"&api_version="+getApiVersion(getStore());

        return axios({
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            withCredentials: useWithCredentials(getStore())
        })
        .then(function (response) {
            if (response.data.code === 200 && response.data !== "null") {
                dispatch({type: SET_VOD_AUTHENTICATION, maxicastertoken: response.data.token});

                return true;
            }

            dispatch({type: SET_VOD_ERROR, errormessage: extractErrorMessage(response)});
            dispatch({type: CLEAR_AUTHENTICATION});
            return false;
        })
        .catch(function (error) {
            dispatch({type: SET_VOD_ERROR, errormessage: error.message});
            dispatch({type: CLEAR_AUTHENTICATION});
            return false;
        });
    }
}


export function fetchGenresFromMaxicaster(type) {
    return async function (dispatch, getStore) {

        dispatch({type: REQUEST_STARTED});

        let url = getMaxicasterUrl(getStore()) + "vods/getProviders&token="+getToken(getStore())+"&device_uuid="+getDeviceUuid(getStore(), dispatch)+"&api_version="+getApiVersion(getStore())+"&languages=eng";
        url += "&include=genres,logos,vods,vods_images";
        url += "&vod_types=svod";
        url += "&types=" + type;
        url += "&vod_limit=1";
        url = url + "&rentals_active=require";

        return axios({
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            withCredentials: useWithCredentials(getStore())
        })
            .then(function (response) {
                if (response.data.code === 200) {
                    let genres = [];
                    for (let i = 0; i < response.data.data.length; i++) {
                        if (response.data.data[i].id !== 146) { //skip audio lib with id 146
                            genres = genres.concat(response.data.data[i].genres);
                        }
                    }
                    dispatch({type: SET_VOD_GENRES, genres: genres});

                } else {
                    dispatch({type: SET_VOD_ERROR, errormessage: extractErrorMessage(response)});
                }
            })
            .catch(function (error) {
                dispatch({type: SET_VOD_ERROR, errormessage: error.message});
            });
    }
}

export function fetchMoviesFromMaxicaster(searchTerm, resetMovies = false, amountToLoad = 20, genreId = null) {
    return async function (dispatch, getStore) {

        dispatch({type: REQUEST_STARTED});


/*
        let amountLoaded = 0;
        if (!resetMovies && getStore().vod.movies) {
            amountLoaded = getStore().vod.movies.length;
        }
*/


        let url = getMaxicasterUrl(getStore()) + "vods/getVods&token="+getToken(getStore())+"&device_uuid="+getDeviceUuid(getStore(), dispatch)+"&api_version="+getApiVersion(getStore())+"&languages=eng";
        url += "&include=details,images&types=movies";
        url += "&limit="+amountToLoad;
        // url += "&offset="+amountLoaded;

        if (searchTerm !== "") {
            url += "&search="+searchTerm;
        }

        if (genreId) {
            url += "&genre_id="+genreId;
        }

        return axios({
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            withCredentials: useWithCredentials(getStore())
        })
            .then(function (response) {
                if (response.data.code === 200) {
                    if (resetMovies) {
                        dispatch({type: SET_VOD_MOVIES, movies: response.data.data});
                    } else {
                        const movies = response.data.data;
                        if (movies.length === 0) {
                            dispatch({type: REQUEST_FINISHED});
                            dispatch({type: MAX_MOVIES_REACHED});
                        } else {
                            for(let i = 0; i < movies.length; i++) {
                                dispatch({type: ADD_VOD_MOVIE, movie: movies[i]});
                            }
                        }
                    }
                } else {
                    dispatch({type: SET_VOD_ERROR, errormessage: extractErrorMessage(response)});
                }
            })
            .catch(function (error) {
                dispatch({type: SET_VOD_ERROR, errormessage: error.message});
            });
    }
}

export function fetchWatchlistFromMaxicaster() {
    return async function (dispatch, getStore) {

        dispatch({type: SET_VOD_WATCHLIST, watchlistitems: []});
        dispatch({type: REQUEST_STARTED});


        let url = getMaxicasterUrl(getStore()) + "vods/getVods&token="+getToken(getStore())+"&device_uuid="+getDeviceUuid(getStore(), dispatch)+"&api_version="+getApiVersion(getStore())+"&languages=eng";
        url += "&include=details,images";
        url += "&watchlist=require";
        // url += "&types=series";

        return axios({
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            withCredentials: useWithCredentials(getStore())
        })
            .then(function (response) {
                if (response.data.code === 200) {
                    dispatch({type: SET_VOD_WATCHLIST, watchlistitems: response.data.data});
                } else {
                    dispatch({type: SET_VOD_ERROR, errormessage: extractErrorMessage(response)});
                }
            })
            .catch(function (error) {
                dispatch({type: SET_VOD_ERROR, errormessage: error.message});
            });
    }
}


export function fetchSeriesFromMaxicaster(searchTerm, genreId, seriesformat=null) {
    return async function (dispatch, getStore) {

        dispatch({type: SET_VOD_SERIES, series: null});
        dispatch({type: REQUEST_STARTED});

        let url = getMaxicasterUrl(getStore()) + "vods/getVods&token="+getToken(getStore())+"&device_uuid="+getDeviceUuid(getStore(), dispatch)+"&api_version="+getApiVersion(getStore())+"&languages=eng";
        url += "&include=details,images";
        url += "&types=series";

        if (seriesformat !== "all") {
            url += "&series_format=series_seasons";
        }

        if (searchTerm !== "") {
            url += "&search="+searchTerm;
        }

        if (genreId) {
            url += "&genre_id="+genreId;
        }

        return axios({
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            withCredentials: useWithCredentials(getStore())
        })
            .then(function (response) {
                if (response.data.code === 200) {
                    dispatch({type: SET_VOD_SERIES, series: response.data.data});
                } else {
                    dispatch({type: SET_VOD_ERROR, errormessage: extractErrorMessage(response)});
                }
            })
            .catch(function (error) {
                dispatch({type: SET_VOD_ERROR, errormessage: error.message});
            });
    }
}

export function fetchAudioSeriesFromMaxicaster(searchTerm, genreId, seriesformat=null) {
    return async function (dispatch, getStore) {

        dispatch({type: SET_VOD_SERIES, series: null});
        dispatch({type: REQUEST_STARTED});

        let url = getMaxicasterUrl(getStore()) + "vods/getVods&token="+getToken(getStore())+"&device_uuid="+getDeviceUuid(getStore(), dispatch)+"&api_version="+getApiVersion(getStore())+"&languages=eng";

        url += "&include=details,images&provider_ids=146&types=series&series_format=series_seasons&languages=eng";
        // url += "&include=details,images";
        // url += "&types=series";
        //
        // if (seriesformat !== "all") {
        //     url += "&series_format=series_seasons";
        // }
        //
        // if (searchTerm !== "") {
        //     url += "&search="+searchTerm;
        // }
        //
        // if (genreId) {
        //     url += "&genre_id="+genreId;
        // }

        return axios({
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            withCredentials: useWithCredentials(getStore())
        })
            .then(function (response) {
                if (response.data.code === 200) {
                    dispatch({type: SET_VOD_SERIES, series: response.data.data});
                } else {
                    dispatch({type: SET_VOD_ERROR, errormessage: extractErrorMessage(response)});
                }
            })
            .catch(function (error) {
                dispatch({type: SET_VOD_ERROR, errormessage: error.message});
            });
    }
}

export function fetchEpisodesFromMaxicaster(serieId, season) {
    return async function (dispatch, getStore) {

        dispatch({type: SET_VOD_SERIE, serie: null});
        dispatch({type: REQUEST_STARTED});

        let url = getMaxicasterUrl(getStore()) + "vods/getVods&token="+getToken(getStore())+"&device_uuid="+getDeviceUuid(getStore(), dispatch)+"&api_version="+getApiVersion(getStore())+"&languages=eng";
        url += "&include=details,images,assets,features";
        url += "&types=series";
        url += "&series_format=series_episodes";
        url += "&series_ids="+serieId+"&series_seasons="+season;

        return axios({
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            withCredentials: useWithCredentials(getStore())
        })
            .then(function (response) {
                if (response.data.code === 200) {
                    dispatch({type: SET_VOD_SERIE, serie: response.data.data[0]});
                } else {
                    dispatch({type: SET_VOD_ERROR, errormessage: extractErrorMessage(response)});
                }
            })
            .catch(function (error) {
                dispatch({type: SET_VOD_ERROR, errormessage: error.message});
            });
    }
}

export function clearPlayoutAssets() {
    return async function (dispatch, getStore) {
        dispatch({type: CLEAR_VOD_ASSET});
    }
}

export function fetchPlayoutAssets(vodId) {
    return async function (dispatch, getStore) {

        return axios({
            method: 'GET',
            url: getMaxicasterUrl(getStore()) + "vods/getPlayoutAssets&token="+getToken(getStore())+"&device_uuid="+getDeviceUuid(getStore(), dispatch)+"&api_version="+getApiVersion(getStore())+"&vod_id="+vodId,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            withCredentials: useWithCredentials(getStore())
        })
            .then(function (response) {
                if (response.data.code === 200) {
                    const responsedata = response.data.data[0];
                    if (responsedata.url) {
                        responsedata.url = VodUtils.checkAndReplaceProtocol(responsedata.url, getStore().vodconfiguration.url);
                    }
                    dispatch({type: SET_VOD_ASSET, vodassets: responsedata});
                } else {
                    dispatch({type: SET_VOD_ERROR, errormessage: extractErrorMessage(response)});
                }
            })
            .catch(function (error) {
                dispatch({type: SET_VOD_ERROR, errormessage: 'error while retrieving playout assets: '+error.message});
            });
    }
}

export function fetchVodDetails(vodId) {
    return async function (dispatch, getStore) {

        dispatch({type: SET_VOD_DETAILS, voddetails: null});

        return axios({
            method: 'GET',
            url: getMaxicasterUrl(getStore()) + "vods/getVods&token="+getToken(getStore())+"&device_uuid="+getDeviceUuid(getStore(), dispatch)+"&api_version="+getApiVersion(getStore())+"&include=details,images&vod_ids="+vodId,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            withCredentials: useWithCredentials(getStore())
        })
            .then(function (response) {
                if (response.data.code === 200) {
                    const videoDetails = response.data.data[0];
                    dispatch({type: SET_VOD_DETAILS, voddetails: videoDetails});
                    return videoDetails;
                } else {
                    dispatch({type: SET_VOD_ERROR, errormessage: extractErrorMessage(response)});
                    return null;
                }
            })
            .catch(function (error) {
                dispatch({type: SET_VOD_ERROR, errormessage: error.message});
                return null;
            });
    }
}

export function updateSecondsPlayed(vodId, seconds) {
    return async function (dispatch, getStore) {

        return axios({
            method: 'GET',
            url: getMaxicasterUrl(getStore()) + "vods/setPosition&token="+getToken(getStore())+"&device_uuid="+getDeviceUuid(getStore(), dispatch)+"&api_version="+getApiVersion(getStore())+"&vod_id="+vodId+"&position_seconds="+parseInt(seconds, 10),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            withCredentials: useWithCredentials(getStore())
        })
            .then(function (response) {
                if (response.data === "null") {
                    dispatch({type: SET_VOD_ERROR, errormessage: 'error while setting played seconds'});
                    dispatch({type: CLEAR_VOD_ITEM});
                }
            })
            .catch(function (error) {
                dispatch({type: SET_VOD_ERROR, errormessage: error.message});
                return null;
            });
    }
}

export function watchlistAdd(vodId, vodSeriesId) {
    return async function (dispatch, getStore) {

        dispatch({type: REQUEST_STARTED});

        let url = getMaxicasterUrl(getStore()) + "vods/watchlistAdd&token="+getToken(getStore())+"&device_uuid="+getDeviceUuid(getStore(), dispatch)+"&api_version="+getApiVersion(getStore());
        if (vodId) {
            url += "&vod_id=" + vodId;
        }

        if (vodSeriesId) {
            url += "&vod_series_id=" + vodSeriesId;
        }

        return axios({
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            withCredentials: useWithCredentials(getStore())
        })
            .then(function (response) {
                if (response.data.code !== 200) {
                    dispatch({type: SET_VOD_ERROR, errormessage: extractErrorMessage(response)});
                    return false;
                } else {
                    dispatch({type: REQUEST_FINISHED});
                    return true;
                }
            })
            .catch(function (error) {
                dispatch({type: SET_VOD_ERROR, errormessage: error.message});
                return null;
            });
    }
}


export function watchlistRemove(vodId, vodSeriesId) {
    return async function (dispatch, getStore) {
        dispatch({type: REQUEST_STARTED});

        let url = getMaxicasterUrl(getStore()) + "vods/watchlistRemove&token="+getToken(getStore())+"&device_uuid="+getDeviceUuid(getStore(), dispatch)+"&api_version="+getApiVersion(getStore());
        if (vodId) {
            url += "&vod_id=" + vodId;
        }

        if (vodSeriesId) {
            url += "&vod_series_id=" + vodSeriesId;
        }

        return axios({
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            withCredentials: useWithCredentials(getStore())
        })
            .then(function (response) {
                if (response.data.code !== 200) {
                    dispatch({type: SET_VOD_ERROR, errormessage: extractErrorMessage(response)});
                    return false;
                } else {
                    dispatch({type: REQUEST_FINISHED});
                    return true;
                }
            })
            .catch(function (error) {
                dispatch({type: SET_VOD_ERROR, errormessage: error.message});
                return null;
            });
    }
}

export function updateMovie(movie) {
    return async function (dispatch) {
        dispatch({ type: UPDATE_VOD_MOVIE, movie: movie});
    }
}

export function updateSeries(serie) {
    return async function (dispatch) {
        dispatch({ type: UPDATE_VOD_SERIES, serie: serie});
    }
}

export function updateSerie(serie) {
    return async function (dispatch) {
        dispatch({ type: SET_VOD_SERIE, serie: serie});
    }
}

export function removeFromWatchlist(item) {
    return async function (dispatch) {
        dispatch({ type: REMOVE_FROM_WATCHLIST, watchlistitem: item});
    }
}

export function logoutAtMaxicaster() {

    return async function (dispatch, getStore) {
        dispatch({type: REQUEST_STARTED});

        return axios({
            method: 'GET',
            url: getMaxicasterUrl(getStore()) + "/customers/logout&token="+getToken(getStore())+"&device_uuid="+getDeviceUuid(getStore(), dispatch)+"&api_version="+getApiVersion(getStore()),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            withCredentials: useWithCredentials(getStore())
        })
        .then(function (response) {
            if (response !== "null") {
                dispatch({type: SET_VOD_ERROR, errormessage: extractErrorMessage(response)});
                console.log("an error occurred while logging out at maxicaster: ", response);
            }

            dispatch({type: CLEAR_VOD_AUTHENTICATION});
            dispatch({type: CLEAR_AUTHENTICATION});
            dispatch({type: CLEAR_VOD_ITEM});
        })
        .catch(function (error) {
            dispatch({type: SET_VOD_ERROR, errormessage: error.message});
            dispatch({type: CLEAR_VOD_AUTHENTICATION});
            dispatch({type: CLEAR_AUTHENTICATION});
        });
    }
}

export const extractErrorMessage = (response) => {
    let errorMessage = "No detailed message available";
    if (response.data === "null") {
        return errorMessage;
    }

    if (response.data) {
        if (response.data.code !== 200) {
            errorMessage = response.data.data;
        }
    }
    return errorMessage;
};

export function setVodFetching(value) {
    return async function (dispatch, getStore) {
        dispatch({type: SET_FETCHING, isFetching: false});
    }
}
