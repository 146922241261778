import React, {useEffect, useState} from 'react';
import {getFromHubNew} from "../../actions/networkActions";
import Loading from "../../components/Loading";
import PresalesExcursionsList from "../../components/presalesExcursions/PresalesExcursionsList";
import { PropTypes } from 'prop-types';

function OnboardExcursionList(props, context) {
    let url;

    if(props.match.path.startsWith("/onboard/excursionsbyday/")){
        url = `excursions/byDay?day=${props.match.params.day}`;
    }else{
        const dateString = props.match.params.date.replace(/-/g, "/");
        url = `excursions/byDate?dateAsString=${dateString}`;
    }

    // hideHeadersAndFilters if(true) => hides headers, filters
    const hideHeadersAndFilters = props.match.path.startsWith("/onboard/excursionsbyday/");

    const [excursions, setExcursions] = useState([]);
    const [loading, setLoading] = useState(true);

    const locationState = props.location?.state;

    useEffect(() => {
        const fetchData = async () => {
            const excursionsData = await getFromHubNew(url);
            excursionsData.sort(customExcursionSort);

            setExcursions(excursionsData.map(excursion => {
                excursion.excursionTypes = excursion.categories ? excursion.categories.map(category => category.name) : [];
                excursion.currentAvailability = 99;
                excursion.fromTime = excursion.timeOfDay;
                return excursion;
            }));
            setLoading(false);
        }

        fetchData();
    }, [url]);

    if(loading) {
        return (
            <Loading/>
        )
    }

    if(excursions.length === 0) {
        return (
            <div>
                <h3>{context.t("no_experiences_available")}</h3>
            </div>
        )
    }

    return (
        <div>
            <PresalesExcursionsList excursions={excursions} locationState={locationState} hideHeadersAndFilters={hideHeadersAndFilters}/>
        </div>
    );
}

function customExcursionSort(a, b) {
    // take booking code up until the first space
    const aBookingCode = a.bookingCode.split(" ")[0];
    const bBookingCode = b.bookingCode.split(" ")[0];

    // if booking codes are the same, sort by fromTime
    if (aBookingCode === bBookingCode) {
        return a.timeOfDay < b.timeOfDay ? -1 : 1;
    }

    //otherwise sort by booking codes
    return aBookingCode > bBookingCode ? 1 : -1;
}

OnboardExcursionList.contextTypes = {
    t: PropTypes.func.isRequired,
}

export default OnboardExcursionList;